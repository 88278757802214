import {useEffect, useState} from "react"
import {useHistory} from "react-router-dom"
import {pageCache} from "../lib";

interface ICachePageProps {
  backupData: any;
  onRestore: (data:any) => void;
}
export const CachePage: React.FC<ICachePageProps> = ({backupData, onRestore}) => {
  const history = useHistory()

  useEffect(() => {
    // console.log('###### backupData', backupData)
    return(() => {
      // console.log('###### usePageCache backup', backupData)
      if (!!backupData) pageCache.set(JSON.stringify(backupData))
    })
  }, [backupData])

  useEffect(() => {
    // console.log('###### usePageCache effect', history.action)
    if (history.action === 'POP') {
      const cachedStr = pageCache.get()
      // console.log('###### usePageCache restore'， cachedStr)
      try {
        const data = JSON.parse(cachedStr)
        onRestore(data)
        pageCache.clear()
      } catch( err ) {
        console.error(err)
      }
    } else if (history.action === 'PUSH') {
      pageCache.clear()
    }
  }, [])

  return null
}
