import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import {THEME} from "../../config";
import WhiteSpace from "../../components/WhiteSpace";
import NativeSelect from "@mui/material/NativeSelect";
import {Button, InputField, notify} from "../../components";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import {requestGet, requestPost, emailForOrderExport} from "../../lib";
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import {InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import axios from "axios";
import fileDownload from 'js-file-download';
import {isiOS} from "../../utils/common";

const REQ_URL = 'group'

const defaultStatus = ['PRE_PAID', 'PAID', 'CREATED']
const defaultShippingStatus = ['DELIVERED', 'UNDELIVERED']
const defaultCancelStatus = ['NA']

function ExportOrder() {
  const history: any = useHistory()
  const [selectedFromDate, handleFromDateChange] = useState(new Date());
  const [selectedToDate, handleToDateChange] = useState(new Date());
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState<string[] | string>(defaultStatus)
  const [cancelStatus, setCancelStatus] = useState<string[] | string>(defaultCancelStatus)
  const [shippingStatus, setShippingStatus] = useState<string[] | string>(defaultShippingStatus)
  const [downloadUrl, setDownloadUrl] = useState<string>('')
  const initial = async () => {
    const cached = emailForOrderExport.get()
    if (!!cached) {
      setEmail(cached)
    }

    if (history.location?.state?.groupId !== undefined) {
      const result = await requestGet(`${REQ_URL}?id=${history.location?.state?.groupId}`)
      if (!!result?.data?.startAt && dayjs(result.data.startAt).isValid()) {
        handleFromDateChange(dayjs(result.data.startAt).toDate())
      }
    }
  }
  useEffect(() => {
    initial()
  }, [])

  const onEmailChange = (e: any) => {
    setEmail(e.target.value)
    // emailForOrderExport.set(e.target.value)
  }
  return (
    <PageLayout pageName="no nav sales statist">
      <Header title="导出订单"/>
      <Container>
        <div>
          下单时间
        </div>
        <div className='picker-container'>
          <MyDatePicker
            inputFormat="dd/MM/yyyy"
            value={selectedFromDate}
            onChange={handleFromDateChange}
            InputProps={{
              endAdornment: (
                <EventIcon/>
              ),
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <div className='text'>
            至
          </div>
          <MyDatePicker
            inputFormat="dd/MM/yyyy"
            value={selectedToDate}
            onChange={handleToDateChange}
            InputProps={{
              endAdornment: (
                <EventIcon/>
              ),
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <WhiteSpace/>
        <div style={{marginBottom: 8}}>
          订单状态
        </div>
        <SelectContainer>
          <Select
            style={{width: '100%'}}
            multiple
            value={status}
            onChange={e => {
              const value = e.target.value
              if (value.length === 0) {
                setStatus(defaultStatus)
              } else {
                setStatus(e.target.value)
              }
            }}
          >
            <MenuItem value='PRE_PAID'>待确认付款</MenuItem>
            <MenuItem value='PAID'>已付款</MenuItem>
            <MenuItem value='CREATED'>未付款</MenuItem>
            <MenuItem value='EXPIRED'>已失效</MenuItem>
          </Select>
        </SelectContainer>
        <WhiteSpace/>
        <div style={{marginBottom: 8}}>
          发货状态
        </div>
        <SelectContainer>
          <Select
            style={{width: '100%'}}
            multiple
            value={shippingStatus}
            onChange={e => {
              const value = e.target.value
              if (value.length === 0) {
                setShippingStatus(defaultShippingStatus)
              } else {
                setShippingStatus(e.target.value)
              }
            }}
          >
            <MenuItem value='DELIVERED'>已发货</MenuItem>
            <MenuItem value='UNDELIVERED'>未发货</MenuItem>
          </Select>
        </SelectContainer>
        <WhiteSpace/>
        <div style={{marginBottom: 8}}>
          取消状态
        </div>
        <SelectContainer>
          <Select
            style={{width: '100%'}}
            multiple
            value={cancelStatus}
            onChange={e => {
              const value = e.target.value
              if (value.length === 0) {
                setCancelStatus(defaultCancelStatus)
              } else {
                setCancelStatus(e.target.value)
              }
            }}
          >
            <MenuItem value='NA'>未取消</MenuItem>
            <MenuItem value='WAITING_CANCEL_APPROVE'>申请取消</MenuItem>
            <MenuItem value='APPROVED'>已取消</MenuItem>
          </Select>
        </SelectContainer>
        <WhiteSpace/>
        <div style={{marginBottom: 8}}>
          邮箱
        </div>
        <EmailInput className="ft-rr15" type="email" name="email" placeholder="邮箱"
                    value={email} onChange={onEmailChange}
        />
        {downloadUrl ? (
          <React.Fragment>
            <WhiteSpace height={16}/>
            <div style={{textAlign: "center"}}>
            <a style={{color: THEME.color.gray["#11-065"]}} href={downloadUrl}>订单下载链接</a>
            </div>
            <WhiteSpace height={16}/>
          </React.Fragment>
          ) : (
          <WhiteSpace height={64}/>
        )}

        <Button
          style={{flex: 1}}
          fullWidth
          onClick={async () => {
            const start = dayjs(selectedFromDate).startOf('day').format()
            const end = dayjs(selectedToDate).endOf('day').format()
            emailForOrderExport.set(email)
            const {data} = await requestPost('manage/exportOrder', {
              from: start,
              to: end,
              status,
              shippingStatus,
              cancelStatus,
              groupId: history.location?.state?.groupId
            })
            if (data) {
              notify.info('已经创建订单统计数据的下载链接。')
              setDownloadUrl(data)
            }
          }}
        >
          创建下载链接
        </Button>
        <WhiteSpace height={32}/>
        <Button
          style={{flex: 1}}
          fullWidth
          onClick={async () => {
            const start = dayjs(selectedFromDate).startOf('day').format()
            const end = dayjs(selectedToDate).endOf('day').format()
            emailForOrderExport.set(email)
            const {data} = await requestPost('manage/exportOrder', {
              from: start,
              to: end,
              status,
              shippingStatus,
              cancelStatus,
              email,
              groupId: history.location?.state?.groupId
            })
            if (data) {
              notify.info('发送成功')
            }
          }}
        >
          发送到邮箱
        </Button>
      </Container>
    </PageLayout>
  )
}

export default ExportOrder

const Container = styled.div`
  padding: 16px;
  color: ${THEME.color.gray["#0-087"]};

  .picker-container {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      width: 50px;
      text-align: center;
    }
  }

  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);

    .left {
      width: 191px;
      height: 40px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      font-size: 17px;
      line-height: 40px;
      border: none;
      color: white;
      padding-left: 28px;
      background-color: black;
    }

    .right {
      height: 40px;
      width: 104px;
      line-height: 40px;
      border: none;
      text-align: center;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      font-size: 17px;
      color: white;
      background-color: #cc1543;
    }
  }
`

const MyDatePicker = styled(MobileDatePicker)`
  flex: 1;
  color: ${THEME.color.gray["#0-087"]};

  .MuiInput-underline:before {
    border-bottom: none;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):after {
    border-bottom: none;
  }

  &.MuiFormControl-root {
    border: 1px solid #ccc;
  }
`

const SelectContainer = styled.div`

`;

const EmailInput = styled.input`
  width: 100%;
  height: 48px;
  padding: .75em .5em .75em 1em;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #c7c8ca;
  border-radius: .25em;

  &::placeholder {
    color: ${THEME.color.gray["#5-025"]};
  }
`
