import { createContext, useState, useEffect } from "react";
import { requestGet } from "../lib";
import { EXTERNAL_URL } from "../config";
// import { checkResponseBody } from "./helper";

const API_ROOT = EXTERNAL_URL.API_ROOT;

/**
  "CREATED":      "待付款",
  "PER_PAID":     "待确认（线下支付）",
  "PAID":         "已付款",
  "CANCEL_APPLY": "申请取消",
  "CANCELED":     "已取消",
 */
export type OrderPaymentStatusType  = "ALL" | "CREATED" | "PER_PAID" | "PAID" | "CANCEL_APPLY" | "CANCELED" | "EXPIRED";
/**
  "UNDELIVERED"   "未发货",
  "DELIVERED"     "已发货",
 */
export type OrderDeliveryStatusType = "ALL" | "UNDELIVERED" | "DELIVERED";

interface IOrdersContextModel {
  // API: GET@manage/groupStatist
  cancelCount     : number;
  totalPrice      : number;
  totalPriceCNY   : number;
  validOrdersCount: number;

  // API: GET@manage/group
  hasMore         : boolean;
  total           : number;
  totalMoney      : number;
  pendingPaymentCount: number; // 待付款
  prepaidCount    : number; // "待确认",
  paidOrderCount  : number; // "已付款",
  cancelApply     : number; // "申请取消",
  groupInfo       : any;
  records         : any[];

  // Page Control
  page            : number;
  paymentStatus   : OrderPaymentStatusType;
  deliveryStatus  : OrderDeliveryStatusType;
  queryString     : string;
  isDataDirty     : boolean;
}
const DEFAULT_MODEL: IOrdersContextModel = {
  cancelCount     : 0,
  totalPrice      : 0,
  totalPriceCNY   : 0,
  validOrdersCount: 0,

  hasMore         : false,
  total           : 0,
  totalMoney      : 0,
  pendingPaymentCount: 0,
  prepaidCount    : 0,
  paidOrderCount  : 0,
  cancelApply     : 0,
  groupInfo       : {},
  records         : [],

  page            : 1,
  paymentStatus   : 'ALL',
  deliveryStatus  : 'ALL',
  queryString     : '',
  isDataDirty     : false,
};

export const OrdersContext = createContext<any>({});
export const OrdersContextProvider = ({ children }) => {
  const [model, setModel] = useState<IOrdersContextModel>({ ...DEFAULT_MODEL });

  // useEffect(() => {
  //   console.log('### [context] effect')
  //   setModel({
  //     ...model,
  //   });
  // }, []);

  // const resetModel = () => {
  //   console.log('### [context] reset model')
  //   updateModel({
  //     ...DEFAULT_MODEL
  //   })
  // }
  const updateModel = (payload: Partial<IOrdersContextModel>) => {
    // console.log('### [context] update model')
    setModel({
      ...model,
      ...payload,
    });
  }
  const fetchData = async (groupId:number) => {
    // console.log('### [context] fetch data', groupId, model.paymentStatus)
    const {data} = await requestGet("manage/group", {
      groupId,
      page          : model.page,
      queryStr      : model.queryString,
      status        : model.paymentStatus,
      deliveryStatus: model.deliveryStatus,
    });
    // console.log('### fetch data result', data)
    if (!!data) {
      updateModel({
        isDataDirty   : false,
        pendingPaymentCount: data.pendingPaymentCount,
        prepaidCount  : data.prepaidCount,
        paidOrderCount: data.paidOrderCount,
        cancelApply   : data.cancelApply,
        hasMore       : data.hasMore,
        groupInfo     : data.group,
        total         : data.total,
        totalMoney    : data.totalMoney,
        records       : (model.page === 1) ? 
                        [ ...data.orders ] : 
                        [ ...model.records, ...data.orders ]
                        ,
      })
    }
  }
  const initial = async (groupId:number) => {
    // console.log('### [context] initial', groupId)
    if (groupId === undefined) return;
    const {data: data1} = await requestGet("manage/groupStatist", {
      groupId
    });
    const {data: data2} = await requestGet("manage/group", {
      groupId,
      page: 1,
      queryStr: '',
      status: model.paymentStatus,
      deliveryStatus: model.deliveryStatus,
    });
    // console.log('### initial result', data1, data2)
    updateModel({
      ...DEFAULT_MODEL,
      isDataDirty       : false,
      cancelCount       : data1?.canceledCount  ?? model.cancelCount,
      totalPrice        : data1?.totalNZD       ?? model.totalPrice,
      totalPriceCNY     : data1?.totalCNY       ?? model.totalPriceCNY,
      validOrdersCount  : data1?.paidCount      ?? model.validOrdersCount,
      pendingPaymentCount: data2?.pendingPaymentCount ?? model.pendingPaymentCount,
      prepaidCount      : data2?.prepaidCount   ?? model.prepaidCount,
      paidOrderCount    : data2?.paidOrderCount ?? model.paidOrderCount,
      cancelApply       : data2?.cancelApply    ?? model.cancelApply,
      hasMore           : data2?.hasMore        ?? model.hasMore,
      groupInfo         : data2?.group          ?? model.groupInfo,
      total             : data2?.total          ?? model.total,
      totalMoney        : data2?.totalMoney     ?? model.totalMoney,
      records           : !!data2?.orders.length ? [ ...data2?.orders ] : [],
    })
  }
  return (
    <OrdersContext.Provider
      value={{
        model,
        countByPaymentStatus: {
          "CREATED":      model.pendingPaymentCount,
          "PER_PAID":     model.prepaidCount,
          "PAID":         model.paidOrderCount,
          "CANCEL_APPLY": model.cancelApply,
        },
        countByDeliveryStatus: {
          "UNDELIVERED": 0,
          "DELIVERED": 0,
        },

        updateModel,
        initial,
        fetchData,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
