import * as React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import styled from "styled-components";
import { THEME } from "../../config";

export interface INZBankAccountFormatProps extends NumberFormatProps {
  label?: string;
  required?: boolean;
}

const ListInputFieldContainer = styled.div`
  font-family: Roboto;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ListInputContainer = styled.div`
  position: relative;
  flex: 1;
  padding-left: 16px;
  & div {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  & div svg {
    width: 16px;
  }
`;

const ListInputStyled = styled(NumberFormat)`
  width: 100%;
  height: 48px;
  font-size: 15px;
  padding: 0.75em 0.5em 0.75em 1em;
  // border-radius: .25em;
  border: 0px;
  color: ${THEME.color.gray["#0-087"]};
  background: ${(props) => props.background || "auto"};
  text-align: right;
  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    font-size: 15px;
    color: ${THEME.color.gray["#5-025"]};
  }
`;
const Label = styled.p`
  color: ${THEME.color.gray["#0-087"]};
  font-size: 15px;
  line-height: 1.5em;
  margin-bottom: 0.25em;
`;
const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin: 0.5em 0 0.25em;
`;
const Hint = styled.p`
  color: ${THEME.color.gray["#3-76"]};
  line-height: 1.2em;
  margin: 0.5em 0 0.25em 0.4em;
  font-weight: 700;
  font-size: 14px;
`;

export const NZBankAccountFormat = (props: INZBankAccountFormatProps) => {
  const { label, required } = props;
  return (
    <ListInputFieldContainer>
      {!label ? null : (
        <Label>
          {label}
          {required ? (
            <span style={{ color: THEME.color.primary.default }}>*</span>
          ) : null}
        </Label>
      )}
      <ListInputContainer>
        <ListInputStyled format="##-####-#######-###" {...props} />
      </ListInputContainer>
    </ListInputFieldContainer>
  );
};
