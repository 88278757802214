import store from "store2";
import {domain} from "../config";

export const saveToken = (token: string) => {
  store.set('user_jwt', token)
}

export const getToken = () => {
  store.get('user_jwt')
}

export const isLogin = () => {
  return store.has('user_jwt')
}

export const logout = () => {
  store.remove('user_jwt')
}

/**
 *  微信登录后跳转页面存储
 * **/

export const setRedirectUrl = (path: string) => {
  store.set('redirect', path)
}

export const getRedirectUrl = () => {
  const path = store.get('redirect')
  store.remove('redirect')
  if (!path || path === 'https://tuan.shenmanz.com/auth/wechatLogin'){
    return '/'
  }
  return path
}
