import { useState } from 'react'
import { Layout, Header, toast } from '../../Layout'
import { requestPost, requestPut } from '../../lib'
import { useLocationMerchantDataChecker } from '../../hooks'
import { Form } from './Form'
import { REQ_URL } from './helper'
import { IPreferenceRecord } from './type.entity'
import { IFormFields } from './type.form'
import { DEFAULT_RECORD, converEntity2Fields, converFields2Entity } from './common'

export default function Page () {
  const { history, location, merchantId, merchantName } = useLocationMerchantDataChecker()
  const [record] = useState<IPreferenceRecord>(
    ('number' === typeof location?.state?.editData?.id) ?
    location.state.editData : DEFAULT_RECORD
  )

  const toCreateNew = async (data:IPreferenceRecord) => {
    return await requestPost(REQ_URL, data)
  }
  const toUpdate = async (data:IPreferenceRecord) => {
    return await requestPut(REQ_URL, data)
  }
  const onSubmit = async (fields: IFormFields) => {
    // alert(JSON.stringify(data))
    const id          = location.state?.editData?.id;
    const data        = converFields2Entity(fields, merchantId, id);
    const action      = ('number' !== typeof id) ? '创建' : '更新';
    if ('number' !== typeof id) {
      delete data.id;
    }
    // console.log(data)
    // return;
    const result:any = ('number' !== typeof id) ? 
      await toCreateNew(data)
      :
      await toUpdate(data)
    ;
    // console.log(data, result)
    if((result?.code === 0) && ('number' === typeof result.data?.id)) {
      toast.success(`${action}成功`)
      history.go(-1)
    } else {
      toast.error(`${action}失败`)
    }
  }

  // console.log('editData', location?.state?.editData)
  // console.log('record', record)
  const isEditing = ('number' === typeof location?.state?.editData?.id);
  return (
    <Layout>
      <Header title={isEditing ? "编辑优惠券" : "创建优惠券"} />
      <Form
        defaultValues={converEntity2Fields(record)}
        submitHandler={onSubmit}
        merchantName={merchantName}
      >
      </Form>
    </Layout>
  )
}
