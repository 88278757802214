import * as React from "react";
import styled from "styled-components";
import {THEME} from "../../config";
import {EntityContext, UserContext} from "../../context";
import numeral from "numeral";
import {getImageAvatar} from "../../utils/ImageUtils";
import dayjs from "dayjs";
import {Avatar} from "@mui/material";
import OrderCard from "../Manage/components/OrderCard";
import {Button} from "../../components";
import {useState} from "react";
import {requestGet} from "../../lib";
import { checkAnonymousViewData } from './anonymousView'
export interface IOrderListProps {
  showMore?: boolean;
  primaryColor?: string
}

const getOrderStatusStr = (order) => {
  const {status, cancelStatus} = order;
  if (cancelStatus === "WAITING_CANCEL_APPROVE") {
    return "申请取消";
  }
  switch (status) {
    case "CANCELED": {
      return "已取消";
    }
    case "PAID": {
      return "已支付";
    }
    case "PRE_PAID": {
      return "待确认支付";
    }
    case "PROCESSING": {
      return "处理中";
    }
    case "READY": {
      return "待取货";
    }
    case "DONE": {
      return "已取货";
    }
    case "CREATED": {
      return "待付款";
    }
  }
};

const StyledCardCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .index {
      width: 30px;
      font-size: 12px;
      color: ${THEME.color.gray["#10-085"]};
      text-align: left;
    }

    .avatar {
      width: 32px;

      img {
        border-radius: 16px;
        height: 32px;
        width: 32px;
      }
    }

    .nameAndTime {
      flex: 1;
      margin-left: 4px;
      font-size: 12px;

      .name {
        color: ${THEME.color.gray["#10-085"]};
      }

      .time {
        color: ${THEME.color.gray["#12-045"]};
      }
    }

    .status {
      width: 60px;
      color: ${THEME.color.primary.default};
      font-size: 12px;
    }
  }

  .address {
    font-size: 12px;
    margin-left: 56px;
    margin-top: 16px;
    line-height: 16px;

    .title {
      font-weight: 500;
      color: ${THEME.color.gray["#10-085"]};
    }

    .contact {
      font-weight: 400;
      color: ${THEME.color.gray["#10-085"]};
    }

    .format-address {
      color: ${THEME.color.gray["#12-045"]};
    }
  }

  .products {
    margin-top: 16px;
    margin-left: 56px;
    font-size: 12px;
    line-height: 18px;
    color: ${THEME.color.gray["#10-085"]};
    font-weight: 400;

    .product {
    }

    .note {
      font-weight: 500;
    }
  }

  .price {
    text-align: right;
    font-size: 15px;
    color: ${THEME.color.primary.default};
  }

  .op-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;

    .btn {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.45);
      box-sizing: border-box;
      border-radius: 16px;
      padding: 3px 8px;
      font-size: 12px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.65);
      margin: 0 4px 0px 4px;
    }
  }
`;

const OtherOrderCard = (props) => {
  const {isMine, order } = props;

  const getOrderPrice = () => {
    if (props.order.status === "PAID") {
      const transaction = props.order.transactions[0];
      if (transaction.currency === "CNY") {
        return numeral(props.order.totalCNYPrice / 100).format("￥0,0.00");
      }
    }
    return numeral(props.order.totalPrice / 100).format("$0,0.00");
  };

  const { avatarLink, userName } = checkAnonymousViewData(!!props.order.isAnonymous, props.order.user || {})

  return (
    <StyledCardCard>
      <div className="header">
        <div className="index">{props.order.groupIndex}</div>
        <div className="avatar">
          <Avatar style={{width: 32, height: 32}} src={avatarLink} alt="" />
        </div>
        <div className="nameAndTime">
          <div className="name">{userName}</div>
        </div>
        {isMine && (
          <div className="status">{getOrderStatusStr(props.order)}</div>
        )}
      </div>
      {props.order.shipType === "PICKUP" && isMine && (
        <div className="address">
          <div className="title">自提 · ${props.order.address.addressName}</div>
          <div>
            {`${props.order.address.contactName} ${props.order.address.telephone}`}
          </div>
        </div>
      )}
      {props.order.shipType === "DELIVERY" && isMine && (
        <div className="address">
          <div className="title">配送</div>
          <div className="contact">
            {`${props.order.address.contactName} ${props.order.address.telephone}`}
          </div>
          <div className="format-address">
            {props.order.address.formattedAddress}
          </div>
        </div>
      )}
      <div className="products">
        {props.order.productions
          ? props.order.productions.map((item, index) => {
            return (
              <div className="product" key={index}>
                {item.title} x {item.amount}
              </div>
            );
          })
          : null}
        {/*{props.order.note && <div className="note">备注：{props.order.note}</div>}*/}
      </div>
      {isMine && <div className="price">{getOrderPrice()}</div>}
      {isMine && (
        <div className="op-container">
          {props.order.status === "CREATED" && (
            <div className="btn">取消订单</div>
          )}
          {props.order.status === "CREATED" && (
            <div className="btn">立即支付</div>
          )}
        </div>
      )}
    </StyledCardCard>
  );
};

const Root = styled.div`
  margin-top: 32px;

  .list-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 16px;
  }
`;

export default function OrderList(props: IOrderListProps) {
  const {model: userModel, logout: userLogout} =
    React.useContext(UserContext);
  const {model: entityModel, query} = React.useContext(EntityContext);
  const groupData: any = entityModel["GROUP_BUY"].response?.data || {};
  const [orders, setOrders] = useState<any[]>(groupData.orders || []);
  const [hasMore, setHasMore] = useState(groupData.hasMore);
  const [page, setPage] = useState(1);
  const id = userModel?.profile?.id;

  const loadMore = async () => {
    if (!hasMore) {
      return;
    }
    const {data} = await requestGet("group/loadMore", {
      id: groupData.id,
      page,
    });
    if (data) {
      setOrders([...orders, ...data.orders]);
      setHasMore(data.hasMore);
      if (data.hasMore) {
        setPage(page + 1);
      }
    }
  };

  React.useEffect(() => {
    if (groupData) {
      setOrders(groupData.orders);
    }
  }, [groupData]);

  if (!orders || orders.length === 0) {
    return null
  }

  const anyOrderPlaced = orders.map((x) => x.userId).includes(id)
  const showNull = (groupData.status === "FINISHED") && (!anyOrderPlaced) && (!groupData.role)
  return (
    showNull ? null :
    <Root id="order">
      {orders && orders.length > 0 && (
        <div className="list-title">订单列表</div>
      )}
      {orders && orders.length > 0
        ? orders.map((o, index) => {
          if (o.userId !== id) {
            return (
              <OtherOrderCard
                key={`${o.id}_${index}`}
                isMine={false}
                order={o}
                // groupRole={groupData?.role}
              />
            );
          }
          return (
            <div key={o.id} style={{background: "rgba(204, 21, 67, 0.18)"}}>
              <OrderCard
                type="USER"
                actions={[]}
                onRefresh={async () => {
                  // setOrders([])
                  // setPage(1)
                  // await loadMore()
                  // 貌似可以废弃了， orderCard内单独刷新了订单数据
                }}
                group={groupData}
                order={o}
              />
            </div>
          );
        })
        : null}
      {hasMore && (
        <div
          style={{
            marginTop: 16,
            paddingLeft: 16,
            paddingRight: 16,
            marginBottom: 16,
          }}
        >
          <Button
            primaryColor={props.primaryColor}
            fullWidth
            onClick={() => {
              loadMore();
            }}
          >
            加载更多
          </Button>
        </div>
      )}
    </Root>
  );
}
