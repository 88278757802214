import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconKBArrowD } from './Icons';
import { THEME } from '../config';

const SelectContainer = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${(props) => props.background || '#fff'};
  position: relative;

  & svg {
    width: 24px;
    fill: rgba(0,0,0, 0.45);
  }

  &.open {
    border: none;
  }
`;
const ArrowIconBlock = styled.div`
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
`;

const OptionList = styled.div`
  position: absolute;
  overflow: scroll;
  max-height: 256px;
  padding: 0 16px;
  width: 100%;
  top: 0;
  left: 0;
  transform: ${(props) => (props.variant === 'drop-down') ? 'translateY(0)' : 'translateY(calc(-100% + 56px))'};
  background: ${(props) => props.background || '#fff'};
  border-radius: 4px;
  border: 1px solid #ddd;
`;

export const SelectOption = styled.div`

  /* border: 1px solid #0d0; */
  &.in-list {
    padding: 12px 0;
    line-height: 32px;
  }

  & ~ & {
    border-top: 1px solid #ddd;
  }
`;

interface SelecctProps {
  children?: any;
  value?: string | number;
  onChange?: (value: any) => void;
  onClick?: () => void;   // 如果传入 onClick 回调函数，那么这个控件将不具备选择功能
  background?: string;
  variant?: "drop-down";
}
export const SelectPopUp: React.FC<SelecctProps> = ({ children, value, onChange, onClick, background, variant }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (currentValue !== value) setCurrentValue(value)
  }, [value])

  const subItems =  (children instanceof Array) ? children :
                    ('object' === typeof children) ? [children] :
                    []
                    ;
  const currentOption = subItems.filter(item => item.props.value === currentValue);

  const onSelect = (newValue) => () => {
    setCurrentValue(newValue);
    setOpen(false);

    if (!!onChange) onChange(newValue);
  }

  return (
    <SelectContainer onClick={ !!onClick ? onClick : (() => setOpen(!open)) } className={!!open && 'open'} background={background}>
    { !!currentOption?.length && currentOption }
    { !!open && 
      <OptionList background={background} variant={variant}>
      {
        subItems.map((child, index) => {
          const newChild = React.cloneElement(child, { 
            ...child?.props,
            className: 'in-list',
            onClick: onSelect(child.props.value)
          });
          return(newChild);
        })
      }
      </OptionList>
    }
      <ArrowIconBlock>
        <IconKBArrowD />
      </ArrowIconBlock>
    </SelectContainer>
  )
}
