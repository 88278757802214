import * as React from "react";
import parse from "html-react-parser";
import { InputField } from "../../components";
import { Alert, TextareaAutosize } from "@mui/material";
import styled from "styled-components";
import ImageUploader from "../../components/ImageUploader";
import { getImageById, getImageByIdWithSize } from "../../utils/ImageUtils";
import { THEME } from "../../config";
import { useDialog } from "../../hooks";
export interface IImageNTextComponentProps {
  value: string;
  onChange: (value: string) => void;
}

interface IParsedData {
  id: number;
  type: "BIG_IMAGE" | "SMALL_IMAGE" | "TEXT";
  content: string[];
}

const imageNodeToUrl = (domNodes: any[]) => {
  const imgNodes = domNodes.filter((d) => d.type === "tag" && d.name === "img");
  if (imgNodes.length > 0) {
    return imgNodes.map((d) => d.attribs.src);
  }
  return [];
};

const Root = styled.div`
  textarea {
    font-family: Roboto;
    font-size: 16px;
    width: 100%;
    padding: 0.75em 0.5em 0.75em 1em;
    resize: none;
    border-radius: 0.25em;
    border: ${(props) =>
      props.error ? `1px solid ${THEME.color.primary.default}` : "none"};
    color: ${THEME.color.gray["#0-087"]};
    background: ${(props) => props.background || "auto"};
    overflow-y: hidden;
    &:focus {
      border: 1px solid ${THEME.color.primary.default};
      outline: none;
    }
    &::placeholder {
      color: ${THEME.color.gray["#5-025"]};
    }
    min-height: 4em;
  }
`;

const StyledDescCardHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0 4px 0;
  .op-btn {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1px 8px;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 2px 0 2px;
    line-height: 16px;
    font-size: initial;
    span {
      font-size: 10px;
    }
  }
  .desc-type {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.45);
  }
  .flex-row {
    display: flex;
    flex-direction: row;
  }
`;

const DescCardHead = ({ index, size, onSwap, onDelete, type }) => {
  if (size <= 1) {
    return null;
  }
  return (
    <StyledDescCardHead>
      <span className="desc-type">
        {type === "TEXT" ? "文字" : type === "BIG_IMAGE" ? "大图" : "小图"}
      </span>
      <div className="flex-row">
        {index > 0 && (
          <div
            className="op-btn"
            onClick={() => {
              onSwap(index, index - 1);
            }}
          >
            <span>上移</span>
          </div>
        )}
        {index < size - 1 && (
          <div
            className="op-btn"
            onClick={() => {
              onSwap(index, index + 1);
            }}
          >
            <span>下移</span>
          </div>
        )}
        <div
          className="op-btn"
          onClick={() => {
            onDelete(index);
          }}
        >
          <span>删除</span>
        </div>
      </div>
    </StyledDescCardHead>
  );
};

const StyledOperationFooter = styled.div`
  margin-top: 8px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 24px 20px 24px;
    .text {
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 4px;
    }
  }
`;

const IconText = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2V16H16V2H2ZM1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM6.869 12L6.049 14H3.833L8 4H10L14.167 14H11.95L11.13 12H6.87H6.869ZM7.689 10H10.311L9 6.8L7.689 10Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);

const IconBigImage = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0ZM16.5 16.5H1.5V12L5.25 8.25L9.4425 12.4425C9.72354 12.7219 10.1037 12.8787 10.5 12.8787C10.8963 12.8787 11.2765 12.7219 11.5575 12.4425L12.75 11.25L16.5 15V16.5ZM16.5 12.8775L13.8075 10.185C13.5265 9.90562 13.1463 9.74881 12.75 9.74881C12.3537 9.74881 11.9735 9.90562 11.6925 10.185L10.5 11.3775L6.3075 7.185C6.02646 6.90562 5.64628 6.74881 5.25 6.74881C4.85372 6.74881 4.47354 6.90562 4.1925 7.185L1.5 9.8775V1.5H16.5V12.8775Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);

const IconSmallImage = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 0.75H1.5C1.30109 0.75 1.11032 0.829018 0.96967 0.96967C0.829018 1.11032 0.75 1.30109 0.75 1.5V7.5C0.75 7.69891 0.829018 7.88968 0.96967 8.03033C1.11032 8.17098 1.30109 8.25 1.5 8.25H7.5C7.69891 8.25 7.88968 8.17098 8.03033 8.03033C8.17098 7.88968 8.25 7.69891 8.25 7.5V1.5C8.25 1.30109 8.17098 1.11032 8.03033 0.96967C7.88968 0.829018 7.69891 0.75 7.5 0.75ZM6.75 6.75H2.25V2.25H6.75V6.75ZM16.5 0.75H10.5C10.3011 0.75 10.1103 0.829018 9.96967 0.96967C9.82902 1.11032 9.75 1.30109 9.75 1.5V7.5C9.75 7.69891 9.82902 7.88968 9.96967 8.03033C10.1103 8.17098 10.3011 8.25 10.5 8.25H16.5C16.6989 8.25 16.8897 8.17098 17.0303 8.03033C17.171 7.88968 17.25 7.69891 17.25 7.5V1.5C17.25 1.30109 17.171 1.11032 17.0303 0.96967C16.8897 0.829018 16.6989 0.75 16.5 0.75ZM15.75 6.75H11.25V2.25H15.75V6.75ZM7.5 9.75H1.5C1.30109 9.75 1.11032 9.82902 0.96967 9.96967C0.829018 10.1103 0.75 10.3011 0.75 10.5V16.5C0.75 16.6989 0.829018 16.8897 0.96967 17.0303C1.11032 17.171 1.30109 17.25 1.5 17.25H7.5C7.69891 17.25 7.88968 17.171 8.03033 17.0303C8.17098 16.8897 8.25 16.6989 8.25 16.5V10.5C8.25 10.3011 8.17098 10.1103 8.03033 9.96967C7.88968 9.82902 7.69891 9.75 7.5 9.75ZM6.75 15.75H2.25V11.25H6.75V15.75ZM16.5 9.75H10.5C10.3011 9.75 10.1103 9.82902 9.96967 9.96967C9.82902 10.1103 9.75 10.3011 9.75 10.5V16.5C9.75 16.6989 9.82902 16.8897 9.96967 17.0303C10.1103 17.171 10.3011 17.25 10.5 17.25H16.5C16.6989 17.25 16.8897 17.171 17.0303 17.0303C17.171 16.8897 17.25 16.6989 17.25 16.5V10.5C17.25 10.3011 17.171 10.1103 17.0303 9.96967C16.8897 9.82902 16.6989 9.75 16.5 9.75ZM15.75 15.75H11.25V11.25H15.75V15.75Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);

const OperationFooter = ({ onInsert }) => {
  const operations = [
    {
      id: 1,
      icon: <IconText />,
      text: "插入文字",
      onClick: () => {
        onInsert("TEXT");
      },
    },
    {
      id: 2,
      icon: <IconBigImage />,
      text: "插入大图",
      onClick: () => {
        onInsert("BIG_IMAGE");
      },
    },
    {
      id: 3,
      icon: <IconSmallImage />,
      text: "插入小图",
      onClick: () => {
        onInsert("SMALL_IMAGE");
      },
    },
  ];

  return (
    <StyledOperationFooter>
      {operations.map((op) => (
        <div key={op.id} onClick={op.onClick} className="item">
          {op.icon}
          <span className="text">{op.text}</span>
        </div>
      ))}
    </StyledOperationFooter>
  );
};

export default function ImageNTextComponent(props: IImageNTextComponentProps) {
  const { value, onChange } = props;

  const { renderDialog, showDialog, hideDialog } = useDialog();

  const [parsedData, setParsedData] = React.useState<IParsedData[]>([]);

  const swapItem = (idx1, idx2) => {
    const newParsedData = [...parsedData];
    const temp = newParsedData[idx1];
    newParsedData[idx1] = newParsedData[idx2];
    newParsedData[idx2] = temp;
    setParsedData(newParsedData);
  };

  const deleteItem = (index) => {
    showDialog({
      title: "确定删除?",
      cancelText: "取消",
      okText: "确定",
      onOK: () => {
        let newParsedData = [...parsedData];
        newParsedData = newParsedData.filter((v, idx) => idx !== index);
        setParsedData(newParsedData);
        hideDialog();
      },
    });
  };

  React.useEffect(() => {
    if (!value) {
      setParsedData([{ type: "TEXT", content: [], id: 1 }]);
      return;
    }
    let tempData: IParsedData[] = [];
    parse(value, {
      replace: (domNode: any) => {
        if (
          domNode.attribs &&
          (domNode.attribs.class === "text" ||
            domNode.attribs.class === "big-image" ||
            domNode.attribs.class === "small-image")
        ) {
          if (domNode.attribs.class === "text") {
            tempData.push({
              id: domNode.attribs.id,
              type: "TEXT",
              content: [
                domNode?.children[0]?.data ? domNode?.children[0]?.data : "",
              ],
            });
          }
          if (domNode.attribs.class === "big-image") {
            tempData.push({
              id: domNode.attribs.id,
              type: "BIG_IMAGE",
              content: imageNodeToUrl(domNode.children),
            });
          }
          if (domNode.attribs.class === "small-image") {
            tempData.push({
              id: domNode.attribs.id,
              type: "SMALL_IMAGE",
              content: imageNodeToUrl(domNode.children),
            });
          }
        }
        return null;
      },
    });
    if (tempData.length === 0) {
      tempData.push({ type: "TEXT", content: [], id: 1 });
    }
    setParsedData(tempData);
  }, [value]);

  const insertItem = (type: "BIG_IMAGE" | "SMALL_IMAGE" | "TEXT") => {
    const newParsedData = [...parsedData];
    newParsedData.push({ type, content: [], id: newParsedData.length + 1 });
    setParsedData(newParsedData);
  };

  const updateItem = (index, content) => {
    let newParsedData = [...parsedData];
    const item = { ...newParsedData[index], content };
    newParsedData[index] = item;
    setParsedData(newParsedData);
  };

  const reactData2Html = () => {
    if (parsedData.length === 0) {
      return "";
    }

    return parsedData
      .map((pd) => {
        if (pd.type === "TEXT") {
          return `<p key='${pd.id}'><div id="${pd.id}" class="text">${
            pd.content[0] ? pd.content[0] : ""
          }</div></p>`;
        } else if (pd.type === "BIG_IMAGE") {
          return `<p key='${pd.id}'><div id="${pd.id}" class="big-image"> ${
            pd.content.length > 0
              ? `<img key='${pd.id}' src='${getImageByIdWithSize(
                  pd.content[0],
                  undefined,
                  375 * 3
                )}' />`
              : ""
          }</div></p>`;
        } else if (pd.type === "SMALL_IMAGE") {
          return `<p key='${pd.id}'><div id="${pd.id}" class="small-image">${
            pd.content.length > 0
              ? pd.content
                  .map(
                    (imgUrl) =>
                      `<img key='${pd.id}-${imgUrl}' src="${getImageByIdWithSize(
                        imgUrl,
                        undefined,
                        240 * 3,
                        240 * 3
                      )}" />`
                  )
                  .join(" ")
              : ""
          }</div></p>`;
        }
        return `<p key='${pd.id}'></p>`;
      })
      .reduce((v1, v2) => v1 + v2, "");
  };

  React.useEffect(() => {
    onChange(reactData2Html());
  }, [parsedData]);

  return (
    <Root>
      {parsedData.length > 0
        ? parsedData.map((pd, index) => {
            if (pd.type === "TEXT") {
              return (
                <div key={pd.id}>
                  <DescCardHead
                    type={pd.type}
                    index={index}
                    size={parsedData.length}
                    onSwap={swapItem}
                    onDelete={deleteItem}
                  />
                  <TextareaAutosize
                    key={pd.id}
                    // style={{ width: "100%" }}
                    value={pd.content[0]}
                    placeholder="请输入拼团介绍"
                    //minRows={3}
                    onChange={(e) => {
                      const newParsedData = [...parsedData];
                      const data = parsedData[index];
                      newParsedData[index] = {
                        ...data,
                        content: [e.target.value],
                      };
                      setParsedData(newParsedData);
                    }}
                  />
                </div>
              );
            } else if (pd.type === "SMALL_IMAGE") {
              return (
                <div key={pd.id}>
                  <DescCardHead
                    index={index}
                    type={pd.type}
                    size={parsedData.length}
                    onSwap={swapItem}
                    onDelete={deleteItem}
                  />
                  <ImageUploader
                    uniqueId={`upload-btn-${index}`}
                    row={3}
                    maxLength={6}
                    fileList={pd.content}
                    onValueChanged={(fileList) => {
                      updateItem(index, fileList);
                    }}
                  />
                </div>
              );
            } else if (pd.type === "BIG_IMAGE") {
              return (
                <div key={`${pd.id}-${pd.content}`}>
                  <DescCardHead
                    index={index}
                    type={pd.type}
                    size={parsedData.length}
                    onSwap={swapItem}
                    onDelete={deleteItem}
                  />
                  <ImageUploader
                    uniqueId={`upload-btn-${index}`}
                    row={1}
                    maxLength={1}
                    fileList={pd.content}
                    onValueChanged={(fileList) => {
                      updateItem(index, fileList);
                    }}
                  />
                </div>
              );
            }
          })
        : null}
      <OperationFooter onInsert={insertItem} />
      {renderDialog()}
    </Root>
  );
}
