import React from 'react';
import styled from 'styled-components';
import { THEME } from '../../config';

const BROWSER_MODE_THRETHOLD    = THEME.size.BROWSER_MODE_THRESHOLD;
const SECTION_CONTENT_MAX_WIDTH = THEME.size.SECTION_CONTENT_MAX_WIDTH;
const SIDE_MARGIN               = THEME.size.SIDE_MARGIN_WIDTH;

const Segment = styled.section`
  width: 100%;
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0'};
  height: ${(props) => props.height || 'auto'};
  background: ${(props) => props.background || 'transparent'};
`;

const StretchColumn = styled.div`
  margin: 0 ${SIDE_MARGIN};
  background: ${(props) => props.background || 'transparent'};
  &.right-through {
    margin: 0 0 0 ${SIDE_MARGIN};
  }
  @media screen and (min-width: ${BROWSER_MODE_THRETHOLD}) {
    margin: 0 ${SIDE_MARGIN};
    max-width: ${SECTION_CONTENT_MAX_WIDTH};
    &.right-through {
      margin: 0 ${SIDE_MARGIN};
    }
  }
  /* @media screen and (min-width: ${SECTION_CONTENT_MAX_WIDTH}) {
    width: ${SECTION_CONTENT_MAX_WIDTH};
    margin: 0 auto;
    &.right-through {
      margin: 0 auto;
    }
  } */
  @media screen and (min-width: ${(props) => (props.fixedColumnWidth)}) {
    width: ${(props) => props.fixedColumnWidth};
    margin: 0 auto;
    &.right-through {
      margin: 0 auto;
    }
  }
`;

interface SectionProps {
  children?: any;
  variant?: 'segment' | 'right-through';
  foreground?: string;
  colMaxWidth?: string;

  background?: string;
  height?: string;
  margin?: string;
  padding?: string;
  className?: string;
  style?: any;
}
export const Region: React.FC<SectionProps> = ({ children, variant, foreground, colMaxWidth, ...restProps }) => (
  (variant === 'segment') ?
  <Segment {...restProps} >
  { children }
  </Segment>
  :
  <Segment {...restProps} >
    <StretchColumn className={`${!!variant ? variant : ''}` } background={foreground} fixedColumnWidth={ colMaxWidth || SECTION_CONTENT_MAX_WIDTH } >
    { children }
    </StretchColumn>
  </Segment>
)
