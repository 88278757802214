import {useEffect, useState} from "react";
import * as React from "react";
import {useForm, Controller, useWatch} from "react-hook-form";
import WhiteSpace from "../../components/WhiteSpace";
import {LOCAL_URL, THEME} from "../../config";
import {isEmpty, requestGet, requestPost} from "../../lib";
import {Item} from "../CreateGroupBuy";
import PageLayout from "../Global/PageLayout";
import {CachePage, Button, InputField, ListInputField, IntegerInputField, notify} from "../../components";
import ImageUploader from "../../components/ImageUploader";
import styled from "styled-components";
import {EntityContext} from "../../context";
import {useHistory} from "react-router-dom";
import Header, {FloatBottom} from "../../components/Header";
import numeral from "numeral";
import {Radio} from "../../forms/Radio";
import useMerchant from "../../hooks/useMerchant";
import {CategoryInput, ICategoryPartialEntity} from './Category'
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

export interface ICreateProductProps {
}

type FormData = {
  id?: number;
  title: string;
  description: string;
  images?: string[];
  price: number;
  originalPrice?: number;
  priceCNY?: number;
  defaultStock: number;
  maxOrderQuantity?: number;
  specification?: string;
  saveToLib?: boolean;

  // added by Chris
  category?: ICategoryPartialEntity;
};

const StyledForm = styled.form`
  padding: 8px;

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .save-to-lib {
    font-size: 15px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    margin: 16px 0 16px 0;
  }
`;

const StyledProductSetting = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 8px 0px 8px;

  .stock-info {
    text-align: right;
  }

  .stock-title {
    font-size: 12px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.45);
  }

  .stock-value {
    color: #cc1543;
  }
`;
const Divider = styled.div`
  height: 8px;
`;

const DEFAULT_STOCK_NUM = 1000

export default function CreateProduct(props: ICreateProductProps) {
  const history = useHistory<any>();

  const fromGroupPage = history.location?.state?.fromGroupPage; // 是否是从“创建团购”页面跳转过来的
  const saveToGroup: boolean = history.location?.state?.saveToGroup; // 是否保存至团购
  const [sold, setSold] = useState(0)
  const [unpaid, setUnpaid] = useState(0)
  const [paid, setPaid] = useState(0)
  const [available, setAvailable] = useState(DEFAULT_STOCK_NUM)
  const [categoryState, setCategoryState] = useState([])

  const productData = history.location?.state?.product;

  const {currentMerchantId, isCurrentMerchantSupportCNY} = useMerchant();

  const schema = yup.object().shape({
    title: yup.string().required("商品名称不能为空"),
    price: yup.string().required("商品价格不能为空"),
    priceCNY: isCurrentMerchantSupportCNY()
      ? yup.string().required("商品价格不能为空")
      : yup.string().optional(),
    maxOrderQuantity: yup.number().typeError('限购需要是个数字').nullable(true).transform((nV, sV) => (
      (sV === '') ? null : nV
    )),
    defaultStock:
      fromGroupPage && history.location?.state?.values?.available
        ? yup
          .number()
          .transform((_, val) => (val === "" ? -1 : Number(val)))
        : yup.string().required("库存不能为空"),
  });

  const {register, handleSubmit, reset, control, errors, clearErrors, watch, setError} =
    useForm<FormData>({
      defaultValues: {
        id: productData ? productData.id : undefined,
        title: productData ? productData.title : "",
        description: productData ? productData.description : "",
        images: productData ? productData.images : [],
        price:
          productData && productData.price
            ? numeral(productData.price).divide(100).format("0.00")
            : undefined,
        originalPrice:
          productData && productData.originalPrice
            ? numeral(productData.originalPrice).divide(100).format("0.00")
            : undefined,
        defaultStock:
          productData && productData.defaultStock
            ? productData.defaultStock
            : 1000,
        maxOrderQuantity: productData?.maxOrderQuantity,
        specification: productData ? productData.specification : undefined,
        saveToLib: true,
        priceCNY:
          productData && productData.priceCNY
            ? numeral(productData.priceCNY).divide(100).format("0.00")
            : undefined,
        category: productData?.category,
      },
      mode: "onChange",
      resolver: yupResolver(schema),
    });

  const setFormFields = ({
                           id,
                           title,
                           description,
                           images,
                           price,
                           originalPrice,
                           priceCNY,
                           defaultStock,
                           specification,
                           maxOrderQuantity,
                           category,
                           available
                         }: any, formatMoney: boolean) => {
    control.setValue("title", title);
    control.setValue("description", description);
    control.setValue("images", images);
    control.setValue(
      "price",
      !price ? "" :
        !formatMoney ? price :
          numeral(price).divide(100).format("0.00")
    );
    control.setValue(
      "originalPrice",
      !originalPrice ? "" :
        !formatMoney ? originalPrice :
          numeral(originalPrice).divide(100).format("0.00")
    );
    control.setValue(
      "priceCNY",
      !priceCNY ? "" :
        !formatMoney ? priceCNY :
          numeral(priceCNY).divide(100).format("0.00")
    );
    control.setValue("maxOrderQuantity", maxOrderQuantity || null);
    control.setValue("defaultStock", defaultStock ? (defaultStock === -1 ? '' : defaultStock) : DEFAULT_STOCK_NUM);
    control.setValue("specification", specification);
    control.setValue("id", id);
    if (category) {
      setCategoryState(category)
    }
    setAvailable(available)
    setPaid(paid)
  }

  const onRestore = (data: any) => {
    if (!!data) setFormFields(data, false)
  }

  const watchStock = watch("defaultStock");

  const {
    model: entityModel,
    query,
    create,
    flush,
    update,
    replace,
  } = React.useContext(EntityContext);

  const onSumbit = async (data) => {
    const {defaultStock} = data
    if ((available < 0 && defaultStock > 0) || defaultStock < 0) {
      console.log(data)
      notify.error('可售数量小于0，请重新设置库存')
      setError("defaultStock", {
        type: "custom",
        message: "可售数量不能小于0",
      })
      return
    }
    const {saveToLib} = data;
    if (fromGroupPage) {
      // 先保存，再返回
      const id = data.id || new Date().getTime();
      const {originalPrice, price, priceCNY} = data;
      let product = {
        ...data,
        merchantId: currentMerchantId,
        id,
        originalPrice: originalPrice ? Number(originalPrice) * 100 : undefined,
        priceCNY: priceCNY ? Number(priceCNY) * 100 : undefined,
        price: price ? Number(price) * 100 : undefined,
      };
      if (saveToLib) {
        product = await create("PRODUCTION_TEMPLATE", {
          ...data,
          merchantId: currentMerchantId,
        });
        await query("GROUP_BUY_LIST", {id: currentMerchantId});
      }

      const groupToProductionId =
        history.location?.state?.values?.groupToProductionId;
      let groupToProductions =
        entityModel["GROUP_BUY"].response?.groupToProductions || [];

      if (groupToProductionId) {
        groupToProductions = groupToProductions.map((gp) => {
          if (groupToProductionId === gp.id) {
            return {
              ...gp,
              available: available,
              productionTemplate: product,
              merchantId: product.merchantId,
              category: categoryState
            };
          } else {
            return gp;
          }
        });
      } else {
        groupToProductions = [
          ...groupToProductions,
          {
            id: new Date().getTime(),
            available: Number(watchStock ? watchStock : -1),
            productionTemplate: product,
            category: categoryState,
            merchantId: product.merchantId,
          },
        ];
      }
      replace("GROUP_BUY", {groupToProductions});
      // 在跳转之前，将需要同步至团购的产品进行保存
      if (saveToGroup) {
        const groupId = history.location.state?.groupId
        const params = {
          groupId: groupId,
          product: {
            id: history.location.state?.values?.groupToProductionId,
            productTemplate: product,
            available: data.availabe ?? product.defaultStock,
            // maxOrderQuantity: product?.maxOrderQuantity,
            category: categoryState
          }
        }
        console.log(params)
        await requestPost('group/updateProduct', params)
      }
      history.goBack();
    } else {
      if (data.id) {
        await update("PRODUCTION_TEMPLATE", data);
      } else {
        await create("PRODUCTION_TEMPLATE", {
          ...data,
          merchantId: currentMerchantId,
        });
      }
      await query("GROUP_BUY_LIST", {id: currentMerchantId});
      history.goBack();
    }
  };

  const getStock = async () => {
    if (history.location.state.values?.id) {
      const {data} = await requestGet("manage/stock", {
        groupId: history.location.state.groupId,
        // title: history.location.state.values.title
        id: history.location.state.values.groupToProductionId
      });
      if (data) {
        setSold(data.sold)
        setUnpaid(data.unpaid)
        // setPaid(data.paid)
        // setAvailable(data.available)
      } else {
        setAvailable(history.location.state.values?.defaultStock ? history.location.state.values?.defaultStock
          : DEFAULT_STOCK_NUM)
      }
    }
  }

  useEffect(() => {
    if (fromGroupPage) {
      getStock()
    }
  }, [fromGroupPage])

  React.useEffect(() => {
    if (!!history.location.state?.values) {
      console.log(history.location.state?.values)
      setFormFields(history.location.state.values, true)
    }
  }, [history.location.state]);

  const groupBuyData = entityModel["GROUP_BUY"]?.response;

  useEffect(() => {
    console.log('effect groupBuyData', groupBuyData)
    if (groupBuyData.product && groupBuyData.product.category) {
      console.log('--->', groupBuyData.product)
      setCategoryState(groupBuyData.product.category)
    }
  }, [groupBuyData])

  const saveState = () => {
    console.log(control.getValues())
    replace("PRODUCT_TEMPLATE_PAGE", control.getValues());
  };

  useEffect(() => {
    const productTempData = entityModel["PRODUCT_TEMPLATE_PAGE"]?.response;
    if (productTempData && !isEmpty(productTempData)) {
      // reset(productTempData)
      control.setValue('title', productTempData.title)
      control.setValue('description', productTempData.description)
      control.setValue('images', productTempData.images)
      control.setValue('originalPrice', productTempData.originalPrice)
      control.setValue('price', productTempData.price)
      control.setValue('priceCNY', productTempData.priceCNY)
      control.setValue('specification', productTempData.specification)
      control.setValue('defaultStock', productTempData.defaultStock)
      control.setValue('maxOrderQuantity', productTempData.maxOrderQuantity)
      control.setValue('id', productTempData.id)
      flush('PRODUCT_TEMPLATE_PAGE')
    }
  }, [])

  const formValues = watch();

  const defaultStock = useWatch({
    control,
    name: 'defaultStock'
  })

  return (
    <PageLayout pageName="createProduct">
      <CachePage backupData={formValues} onRestore={onRestore}/>
      <Header title=""/>
      <StyledForm onSubmit={handleSubmit(onSumbit)}>
        <input ref={register} name="id" style={{display: "none"}}/>

        <InputField
          label="商品名称"
          register={register}
          type="text"
          name="title"
          required
          placeholder="请输入商品名称"
          errors={errors}
        />
        <Divider/>
        <InputField
          textarea
          label="商品介绍"
          register={register}
          type="text"
          name="description"
          required
          placeholder="请输入商品介绍"
          errors={errors}
        />
        <Divider/>
        <label>商品图片</label>
        <Controller
          name="images"
          control={control}
          render={({onChange, onBlur, value, ref}) => (
            <ImageUploader
              maxLength={6}
              fileList={value}
              onValueChanged={(fileList: string[]) => {
                control.setValue("images", fileList);
              }}
            />
          )}
        />
        <Divider/>
        <label>产品设置</label>
        <Divider/>

        <StyledProductSetting>
          <ListInputField
            register={register}
            name="price"
            label="价格"
            placeholder="请输入价格"
            bottomDivider
            required
            inputMode="decimal"
            errors={errors}
          />
          {isCurrentMerchantSupportCNY() && (
            <ListInputField
              required
              register={register}
              name="priceCNY"
              label="人民币价格"
              placeholder="请输入人民币价格"
              bottomDivider
              inputMode="decimal"
            />
          )}
          <ListInputField
            register={register}
            name="originalPrice"
            label="划线价"
            placeholder="请输入划线价"
            bottomDivider
            inputMode="decimal"
          />
          <ListInputField
            register={register}
            name="specification"
            label="规格"
            placeholder="请输入规格，如1kg，1盒"
            bottomDivider
          />
          <IntegerInputField
            bottomDivider
            errors={errors}
            register={register}
            name="maxOrderQuantity"
            label="限购"
            placeholder="请输入限购数量，不填为不限购"
            onChange={(e) => {
              if (e.target.value === '') {
                clearErrors('maxOrderQuantity')
              }
            }}
          />
          {history.location.state?.from === 'template' ? (
            <Controller name="category" control={control}
                        render={({onChange, value}) => (
                          <CategoryInput
                            onBeforeClick={() => {
                              saveState()
                            }}
                            value={value} onChange={onChange}
                          />
                        )}
            />
          ) : (
            <div style={{borderBottom: '1px solid rgba(0,0,0,0.09)'}}>
              <WhiteSpace/>
              <Item onClick={() => {
                saveState()
                history.push(LOCAL_URL["CATEGORY"], {
                  type: 'PRODUCT',
                  data: {
                    category: groupBuyData.category,
                    productions: groupBuyData.groupToProductions,
                    product: {...history.location.state?.values, category: categoryState}
                  }
                });
              }}>
                <div>
                  <div className="title">分类</div>
                  <div className="switch" style={{color: THEME.color.gray["#11-065"]}}>
                    {categoryState.length > 0 ? categoryState.map(item => item.title).join(', ') : '请选择分类 >'}
                  </div>
                </div>
              </Item>
              <WhiteSpace/>
            </div>
          )}
          <Divider/>
          <ListInputField
            required
            errors={errors}
            register={register}
            name="defaultStock"
            label="库存"
            inputMode="decimal"
            placeholder="请输入库存"
            onChange={(e) => {
              const value = Number(e.target.value)
              if (!isNaN(value)) {
                const newAvailable = value - paid - unpaid
                if (newAvailable < 0) {
                  setAvailable(newAvailable)
                } else {
                  setAvailable(newAvailable)
                }
              }
            }}
          />
          {fromGroupPage ? (
            <div className="stock-info">
              <span className="stock-title">
                已支付{" "}
                <span className="stock-value">
                  {paid}
                </span>
                {"，"}
              </span>
              <span className="stock-title">
                  待支付{" "}
                <span className="stock-value">
                    {unpaid}
                  </span>
                {"，"}
                </span>
              <span className="stock-title">
                可售{" "}
                <span className="stock-value">
                  {!defaultStock
                    ? "不限"
                    : available
                  }
                </span>
              </span>
            </div>
          ) : null}
        </StyledProductSetting>
        <div style={{height: 8}}/>
        {fromGroupPage && !!!history.location?.state?.values && (
          <Controller
            name="saveToLib"
            control={control}
            render={({onChange, value}) => (
              <div className="flex-row save-to-lib">
                <Radio
                  onChange={(value) => {
                    onChange(value);
                  }}
                  name="saveToLib"
                  value={value}
                />
                同时保存到产品库
              </div>
            )}
          />
        )}
        <FloatBottom>
          <Button fullWidth type="submit" size="large" color="red">
            完成
          </Button>
        </FloatBottom>
      </StyledForm>
    </PageLayout>
  );
}
