// import { formatRelative } from 'date-fns';
import { LOCATION } from '../config';
// import SVGCoffeePass  from '../../../svg/coffeepass_as_coffee_on_pass.svg';

// watch?v=WZcxJGmLbSo&t=184s
// watch?v=U3dLjHN0UvM
// <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=400x300&sensor=false&markers=color:red%7C${lat},${lng}&key=${GOOGLE_MAP_API_KEY}`} alt='google map image' />

// https://www.mindbowser.com/react-google-map-with-a-custom-pin-marker/
// https://sites.google.com/site/gmapsdevelopment/
// https://yoksel.github.io/url-encoder/

// https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=
const API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';
const GOOGLE_MAP_API_KEY = LOCATION.CREDENTIAL.GOOGLE_MAP.API_KEY;

export const reqCurrentPosition = () => ( new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(
    (pos) => { resolve(pos) },                        // 用户授权且获得位置
    () => { reject(new Error('End user denied!')) },  // 用户拒绝授权
    { enableHighAccuracy: true }
  )
}));

export const findPlace = (lat, lng) => ( new Promise((resolve, reject) => {
  fetch(
    `${API_URL}?latlng=${lat},${lng}&key=${GOOGLE_MAP_API_KEY}`,
    { 
      method: 'get',
      headers: {
        // "Content-Type"  : "application/json; charset=utf-8",
        // "Content-Type": 'application/json',
        Accept: 'application/json',
      }
    }
  )
  .then((response) => {
    // console.log(response);
    const { status, ok } = response;
    if ((status !== 200) || (!ok)) {
      throw new Error('Response Error');
    }
    return response.json();
  })
  .then((response) => {
    // console.log(response);
    const formatted = response.results[0].formatted_address;
    let city, state, country;
    for (let i = 0; i < response.results[0].address_components.length; i++) {
      for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
        switch (response.results[0].address_components[i].types[j]) {
          case "locality":
            city = response.results[0].address_components[i].long_name;
            break;
          case "administrative_area_level_1":
            state = response.results[0].address_components[i].long_name;
            break;
          case "country":
            country = response.results[0].address_components[i].long_name;
            break;
        }
      }
    }
    // console.log(city, state, country);
    // console.log(formatted);
    resolve({
      formatted,
      city,
      state,
      country,
    })
  })
  .catch((err) => {
    console.error(err);
    reject(err);
  });

} ))
