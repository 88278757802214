import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ToastManager} from './Layout';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {FIRBASE_CONFIG} from "./config";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://66a49a06fbf948e99154b3d372d4ee1e@o89659.ingest.sentry.io/5742357",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions forr performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Initialize Firebase
  const app = initializeApp(FIRBASE_CONFIG);
  const analytics = getAnalytics(app);
}

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
ReactDOM.render(
  <ToastManager />,
  document.getElementById('toast')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
