import styled from 'styled-components';
import { THEME } from "../config";
import { BreathingFrames } from './Animation';

const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;
const COLOR_BG = THEME.color.gray['#6-d9'];

const SkeletonBase = styled.div`
  background: ${COLOR_BG};
  animation: ${BreathingFrames} infinite 1s linear alternate;
`;
const SkeletonCircle = styled(SkeletonBase)`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-radius: ${props => `${props.size/2}px`};
`;
const SkeletonBox = styled(SkeletonBase)`
  width: ${props => props.width};
  height: ${props => props.height || '14px'};
`;

export const SkeletonCarousel = styled(SkeletonBase)`
  width: 100%;
  height: 160px;
  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    height: 320px;
  }
`;

const HomeCategory = () => (
  <div className="flex-center direction-col align-center" >
    <SkeletonCircle size={48} />
    <SkeletonBox width='48px' height='8px' style={{ marginTop: '8px'}}/>
  </div>
)
export const SkeletonCategoriesList = () => (
  <div className="flex-between align-center" >
  { [1,2,3,4,5].map((id) => (
    <HomeCategory key={id} />
  )) }
  </div>
)

const ShowCaseCover = styled(SkeletonBase)`
  width: ${props => (props.size === 'small') ? '264px' : '100%'};
  height: ${props => (props.size === 'small') ? '100px' : '140px'};
`;
const ListContainer = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const ItemContainer = styled.li`
  margin-top: 16px;
  width: 100%;
  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    width: calc(25% - 10px);
    min-width: 300px;
  }
`;
const ShowCase = (props) => {
  return (
    <div style={{ width: '100%' }} className="flex-between direction-col align-center" >
      <ShowCaseCover {...props} />
      
      <div style={{ marginTop: '9px', width: '100%'}} className="flex-between align-center">
        <div>
          <SkeletonBox width='190px' />
          <SkeletonBox width='125px' style={{ marginTop: '9px'}}/>
        </div>
        <SkeletonCircle size={36} />
      </div>
    
    </div>
  )
}

export const SkeletonShowCaseList = (props) => (
  <ListContainer>
  { [1,2,3,4].map((id) => (
    <ItemContainer key={id} >
      <ShowCase {...props} />
    </ItemContainer>
  )) }
  </ListContainer>
)

export const SkeletonTicketList = () => (
  <>
  <SkeletonBox width='108px' height='28px'/>
  <ListContainer>
  { [1,2,3,4].map((id) => (
    <ItemContainer key={id} >
      <SkeletonBox width="100%" height="96px" />
    </ItemContainer>
  )) }
  </ListContainer>
  </>
)

const HomeGroupTitle = styled(SkeletonBase)`
  width: 199px;
  height: 28px;
`;
const GroupContainer = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
`;

const GroupItemContainer = styled.li`
  margin: 16px 8px 8px 0;
`;

export const SkeletonGroupList = () => (
  <div style={{marginTop: '16px', overflow: 'hidden'}}>
    <HomeGroupTitle />
    <GroupContainer>
    { [1,2,3,4].map((id) => (
      <GroupItemContainer key={id} >
        <ShowCase size="small" />
      </GroupItemContainer>
    )) }
    </GroupContainer>
  </div>
)

export const SkeletonStoreInfo = () => (
  <>
    <SkeletonBox width="199px" height="28px" />
    <SkeletonBox width="190px"  style={{ marginTop: '24px'}} />
    <SkeletonBox width="125px"  style={{ marginTop: '9px'}} />
    <SkeletonBox width="292px"  style={{ marginTop: '24px'}} />
    <SkeletonBox width="344px"  style={{ marginTop: '8px'}} />
    <SkeletonBox width="221px"  style={{ marginTop: '8px'}} />
    <SkeletonBox width="67px"   style={{ marginTop: '8px'}} />
  </>
)