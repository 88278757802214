import React from 'react';
import styled from 'styled-components';
import { THEME } from '../../config';

const SIZE = 16;
const BG_COLOR = THEME.color.primary.variant;
const HOLE_COLOR = 'transparent';
const RADIUS = '8px';

const BaseLayer = styled.div`
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '110px'};
  border-radius: ${RADIUS};
  background: ${BG_COLOR};
  position: relative;
`;
const ContentContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding: ${(props) => props.padding || '0'};
  text-align: center;
  color: #fff;
`;
const HalfCircle = styled.div`
  position: absolute;
  height: ${SIZE}px;
  width: ${SIZE}px;
  border-radius: ${SIZE/2}px;
  background: ${(props) => props.bgColor || '#fff'};
  
  top: 50%;
  &.left {
    left: 0;
    transform: translate(-50%, -50%);
  }
  &.right {
    right: 0;
    transform: translate(50%, -50%);
  }
`;

interface TicketProps {
  children?: any;
  [property: string]: any;
}
export const Ticket2:React.FC<TicketProps> = ({children, ...restProps}) => (
  <BaseLayer {...restProps} >
    <HalfCircle className="left"  {...restProps} />
    <HalfCircle className="right" {...restProps} />

    <ContentContainer>
      {children}
    </ContentContainer>

  </BaseLayer>
)
