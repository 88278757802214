import styled from 'styled-components'

export const MaskLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  background-color: rgba(0,0,0,0.65);
`

export const Wrapper = styled.div`
  position: absolute;
  top: 90px;
  left: 24px;
  right: 24px;
  bottom: 96px;
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem 1rem;

  & h1 {
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: black;
    margin-bottom: 1rem;
  }
  & p {
    font-size: .75rem;
    line-height: 1.1rem;
    color: rgba(0,0,0,0.45);
  }
  & textarea {
    margin: 1rem 0;
    padding: 1rem;
    width: 100%;
    resize:none;
    background-color: #F5F5F5;
    border-radius: 8px;
    border: none;

    font-size: .75rem;
    line-height: 1.1rem;
    color: rgba(0,0,0,0.65);
  }
  & textarea::placeholder {
    color: rgba(0,0,0,0.45);
  }
`

export const IconButton = styled.div`
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);
`
