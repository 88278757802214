import { ICategoryPartialEntity, ICategoryData } from './type'

export const fnSortNumberASC = (a: ICategoryData, b: ICategoryData) => (a.index < b.index ? -1:1)

export const convertData2PartialEntity
: (data: ICategoryData) => ICategoryPartialEntity
= (data) => ({
  id: data.categoryId,
  index: data.index,
  title: data.title,
})
