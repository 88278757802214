import styled from 'styled-components'
// import { ModalMask } from '../Layout'
import { ScreenMaskDrawer } from '../components'

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px 8px 0 0;
  background-color: white;

  text-align: center;

`
const MainBlock = styled.div`
  padding: 1.5rem 1rem;

  & p {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color:rgba(0,0,0,0.85);
  }
`
const CancelBlock = styled.div`
  border-top: 1px solid rgba(0,0,0,0.09);
`
const Button = styled.button`
  min-height: 3rem;
  width: 100%;
  padding: .75rem 1rem;
  border: none;
  border-radius:8px;
  color: white;
  background-color: ${props => props.color || 'red'};
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;

  &.cancel {
    background-color:transparent;
    color: rgba(0,0,0,0.65);
  }
`

interface IMenuOption {
  value: string;
  label: string;
  color?: string;
}
interface IPopUpMenuProps {
  title?: string;
  options: IMenuOption[];
  onClick: (value:string) => void;
  visible?: boolean;
  onCancel?: () => void;
}


export const PopUpMenu: React.FC<IPopUpMenuProps> = ({visible, onCancel, title, options, onClick}) => {
  return (
    <ScreenMaskDrawer open={visible}
      onClose={onCancel}
      padding="1.5rem 1rem"
    >
      <Wrapper>
        <MainBlock className="flex-start direction-col" style={{gap: '1rem'}}>
          <p>{title}</p>
          { options.map((op: IMenuOption, index:number) => (
            <Button key={index}
              color={op.color}
              onClick={() => onClick(op.value)}
            >
              {op.label}
            </Button>
          )) }
        </MainBlock>
        <CancelBlock>
          <Button className="cancel" onClick={onCancel}>取消</Button>
        </CancelBlock>
      </Wrapper>
    </ScreenMaskDrawer>
  )
}
