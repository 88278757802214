import dayjs from 'dayjs'
import { requestGet } from '../../lib'

export const REQ_URL = 'promotion'

export const initValidPromotionData = async (merchantId:number) => {
  if ('number' !== typeof merchantId) return;

  const result = await requestGet(`${REQ_URL}?merchantId=${merchantId}`, {})
  if ((result.code !== 0) && (!result.data?.length)) return [];

  return result.data.map((x)=> ({
    ...x,
    dueDate: dayjs(x.dueDate),
    createdAt: dayjs(x.createdAt),
  })).filter((x) => (
    x.createdAt.isValid() &&
    x.dueDate?.isValid() && 
    !dayjs(x.dueDate).isBefore(dayjs())
  )).sort((a, b) => (
    dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1
  ))
}
