import React, { useRef, useState, useEffect } from 'react';
import { THEME } from '../config';

type AnchorIdName = string;
interface PageAnchor {
  [pageName: string]: AnchorIdName;
}

const globalPageAnchor: PageAnchor = {};

export function useWindowScroll (pageName: string) {

  const thisPage = useRef(null);
  const hiddenRef= useRef(null);
  const [isHiddenVisible, setHiddenVisible] = useState(false);

  const scrollHandler = () => {
    // console.log(window.pageYOffset, window.innerHeight, hiddenRef.current.offsetTop)
    if(
      (!!hiddenRef.current?.offsetTop) && 
      ((window.pageYOffset + window.innerHeight) >= hiddenRef.current.offsetTop)
    ) {
      // console.log('show')
      setHiddenVisible(true);
    }
    else {
      // console.log('hide')
      setHiddenVisible(false);
    }
  }
  useEffect(() => {
    thisPage.current = pageName;

    window.addEventListener("scroll", scrollHandler);
    return (() => window.removeEventListener("scroll", scrollHandler))
  }, []);

  const setPageAnchor = (anchorId: AnchorIdName) => {
    if (!thisPage.current) return;
    globalPageAnchor[thisPage.current] = anchorId;
    // console.log('setAnchor')
    // console.log(globalPageAnchor)
    // console.log(thisPage.current)
    // console.log(globalPageAnchor[thisPage.current])
  }
  const scrollToPageAnchor = () => {
    // console.log('scroll function')
    // console.log(globalPageAnchor)
    // console.log(thisPage.current)
    // console.log(globalPageAnchor[thisPage.current])
    if (!globalPageAnchor[thisPage.current]) return;

    const anchorElement = document.getElementById(globalPageAnchor[thisPage.current]);
    if(!!anchorElement) {
      // Todo:
      // 尝试解决 Bug：从店铺返回首页的时候，因为页面加载速度问题，导致自动滚动到之前位置的功能，如果不加延时立刻执行，会失效
      window.setTimeout(() => {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
        // console.log('scroll to anchor', anchorElement)
        delete globalPageAnchor[thisPage.current];
      }, 1000)
    }
  }

  return ({
    setPageAnchor, 
    scrollToPageAnchor,

    hiddenRef,
    isHiddenVisible,
  })

}
