import { ApplicationTypeType, ApplicationScopeType } from './type.entity'
import { SCOPE_SETTING_DICT } from './common'

export const formatApplicationScope = (scopes: ApplicationScopeType[]) => (
  `仅限${scopes?.map?.((key) => (SCOPE_SETTING_DICT[key] || key)).join?.('及') || ''}使用`
)
export const autoDescription = ({merchantName, scopes}:{
  merchantName: string;
  scopes: ApplicationScopeType[];
}) => (
`仅限${merchantName}${scopes?.map?.((key) => (SCOPE_SETTING_DICT[key] || key)).join?.('及') || ''}使用
本活动最终解释权归${merchantName}所有
`
)

export const autoPreferenceName = (type: ApplicationTypeType, amount:number, miniExpense:number) => (
  (type === 'DISCOUNT') ? `${amount}%OFF 券` :
  (type === 'DEDUCT')   ? `满$${miniExpense}减$${amount}` :
  '兑换券'
)

export const REQ_URL = 'preference'
