import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;

  & input::placeholder, textarea::placeholder {
    color: rgba(0,0,0,0.45);
  }

  & span.required {
    color: ${props => `rgb(${props.theme?.color?.highlight?.RGB})`};
  }
`
