import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { IconArrowRight } from '../Layout'
import { IFormInputProps } from './type'
import { Wrapper as BaseWrapper } from './styled'

const Wrapper = styled(BaseWrapper)`
  padding: 1rem .5rem;
  border-radius: 8px;
  background-color: white;
  color: rgba(0,0,0,0.85);
  font-size: 15px;
  line-height: 21px;
  /* white-space: pre-wrap; */
`

interface ITextInputProps
extends IFormInputProps {
  type: 'text' | 'date';
  autoText?: () => string;
}
export const TextInput: React.FC<ITextInputProps> = ({required, label, placeholder, value, onChange, type, autoText}) => {
  const [mode, setMode] = useState<'view'|'edit'>('view')
  const [text, setText] = useState<string>(value)
  const ref = useRef<any>(null)

  const goEdit = () => {
    ref.current.value = text;
    setMode('edit')
  }
  const quitEdit = () => {
    setText(ref.current.value)
    onChange(ref.current.value)
    setMode('view')
  }
  const onFocus = () => {
    if (type === 'date') {
      ref.current?.showPicker?.()
    }
  }
  const onKeyDown = (e:any) => {
    if (e.code === 'Enter') {
      e.preventDefault()
      quitEdit()
    }
  }

  useEffect(() => {
    if (mode === 'edit') ref.current.focus();
  }, [mode, ref.current])

  useEffect(() => {
    if (!autoText) return;
    const t = autoText();
    if (
      (!!t) && 
      ((t !== text) || (t !== value))
    ) {
      ref.current.value = t;
      setText(t)
      onChange(t)
    }
  }, [autoText])
  useEffect(() => {
    if (value !== text) {
      ref.current.value = value;
      setText(value)
    }
  }, [value])

  return (
    <Wrapper className="flex-between align-center">
      <label>{label}<span className='required'>{required ? ' *' : ''}</span></label>
      <div className="flex-end align-center"
        style={{display: mode === 'edit' ? 'none' : '', flex: 1}}
        onClick={ goEdit }
      >
        <p>
          {text}
          { !text && 
            <span style={{color: 'rgba(0,0,0,0.45)'}}>{placeholder}</span> 
          }
        </p>
        <IconArrowRight />
      </div>
      <input autoFocus
        ref={ref}
        type={type}
        placeholder={placeholder}
        style={{display: mode === 'view' ? 'none' : ''}}
        onBlur={quitEdit}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
    </Wrapper>
  )
}
