import React, {useContext, useEffect, useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import confirm_ok from "../../svg/confirm_ok.svg";
import styled from "styled-components";
import WhiteSpace from "../../components/WhiteSpace";
import {useHistory} from "react-router-dom";
import {LOCAL_URL, THEME, WECHAT_FOLLOW_URL} from "../../config";
import {EntityContext} from "../../context";
import {getParams} from "../../lib";

function DonePage() {
  const history = useHistory<any>();
  const {query} = useContext(EntityContext);
  const [order, setOrder] = useState<any>({})
  const [group, setGroup] = useState<any>({})

  useEffect(()=>{
    getOrderData()
  }, [])

  const getOrderData = async () => {
    let {id} = getParams(history.location.search);
    if (!id){
      id = history.location.state.id;
    }
    if (id){
      const {data} = await query("ORDER", {id: id});
      setOrder(data)
      setGroup(data.group)
    }
  };

  return (
    <PageLayout pageName="no nav orderDone">
      <Header title={order.status === "PAID" ? "支付成功" : "下单成功"}/>
      <Container>
        <WhiteSpace height={50}/>
        <img src={confirm_ok} alt=""/>
        <WhiteSpace height={24}/>
        <div className="title">
          {order.status === 'PAID' && (<span>恭喜您支付成功</span>)}
          {order.status === 'PRE_PAID' && (<span>恭喜您下单成功, 请联系团长付款</span>)}
        </div>
        <WhiteSpace height={12}/>
        <div className="order-no">订单号： {`SMT${order.id}`}</div>
        {order.status === "PRICE_CONFIRM" && (
          <div>
            <WhiteSpace height={24}/>
            <div className="desc noti">
              您的订单包含称重类商品，需要店主确认实际重量和实际支付金额再付款。
            </div>
          </div>
        )}
        <WhiteSpace height={24}/>
        <div className="desc">
          所有的订单通知将通过微信公众号“新西兰神马大吉科技”发送，请关注公众号并留意消息哦。
          <a href={WECHAT_FOLLOW_URL} target="_blank">
            立即点击关注
          </a>
        </div>
      </Container>
      <WhiteSpace height={80}/>
      <Button
        onClick={() => {
          if (group.type === 'DEFAULT'){
            if (order && order.groupId){
              history.replace(
                LOCAL_URL["GROUP_BUY_DETAIL"] +
                `?groupId=${order.groupId}`
              )
            }else {
              history.replace(
                LOCAL_URL["WELCOME"]
              )
            }
          }else if (group.type === 'STORE'){
            history.replace(
              LOCAL_URL["SHOPPING_DETAIL"] +
              `?groupId=${order.groupId}`
            )
          }
        }}
      >
        {group.type === 'DEFAULT' ? '返回团购接龙': null}
        {group.type === 'STORE' ? '返回在线网店': null}
      </Button>
    </PageLayout>
  );
}

export default DonePage;

const Button = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  background: #ffffff;
  color: ${THEME.color.primary.default};
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;

  text-align: center;
`;

const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  .order-no {
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
  }

  .desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
  }

  .noti {
    color: ${THEME.color.primary.default};
  }
`;
