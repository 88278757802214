import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-use";
import styled from "styled-components";
import {Button, notify} from "../../components";
import Header, {FloatBottom} from "../../components/Header";
import WhiteSpace from "../../components/WhiteSpace";
import {LOCAL_URL, THEME, WEB_ROOT} from "../../config";
import {UserContext} from "../../context";
import {useRouter} from "../../hooks/router";
import {IconSetting, Layout} from "../../Layout";
import {requestGet, requestPost} from "../../lib";
import {getImageById} from "../../utils/ImageUtils";
import {setRedirectUrl} from "../../utils/persistence";
import CouponCard from "./components/CouponCard";
import FollowerTrack from "./components/FollowerTrack";
import InventFriendsModal from "./components/InventFriendsModal";
import ShareImageModal from "./components/ShareImageModal";
import {useHistory} from "react-router-dom";

function PromotionDetailPage() {
  const [showInventFriendsModal, setShowInventFriendsModal] = useState(false)
  const [showShareImageModal, setShowShareImageModal] = useState(false)
  const [promotionData, setPromotionData] = useState(null)
  const [floatBtnStr, setFloatBtnStr] = useState('立即参加')
  const [formattedCoupons, setFormattedCoupons] = useState<any[]>([])
  const router = useRouter()
  const history = useHistory()
  const location = useLocation()
  const {promotionId, referrerId} = router.query
  const {model} =
    React.useContext(UserContext);
  const {profile} = model;
  const fetchData = async () => {
    const result = await requestGet(`promotion/${promotionId}`)
    if ((result.code === 0) && (!!result.data)) {
      const {data} = result
      setPromotionData(data)
      if (data.status === 'PROCESSING') {
        if (data.attendInfo) {
          setFloatBtnStr('邀请好友')
        } else {
          setFloatBtnStr('立即参加')
        }
      } else {
        setFloatBtnStr('活动已结束')
        if (!profile || !profile.id) {
          setRedirectUrl(location?.href)
          history.push(LOCAL_URL.USER_LOGIN);
          return
        }
      }
      if (data.rules && data.rules.total > 0) {
        const result = []
        let gotIds = new Map<number, any>([])
        let defaultExpiredDate = ''
        if (data.couponDueDate) {
          defaultExpiredDate = `有效期至${dayjs(data.couponDueDate).format('YYYY年MM月DD日')}`
        } else if (data.couponLiveTime) {
          defaultExpiredDate = `领取后${data.couponLiveTime}天过期`
        }
        if (data.coupons && data.coupons.length > 0) {
          data.coupons.forEach(item => {
            gotIds.set(item.preferenceId, item)
          })
        }
        for (const rule of data.rules.rules) {
          const {preference} = rule
          const got = gotIds.has(preference.id)
          if (got) {
            const item = gotIds.get(preference.id)
            defaultExpiredDate = `有效期至${dayjs(item.dueDate).format('YYYY年MM月DD日')}`
          }
          let desc2 = ''
          const scope = preference.applicationScope.setting
          if (scope.length === 2) {
            desc2 = '在线网店、实体店通用'
          } else if (scope.length === 1) {
            desc2 += '仅限'
            if (scope[0] === 'IN-STORE') {
              desc2 += '实体店'
            } else {
              desc2 += '在线网店'
            }
            desc2 += '使用'
          }
          const couponData = {
            name: preference.name,
            desc1: desc2,
            desc2: defaultExpiredDate,
            status: got ? '已领取' : '待领取',
            data: {
              preference,
              coupon: gotIds.get(preference.id) ?? null
            }
          }
          result.push(couponData)
        }
        setFormattedCoupons(result)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (!promotionData) {
    return null
  }


  const {
    merchant,
    follower,
    getCoupons,
    role,
    attendInfo
  } = promotionData

  const descArray = promotionData.description.split('\n')
  let temp = 0
  const keyPoints = new Map<number, number>()
  let nextTarget = 0
  try {
    promotionData?.rules?.rules.forEach((item) => {
      temp = temp + item.followerNumber
      if (temp > follower.length) {
        nextTarget = temp - follower.length
        throw new Error('exit')
      }
    })
  } catch (e) {

  }

  const attendSubmit = async () => {
    const {data} = await requestPost('followtrack', {
      promotionId: promotionId,
      userId: profile?.id,
      referrerId: referrerId ?? null
    })
  }
  const shareContentStr = role ? (
    WEB_ROOT +
    LOCAL_URL["PROMOTION_DETAIL"] +
    `?promotionId=${promotionId}`
  ) : (
    WEB_ROOT +
    LOCAL_URL["PROMOTION_DETAIL"] +
    `?promotionId=${promotionId}&referrerId=${profile?.id}`)

  return (
    <Layout>
      <Header
        title={promotionData.name}
        backgroundColor={merchant?.meta?.themeColor}
        hidePlaceholder
        right={(promotionData.role) ? (
          <div style={{marginRight: 16}}
            onClick={()=>{
              history.push(LOCAL_URL.PROMOTION_EDITING, {
                formData: promotionData,
                merchantData: merchant
              })
            }}
          >
            <IconSetting style={{display: 'block'}}/>
          </div>
        ) : null}
      />
      <Container primaryColor={merchant?.meta?.themeColor}>
        {(attendInfo) ? (
          <div>
            <div className='status-card'>
              <div className='line1'>参与中</div>
              <div className='line2'>符合领取条件后，将自动领取奖励</div>
            </div>
            <WhiteSpace height={8}/>
            <FollowerTrack
              themeColor={merchant?.meta?.themeColor}
              rules={promotionData.rules?.rules ?? []}
              followers={follower ?? []}
            />
          </div>
        ) : (
          <div>
            <img
              className='poster-link'
              src={getImageById(promotionData.posterLink)}
              alt="poster link"/>
            <WhiteSpace/>
            <PromotionDescCard>
              <div className='title'>{promotionData.name}</div>
              <div className='desc'>
                {descArray.map(item => {
                  return (
                    <p key={item}>{item}</p>
                  )
                })}
              </div>
            </PromotionDescCard>
          </div>
        )}
        <WhiteSpace/>
        {formattedCoupons.length > 0 ? (
          <div>
            {formattedCoupons.map(item => {
              return (
                <CouponCard
                  themeColor={merchant?.meta?.themeColor}
                  key={item.name}
                  {...item}
                  onClick={() => {
                    const {data} = item
                    if (data.coupon){
                      history.push(LOCAL_URL.COUPON_DETAILS_IN_PROMOTION, {
                        coupon: data.coupon,
                        merchant: merchant
                      })
                    }else if (data.preference){
                      history.push(LOCAL_URL.COUPON_DETAILS_IN_PROMOTION, {
                        preference: data.preference,
                        merchant: merchant
                      })
                    }
                  }}
                />
              )
            })}
          </div>
        ) : null}
        <div className='merchantCard'
             onClick={() => {
               history.push(LOCAL_URL["MERCHANT_DETAIL"] + `?id=${merchant.id}`)
             }}
        >
          <div className='icon'>
            <img src={getImageById(merchant.logo)} alt=""/>
          </div>
          <div className='name'>
            {merchant.name}
          </div>
          <div className='right'>
            进入店铺
          </div>
        </div>
        {attendInfo ? (
          <div>
            <WhiteSpace height={16}/>
            <PromotionDescCard>
              <div className='title'>{promotionData.name}</div>
              <div className='desc'>
                {descArray.map(item => {
                  return (
                    <p key={item}>{item}</p>
                  )
                })}
              </div>
            </PromotionDescCard>
          </div>

        ) : null}
      </Container>
      <FloatBottom>
        <Button
          disabled={promotionData.status !== 'PROCESSING'}
          fullWidth
          size="large"
          primaryColor={merchant?.meta?.themeColor}
          onClick={async () => {
            if (!profile || !profile.id) {
              setRedirectUrl(location?.href)
              history.push(LOCAL_URL.USER_LOGIN);
              return
            } else {
              if (!attendInfo) {
                await attendSubmit()
              }
              setShowInventFriendsModal(true)
            }
            await fetchData()
          }}
        >
          {floatBtnStr}
        </Button>
      </FloatBottom>
      <div>
        <InventFriendsModal
          copyUrl={`${promotionData.copywriting} + " " +${shareContentStr}`}
          open={showInventFriendsModal}
          nextTarget={nextTarget}
          onShowShareImageClicked={() => {
            setShowInventFriendsModal(false)
            setShowShareImageModal(true)
          }}
          onCopyLinkClicked={() => {
            setShowInventFriendsModal(false)
            notify.info("邀请链接已复制");
          }}
          onClose={() => {
            setShowInventFriendsModal(false)
          }}
        />
        <ShareImageModal
          copyUrl={shareContentStr}
          avatar={profile?.avatar}
          name={profile?.name}
          userId={profile?.id}
          posterLink={promotionData.posterLink}
          copywriting={promotionData.copywriting}
          promotionId={promotionData.id}
          show={showShareImageModal}
          onDismiss={() => {
            setShowShareImageModal(false)
          }}
        />
      </div>
    </Layout>
  )
}

export default PromotionDetailPage

const Container = styled.div`
  padding: 16px;

  .poster-link {
    width: 100%;
  }

  .status-card {
    background-color: ${props => props.primaryColor ?? THEME.color.primary.default};
    border-radius: 8px;
    padding: 24px;

    .line1 {
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: white;
    }

    .line2 {
      margin-top: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: white;
    }
  }

  .merchantCard {
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      height: 40px;
      width: 40px;
      border-radius: 20px;

      img {
        width: 100%;
      }
    }

    .name {
      padding-left: 12px;
      flex: 1;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: ${THEME.color.gray["#10-085"]}
    }

    .right {
      height: 32px;
      min-width: 64px;
      color: white;
      background-color: ${props => props.primaryColor ?? THEME.color.primary.default};
      border-radius: 4px;
      line-height: 16px;
      padding: 8px;
      font-weight: 500;
      font-size: 12px;
    }
  }
`

const PromotionDescCard = styled.div`
  background-color: white;
  padding: 16px;

  .title {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: ${THEME.color.gray["#10-085"]};
    margin-bottom: 8px;
  }

  .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${THEME.color.gray["#12-045"]};

    p {
      margin-top: 4px;
    }
  }
`




