import React from "react";
import styled from "styled-components";
import WhiteSpace from "../../../components/WhiteSpace";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {notify} from "../../../components";
import {LOCAL_URL, WEB_ROOT} from "../../../config";
import {QRCodeSVG} from "qrcode.react";

type UploadReceiptViewProps = {
  orderId?: string,
  orderIndex?: string,
  accountName: string,
  accountNo: string,
  amount: string,
  showQRCode: boolean
}

function UploadReceiptView(props: UploadReceiptViewProps) {

  return (
    <div>
      <Container>
        <WhiteSpace height={16}/>
        <div className="title">付款信息</div>
        <div style={{paddingBottom: 0}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 16,
            }}
          >
            <div style={{color: 'rgba(0,0,0,0.45)', fontSize: 15}}>账户名请填写：</div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1, fontWeight: 500,
              fontSize: 24,
              lineHeight: '34px',
              marginTop: 8,
              justifyContent: 'space-between',
            }}>
              {props.accountName}
              <CopyToClipboard text={props.accountName}>
                    <span
                      onClick={() => {
                        notify.info("账号名已复制");
                      }}
                      style={{
                        marginLeft: 16,
                        fontSize: 12,
                        border: "1px solid #CC1543",
                        color: "#CC1543",
                        height: 36,
                        lineHeight: '34px',
                        paddingLeft: 4,
                        paddingRight: 4,
                        borderRadius: 6,
                      }}
                    >
                      复制账号
                    </span>
              </CopyToClipboard>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 16,
            }}
          >
            <div style={{color: 'rgba(0,0,0,0.45)', fontSize: 15}}>账号请填写：</div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
              fontWeight: 500,
              fontSize: 24,
              lineHeight: '34px',
              justifyContent: 'space-between',
            }}>
              {props.accountNo}
              <CopyToClipboard text={props.accountNo}>
                    <span
                      onClick={() => {
                        notify.info("银行账号已复制");
                      }}
                      style={{
                        marginLeft: 16,
                        fontSize: 12,
                        border: "1px solid #CC1543",
                        color: "#CC1543",
                        height: 36,
                        lineHeight: '34px',
                        paddingLeft: 4,
                        paddingRight: 4,
                        borderRadius: 6,
                      }}
                    >
                      复制账号
                    </span>
              </CopyToClipboard>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 16,
            }}
          >
            <div style={{color: 'rgba(0,0,0,0.45)', fontSize: 15}}>Reference请填写接龙号：</div>
            <div
              style={{
                flex: 1,
                fontWeight: 500,
                fontSize: 24,
                lineHeight: '34px',
                marginTop: 8,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>{props.orderIndex}
              <CopyToClipboard text={props.orderIndex}>
                    <span
                      onClick={() => {
                        notify.info("接龙号已复制");
                      }}
                      style={{
                        marginLeft: 16,
                        fontSize: 12,
                        border: "1px solid #CC1543",
                        color: "#CC1543",
                        height: 36,
                        lineHeight: '34px',
                        paddingLeft: 4,
                        paddingRight: 4,
                        borderRadius: 6,
                      }}
                    >
                      复制账号
                    </span>
              </CopyToClipboard>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 16,
            }}
          >
            <div style={{color: 'rgba(0,0,0,0.45)', fontSize: 15}}>付款金额请填写：</div>
            <div style={{flex: 1, fontWeight: 500, fontSize: 24, lineHeight: '34px', marginTop: 8}}>
              {props.amount}
            </div>
          </div>
          <div style={{marginTop: 24, color: "#CC1543"}}>
            *Reference务必填写订单号
          </div>
          <div style={{color: "#CC1543", lineHeight: "30px", marginTop: 8}}>
            *付款成功请截图，并上传付款截图
          </div>
        </div>
        {props.showQRCode && (
          <div className="qrcode-container">
            <QRCodeSVG
              size={120}
              value={
                WEB_ROOT +
                LOCAL_URL["PAYMENT_UPLOAD"] +
                `?order=${props.orderId}`
              }
            />
            <WhiteSpace/>
            <div className="qrcode-title">微信扫一扫</div>
            <div className="qrcode-title">上传付款截图</div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default UploadReceiptView;

const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;

  .title {
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    width: 100%;
    text-align: center;
  }

  .title2 {
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  .order-no {
    a {
      font-size: 15px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      text-decoration: none;
    }
  }

  .desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
  }

`;
