import React, { createContext, useState } from 'react';
import { request, requestPost, requestPut, requestDelete } from '../lib';
import { LocationDS } from './data.d';
// import { RECOMMENDATION_COFFEEPASS_DATA } from './data/index';
import { checkResponseBody } from './helper';
import { GROUP_CONFIG } from "./group";

interface EntityModel {
  apiUrl: string;
  response: any;
  ready: boolean;
}
interface ContextModel {
  [entityName: string]: EntityModel;
}

const initEntityModel = (name, apiUrl) => ({
  [name]: {
    apiUrl,
    response: {},
    ready: false,
  }
})
const initContextModel = (entities) => (
  entities.reduce((total, item) => ({
    ...total,
    ...initEntityModel(item.name, item.url)
  }), {})
)

const ENTITY_CONFIG = [
  { name: 'GLOBAL_DATA', url: null, },                   // 存放供全局使用的，经过加工的数据（从 HOME_PAGE_DATA 而来）
  { name: 'PLACE_ORDER_GLOBAL_DATA', url: null, },
  { name: 'PRODUCT_TEMPLATE_PAGE', url: null, },
  { name: 'STORE_CONFIG', url: null, },
  { name: 'PRODUCT_TEMPLATE_CATEGORY', url: 'production-template/category', },
  { name: 'ORDER', url: 'order', },
  { name: 'ADDRESS', url: 'address', },
  { name: 'PRODUCTION_TEMPLATE', url: 'production-template', },
  { name: 'GROUP_BUY', url: 'group', },
  { name: 'GROUP_BUY_LIST', url: 'group/find-by-merchant', },
  { name: 'MERCHANT', url: 'merchant' },
  { name: 'ALL_MERCHANT', url: 'merchant/all' },
  { name: 'MERCHANT-USERS', url: 'merchant/users' },
  { name: 'INVITE_MEMBERS', url: 'invite-members' },
  { name: 'ACCEPT-INVITING', url: 'invite-members/accept-inviting' },
  { name: 'MY_GROUPS', url: 'group/my-groups' },
  { name: 'MY_GROUPS_IN_ORDERS', url: 'group/my-groups-in-orders' },
  { name: 'RECENTLY_VIEWED_GROUPS', url: 'group/recently-viewed-groups' },
  { name: "MY_ORDERS", url: 'order/my-orders' },
  ...GROUP_CONFIG
]

const MOCK_DATA = ENTITY_CONFIG.reduce((total, item) => ({
  // ...total,
  // [item.name]: item.mock,
}), {});

export const EntityContext = createContext<any>({});
export const EntityContextProvider = ({ children }) => {
  const [model, setModel] = useState<any>(initContextModel(ENTITY_CONFIG));

  const validateEntityName = (name) => (Object.keys(model).includes(name));

  const create = async (entityName: string, payload: any) => {
    // console.log(`[${entityName}] create`)
    if (!validateEntityName(entityName)) {
      return;
    }
    const response = await requestPost(model[entityName].apiUrl, payload);

    // console.log(response);
    const data = checkResponseBody(response);
    // console.log(data);

    return data;
  }
  const remove = async (entityName: string, payload: any, noSync: boolean = false) => {
    // console.log(`[${entityName}] remove`)
    if (!validateEntityName(entityName)) {
      return;
    }

    const response = await requestDelete(model[entityName].apiUrl, payload);
    // console.log(response);
    if (!noSync) {
      model[entityName].response = response;
      setModel({ ...model });
    }
    return response;
  }
  const update = async (entityName: string, payload: any) => {
    console.log(`[${entityName}] update`)
    if (!validateEntityName(entityName)) {
      return;
    }
    const response = await requestPut(model[entityName].apiUrl, payload);
    // console.log(response);
    const data = checkResponseBody(response);
    model[entityName].response = response;
    // setModel({ ...model });  // 这里返回的数据格式，不是表记录，而是 SQL 执行结果报告 
    return data;
  }
  const query = async (entityName: string, payload: any = undefined) => {
    // console.log(`[${entityName}] query`);
    if (!validateEntityName(entityName)) {
      return;
    }
    const response = await request(model[entityName].apiUrl, 'get', payload);
    // console.log(entityName, response);
    model[entityName].response = response;
    model[entityName].ready = true;
    setModel({ ...model });
    return response;
  }
  const queryBypass = async (entityName: string, payload: any = undefined) => {
    // console.log(`[${entityName}] queryBypass`)
    if (!validateEntityName(entityName)) {
      return;
    }
    return await request(model[entityName].apiUrl, 'get', payload);
  }
  const fetch = async (entityName: string, payload: any) => {
    // console.log(`[${entityName}] fetch`)
    if (!validateEntityName(entityName)) {
      return;
    }
    const response = await requestPost(model[entityName].apiUrl, payload);
    // console.log(response);
    const data = checkResponseBody(response);
    // console.log(data);
    model[entityName].response = response;
    model[entityName].ready = true;
    setModel({ ...model });

    return data;
  }
  const flush = async (entityName: string) => {
    // console.log(`[${entityName}] mock`)
    if (!validateEntityName(entityName)) {
      return;
    }
    model[entityName].response = {};
    model[entityName].ready = false;
    setModel({ ...model });
  }
  const replace = async (entityName: string, payload: any) => {
    if (!validateEntityName(entityName) || !payload) {
      return;
    }
    model[entityName].response = { ...model[entityName].response, ...payload };
    model[entityName].ready = true;
    setModel({ ...model });
  }

  const mock = async (entityName: string) => {
    // console.log(`[${entityName}] mock`)
    if (!validateEntityName(entityName)) {
      return;
    }
    model[entityName].response = MOCK_DATA[entityName];
    setModel({ ...model });
  }
  const shiftResponseData = (entityName) => {
    if (!model[entityName].response?.data?.length) return null;
    const item = model[entityName].response.data.shift();
    setModel({ ...model });
    return item;
  }

  const logout = () => {
    setModel({ ...initContextModel(ENTITY_CONFIG) });
  }

  const refreshGlobalDependence = () => {
    console.log('refreshGlobalDependence');
    query('ALL_MERCHANT');
    // query('MY_MEMBERSHIPS');
    // query('MY_CARDS');

    // query('MY_STATISTICS');
    // query('MY_SURVEY_WAITINGG_ORDERS'); // 数据更新后，waitingList hook 应该会被触发，进而 payment hook 会因为 waitingList 而间接被触发
  }

  return (
    <EntityContext.Provider value={{
      model,

      // Sync
      flush, replace, mock, logout, shiftResponseData,

      // Async
      create,
      remove,
      update,
      fetch, query, queryBypass,
      refreshGlobalDependence,

    }}>
      {children}
    </EntityContext.Provider >
  )
}
