import styled from 'styled-components'
import { THEME } from '../config';

const RibbonBox = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;

  & p {
    position: absolute;
    top: 30px;
    left: 30px;
    transform: translateY(-2px) rotate(45deg);
  }
  & svg {
    fill: ${props => props.color || THEME.color.primary.default};
  }
`;

const SVGRibbon = () => (
  <svg width="107" height="107" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.67 0H0V8H35.67V0Z" fill="#800000"/>
    <path d="M107 71.3203H99V107H107V71.3203Z" fill="#800000"/>
    <path d="M104.63 61.87L54.77 12L45.14 2.37C43.62 0.85 41.57 0 39.43 0H0L8 8L106.98 107L107 106.99V67.58C107 65.44 106.15 63.38 104.63 61.87Z" />
  </svg>
)

interface RibbonProps {
  color?: string;
  [propName: string]: any
}
export const Ribbon:React.FC<RibbonProps> = ({color, children}) => {
  return (
    <RibbonBox color={color}>
      <SVGRibbon />
      {children}
    </RibbonBox>
  )
}