export const LOCATION = {
  // MAPBOX: { 
  //   PUBLIC_KEY: 'pk.eyJ1IjoiY2hyaXN3ZWkiLCJhIjoiY2ttcjJ4d2xxMDN2bjJ2cTVtcXU3a2IzZyJ9.kDxnCKatlakm0FyseqwwjA',
  //   STYLE: 'mapbox://styles/mapbox/streets-v11',
  //   API_ENDPOINT: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  //   MAP_CENTER: { 
  //     LONGITUDE: 174.763336,
  //     LATITUDE:  -36.848461,
  //   }
  // },
  AUCKLAND: { 
    lng: 174.763336,
    lat:  -36.848461,
  },
  CREDENTIAL: { 
    GOOGLE_MAP: {
      API_KEY: 'AIzaSyBHQTFoX6GPzz43PekYcpsRnkjymy3py88',
    }
  }
}
