import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NativeSelect, { NativeSelectProps } from '@mui/material/NativeSelect';
import { THEME } from '../../config';

const Frame = styled.div`
  width: 100%;
  height: 48px;
  padding: 12px;
  border-radius: 4px;
  border: ${(props) => props.border || `1px solid ${THEME.color.gray['#0-087']}`} ;
  background: #fff;
`;
const SelectContainer = styled.div`
  padding-right: 4px;
  border-right: 1px solid ${THEME.color.gray['#0-087']};
  & svg {
    fill: ${THEME.color.primary.default};
  }
`;
const InputContainer = styled.div`
  height: 100%;
  padding-left: 12px;
  flex-grow: 1;
`;
const Input = styled.input`
  width: 100%;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: .6px;
  transform: translateY(2px);
  border: none;
  &:focus {
    outline: none;
  }
`;

const AREA_CODE_OPTIONS = ['+64', '+86'];
const formatAreaCodeLabel = (code) => code;

interface InputProps {
  onChange?: (value: string) => void;
  name: string;
  value?: string;
  errors?: any;
  border?: string;
}
export const InputPhoneNumber: React.FC<InputProps> = ({onChange, name, value, errors, border}) => {
  const spacePos = (value || '').indexOf(' ');
  // console.log(value, spacePos);
  const ac = (spacePos > 0) ? value.slice(0, spacePos) : null;
  // console.log(ac);
  const [areaCode, setAreaCode] = useState( ((!!ac) && (AREA_CODE_OPTIONS.includes(ac))) ? ac : AREA_CODE_OPTIONS[0]);
  const [phoneNumber, setPhoneNumber] = useState( (spacePos > 0) ? value.slice(spacePos + 1) : '' );
  // console.log(areaCode, phoneNumber)

  const onAreaCodeChange = (e) => {
    // console.log(e.target.value)
    setAreaCode(e.target.value)
  }
  const onInputChange = (e) => {
    // console.log(e.target.value)
    if (e.target.value === '0') return;
    setPhoneNumber(e.target.value)
  }
  useEffect(() => {
    // console.log('effect')
    onChange(`${areaCode} ${phoneNumber}`);
  }, [areaCode, phoneNumber])

  // console.log(errors)
  return(
    <>
      <Frame border={border} className="flex-between align-center">
        <SelectContainer>
          <NativeSelect
            disableUnderline
            style={{ width: '56px'}}
            value={areaCode}
            onChange={onAreaCodeChange}
          >
          { AREA_CODE_OPTIONS.map((code, index) => (
            <option key={index} value={code}>{formatAreaCodeLabel(code)}</option>
          ))}
          </NativeSelect>
        </SelectContainer>

        <InputContainer>
          <Input type="tel" onChange={onInputChange} value={phoneNumber} />
        </InputContainer>

      </Frame>
      { (!errors || !errors[name]) ? null : <p className="ft-rr14 clr-ft-primary">{errors[name].message}</p> }
    </>
  )
}
