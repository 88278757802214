const IconPlus = (props: {color?: string}) => (
  <svg
    display="block"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00073 12C2.00073 17.5228 6.47788 22 12.0007 22C17.5236 22 22.0007 17.5228 22.0007 12C22.0007 6.47715 17.5236 2 12.0007 2C6.47788 2 2.00073 6.47715 2.00073 12ZM20.8007 12C20.8007 16.8601 16.8608 20.8 12.0007 20.8C7.14063 20.8 3.20073 16.8601 3.20073 12C3.20073 7.13989 7.14063 3.2 12.0007 3.2C16.8608 3.2 20.8007 7.13989 20.8007 12ZM17.0007 11.4V12.6H12.6007V17H11.4007V12.6H7.00073V11.4H11.4007V7H12.6007V11.4H17.0007Z"
      fill={props.color ?? "#CC1543"}
    />
  </svg>
);
export default IconPlus;
