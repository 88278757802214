import { useLocation } from 'react-router-dom'
import { Layout, Header } from '../../Layout'
import { CouponList } from './CouponList' 

export default function Page() {
  const location = useLocation()
  const queryParams = ('number' === typeof location.state?.preference?.id) ? 
                      {
                        preferenceId: location.state.preference.id,
                      } : undefined;
  // console.log(location.state.preference, queryParams)

  return (
    <Layout>
      <Header title={location.state?.preference?.name || "优惠券发放统计"} />
      <CouponList mode={'BY-PREFERENCE'} queryParams={queryParams}></CouponList>
    </Layout>
  )
}
