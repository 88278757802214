import * as React from "react";
import { EntityContext } from "../context";
import { currentmerchantCache } from "../lib";

export default function useMerchant() {
  const { model: entityModel } = React.useContext(EntityContext);
  let merchants = entityModel["ALL_MERCHANT"].response?.data;

  const cachedMerchantId = currentmerchantCache.get();

  const [currentMerchantId, setCurrentMerchantId] = React.useState<
    number | undefined
  >(cachedMerchantId ? Number(cachedMerchantId) : undefined);

  React.useEffect(() => {
    if (cachedMerchantId) {
      setCurrentMerchantId(+cachedMerchantId);
    }
  }, [cachedMerchantId]);

  const switchMerchant = (merchantId: number) => {
    setCurrentMerchantId(merchantId);
    currentmerchantCache.set(merchantId + "");
  };

  const appendMerchant = (merchant: any )=>{
    if (merchants){
      merchants.push(merchant)
    }else {
      merchants = [merchant]
    }
  }

  const getCurrentMerchant = () => {
    if (!merchants?.length || !currentMerchantId) {
      return {};
    }
    const curMerchant = merchants.filter((m) => m.id === currentMerchantId)?.[0]
    return curMerchant || merchants[0];
  };

  const isCurrentMerchantSupportCNY = () => {
    if (!merchants || !currentMerchantId) {
      return false;
    }

    const curerntMerchant = merchants.filter(
      (m) => m.id === currentMerchantId
    )[0];

    if (
      curerntMerchant &&
      curerntMerchant.finance &&
      curerntMerchant.finance.paymentMethod &&
      curerntMerchant.finance.paymentMethod.length > 0
    ) {
      return curerntMerchant.finance.paymentMethod.includes("WECHAT_NIUYAO");
    }
    return false;
  };

  React.useEffect(() => {
    if (merchants && merchants.length === 1 && !currentMerchantId) {
      setCurrentMerchantId(merchants[0].id);
      currentmerchantCache.set(merchants[0].id + "");
    }
  }, [merchants, currentMerchantId]);

  return {
    switchMerchant,
    currentMerchantId,
    merchants,
    getCurrentMerchant,
    isCurrentMerchantSupportCNY,
    appendMerchant
  };
}
