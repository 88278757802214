import React, {useContext, useEffect} from "react";
import {UserContext} from "../../context";
import {useHistory} from "react-router-dom";

export default function ClearPage(){
  const history = useHistory()
  const { logout } = useContext(UserContext);

  useEffect(()=>{
    logout()
    history.replace('/')
  })
  return <div>
    退出登录
  </div>
}
