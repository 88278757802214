import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import { EntityContext } from '../context';
import { EXTERNAL_URL } from '../config';
import { calculateCurrentTimeTableItemId, filterNavGroupsIds } from "../lib";

// const IMAGE_STORE_ROOT= `${EXTERNAL_URL.IMAGE_STORE_ROOT}/c_scale,w_48/`;
const IMAGE_STORE_ROOT  = `${EXTERNAL_URL.IMAGE_STORE_ROOT}/`;

export function useSetting () {
  
  const { model: entityModel } = useContext(EntityContext);

  const { allStores, navGroups, groupMerchantIds, storeCategories, recommendGroups, } = useMemo(() => {
    const {allStores, timeTable, ttNavGroupsIds, navGroups, groupMerchantIds} = entityModel['GLOBAL_DATA']?.response?.data || {};

    const ttItemId        = calculateCurrentTimeTableItemId(timeTable);
    const validGroupsIds  = filterNavGroupsIds(ttNavGroupsIds, ttItemId);
    const recommendGroups = navGroups?.filter(c => (c.position === 'RECOMMEND') && validGroupsIds.includes(c.id)).sort((a, b) => (a.index < b.index) ? -1 : 1);
    const storeCategories = navGroups?.filter(c => (c.status === 'ON') && (c.position === 'BUTTON')).sort((a, b) => (a.index < b.index) ? -1 : 1).map((btn) => ({ 
      ...btn,
      icon: `${IMAGE_STORE_ROOT}${btn.icon}.jpg`,
    }));
    // console.log('###################')
    // console.table('time table id', ttItemId);
    // console.table('valid group ids');
    // console.table(validGroupsIds);
    // console.table('recommend groups');
    // console.table(recommendGroups);
    // console.table('manual group merchant ids');
    // console.table(groupMerchantIds);

    return {
      allStores, navGroups,
      groupMerchantIds,
      storeCategories,
      recommendGroups,
    }

  }, [entityModel['GLOBAL_DATA']?.response?.data])
  // console.log('*******************')

  /**
    Todo: 这里 ready 状态和 response.data 状态不同步，有时间需要深入查一下
    console.log('setting', entityModel['GLOBAL_DATA'].ready, entityModel['GLOBAL_DATA'].response?.data)
   */

  const findChannelBySlug = (slug: string)  => (navGroups?.find(ch => ch?.slug === slug))
  return ({
    isDataReady: (!!entityModel['GLOBAL_DATA'].ready && !!entityModel['GLOBAL_DATA']?.response?.data),
    allStores,
    groupMerchantIds,
    
    storeCategories,
    recommendGroups,

    findChannelBySlug,
  })
}
