import * as React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {getImageById} from "../utils/ImageUtils";
import {Gallery} from "./Carousel.gallery";
import IconClose from "./Icons/IconClose";
import styled from "styled-components";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  outline: "none",
};

export interface IImageDialogProps {
  visible: boolean;
  onClose: () => void;
  url: string[];
}

const StyledCloseButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export default function ImageDialog(props: IImageDialogProps) {
  const { visible, onClose, url } = props;
  const imageUrls = props.url?.map(item=>getImageById(item)) || []
  return (
    <Modal
      style={{ outline: "none" }}
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box alignItems="center" alignContent="center" sx={style}>
        {/*<img*/}
        {/*  style={{ maxWidth: "90vw", maxHeight: "90vh" }}*/}
        {/*  alt="big-image"*/}
        {/*  src={url}*/}
        {/*/>*/}
        <Gallery
          images={imageUrls}
          showPageNumber
          width='90vw'
        />
        <StyledCloseButton onClick={onClose}>
          <IconClose />
        </StyledCloseButton>
      </Box>
    </Modal>
  );
}
