import {Backdrop} from "@mui/material";
import React, {useEffect} from "react";
import styled from "styled-components";
import WaringIcon from "../../../components/Icons/WarningIcon";
import WhiteSpace from "../../../components/WhiteSpace";
import {THEME} from "../../../config";

interface RedeemCouponWarningTypes {
  open: boolean
  onCancel: () => void
  onOK: () => void
}

const RedeemCouponWarning = (props: RedeemCouponWarningTypes) => {

  return (
    <Backdrop
      open={props.open}
      onClick={() => {
        props.onCancel()
      }}
    >
      <Container>
        <div className='card'>
          <div className='icon'>
            <WaringIcon/>
          </div>
          <WhiteSpace height={8}/>
          <div className='title'>重要提示</div>
          <WhiteSpace/>
          <div className='desc'>
            请务必到店，由店铺员工操作使用<br/>
            本券一旦使用，不可恢复
          </div>
          <WhiteSpace height={35}/>
          <div className='operation-area'>
            <div className='cancel'
              onClick={()=>{
                props.onCancel()
              }}
            >取消</div>
            <div className='ok'
                 onClick={()=>{
                   props.onOK()
                 }}
            >我是店员，确认使用</div>
          </div>
        </div>
      </Container>
    </Backdrop>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 90%;

  .card {
    padding: 24px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: ${THEME.color.gray["#10-085"]};
    }
    .desc{
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.gray["#10-085"]};
      text-align: center;
    }
    .operation-area{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex: 1;
      .cancel{
        background: #F5F5F5;
        border: 1px solid rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        width: 86px;
        text-align: center;
        padding: 4px 8px;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
      }
      .ok{
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        color: ${THEME.color.primary.default};
        width: 160px;
        text-align: center;
        padding: 4px 8px;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
`

export default RedeemCouponWarning
