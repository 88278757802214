// const HOME = '/app/lovinlocal';
const HOME = "";
export const WEB_ROOT = `${window.location.protocol}//${window.location.host}`;
// (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'master') ? `https://lovinlocal.vercel.app`
// :
// (process.env.NODE_ENV === 'development') ? `http://localhost:9090`
// :
// `https://lovinlocal-web.vercel.app`
// ;
// console.log(window.location)
// console.log(WEB_ROOT)

// const PRIVATE_SLUG_ROOT = `${HOME}/private/slug`;
const PRIVATE_SLUG_ROOT = `${HOME}/private`;

export const LOCAL_URL = {
  TEST_WECHAT_SHARE: `${HOME}/private/testing/wechat/share`,

  PAYMENT_RETURN_URL: `${WEB_ROOT}${HOME}/private/payment/return`,
  PAYMENT_RETURN: `${HOME}/private/payment/return`,
  ORDER_CONFIRM: `${HOME}/private/payment/confirm`,
  WAITING_CONFIRM: `${HOME}/private/payment/waiting-confirm`,
  CHOOSE_PAYMENT: `${HOME}/private/payment/choosePayment`,
  PAYMENT_DONE: `${HOME}/private/payment/done`,
  PAYMENT_UPLOAD: `${HOME}/public/payment/upload`,
  RECEIPT_VIEW: `${HOME}/private/receipt`,
  ORDER_SELECT_ADDRESS: `${HOME}/private/payment/address`,
  TRANS_ACCOUNT: `${HOME}/private/trans-account`,
  // OAUTH_RETURN_URL                : `${WEB_ROOT}${HOME}/oauth/return`,
  // OAUTH_RETURN                    : `${HOME}/oauth/return`,

  // PREMIUM_SUPPORT                 : `${HOME}/premium/support`,
  // CUSTOMER_SUPPORT                : `${HOME}/customer/support`,

  // DOC_CONTENT                     : `${HOME}/doc/statements`,
  // PRIVACY_POLICY                  : `${HOME}/privacypolicy`,

  // NOTICE_ABOUT_WECHAT             : `${HOME}/notice/about/wechat`,

  // PARTNERS                        : "/partners",
  ROOT: "/",

  PROMOTION_LIST: `${HOME}/promotion/list`,
  PROMOTION_DETAIL: `${HOME}/promotion/detail`,
  PROMOTION_CREATE_NEW: `${HOME}/promotion/new`,
  PROMOTION_EDITING: `${HOME}/promotion/editing`,
  PREFERENCE_LIST: `${HOME}/preference/list`,
  PREFERENCE_SELECT: `${HOME}/preference/select`,
  PREFERENCE_DASHBOARD: `${HOME}/preference/dashboard`,
  PREFERENCE_DETAILS: `${HOME}/preference/details`,
  COUPON_LIST: `${HOME}/coupon/list`,
  COUPON_DETAILS: `${HOME}/coupon/details`,
  COUPON_DETAILS_IN_PROMOTION: `${HOME}/promotion/coupon/details`,
  COUPON_REDEEM: `${HOME}/coupon/redeem`,

  EXCEPTION: `${HOME}/exception`,
  UI_SPEC: `${HOME}/ui/spec`,
  TESTING: `${HOME}/testing`,

  WELCOME: `${HOME}/private/welcome`,
  WARNING: `${HOME}/warning`,
  ABOUT: `${HOME}/about`,
  // MEMBERSHIP                      : `${HOME}/membership`,
  // COFFEE_PASS                     : `${HOME}/coffeepass`,
  // FAQ                             : `${HOME}/faq`,
  // ABOUT                           : `${HOME}/about`,

  USER_LOGIN: `${HOME}/auth/wechatLogin`,
  CLEAR: `${HOME}/clear`,
  CUSTOMER_SERVICE: `${HOME}/customerService`,
  CUSTOMER_SUPPORT: `${HOME}/common/customerSupport`,
  GROUP_BUY_DETAIL: `${HOME}/public/group-buy/detail`,
  SHOPPING_DETAIL: `${HOME}/public/shopping/detail`,
  MERCHANT_DETAIL: `${HOME}/public/merchant/detail`,
  // USER_LOGOUT                     : `${HOME}/user/logout`,
  // USER_PASSWORD_RESET             : `${HOME}/user/password/reset`,

  PRIVATE: `${HOME}/private`,

  // PRIVATE_SLUG_ROOT,
  // PUBLIC_CHANNEL_SLUG             : `${HOME}/public/channel`,
  // CHANNEL_SLUG                    : `${PRIVATE_SLUG_ROOT}/channel`,
  // STORE_SLUG                      : `${PRIVATE_SLUG_ROOT}/shop`,

  // STORE_OFFER                     : `${HOME}/private/ShoppingMall/offer`,
  // STORE_IMAGE_GALLERY             : `${HOME}/private/ShoppingMall/imagegallery`,

  PAYMENT_AMOUNT_BILL: `${HOME}/private/payment/amountbill`,
  PAYMENT_BY_BANK_CARD: `${HOME}/private/payment/by/bankcard`,
  PAYMENT_RECEIPT: `${HOME}/private/payment/receipt`,
  REDEEM_RESULT: `${HOME}/private/redeem`,

  // MAP_VIEW                        : `${HOME}/private/mapview`,

  // SEARCH                          : `${HOME}/private/search/index`,
  // SEARCH_RESULT                   : `${HOME}/private/search/result`,

  // LOCATION_INPUT                  : `${HOME}/private/location/input`,

  // MY_ORDERS                       : `${HOME}/private/myorders`,
  // ORDER_DETAILS                   : `${HOME}/private/Order/details`,

  PERSONAL_CENTER: `${HOME}/private/personal/center`,
  CREATE_GROUP_BUY: `${HOME}/private/create-group-buy`,
  SHOPPING_MANAGE_PAGE: `${HOME}/private/shopping/manage`,
  SHOPPING_MANAGE_PRODUCTS_PAGE: `${HOME}/private/shopping/products/manage`,
  CREATE_GROUP_BUY_TYPE: `${HOME}/private/group_buy_meta_type`,
  CREATE_GROUP_BUY_SELECT: `${HOME}/private/group_buy_meta_select`,
  MANAGE_STORE: `${HOME}/private/store/manage`,
  MANAGE_STORE_PRODUCTS: `${HOME}/private/store/products/manage`,
  CREATE_PRODUCT: `${HOME}/private/create-product`,
  STORE_CREATE_PRODUCT: `${HOME}/private/store/create-product`,
  ADD_PICK_UP_POINTER: `${HOME}/private/add-pick-up-pointer`,
  SELECT_ADDRESS: `${HOME}/private/select-address`,
  SELECT_BACKGROUND: `${HOME}/private/select-background`,
  SELECT_THEME_COLOR: `${HOME}/private/select-theme-color`,
  MY_ADDRESS: `${HOME}/private/my-address`,
  ADD_ADDRESS: `${HOME}/private/add-address`,
  PRODUCT_TEMPLATE: `${HOME}/private/product-templates`,
  PRODUCT_CATEGORY_LIST: `${HOME}/private/product-template/category`,
  CATEGORY: `${HOME}/private/category`,
  STORE_CATEGORY: `${HOME}/private/store/category`,
  MERCHANT_CATEGORY: `${HOME}/private/merchant/category`,
  GROUP_LIST: `${HOME}/private/group-list`,
  ADD_MERCHANT: `${HOME}/private/add-merchant`,
  MANAGE_ORDER: `${HOME}/private/manage/order`,
  CHANNEL_SETTING: `${HOME}/private/group/channel/setting`,
  GROUP_STATIST: `${HOME}/private/manage/group-statist`,
  ORDER_EXPORT: `${HOME}/private/manage/order-export`,
  ORDER_DETAIL: `${HOME}/private/order/detail`,
  INVITE_MEMBER: `${HOME}/private/invite-member`,
  ACCEPT_INVITING: `${HOME}/private/accept-inviting`,
  SWITCH_MERCHANT: `${HOME}/private/switch-merchant`,
  MY_ORDERS: `${HOME}/private/my-orders`,
  ADD_MERCHANT_SUCCESS: `${HOME}/private/add-merchant-success`,
  ADD_REMARK: `${HOME}/private/add/remark`,
  DELIVERY_PAGE: `${HOME}/private/delivery`,
  DELIVERY_ADD_PAGE: `${HOME}/private/delivery-add`,

  // MY_PREMIUM                      : `${HOME}/private/my/premium`,
  // MY_POINTS                       : `${HOME}/private/my/points`,
  // MY_PROFILE                      : `${HOME}/private/my/profile`,
  // MY_COFFEE_PASS                  : `${HOME}/private/my/coffeepass`,
  // MY_VOUCHERS                     : `${HOME}/private/my/vouchers`,
  // MY_FAVORITES                    : `${HOME}/private/my/favorites`,
  // MY_WALLET                       : `${HOME}/private/my/wallet`,

  // VERIFY                          : `${HOME}/private/verify`,

  USER_PROFILE_TAG: `${HOME}/private/user/profile/tag`,
  // USER_PROFILE_RECOMMEND          : `${HOME}/private/user/profile/recommend`,
};
