import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {requestGet} from "../../lib";
import {Grid} from "@mui/material";
import {THEME} from "../../config";
import numeral from 'numeral'

function SalesStatistPage() {
  const [result, setResult] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const history: any = useHistory()
  const getStatistData = async () => {
    const {data} = await requestGet('manage/group/statist', {id: history.location.state.groupId})
    if (data) {
      setResult(data)
      const t = data.reduce((a, b) => {
        return a + b.price
      }, 0)
      setTotal(t)
      setCount(data.reduce((a, b) => {
        return a + b.amount
      }, 0))
    }
  }
  useEffect(() => {
    getStatistData()
  }, [])
  return (
    <PageLayout pageName="no nav sales statist">
      <Header title="销售统计"/>
      <Container>
        <Grid container spacing={2} style={{fontSize: 15}}>
          <Grid item xs={7}>
            商品名称
          </Grid>
          <Grid item xs={2} style={{textAlign: "center"}}>
            数量
          </Grid>
          <Grid item xs={3} style={{textAlign: "center"}}>
            销售额
          </Grid>
        </Grid>
        {result.map(item => {
          return (
            <div style={{marginTop: 4}} key={item.title}>
              <Grid container spacing={2} style={{fontSize: 12, paddingTop: 4}}>
                <Grid item xs={7}>
                  {item.title}
                </Grid>
                <Grid item xs={2}  style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  {item.amount}
                </Grid>
                <Grid item xs={3} style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent:'flex-end'}}>
                  {numeral(item.price / 100).format('$0,0.00')}
                </Grid>
              </Grid>
            </div>
          )
        })}
        {result.length > 0 && (
          <Grid container spacing={2} style={{fontSize: 15, marginTop: 1}}>
            <Grid item xs={7} style={{fontWeight: 500}}>
              总计
            </Grid>
            <Grid item xs={2} style={{textAlign: "center"}}>
              {count}
            </Grid>
            <Grid item xs={3} style={{textAlign: "center"}}>
              {numeral(total / 100).format('$0,0.00')}
            </Grid>
          </Grid>
        )}
      </Container>
    </PageLayout>
  )
}

export default SalesStatistPage

const Container = styled.div`
  padding: 16px;
  color: ${THEME.color.gray["#0-087"]};
`
