import {useContext, useEffect, useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import {useHistory, useLocation } from "react-router-dom";
import {isEmpty} from "../../lib";
import Card from "../Payment/Components/Card";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import numeral from "numeral";
import {LOCAL_URL} from "../../config";
import {InputSearch} from "./components/Input.Search";
import IconTip from "../../components/Icons/IconTip";
import {useDialog} from "../../hooks";
import OrderOperatorMenu from "./components/OrderOperatorMenu";
import { OrdersContext, OrderPaymentStatusType, OrderDeliveryStatusType } from "../../context";
import { OrderList } from './OrderList'
import { OrderTab, Container } from './Order.styled'
import { PAYMENT_STATUS_LIST, DELIVERY_STATUS_LIST } from './Order.data'

export default function OrderManagement() {
  const history: any = useHistory();
  const location = useLocation();
  const {
    model, countByPaymentStatus, countByDeliveryStatus,
    updateModel, initial, fetchData,
  } = useContext(OrdersContext)
  const {
    records: orders,
    page, hasMore,
    groupInfo,
    total, validOrdersCount, cancelCount,
    totalPrice, totalMoney, totalPriceCNY,
    paymentStatus: currentSelected,
    deliveryStatus: currentDeliverySelected,
    isDataDirty,
  } = model || {};
  const [queryInput, setQueryInput] = useState<string>("");

  const checkState= () => {
    if (location.state?.id === undefined) {
      history.go(-1)
      return false
    }
    else return true
  }
  useEffect(() => {
    if (!checkState()) return
    // console.log('### [manage/order] effect ', history.action, location.state, model)
    if (
      /**
       * 如果通过 history.push 来到这个页面的时候
       * 需要初始化
       */ 
      (history.action === 'PUSH') ||
      /**
       * 当用户手动点击浏览器刷新按钮的时候，action === 'POP'
       * 这时候通过判断 model.records 确定缓存里面没有数据
       * 也需要初始化
       */ 
      ((history.action === 'POP') && (!model.records?.length))
    ) {
      initial(location?.state?.id);
    }
    /**
     * 否则，意味着是从其他页面回退回来的
     * 这时候，使用之前 contextmodel 里面的数据
     */
  }, []);
  useEffect(() => {
    if (!checkState()) return
    // console.log('### [manage/order] effect isDataDirty', isDataDirty)
    if (isDataDirty) {
      setTimeout(() => {
        // to make sure the context model is fully updated
        fetchData(location?.state?.id);
      }, 50);
    }
  }, [isDataDirty])

  const onLoadMore = () => {
    updateModel({
      page: page + 1,
      isDataDirty: true,
    })
  }
  const onPaymentStatusChange = (statusValue: OrderPaymentStatusType) => () => {
    updateModel({
      records: [],
      page: 1,
      paymentStatus: statusValue,
      isDataDirty: true,
    })
  }
  const onDeliveryStatusChange = (statusValue: OrderDeliveryStatusType) => () => {
    updateModel({
      records: [],
      page: 1,
      deliveryStatus: statusValue,
      isDataDirty: true,
    })
  }
  const onSearch = (v?:string) => {
    updateModel({
      records: [],
      page: 1,
      queryString: v ?? queryInput,
      isDataDirty: true,
    })
  }
  const onQueryInputChange = (v:string) => {
    setQueryInput(v)
    if (v === '') {
      onSearch(v)
    }
  }

  // console.log('render orders', orders)
  const {showDialog, renderDialog} = useDialog();
  return (
    <PageLayout pageName="no nav orderManage">
      <Header title="订单管理"/>
      <Container style={{}}>
        <Card>
          <div
            className="title"
            onClick={() => {
              if (groupInfo?.type === 'DEFAULT') {
                history.push(
                  LOCAL_URL["GROUP_BUY_DETAIL"] +
                  "?groupId=" +
                  location?.state?.id
                );
              } else if (groupInfo?.type === 'STORE') {
                history.push(
                  LOCAL_URL["SHOPPING_DETAIL"] +
                  "?groupId=" +
                  location?.state?.id
                );
              }

            }}
          >
            <div
              className="left">{groupInfo?.type === 'DEFAULT' ? (groupInfo.title || '') : `${groupInfo.merchant?.name || ''}在线网店`}</div>
            <div className="right">
              团购详情 <ChevronRightIcon/>
            </div>
          </div>
        </Card>
        <Card>
          <div className="statist">
            <div className="item border">
              <div className="top red">{validOrdersCount}</div>
              <div className="label">
                已付订单数
                <IconTip
                  onClick={() =>
                    showDialog({
                      title: "支付订单数",
                      message:
                        "支付订单数统计的是已经支付并且没有取消的订单总数。",
                    })
                  }
                />
              </div>
            </div>
            <div className="item border">
              <div className="top">{cancelCount}</div>
              <div className="label">
                退款订单数
                <IconTip
                  onClick={() =>
                    showDialog({
                      title: "退款订单数",
                      message: "退款订单数统计的是支付后取消的订单总数。",
                    })
                  }
                />
              </div>
            </div>
            <div className="item">
              <div className="top red">
                {totalPriceCNY > 0 ? (
                  <div
                    style={{
                      fontSize: 12,
                      lineHeight: "17px",
                    }}
                  >
                    ￥{numeral(totalPriceCNY / 100).format("0,0.00")}
                  </div>
                ) : null}
                <div
                  style={{
                    fontSize: totalPriceCNY > 0 ? 12 : 17,
                    lineHeight: totalPriceCNY > 0 ? "17px" : "24px",
                  }}
                >
                  {numeral(totalPrice / 100).format("$0,0.00")}
                </div>
              </div>
              <div className="label">
                已付订单金额
                <IconTip
                  onClick={() =>
                    showDialog({
                      title: "支付订单金额",
                      message:
                        "支付订单金额统计的是已经支付并且没有取消的订单总金额",
                    })
                  }
                />
              </div>
            </div>
          </div>
        </Card>
      </Container>
      <div style={{width: 200, margin: 'auto', marginTop: 16}}>
        <OrderOperatorMenu groupId={location?.state?.id}/>
      </div>
      <OrderTab>
        <div className="search-container">
          <InputSearch
            value={queryInput}
            placeholder="订单号、商品名称、手机号、用户名"
            onChange={onQueryInputChange}
            onSearch={onSearch}
          />
        </div>
        <div className='tab-title'>付款状态筛选</div>
        <div className="tab-container">
          {PAYMENT_STATUS_LIST.map((item, index) => (
            <div
              className="tab-item"
              key={index}
              onClick={onPaymentStatusChange(item.value)}
            >
              { countByPaymentStatus[item.value] > 0 &&
              (item.value === "PER_PAID" ||
                item.value === "CREATED" ||
                item.value === "PAID" ||
                item.value === "CANCEL_APPLY") ? (
                <div className="count">
                  <span className="count-label">{countByPaymentStatus[item.value]}</span>
                </div>
              ) : (
                <div style={{height: 16}}/>
              )}
              <div
                className='label-wrap'
              >
                <div className={`label${
                  item.value === currentSelected ? " label-selected" : ""
                }`}>
                  {item.label}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='tab-title'>发货状态筛选</div>
        <div className="tab-container">
          {DELIVERY_STATUS_LIST.map((item, index) => (
            <div
              className="tab-item fixed-width"
              key={index}
              onClick={onDeliveryStatusChange(item.value)}
            >
              { countByDeliveryStatus[item.value] > 0 &&
              (item.value === "ALL") ? (
                <div className="count">
                  <span className="count-label">{countByDeliveryStatus[item.value]}</span>
                </div>
              ) : (
                <div style={{height: 16}}/>
              )}
              <div
                className='label-wrap'
              >
                <div className={`label${
                  item.value === currentDeliverySelected ? " label-selected" : ""
                }`}>
                  {item.label}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='result'>筛选结果：共 <span className='number'>{total}</span> 条订单,订单金额：<span className='number'>{numeral(totalMoney).divide(100).format('$0,0.00')}</span></div>
      </OrderTab>
      <OrderList
        loadMore={onLoadMore}
        hasMore={hasMore}
        orders={orders}
        groupInfo={groupInfo}
        refresh={() => {
          fetchData(location?.state?.id);
        }}
      />
      {renderDialog()}
    </PageLayout>
  );
}

/**
const StyledWechatInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(204, 21, 67, 0.3);
  padding: 8px 16px 8px 16px;
  align-items: center;

  .text {
    font-size: 12px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.65);
  }

  .btn-follow {
    width: 52px;
    height: 22px;
    background: #cc1543;
    border-radius: 8px;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
  }
`;

const WechatInfo = () => {
  return (
    <StyledWechatInfo>
      <div className="text">
        提示：所有的订单通知将通过微信公众号“新西兰神马网”发送，请关注公众号并留意消息。
      </div>
      <a href={WECHAT_FOLLOW_URL} target="_blank" className="btn-follow">
        关注
      </a>
    </StyledWechatInfo>
  );
};
 */