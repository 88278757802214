import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Button} from "../../../components";
import Header from "../../../components/Header";
import useMerchant from '../../../hooks/useMerchant'
import {
  requestDelete, requestGet, requestPost,
  returnResultOfPageFunction
} from "../../../lib";
import PageLayout from "../../Global/PageLayout";
import { toast } from '../../../Layout'
import IconAdd from "../../../components/Icons/IconAdd"
import {useHistory} from "react-router-dom";
import { CategoryCard } from './Card'
import { CategoryDetailsPage } from './Details'
import { ICategoryData, ICategoryPartialEntity } from './type'
import { fnSortNumberASC } from './helper'

const CATEGORY_NULL = {
  index: -1,
  title: '未分类',
}
const CATEGORY_NULL_DATA: ICategoryData = {
  cateId: null,
  categoryId: null,
  count: 0,
  index: CATEGORY_NULL.index,
  title: CATEGORY_NULL.title,
}

interface IEditingItem {
  id?:number;
  index?:number;
  title?: string;
}
export default function CategoryListPage() {
  const history = useHistory<any>();
  const { currentMerchantId } = useMerchant();
  const [selectIndex, setSelectedIndex] = useState<number>(0)
  const [categoryArray, setCategoryArray] = useState<ICategoryData[]>([])
  const [editingItem, setEditingItem] = useState<IEditingItem>(null)
  const isRoleAsPageFunction = (history.location?.state?.role === 'PAGE-FUNCTION')

  // console.log(currentMerchantId)
  // console.log(categoryArray)

  const initial = async (merchantId:number) => {
    const result = await requestGet(`category/product_count/by_merchant/${merchantId}`)
    // console.log(result)
    if (!!result?.data?.length) {
      result.data.forEach((item: ICategoryData) => {
        if (item.categoryId === null && item.title === null && item.index === null) {
          item.title = CATEGORY_NULL.title
          item.index = CATEGORY_NULL.index
        } else if (item.categoryId === null && item.count === null && item.cateId !==null ) {
          item.count = 0
          item.categoryId = item.cateId
        }
      })
      if (result.data[0].index !== -1) {
        result.data.unshift({ ...CATEGORY_NULL_DATA })
      }
      // console.log(result.data)
      setCategoryArray(result.data.sort(fnSortNumberASC))
    }
  }
  useEffect(() => {
    if (!currentMerchantId) {
      toast.error('数据错误！')
      history.go(-1)
    } else {
      initial(currentMerchantId)
    }
  }, [currentMerchantId])
  useEffect(() => {
    const currentCategoryId = history.location.state?.currentCategoryId
    if (
      ('number' === typeof currentCategoryId) &&
      (!!categoryArray.length) 
    ) {
      categoryArray.forEach((cate: ICategoryData, index: number) => {
        if (cate.categoryId === currentCategoryId) {
          setSelectedIndex(index)
        }
      })
    }
  }, [categoryArray])

  const onSelect = (index:number) => () => {
    if (index !== selectIndex) setSelectedIndex(index);
  }
  const onMove = (index:number) => async (direction: -1 | 1) => {
    // #1 必须先计算得到 payload，否则后面会改变 categoryArray 里面的内容，导致 payload 计算错误
    const payload = (direction < 0) ? {
      ids: [categoryArray[index].categoryId, categoryArray[index + direction].categoryId],
      indexOffset: categoryArray[index + direction].index,
    } : {
      ids: [categoryArray[index + direction].categoryId, categoryArray[index].categoryId],
      indexOffset: categoryArray[index].index,
    }
    // console.log(index, direction)
    // console.log(payload)

    // #2 这里开始后面的代码会修改 categoryArray
    const currentIndex = categoryArray[index].index;
    const targetIndex  = categoryArray[index + direction].index;
    categoryArray[index].index = targetIndex;
    categoryArray[index + direction].index = currentIndex;
    setCategoryArray((x) => [ ...x ].sort(fnSortNumberASC) )

    // API 调用的 payload 来自于 #1
    const result = await requestPost(`category/reindex`, payload)
    // console.log(result)
    // 为了提升用户性能，#2 会事先由前端更新一下列表顺序
    // 这里会再次获取后台数据，再次更新列表内容（用户感知不到）
    initial(currentMerchantId)
  }
  const onDelete = (categoryId: number, indexOffset: number) => async () => {
    // console.log(categoryId)
    const result = await requestDelete(`category`, {
      id: categoryId,
      indexOffset,
      ids: categoryArray.filter((x: ICategoryData) => (x.index > indexOffset)).map((x: ICategoryData) => x.categoryId)
    })
    initial(currentMerchantId)
  }

  const onExitEditing = (isCancelled:boolean) => {
    // console.log('exit', isCancelled)
    if (!isCancelled) initial(currentMerchantId)
    setEditingItem(null)
  }
  const onConfirm = () => {
    if (isRoleAsPageFunction && (selectIndex >= 0) && (!!categoryArray[selectIndex])) {
      returnResultOfPageFunction.set(JSON.stringify(categoryArray[selectIndex]))
    }
    history.go(-1)
  }

  return (
    (!!editingItem) ? 
    <CategoryDetailsPage
      data={{
        id: editingItem.id,
        title: editingItem.title
      }}
      newIndex={editingItem.index}
      exit={onExitEditing}
    />
    :
    <PageLayout pageName="category no nav">
      <Header title={ isRoleAsPageFunction ? "商品分类选择" : "商品分类管理"} right={(
        <HeaderRight onClick={() => {
          setEditingItem({ index: categoryArray.length - 1 })
        }}>
          <IconAdd />
          &nbsp;
          <span>创建新分类</span>
        </HeaderRight>
      )}/>
      <CategoryListingPageContainer>
        <ListContainer>
          { categoryArray?.map((item: ICategoryData, index: number) => {
            // 未分类的 index = 0
            // 第一个有效分类的 index = 1
            return (
              <CategoryCard
                key={index}
                noMoveUp  ={ (index === 0) || (index === 1) }
                noMoveDown={ (index === 0) || ((index+1) === categoryArray.length) }
                onMove={onMove(index)}
                onEdit={ (index === 0) ? undefined : () => setEditingItem({id: item.categoryId, title: item.title})}
                onDelete={ (index === 0) ? undefined : onDelete(item.categoryId, item.index)}
                onSelect={ isRoleAsPageFunction ? onSelect(index) : undefined}
                selected={selectIndex === index}
                {...item}
              />
            )
          }) }
        </ListContainer>

        { isRoleAsPageFunction &&
          <div className="bottom-float">
            <Button fullWidth onClick={onConfirm}>确定</Button>
          </div>
        }

      </CategoryListingPageContainer>

    </PageLayout>
  )
}


const HeaderRight = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;

  .plus {
    display: inline-block;
    text-align: center;
    font-size: 24px;
    height: 24px;
    line-height: 18px;
    width: 24px;
    border-radius: 12px;
    border: 1px solid white;
    margin-right: 8px;
  }
`

const CategoryListingPageContainer = styled.div`
  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`

const ListContainer = styled.div`
  padding: 16px;
  margin-bottom: 48px;
`

