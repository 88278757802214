import { useState, useContext } from 'react'
import styled from 'styled-components'
import {LOCAL_URL} from "../../config"
import { UserContext } from "../../context";
import { useLocationMerchantDataChecker } from '../../hooks'
import { 
  Layout, Header, IconSetting, 
  Switch, SwitchOption,
  ModalMask, Menu, MenuItem,
} from '../../Layout'
import { CouponList } from '../Coupon/CouponList'
import { PromotionList } from './PromotionList'

const Wrapper = styled.div`
  padding: 20px 1rem 0;
`

enum SwitchValueEnum { Promotion, Coupon }
const SWITCH_OPTIONS = [ SwitchValueEnum.Promotion, SwitchValueEnum.Coupon ]
const SWITCH_LABEL = ['本店活动', '我的卡券']

export default function Page() {
  const [showMenu, setShowMenu] = useState(false)
  const { location, searchParams, history, merchantData } = useLocationMerchantDataChecker()
  const { model: userModel } = useContext(UserContext);

  const paramSwitch = searchParams?.get?.('switch')
  const switchOp = !!paramSwitch ? parseInt(paramSwitch) : SwitchValueEnum.Promotion;
  const gotoSwitch = (op: SwitchValueEnum) => {
    history.replace(`${location.pathname}?switch=${op}`, {
      merchantData
    })
  }

  const toCloseMenu = () =>{
    setShowMenu(false);
  }
  const toCreateNewPromotion = () => {
    toCloseMenu();
    history.push(LOCAL_URL.PROMOTION_CREATE_NEW, {
      merchantData
    })
  }
  const toCreateNewPreference = () => {
    toCloseMenu();
    history.push(LOCAL_URL.PREFERENCE_DETAILS, {
      merchantData
    })
  }
  const toManagePreference = () => {
    toCloseMenu();
    history.push(LOCAL_URL.PREFERENCE_LIST, {
      merchantData
    })
  }
  const goBackToMerchantDetails = () => {
    // console.log(merchantData)
    history.push(LOCAL_URL["MERCHANT_DETAIL"] + `?id=${merchantData.id}`)
  }

  return (
    <Layout>

      <Header title="店铺活动" onBack={goBackToMerchantDetails} icon={merchantData?.isAdmin ? (
        <div onClick={() => setShowMenu(true)}>
          <IconSetting style={{display: 'block'}} />
        </div>
      ) : null} />

      <Wrapper>
        <Switch position={(switchOp === SwitchValueEnum.Promotion) ? 'left' : 'right'}>
        { SWITCH_OPTIONS.map((op, index:number)=>(
          <SwitchOption key={index}
            className={(switchOp === op) ? 'active' : ''}
            onClick={() => gotoSwitch(op)} 
          >
            {SWITCH_LABEL[op]}
          </SwitchOption>
        )) }
        </Switch>
      </Wrapper>

      { (switchOp == SwitchValueEnum.Promotion) &&
        <PromotionList />
      }
      { (switchOp == SwitchValueEnum.Coupon) && 
        ('number' === typeof userModel?.profile?.id) &&
        <CouponList mode="BY-USER" queryParams={{
          userId: userModel.profile.id,
        }}/>
      }

      { showMenu &&
      <ModalMask>
        <Menu style={{
          width: 'calc(100% - 16px)',
          bottom: '2rem',
        }}>
          <MenuItem onClick={toCreateNewPromotion}>创建活动</MenuItem>
          <MenuItem onClick={toCreateNewPreference}>创建优惠券</MenuItem>
          <MenuItem onClick={toManagePreference}>管理优惠券</MenuItem>
          <MenuItem className="cancel" onClick={toCloseMenu}>取消</MenuItem>
        </Menu>
      </ModalMask>
      }

    </Layout>
  )
}
