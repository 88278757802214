import {TextField} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Button, Divider} from "../../components";
import Header from "../../components/Header";
import LoadingDialog from "../../components/LoadingDialog";
import WhiteSpace from "../../components/WhiteSpace";
import {THEME} from "../../config";
import {EntityContext} from "../../context";
import {Radio} from "../../forms/Radio";
import {useDialog} from "../../hooks";
import {useRouter} from "../../hooks/router";
import {requestDelete, requestGet, requestPost, requestPut} from "../../lib";
import iconEdit from "../../svg/icon_edit.svg";
import iconRubbishBin from "../../svg/icon_rubbish.svg";
import iconUpEnable from "../../svg/icon_up_enable.svg";
import iconDownEnable from "../../svg/icon_down_enable.svg";
import iconUpDisable from "../../svg/icon_up_disable.svg";
import iconDownDisable from "../../svg/icon_down_disable.svg";
import PageLayout from "../Global/PageLayout";
import TTModal from "../Manage/components/Modal";
import {useHistory} from "react-router-dom";

type CategoryType = {
  id: number
  title: string,
  count: number,
  index: number
}

type ProductInGroupType = {
  category: CategoryType[],
}

interface ICategoryListingPageProps {
  location: {
    state: {
      type: 'GROUP' | 'PRODUCT',
      data: {
        category?: CategoryType[],
        productions: ProductInGroupType[]
        product: any
        merchantId: number
      }
    }
  }
}

export const DEFAULT_CATEGORY_NAME = '未分类'

const merchantCategoryUrl = 'production-template/category'


function MerchantCategoryListingPage(props: ICategoryListingPageProps) {
  const history = useHistory<any>();
  const router = useRouter()
  const {merchantId} = router.query
  const [name, setName] = useState<string>()
  const [showModal, setShowModal] = useState(false)
  const currentEditingCategory = useRef<CategoryType>()
  const [categoryGroupData, setCategoryGroupData] = useState<any>()
  const [selectedCategory, setSelectedCategory] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const {
    replace,
    model: entityModel,
  } = React.useContext(EntityContext);

  const getCategoryData = async () => {
    const {data} = await requestGet(merchantCategoryUrl, {
      merchantId: merchantId
    })
    if (data) {
      reFormatCategoryData(data)
    }
  }

  useEffect(() => {
    getCategoryData()
  }, [])

  const reFormatCategoryData = (data: any) => {
    // 1, format category data
    const categoryData = new Map<string, { count: number, id?: number }>()
    addedToCate(DEFAULT_CATEGORY_NAME, null)
    if (data.category) {
      for (const cate of data.category) {
        categoryData.set(cate.title, {count: 0, id: cate.id})
      }
    }
    // 2, format products data
    if (data.products) {
      for (const product of data.products) {
        if (product.category && product.category.length > 0) {
          for (const cate of product.category) {
            addedToCate(cate.title, cate.id)
          }
        }
      }
    }

    function addedToCate(title: string, id?: number) {
      if (categoryData.has(title)) {
        categoryData.set(title, {
          count: categoryData.get(title).count + 1,
          id: id
        })
      } else {
        categoryData.set(title, {
          count: 1,
          id: id
        })
      }
    }

    const result: any[] = []
    for (const key of categoryData.keys()) {
      result.push({
        title: key,
        count: categoryData.get(key).count,
        id: categoryData.get(key).id
      })
    }
    setCategoryGroupData(result)
  }

  const categoryOperation = async (event: OperationType, target: any, index: number) => {
    switch (event) {
      case "EDIT": {
        currentEditingCategory.current = target
        setName(target.title)
        setShowModal(true)
        break
      }
      case "DELETE": {
        // 删除分类
        await requestDelete(merchantCategoryUrl, {
          id: currentEditingCategory.current.id,
          merchantId: merchantId
        })
        getCategoryData()
        break
      }
      case "UP": {
        setLoading(true)
        await requestPost(merchantCategoryUrl + '/move', {
          id: target.id,
          operate: 'UP'
        })
        setLoading(false)
        getCategoryData()
        break
      }
      case "DOWN": {
        setLoading(true)
        await requestPost(merchantCategoryUrl + '/move', {
          id: target.id,
          operate: 'DOWN'
        })
        setLoading(false)
        getCategoryData()
        break
      }
    }
  }

  const submit = () => {
    history.goBack()
  }

  return (
    <PageLayout pageName="category no nav">
      <Header title="商品库分类管理" right={(
        <HeaderRight onClick={() => {
          setShowModal(true)
        }}>
          <span className='plus'>+</span><span className='text'>创建新分类</span>
        </HeaderRight>
      )}/>
      <CategoryListingPageContainer>
        <ListContainer>
          {categoryGroupData ? categoryGroupData.map((item, index) => {
            return (
              <CategoryCard
                key={item.title}
                title={item.title}
                count={item.count}
                canOperation={item.title !== DEFAULT_CATEGORY_NAME}
                canSelected={false}
                isSelected={selectedCategory.filter(_item => _item === item.title).length > 0}
                index={index}
                total={categoryGroupData.length}
                onOperation={(event => {
                  categoryOperation(event, item, index - 1)
                })}
                onSelect={(selected) => {
                  if (selected) {
                    setSelectedCategory([
                      item.title
                    ])
                  }
                }}
              />
            )
          }) : null}
        </ListContainer>
        <div className="bottom-float">
          <Button
            fullWidth
            onClick={submit}
          >
            确定
          </Button>
        </div>


      </CategoryListingPageContainer>
      <TTModal
        show={showModal}
        title='分类名称'
        onConfirm={async () => {
          if (!name) {
            return
          }
          if (currentEditingCategory && currentEditingCategory.current) {
            await requestPut(merchantCategoryUrl, {
              title: name,
              merchantId: merchantId,
              id: currentEditingCategory.current.id
            })
          } else {
            await requestPost(merchantCategoryUrl, {
              title: name,
              merchantId: merchantId,
            })
          }
          setName('')
          setShowModal(false)
          getCategoryData()
        }}
        onCancel={() => {
          setShowModal(false)
        }}
        content={(
          <div style={{
            padding: 16
          }}>
            <TextField
              style={{width: '100%'}}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </div>
        )}
      />
      <LoadingDialog visible={loading}>
        <div></div>
      </LoadingDialog>
    </PageLayout>
  )
}

export default MerchantCategoryListingPage

const HeaderRight = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;

  .plus {
    display: inline-block;
    text-align: center;
    font-size: 24px;
    height: 24px;
    line-height: 18px;
    width: 24px;
    border-radius: 12px;
    border: 1px solid white;
    margin-right: 8px;
  }
`

const CategoryListingPageContainer = styled.div`
  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`

const ListContainer = styled.div`
  padding: 16px;
  margin-bottom: 48px;
`

type OperationType = 'EDIT' | 'DELETE' | 'UP' | 'DOWN'

interface ICategoryCardProps {
  title: string
  count: number,
  index: number,
  total: number,
  canSelected?: boolean
  isSelected?: boolean,
  onSelect?: (selected: boolean) => void
  canOperation?: boolean
  onOperation?: (e: OperationType) => void
}

function CategoryCard(props: ICategoryCardProps) {
  const {renderDialog, showDialog, hideDialog} = useDialog();
  return (
    <CardContainer>
      <div className='line1'>
        <div className='title'>{props.title}</div>
        {props.canSelected ? (
          <Radio
            className='radio'
            value={props.isSelected}
            onChange={(selected) => {
              if (props.onSelect) {
                props.onSelect(selected)
              }
            }}
          ></Radio>
        ) : null}
      </div>
      <WhiteSpace height={8}/>
      <div className='line2'>
        <div className='count'>商品数:{props.count}</div>
        {props.canOperation ? (
          <div className='buttons'>
            <img
              className='edit-icon'
              src={iconEdit}
              onClick={() => {
                if (props.onOperation) {
                  props.onOperation('EDIT')
                }
              }}
            />
            <img
              className='delete-icon'
              src={iconRubbishBin}
              onClick={() => {
                if (props.onOperation) {
                  showDialog({
                    title: `确定删除分类-${props.title}?`,
                    message: '该分类一旦删除，将无法恢复',
                    cancelText: "取消",
                    okText: "确定",
                    onOK: () => {
                      hideDialog();
                      props.onOperation('DELETE')
                    },
                  });

                }

              }}
            />
            <img
              className='up-icon'
              src={props.index <= 1 ? iconUpDisable : iconUpEnable}
              onClick={() => {
                if (props.onOperation && props.index > 0) {
                  props.onOperation('UP')
                }
              }}
            />
            <img
              className='down-icon'
              src={props.index === props.total - 1 ? iconDownDisable : iconDownEnable}
              onClick={() => {
                if (props.onOperation && props.index < props.total - 1) {
                  props.onOperation('DOWN')
                }
              }}
            />
          </div>
        ) : null}

      </div>
      <WhiteSpace/>
      <Divider/>
      {renderDialog()}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  margin-top: 8px;

  .line1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
    }

    .radio {

    }
  }

  .line2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .count {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: ${THEME.color.gray["#11-065"]};
    }

    .buttons {
      width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`
