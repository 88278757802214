import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IconKBArrowL } from '../Icons';
import { THEME } from '../../config';

const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;

const BackButtonContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top || '0'};
  margin: .5em;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
  color: ${(props) => props.color || '#4a4a4a'};
  cursor: pointer;
  z-index: 999999;

  display: inline-flex;
  align-items: center;
  & svg {
    display: inline-block;
    fill: ${(props) => props.color || '#4a4a4a'};
    width: 2em;
    height: 2em;
  }

  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    display: none;
  }
`;
interface BackButtonProps {
  onClick?: () => void;
  children?: any;
  color?: string;
  top?: string;
  icon?: any;
}
export const BackButton: React.FC<BackButtonProps> = ({ onClick, children, color, top, icon }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }
  return (
    <BackButtonContainer top={top} color={color} onClick={onClick || goBack}>
      {(icon === null) ? null : (icon || <IconKBArrowL />)}
      {children}
    </BackButtonContainer>
  )
}
