import { useState, useEffect } from 'react'
import styled from "styled-components";
import IconMinus from "../../components/Icons/IconMinus";
import IconPlus from "../../components/Icons/IconPlus";
import ImageDialog from "../../components/ImageDialog";
import { IProductCardProps } from './ProductCard.type';
import { ProductCardNormal } from './ProductCard.normal';
import { ProductCardLargeImage } from './ProductCard.large';

const StyledAddToCartButton = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  align-content: center;

  height: 2rem;

  .amount-container {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 15px;
    line-height: 21px;
  }
`;

export const AddToCartButton = ({onRemove, onAdd, amount, available, primaryColor, noMoreQuota}: any) => {
  return (
    <StyledAddToCartButton>
      {amount ? (
        <div onClick={onRemove}>
          <IconMinus color={primaryColor}/>
        </div>
      ) : null}
      <div className="amount-container">{amount ? amount : ""}</div>
      {amount < available ? (
        <div onClick={!noMoreQuota ? onAdd : undefined}>
          <IconPlus color={!noMoreQuota ? primaryColor : 'rgba(0,0,0,0.09)'}/>
        </div>
      ) : null}
    </StyledAddToCartButton>
  );
};

export const ProductCard: React.FC<IProductCardProps> = (props) => {
  const { id, amount, images, viewMode, onAddProduct,  onRemoveProduct } = props;
  const [mAmount, setAmount] = useState<number>(amount || 0);
  const [showImageDialog, setShowImageDialog] = useState<boolean>(false);

  // console.log('ProductCard amount', amount)
  useEffect(() => {
    if (
      (mAmount !== amount)
    ) {
      if (
        (amount !== undefined) && 
        ('number' === typeof amount)
      ) {
        setAmount(amount)
      } else {
        setAmount(0)
      }
    }
  }, [amount])

  const onRemove = () => {
    setAmount(mAmount - 1);
    onRemoveProduct(id, mAmount - 1);
  };

  const onAdd = () => {
    setAmount(mAmount + 1);
    onAddProduct(id, mAmount + 1);
  };

  return (
    <>
      { (viewMode === 'LARGE-IMAGE') &&
        <ProductCardLargeImage {...props} 
          mAmount={mAmount} setShowImageDialog={setShowImageDialog}
          onRemove={onRemove} onAdd={onAdd}
        />
      }
      { (viewMode === 'NORMAL') &&
        <ProductCardNormal {...props} 
          mAmount={mAmount} setShowImageDialog={setShowImageDialog}
          onRemove={onRemove} onAdd={onAdd}
        />
      }
      <ImageDialog
        visible={showImageDialog}
        onClose={() => setShowImageDialog(false)}
        // url={getImageById(images[0])}
        url={images}
      />
    </>
  );
};
