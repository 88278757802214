import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { IconQuestionMark, IconRadioUncheck, IconRadioCheck } from '../Layout'
import { IFormInputProps } from './type'
import { Wrapper as BaseWrapper } from './styled'

const Wrapper = styled(BaseWrapper)`
  color: rgba(0,0,0,0.45);
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;

  & div.block {
    color: rgba(0,0,0,0.85);
    font-weight: 400;

    border-radius: 8px;
    background-color: white;
    border-radius: 8px;
    border: none;
    overflow: hidden;
  }

  & p.selected {
    color: ${props => props.theme?.color?.primary?.HEX};
  }
`
const Option = styled.div`
  padding: 12px 8px;

  & ~ & {
    border-top: 1px solid rgba(0,0,0,0.09);
  }
  & button {
    margin-left: .5rem;
    background-color:transparent;
    border: none;
    transform: translateY(2px);
  }
`

type ChoiceOptionType = 'SINGLE' | 'MULTIPLE';
interface IChoiceInputOption {
  value: string;
  label: string;
}
interface IChoiceTipsHandler {
  (): void;
}
interface IChoiceInputProps
extends IFormInputProps {
  options: IChoiceInputOption[];
  type: ChoiceOptionType;
  handleTips?: {
    [optionValue: string]: IChoiceTipsHandler;
  };
}
export const ChoiceInput: React.FC<IChoiceInputProps> = ({required, label, value, onChange, options, type, handleTips}) => {
  const [choice, setChoice] = useState<string | string[]>(value)

  useEffect(() => {
    if (type === 'SINGLE') {
      if (choice !== value) {
        setChoice(value)
      }
    } else if (type === 'MULTIPLE') {
      if (choice?.length !== value?.length) {
        setChoice(value)
      } else {
        for (let i=0;i<value.length;i++) {
          if (choice[i] !== value[i]) {
            setChoice(value)
            break;
          }
        }
      }
    }
  }, [value])
  useEffect(() => {
    if (!!choice) {
      onChange(choice)
    }
  }, [choice])
  useEffect(() => {
    if (
      (!value) ||
      ( (type==='MULTIPLE') && (!(value instanceof Array)) )
    ) {
      if (type==='MULTIPLE') {
        setChoice([])
      } else {
        setChoice('DISCOUNT')
      }
    }
  }, [])

  const onOptionClick = (opValue: string) => () => {
    if (type==='MULTIPLE') {
      if (choice?.includes(opValue)) {
        setChoice( (choice as string[]).filter(v => v!==opValue) )
      } else {
        setChoice([ ...choice, opValue ])
      }
    } else {
      setChoice(opValue)
    }
  }

  return (
    <Wrapper className="flex-start direction-col debugx"
      style={{gap: '8px'}}
    >
      { !!label && 
        <label>
          {label}
          <span className='required'>{required ? ' *' : ''}</span>
        </label>
      }
      <div className='block'>
      { options?.map((op, index:number) => {
        const selected = (
          ( (type==='SINGLE')   && (op.value === choice) ) ||
          ( (type==='MULTIPLE') && (!!choice.length) && (choice?.includes(op.value)) )
        )
        return (
          <Option key={index} className='flex-between align-center' onClick={onOptionClick(op.value)}>
            <p className={selected ? 'selected' : ''}>
              {op.label}
              { !!handleTips?.[op.value] &&
                <button onClick={() => handleTips[op.value]()}>
                  <IconQuestionMark />
                </button>
              }
            </p>
            { selected ? <IconRadioCheck /> : <IconRadioUncheck /> }
          </Option>
        )
      } )}
      </div>
    </Wrapper>
  )
}
