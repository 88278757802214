interface IFnToastInfo {
  (msg: string, ms?: number) : void; 
}

export interface IToastFunctions {
  info    : IFnToastInfo,
  warning : IFnToastInfo,
  error   : IFnToastInfo,
  success : IFnToastInfo,
}

export type ToastMsgType = 'info' | 'warning' | 'error' | 'success';
export const DEFAULT_TOAST_TYPE = 'info';

export interface IToastMsg {
  msg: string;
  ms?: number;
  type?: ToastMsgType;
}
export const DEFAULT_TOAST_MS = 3000;
