import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { LOCAL_URL } from '../../config'
import { SubmitButtonArea, SubmitButton } from '../../Layout'
import {
  IFormValues, IFormProps, 
  ImageInput, 
  TextInput, TextAreaInput, 
  DueDateInput, RulesInput,
} from '../../FormElements'
import { autoDescription, autoCopyWriting } from './helper'
import { useEffect } from 'react'
  
const Wrapper = styled.div`
  padding: 1rem 1rem 6rem;
`

const VALID_SCHEMA = yup.object({
  'posterLink'    : yup.string().required(`活动海报不能为空`),
  'name'          : yup.string().required(`活动名称不能为空`),
  'dueDate'       : yup.string().required(`截止时间不能为空`),
  'couponDueDate' : yup.object().required(`卡券有效期不能为空`),
  'rules'         : yup.object().required(`奖品设置不能为空`),
  'description'   : yup.string().required(`活动说明不能为空`),
  'copywriting'   : yup.string().required(`分享文案不能为空`),
}).required();

export const Form: React.FC<IFormProps> = ({merchantData, defaultValues, validationSchema, submitHandler}) => {
  const {
    handleSubmit, control, watch, setError, trigger, // register, clearErrors, reset,
    getValues, setValue,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // shouldFocusError: true,
    defaultValues,
    resolver: yupResolver(VALID_SCHEMA),
    // resolver: !validationSchema ? undefined : yupResolver(validationSchema),
  })
  const history = useHistory()
  const allFields = watch()
  // console.log('watch all fields', allFields.couponDueDate)

  useEffect(() => {
    // console.log('$$$$ effect', allFields, errors, isValid)
    if (!allFields.rules?.total && !errors.rules) {
      setError('rules', { message: '奖品设置不能为空' })
      // trigger()
    }
  }, [allFields])

  const onSubmit = async (data: IFormValues) => {
    if (!!submitHandler) {
      submitHandler(data);
    }
    // if (!result.token) setError('name',  { type: 'custom', message: '登录失败' })
  }

  const toResetDescription = () => {
    return autoDescription({
      merchantName: merchantData.name,
      dueDate: allFields.dueDate,
      rules: allFields.rules?.rules
    })
  }
  const toResetCopywriting = () => {
    return autoCopyWriting({
      merchantName: merchantData.name,
      promotionName: allFields.name,
      ruleNames: allFields.rules?.rules.map((rule) => (rule.preference?.name || '##'))
    })
  }

  const onGotoSelectPreference = (ruleId:number) => {
    const formData = getValues()
    history.push(LOCAL_URL["PREFERENCE_SELECT"], {
      ruleId,
      formData,
      merchantData,
    })
  }
  // console.log(`##### render: valid ${isValid} dirty ${isDirty}`)

  const couponFixedDue =  dayjs(allFields.dueDate).isValid() ? 
                          dayjs(allFields.dueDate).add(7, 'd').format('YYYY-MM-DD') : 
                          dayjs().add(7, 'd').format('YYYY-MM-DD')
  const noError = (Object.keys(errors).length === 0);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper className="flex-start direction-col" style={{gap: '18px'}}>
        <Controller control={control} // defaultValue={""}
          name="posterLink"
          render={(field) => (
            <ImageInput {...field} ref={undefined}
              texts={['上传活动海报', '建议尺寸900*500']}
            />
          )}
        />
        <Controller control={control} // defaultValue={""}
          name="name"
          render={(field) => (
            <TextInput {...field} placeholder="请输入活动名称" ref={undefined} label="活动名称" type="text" required />
          )}
        />
        <Controller control={control} // defaultValue={""}
          name="dueDate"
          render={(field) => (
            <TextInput {...field} ref={undefined} label="活动截止时间" type="date" required />
          )}
        />
        <Controller control={control} // defaultValue={""}
          name="couponDueDate"
          render={(field) => (
            <DueDateInput {...field} ref={undefined}
              defaultValues={{
                lifeTime: 7,
                fixDate: couponFixedDue,
              }}
              required
              label="卡券使用有效期设置" 
            />
          )}
        />
        <Controller control={control} // defaultValue={""}
          name="rules"
          render={(field) => (
            <RulesInput {...field} ref={undefined}
              gotoSelect4RuleId={onGotoSelectPreference}
              label="" 
            />
          )}
        />
        <Controller control={control} // defaultValue={""}
          name="description"
          render={(field) => (
            <TextAreaInput {...field} ref={undefined}
              label="活动说明"
              placeholder="请输入活动说明"
              required
              autoText={toResetDescription}
            />
          )}
        />
        <Controller control={control} // defaultValue={""}
          name="copywriting"
          render={(field) => (
            <TextAreaInput {...field} ref={undefined}
              label="活动分享文案"
              placeholder="请输入活动分享文案"
              required
              autoText={toResetCopywriting}
            />
          )}
        />
      </Wrapper>

      <SubmitButtonArea className="fix-bottom">
        {/* after back from preference select page, it is not dirty again */}
        <SubmitButton type="submit" disabled={!noError || !isValid}>
          保存
        </SubmitButton>
      </SubmitButtonArea>
    </form>
  )
}
