import {isEmpty, userCache} from './index'
import { LOCAL_URL, EXTERNAL_URL } from '../config';

const API_ROOT = EXTERNAL_URL.API_ROOT;

interface IApplication {
  router: any;
  // [propName: string]: any;
}
export const thisApp: IApplication = { router: null };

const getCachedToken = () => {
	try {
		const {token} = JSON.parse(userCache.get() || '{}');
		return token;
	} catch (err) {
		return null;
	}
}

interface HeaderDT {
	[propName: string]: string;
}

interface RequestFn {
	(
		apiUrl: string,
		method?: 'post' | 'delete' | 'put' | 'get',
		payload?: any,
		extra?: HeaderDT,
	)
		: Promise<any>;
}

const HEADERS_DEFAULT: HeaderDT = {
	"Content-Type": 'application/json',
	Accept: 'application/json',
}
export const request: RequestFn = (apiUrl, method = 'get', payload = undefined, extra = {}) => (new Promise((resolve, reject) => {
	// console.log('request', apiUrl, method, payload, extra)

	const headers = {
		...HEADERS_DEFAULT,
		...extra
	};
	const jwt = getCachedToken();

	if (!!jwt) {
		headers.authorization = `Bearer ${jwt}`;
	}

	const options: any = {
		method,
		headers,
	};
	if (payload instanceof FormData) {
		// Todo:
	} else if ((!!payload) && ['post', 'put', 'delete'].includes(method)) {
		options.body = JSON.stringify(payload);
	}
	// console.log(options)

	const queryParams = ((method === 'get') && !isEmpty(payload))
		?
		Object.keys(payload).map((key) => `${key}=${payload[key]}`).join('&')
		:
		null
	;
	const url = (apiUrl.indexOf('http') === 0) ? apiUrl : `${API_ROOT}/${apiUrl}`;
	fetch(
		!queryParams ? url : `${url}?${queryParams}`,
		options
	)
		.then( async (response) => {
			if (extra && extra.responseType === 'blob'){
				return response.blob();
			}
			const {status, ok} = response;
			// console.log(response, status);
      if ((status === 486) && (!!response)) {
        thisApp.router?.history?.push({
          pathname: `${LOCAL_URL.EXCEPTION}/486`,
          state: { html: await response.text() }
        });
        throw new Error(`Error 486`);
        return;
      }
			else if (
				(status >= 200) && (status < 300) && (!!ok)
			) {
				return response.json();
			} else {
				// throw new Error(`Requet response error !!! ${status} ${ok}`);
				return response.text();
			}
		})
		.then((response) => {
			if (extra && extra.responseType === 'blob'){
				resolve(response)
			}
			// console.log(response);
			// console.log(typeof response);
			if ('string' === typeof response) {
				const err = JSON.parse(response)
				// console.log(err)
				resolve(err)
			} else if (response?.code !== 0) {
				resolve(new Error(response?.msg || 'Response error!'))
			} else resolve(response)
		})
		.catch((err) => {
			console.error(err);
			resolve(err);
			// reject(err);
		});
}));

interface RequestXFn {
	(
		apiUrl: string,
		payload?: any,
		extra?: HeaderDT,
	)
		: Promise<any>;
}

export const requestPost: RequestXFn = (apiUrl, payload, extra = {}) => (request(apiUrl, 'post', payload, extra));
export const requestGet: RequestXFn = (apiUrl, payload, extra = {}) => (request(apiUrl, 'get', payload, extra));
export const requestPut: RequestXFn = (apiUrl, payload, extra = {}) => (request(apiUrl, 'put', payload, extra));
export const requestDelete: RequestXFn = (apiUrl, payload, extra = {}) => (request(apiUrl, 'delete', payload, extra));
