import React, { forwardRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const Input = forwardRef<unknown, TextFieldProps>((props, ref) => {
  return (
    <TextField 
      {...props}
      variant='outlined' 
      margin="normal" 
      InputLabelProps={{shrink: false}}
      label={null}
      fullWidth
      inputRef={ref} 
    />
  )
})
