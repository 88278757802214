import styled, { createGlobalStyle } from "styled-components";
import { THEME } from "./config";

/**

  Typography

  - r: Roboto
  - p: Poppins

  - r: Regular
  - m: Medium
  - s: Semibold
  - b: Bold

  - xx: xxpx

 */
const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .debug {
    border: 1px solid #f00;
    padding: 2px;
  }

  .ellipsis {
    /* Required for text-overflow to do anything */
    overflow: hidden; //超出的文本隐藏
    white-space: nowrap; //溢出不换行
    text-overflow: ellipsis; //溢出用省略号显示
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .text-align-center {
    text-align: center;
  }

  .full-size {
    width: 100%;
    height: 100%;
  }

  .relative-position {
    position: relative;
  }
  .absolute-position {
    position: absolute;
  }


  .flex-start {
    display: flex;
  }
  .flex-end {
    display: flex;
    justify-content: flex-end;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .flex-around {
    display: flex;
    justify-content: space-around;
  }
  .flex-center {
    display: flex;
    justify-content: center;
  }
  .align-center {
    align-items: center;
  }
  .align-start {
    align-items: flex-start;
  }
  .align-end {
    align-items: flex-end;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .direction-reverse {
    flex-direction: row-reverse;
  }
  .direction-col {
    flex-direction: column;
  }



  .ft-rr10 {
    font-family: Roboto;
    font-weight: 400;
    font-size: 10px;
  }
  .ft-rr12 {
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
  }
  .ft-rm12 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
  }
  .ft-rb12 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 12px;
  }
  .ft-rr14 {
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
  }
  .ft-rm14 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
  }
  .ft-rb14 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
  }
  .ft-rr15 {
    font-family: Roboto;
    font-weight: 400;
    font-size: 15px;
  }
  .ft-rr16 {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
  }
  .ft-rm16 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
  }
  .ft-rb16 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 16px;
  }
  .ft-rr18 {
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
  }
  .ft-rm18 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
  }
  .ft-rb18 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 18px;
  }
  .ft-rb20 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 20px;
  }
  .ft-rb24 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 24px;
  }
  .ft-rb32 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 32px;
  }
  .ft-rb36 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 36px;
  }
  .ft-rb48 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 38px;
  }
  .ft-rb56 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 56px;
  }

  .ft-pm12 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
  }
  .ft-pb14 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 14px;
  }
  .ft-pm14 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
  }
  .ft-ps16 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
  }
  .ft-pm16 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
  }
  .ft-pb16 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 16px;
  }
  .ft-pm18 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 18px;
  }
  .ft-pb18 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 18px;
  }
  .ft-ps18 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
  }
  .ft-pb24 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 24px;
  }
  .ft-ps24 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 24px;
  }
  .ft-ps22 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 22px;
  }
  .ft-ps30 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 30px;
  }
  .ft-ps42 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 42px;
  }

  .clr-ft-gold {
    color: ${THEME.color.accent.gold};
  }
  .clr-ft-blue {
    color: ${THEME.color.accent.blue};
  }
  .clr-bg-gray0-087 {
    background: ${THEME.color.gray["#0-087"]};
  }
  .clr-ft-gray0-087 {
    color: ${THEME.color.gray["#0-087"]};
  }
  .clr-bg-gray1-32 {
    background: ${THEME.color.gray["#1-32"]};
  }
  .clr-ft-gray1-32 {
    color: ${THEME.color.gray["#1-32"]};
  }
  .clr-bg-gray2-48 {
    background: ${THEME.color.gray["#2-48"]};
  }
  .clr-ft-gray2-48 {
    color: ${THEME.color.gray["#2-48"]};
  }
  .clr-bg-gray3-76 {
    background: ${THEME.color.gray["#3-76"]};
  }
  .clr-ft-gray3-76 {
    color: ${THEME.color.gray["#3-76"]};
  }
  .clr-bg-gray4-97 {
    background: ${THEME.color.gray["#4-97"]};
  }
  .clr-ft-gray4-97 {
    color: ${THEME.color.gray["#4-97"]};
  }
  .clr-ft-gray5-025 {
    color: ${THEME.color.gray["#5-025"]};
  }
  .clr-bg-gray5-025 {
    background: ${THEME.color.gray["#5-025"]};
  }
  .clr-bg-gray6-d9 {
    background: ${THEME.color.gray["#6-d9"]};
  }
  .clr-ft-gray6-d9 {
    color: ${THEME.color.gray["#6-d9"]};
  }
  .clr-bg-gray7-f5 {
    background: ${THEME.color.gray["#7-f5"]};
  }
  .clr-bg-gray8-bd {
    background: ${THEME.color.gray["#8-bd"]};
  }

  .clr-bg-primary {
    background: ${THEME.color.primary.default};
  }
  .clr-ft-primary {
    color: ${THEME.color.primary.default};
  }
  .clr-bg-primary-variant {
    background: ${THEME.color.primary.variant};
  }
  .clr-ft-secondary {
    color: ${THEME.color.secondary.default};
  }
  .clr-bg-secondary {
    background: ${THEME.color.secondary.default};
  }
  .clr-bg-green {
    background: ${THEME.color.accent.green};
  }
  .clr-ft-green {
    color: ${THEME.color.accent.green};
  }
  .clr-bg-yellow {
    background: ${THEME.color.accent.yellow};
  }
  .clr-bg-blue {
    background: ${THEME.color.accent.blue};
  }
  .clr-bg-fff {
    background: #fff;
  }
  .clr-ft-fff {
    color: #fff;
  }
  .clr-bg-000 {
    background: #000;
  }
  .clr-ft-000 {
    color: #000;
  }
  .clr-ft-gray12-45 {
    color: ${THEME.color.gray['#12-045']};
  }



  .shadow01 {
    box-shadow: 
      0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
  }
  .shadow02 {
    box-shadow: 
      0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 3px 1px rgba(0, 0, 0, 0.12),
      0px 1px 5px rgba(0, 0, 0, 0.2);
  }
  .shadow03 {
    box-shadow: 
      0px 3px 4px rgba(0, 0, 0, 0.14),
      0px 3px 3px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.2);
  }
  .shadow04 {
    box-shadow: 
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .shadow06 {
    box-shadow: 
      0px 6px 10px rgba(0, 0, 0, 0.14), 
      0px 1px 18px rgba(0, 0, 0, 0.12), 
      0px 3px 5px rgba(0, 0, 0, 0.2);
  }
  .shadow08 {
    box-shadow: 
      0px 8px 10px rgba(0, 0, 0, 0.14),
      0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 5px 5px rgba(0, 0, 0, 0.2);
  }
  .shadow09 {
    box-shadow: 
      0px 9px 12px rgba(0, 0, 0, 0.14),
      0px 3px 16px rgba(0, 0, 0, 0.12),
      0px 5px 6px rgba(0, 0, 0, 0.2);
  }
  .shadow12 {
    box-shadow: 
      0px 12px 17px rgba(0, 0, 0, 0.14),
      0px 5px 22px rgba(0, 0, 0, 0.12),
      0px 7px 8px rgba(0, 0, 0, 0.2);
  }
  .shadow16 {
    box-shadow: 
       0px 16px 24px rgba(0, 0, 0, 0.14),
       0px 6px 30px rgba(0, 0, 0, 0.12),
       0px 8px 10px rgba(0, 0, 0, 0.2);
  }
  .shadow24 {
    box-shadow: 
      0px 3px 8px rgba(0, 0, 0, 0.09),
      0px 2px 1px rgba(0, 0, 0, 0.02),
      0px 1px 3px rgba(0, 0, 0, 0.06);
  }

  
}

body {
  background: ${THEME.color.gray["#7-f5"]};
}

img, iframe {
  loading: lazy;
}

h1 {
  font-family: Roboto;
  font-weight: 300;
  font-size: 96px;
}
h2 {
  font-family: Roboto;
  font-weight: 300;
  font-size: 60px;
}
h3 {
  font-family: Roboto;
  font-weight: normal;
  font-size: 48px;
}
h4 {
  font-family: Roboto;
  font-weight: normal;
  font-size: 34px;
}
h5 {
  font-family: Roboto;
  font-weight: normal;
  font-size: 24px;
}
h6 {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
}
`;
export default GlobalStyle;
