import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { LOCAL_URL } from "../../../config";
import { returnResultOfPageFunction } from '../../../lib'
import { IconArrowRight } from '../../../Layout'
import { ICategoryPartialEntity } from './type'
import { convertData2PartialEntity } from './helper'

const Wrapper = styled.div`
  height: 48px;
  border-bottom: 1px solid rgba(0,0,0,0.09);
  font-size: 15px;

  & span.placeholder {
    color: rgba(0,0,0,0.27);
  }
`

interface ICategoryInputProps {
  value?: ICategoryPartialEntity[];   // !!! 虽然这里是数组，但数组里面只有一个对象，不能有多个 !!!
  onChange?: (value: ICategoryPartialEntity[]) => void;
  onBeforeClick?:()=>void
}
export const CategoryInput: React.FC<ICategoryInputProps> = ({value, onChange, onBeforeClick}) => {
  const location = useLocation()
  const history = useHistory()
  const [category, setCategory] = useState<ICategoryPartialEntity>(value?.[0] || null)
  const isCategoryValid = ('number' === typeof category?.id) && (category.id >= 0)

  const onClick = () => {
    if (onBeforeClick){
      onBeforeClick()
    }
    returnResultOfPageFunction.clear()
    history.push( LOCAL_URL["PRODUCT_CATEGORY_LIST"], {
      role: 'PAGE-FUNCTION',
      // action: 'PICK-UP',
      currentCategoryId: category?.id,
    } )
  }
  const updateCategory = (cate: ICategoryPartialEntity) => {
    setCategory(cate)
    if (value?.[0]?.id !== cate?.id) {
      setTimeout(() => {
        // form 页面因为路由变化必然发生重新初始化
        // 加入延时，确保在页面初始化之后，再传递 value change
        onChange([cate])
      }, 200);
    }
  }

  useEffect(() => {
    if (value?.[0]?.id !== category?.id) {
      setCategory(value[0])
    }
  }, [value])

  useEffect(() => {
    // console.log('effect on location', location)
    const result = returnResultOfPageFunction.get()
    if (!!result) {
      try {
        const cate = JSON.parse(result)
        // console.log('get return result', cate)
        if (!!cate) {
          updateCategory(convertData2PartialEntity(cate))
        }
      }
      catch(err) {
        console.error(err)
      }
      finally {
        returnResultOfPageFunction.clear()
      }
    }
  }, [location])

  return (
    <Wrapper className="flex-between align-center">
      <div>分类</div>
      <p className='placeholder flex-end align-center' onClick={onClick}>
        <span className={!isCategoryValid ? 'placeholder' : ''}>{!!isCategoryValid ? category.title : '请选择分类'}</span>
        <IconArrowRight fill={!isCategoryValid ? "rgba(0,0,0,0.27)" : "rgba(0,0,0,0.87)"} />
      </p>
    </Wrapper>
  )
}
