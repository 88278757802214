import styled from 'styled-components'
import { THEME } from '../../config'
import { IconKBArrowR, SVGPremiumIcon } from '../Icons'
import { Button } from '../Button'

/**
 */

const Container = styled.div`
  border-radius: 8px;
  border: 2px solid ${THEME.color.secondary.default};
  overflow: hidden;
`;
const HeaderBlock = styled.div`
  height: 48px;
  border-bottom: 2px solid ${THEME.color.secondary.default};
  background: ${THEME.color.gray['#1-32']};
  color: ${THEME.color.secondary.default};
  padding: 0 8px 0 16px;

  & div.left svg {
    width: 40px;
  }
`;
const HeaderAction = styled.div`
  cursor: ${(props) => !props.onClick ? '' : 'pointer'};
  padding: ${(props) => !props.onClick ? '0 8px 0 0' : '0'};

  & svg {
    width: 18px;
    fill: #fff;
  }
`;

const ContentBlock = styled.div`
  padding: 8px 16px;
`;
const Column = styled.div`
  display: inline-block;
  position: relative;
  & svg {
    position: absolute;
    width: 64px;
    right: -72px;
    top: 0;
  }
`;

/**
  background: ${`
    radial-gradient(circle at bottom right, ${HOLE_COLOR} ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) top,
    radial-gradient(circle at top    right, ${HOLE_COLOR} ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) center 40%,
    radial-gradient(circle at bottom right, ${BG_COLOR}   ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) bottom;
  `};
  background-size: 100% 30%, 100% 25%, 100% 45%;
  background-repeat: no-repeat;
  border-radius: 20px;
 */

interface CardPremiumProps {
  children?: any;
  renderAction?: () => void;
  onHeaderClick?: () => void;
}
export const CardPremium: React.FC<CardPremiumProps> = ({children, renderAction, onHeaderClick}) => (
  <Container>
    <HeaderBlock className="flex-between align-center">
      <div className="flex-between align-center left">
        <SVGPremiumIcon />
        <p className="ft-ps-16">PREMIUM OFFERS</p>
      </div>
      {/* <HeaderAction className="flex-between align-center"  */}
        {/* onClick={!onHeaderClick ? null : onHeaderClick} */}
      {/* > */}
      { // !onHeaderClick ?
        // <p className="ft-rb12 clr-ft-fff">Premium Only Deal</p>
        // <p></p>
        // :
        // <>
          // <p className="ft-rb12 clr-ft-fff">Join Premium</p>
          // <IconKBArrowR />
        // </>
      }
      {/* </HeaderAction> */}
    </HeaderBlock>
    <ContentBlock className="flex-between align-center">
      <Column>
      { children }
      </Column>
      <Column>
        { !renderAction ? null : renderAction() }
      </Column>
    </ContentBlock>
  </Container>
)
