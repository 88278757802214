import React, {useContext, useEffect, useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import {getParams, requestGet} from "../../lib";
import {useHistory} from "react-router-dom";
import {CommonCard, useOrderUIComponents} from "../../hooks/order";
import {LOCAL_URL, THEME} from "../../config";
import WhiteSpace from "../../components/WhiteSpace";

function OrderDetailPage() {
  const history: any = useHistory()
  const {
    initByOrderId,
    statusStr,
    BaseInfoCard,
    ProductsCard,
    ShipInfoCard,
    PaymentCard,
    RemarkCard,
    DeliveryInfoCard,
    ActionInfoCard,
    ActionList,
    isReady,
    orderData,
    shippingStatusStr
  } = useOrderUIComponents()

  const getOrderData = async () => {
    let orderId = null
    if (history.location.state) {
      orderId = history.location.state.orderId
    }
    if (!orderId) {
      if (history.location.search) {
        orderId = getParams(history.location.search).orderId
      }
    }
    if (!orderId) {
      return
    }
    await initByOrderId(orderId)
  }

  useEffect(() => {
    getOrderData()
  }, [])

  return (
    <PageLayout pageName="no nav orderDetailPage">
      <Header title="订单详情" onBackClicked={() => {
        if (!!history.location.state?.orderId) {
          // this page is redirected from other internal pages
          history.go(-1);
          return
        }
        // else : this page is opened by wechat notification
        if (orderData?.group?.type === 'DEFAULT') {
          history.replace(LOCAL_URL["GROUP_BUY_DETAIL"] + "?groupId=" + orderData.groupId);
        } else if (orderData.group.type === 'STORE') {
          history.replace(LOCAL_URL["SHOPPING_DETAIL"] + "?groupId=" + orderData.groupId);
        }
      }}/>
      <Container>
        <div className='status-card'>
          {statusStr()} | {shippingStatusStr()}
        </div>
        <BaseInfoCard/>
        <ProductsCard/>
        <ShipInfoCard/>
        <PaymentCard/>
        <RemarkCard/>
        <DeliveryInfoCard/>
        {isReady && (
          <CommonCard>
            <div className='title'>
              操作记录
            </div>
            <ActionInfoCard onRefresh={getOrderData}/>
          </CommonCard>
        )}
        <WhiteSpace/>
        {isReady && (
          <ActionList onRefresh={getOrderData}/>
        )}
      </Container>
    </PageLayout>
  )
}

export default OrderDetailPage

const Container = styled.div`
  padding: 16px;

  .status-card {
    background-color: ${THEME.color.primary.default};
    height: 64px;
    color: white;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding-left: 16px;
  }
`
