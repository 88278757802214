import { useRef, useMemo, useState, useEffect } from 'react';
import { newMoment, calculateDuration } from '../lib';

export function useCounter(interval?: number) {
  const [counter, setCounter] = useState(0);
  const timerId = useRef(null);

  useEffect(() => {
    timerId.current = window.setInterval(() => {
      setCounter(counter+1);
    }, interval || 1000);

    return (() => {
      if (!!timerId?.current) {
        window.clearInterval(timerId.current);
      }
    });
  });

  return { counter };
}

export function useCountDownTimer(stopTime: string | null) {
  const [stopMoment, resetStopMoment] = useState((!stopTime) ? null : newMoment(stopTime));
  const [nowMoment, setNowMoment] = useState(null);
  const timerId = useRef(null);

  const fnTimerCallback = () => {
    setNowMoment(newMoment());
  }

  const stopTimer = () => {
    if (!!timerId.current) {
      window.clearInterval(timerId.current);
      timerId.current = null;
    }
  }
  const resetCountDownTimer = (newStopTime: string) => {
    if (!newStopTime) return;
    stopTimer();

    resetStopMoment( newMoment(newStopTime) );
    timerId.current = window.setInterval(fnTimerCallback, 1000);
  }

  useEffect(() => {
    if(!!stopMoment) resetCountDownTimer(stopMoment.format('YYYY-MM-DD HH:mm:ss'));
    return (() => { stopTimer(); });
  }, []);

  const isTimeout = (!stopMoment || !nowMoment) ? false : (nowMoment >= stopMoment);
  const duration  = (!stopMoment || !nowMoment || isTimeout) ? null : calculateDuration(stopMoment, nowMoment);

  useEffect(() => {
    if (isTimeout) { stopTimer(); }
  }, [nowMoment, stopMoment]);
  
  return {resetCountDownTimer, stopTimer, nowMoment, stopMoment, duration, isTimeout, isRunning: !!timerId.current };
}
