export const isWeixinBrowser = /micromessenger/.test(navigator.userAgent.toLowerCase());

export const isiOS = () => {
  const iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }
  return false;
}

const u = navigator.userAgent;
export const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
export const isAndroid = u.indexOf('Android') > -1 || u.indexOf('android') > -1 || u.indexOf('Linux') > -1;

export const getEnv = () => {
  if (isWeixinBrowser) {
    return 'wx';
  }
  return '';
};

export const scrollToBottom = () => {
  const currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // 已经被卷掉的高度
  const clientHeight = document.documentElement.clientHeight; // 浏览器高度
  const scrollHeight = document.documentElement.scrollHeight; // 总高度
  if (scrollHeight - 10 > currentScroll + clientHeight) {
    window.scrollTo(0, currentScroll + (scrollHeight - currentScroll - clientHeight) / 2);
  }
};

export const removeEmoji = (content) => {
  if (!content){
    return ''
  }
  const result = []
  let startIndex: number = 0
  const contentArray = content.split('')
  contentArray.forEach((char, index)=>{
    if (startIndex !== 0 && startIndex < 8){
      startIndex ++;
      return
    }
    if (startIndex === 8){
      startIndex = 0
      return;
    }
    if (char === '&' && contentArray[index + 1] === '#'){
      startIndex = 1
      return;
    }else {
      result.push(char)
    }
  })
  return  result.join('')
}

export const randomString = (length: number, number: boolean = false) => {
  let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (number) {
    str = '0123456789'
  }
  let result = ''
  for (let i = length; i > 0; --i)
    result += str[Math.floor(Math.random() * str.length)]
  return result
}

export const getShipStr = (groupData: any) => {
  if (!groupData) {
    return ''
  }
  let result = ''
  if (groupData.canDelivery) {
    result += '配送'
  }
  if (groupData.canPickup) {
    if (result) {
      result += '、'
    }
    result += '自提'
  }
  return result
}

export function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

export function getHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}

export const hexToRGB = (h:string) => {
  let r: string, g: string, b: string;

  // 3 digits
  if (h.length == 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return +r + "," + +g + "," + +b;
}
