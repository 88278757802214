import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IconArrowRight } from '../Layout'
import { IFormInputProps } from './type'
import { NumberInput } from './Input.number'
import { IPromotionRule } from '../pages/Promotion/type.entity'

const Wrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  color: rgba(0,0,0,0.45);
  font-size: 15px;
  line-height: 21px;

  & ~ & {
    margin-top: 1rem;
  }

  & div.body {
    margin-top: 8px;
    color: rgba(0,0,0,0.85);
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }
  & div.name {
    height: 56px;
    padding: 0 8px;
    border-top: 1px solid rgba(0,0,0,0.09);
  }
  & div p span {
    color: rgb(204, 21, 67);
  }
`
const Button = styled.button`
  cursor: pointer;
  padding: 1px 8px;
  line-height: 14px;
  font-size: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.45);
  background-color: transparent;
`
interface IRuleInputProps
extends IFormInputProps {
  index: number;
  onDelete: (e:React.MouseEvent) => void;
  gotoSelect4RuleId: (id:number) => void;
  value: IPromotionRule;
}
export const RuleInput: React.FC<IRuleInputProps> = ({value, onChange, onDelete, index, gotoSelect4RuleId}) => {
  const [rule, setRule] = useState<IPromotionRule>(value)

  useEffect(() => {
    if (!!rule?.followerNumber) onChange(rule)
  }, [rule])
  useEffect(() => {
    if (
      (value.preference?.id !== rule.preference?.id) ||
      (value.followerNumber !== rule.followerNumber) 
    ) {
      setRule(value)
    }
  }, [value])

  const onNumberChange = (n:number) => {
    if (n > 0){
      setRule({
        ...rule,
        followerNumber: n,
      })
    }
  }

  return (
    <Wrapper className="">
      <div className='flex-between align-center title'>
        <p>奖品{index+1}</p>
        <Button onClick={onDelete}>删除</Button>
      </div>
      <div className='body debugx'>
        <NumberInput value={rule.followerNumber} onChange={onNumberChange} label="累计邀请人数*" type="number" />
        <div className="name flex-between align-center">
          <p>卡券 <span>*</span></p>
          <div className="flex-end align-center" onClick={() => gotoSelect4RuleId(index)}>
            <p>{rule.preference?.name}</p>
            <IconArrowRight />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
