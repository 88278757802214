import * as React from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import { blue } from "@mui/material/colors";
import { THEME } from "../../config/theme";
import { Link, useHistory } from "react-router-dom";
import { LOCAL_URL } from "../../config";
import GroupStatus from "../../components/GroupStatus";
import dayjs from "dayjs";
import { getImageById } from "../../utils/ImageUtils";

const Card = styled.div`
  border-radius: 8px;
  background-color: #fff;
  padding: 16px 8px 16px 8px;
  color: ${THEME.color.gray["#12-045"]};
  margin-bottom: 8px;
  .bottom-layout {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    justify-content: space-between;
  }
  .title {
    color: ${THEME.color.gray["#10-085"]};
  }
  .nickname {
    width: 58px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 8px;
  }
  .avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export interface IGroupBuyCardProps {}

export default function GroupBuyCard(props: any) {
  const histroy = useHistory();
  const {
    createdAt,
    viewCount,
    status,
    startAt,
    endAt,
    orderCount,
    nickname,
    merchantName,
    id,
    avatar,
    logo,
    title,
    primaryColor
  } = props;
  return (
    <Card
      onClick={() => {
        histroy.push(LOCAL_URL["GROUP_BUY_DETAIL"] + "?groupId=" + id);
      }}
    >
      <div className="ft-rm16 title">{title}</div>
      <GroupStatus status={status} endAt={endAt} startAt={startAt} primaryColor={primaryColor}/>
      <div className="bottom-layout">
        <div className="avatar-container">
          <Avatar
            src={getImageById(logo)}
            sx={{ width: 24, height: 24, bgcolor: blue[700] }}
          ></Avatar>
          <span className="nickname">{merchantName}</span>
        </div>
        <span>发布于{dayjs(createdAt).format("DD/MM/HH:mm")}</span>
        <span>
          浏览 {viewCount} 订单 {orderCount}
        </span>
      </div>
    </Card>
  );
}
