import styled from 'styled-components';
import { SpinFrames } from './Animation';

const Container = styled.div`
  & svg {
    width: ${props => props.size || '64px'};
    fill: ${props => props.color || '#fff'};
    animation: ${SpinFrames} infinite 1s linear;
    /* transform-origin: center; */
  }
`
const SVGSpin = () => (
<svg  viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M32 64C27.6812 64 23.4875 63.1562 19.5438 61.4875C15.7313 59.875 12.3125 57.5625 9.375 54.625C6.4375 51.6875 4.125 48.2687 2.5125 44.4562C0.84375 40.5125 0 36.3187 0 32C0 30.7563 1.00625 29.75 2.25 29.75C3.49375 29.75 4.5 30.7563 4.5 32C4.5 35.7125 5.225 39.3125 6.6625 42.7062C8.05 45.9813 10.0312 48.925 12.5562 51.45C15.0812 53.975 18.025 55.9625 21.3 57.3438C24.6875 58.775 28.2875 59.5 32 59.5C35.7125 59.5 39.3125 58.775 42.7062 57.3375C45.9813 55.95 48.925 53.9688 51.45 51.4437C53.975 48.9187 55.9625 45.975 57.3438 42.7C58.775 39.3125 59.5 35.7125 59.5 32C59.5 28.2875 58.775 24.6875 57.3375 21.2938C55.9547 18.0266 53.9534 15.0576 51.4437 12.55C48.9389 10.037 45.9692 8.03522 42.7 6.65625C39.3125 5.225 35.7125 4.5 32 4.5C30.7563 4.5 29.75 3.49375 29.75 2.25C29.75 1.00625 30.7563 0 32 0C36.3187 0 40.5125 0.84375 44.4562 2.5125C48.2687 4.125 51.6875 6.4375 54.625 9.375C57.5625 12.3125 59.8688 15.7375 61.4813 19.5438C63.15 23.4875 63.9938 27.6812 63.9938 32C63.9938 36.3187 63.15 40.5125 61.4813 44.4562C59.875 48.2687 57.5625 51.6875 54.625 54.625C51.6875 57.5625 48.2625 59.8688 44.4562 61.4813C40.5125 63.1562 36.3187 64 32 64Z"/>
</svg>
)

export const Spin = (props) => (
  <Container {...props}>
    <SVGSpin />
  </Container>
)
