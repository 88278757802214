import * as React from "react";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Header from "../../components/Header";
import { Radio } from "../../forms/Radio";
import { EntityContext } from "../../context";
import useMerchant from "../../hooks/useMerchant";
import { useHistory } from "react-router-dom";
import { number } from "yup";
import { LOCAL_URL } from "../../config";
import { getImageById } from "../../utils/ImageUtils";
import { IconKBArrowR, notify } from "../../components";
import {blue} from "@mui/material/colors";

export interface ISwitchMerchantProps {}

const Root = styled.div`
  padding: 16px;
`;

const StyledMerchantCard = styled.div`
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  margin-bottom: 16px;
  .info {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    flex-grow: 1;
    .merchant-name {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.85);
    }
    .merchant-id {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.85);
    }
    .role {
      margin-left: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #ed612b;
    }
  }
  .radio-container {
    width: 32px;
    height: 32px;
  }
`;

const MerchantCard = ({ name, id, role, logo, checked, onClick }) => {
  let sRole = "";
  switch (role) {
    case "admin":
      sRole = "管理员";
      break;
    case "operator":
      sRole = "操作员";
      break;
    case "owner":
      sRole = "团长";
      break;
    default:
      break;
  }
  return (
    <StyledMerchantCard onClick={onClick}>
      <Avatar
        src={getImageById(logo)}
        sx={{ width: 40, height: 40, bgcolor: blue[700] }}
      ></Avatar>
      <div className="info">
        <div className="merchant-name">{name}</div>
        <div className="merchant-id">
          ID: {id}
          <span className="role">{sRole}</span>
        </div>
      </div>
      <div className="radio-container">
        <IconKBArrowR />
      </div>
    </StyledMerchantCard>
  );
};

export default function SwitchMerchant(props: ISwitchMerchantProps) {
  const { model: entityModel, flush } = React.useContext(EntityContext);
  const merchants = entityModel["ALL_MERCHANT"].response?.data;

  const history = useHistory<any>();
  const { url, state } = history?.location?.state;

  const { currentMerchantId, switchMerchant } = useMerchant();

  const onSwitchMerchant = (merchantId: number) => {
    const merchant = merchants.filter((m) => m.id === merchantId);

    if (merchant.role === "admin" && url === LOCAL_URL["INVITE_MEMBER"]) {
      // 无权限
      notify.error("无权限");
      return;
    }

    if (merchant.role === "operator" && url !== LOCAL_URL["MANAGE_ORDER"]) {
      // 无权限
      notify.error("无权限");
      return;
    }
    switchMerchant(merchantId);

    if (url === LOCAL_URL["CREATE_GROUP_BUY"]) {
      flush("GROUP_BUY");
    }

    history.replace(url, state);
  };

  React.useEffect(() => {
    if (url && merchants && merchants.length === 1) {
      if (url === LOCAL_URL["CREATE_GROUP_BUY"]) {
        flush("GROUP_BUY");
      }
      history.replace(url, state);
    }
  }, [url, merchants]);

  return (
    <PageLayout pageName="no nav">
      <Header title="选择店铺" />
      <Root>
        {merchants && merchants.length > 0
          ? merchants.map((m) => (
              <MerchantCard
                key={m.id}
                {...m}
                checked={m.id === currentMerchantId}
                onClick={() => onSwitchMerchant(m.id)}
              />
            ))
          : null}
      </Root>
    </PageLayout>
  );
}
