import React, { useState } from 'react';
import styled from 'styled-components';
import { IconAddCircle, IconRemoveCircle } from './Icons';
import { THEME } from '../config';

export const QuantityContainer = styled.div`
  width: 100%;
  text-align: center;
`;
export const Counter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 2em;
    height: 2em;
    cursor: pointer;
    transform: translateY(.16em);
    fill: ${THEME.color.gray['#0-087']};
  }
  & button {
    background: transparent;
    border: none;
  }
  & button:disabled svg {
    fill: ${THEME.color.gray['#6-d9']};
  }
`;

const MIN_NUMBER = 1;
interface QuantityProps {
  onChange?: (value: number) => void;
  value: number;
  limit?: number;
  exceedWarning?: string;
  exceedAllowed?: boolean;
}
export const Quantity: React.FC<QuantityProps> = ({onChange, value, limit, exceedWarning, exceedAllowed}) => {
  const isOverThrethold = (!!limit) && (value >= limit);
  const add = () => { 
    if ((!exceedAllowed) && (!!isOverThrethold)) return;
    if (!!onChange) onChange(value+1);
  }
  const sub = () => { 
    if (value <= MIN_NUMBER) return;
    if (!!onChange) onChange(value-1);
  }

  // console.log(limit)
  return (
    <QuantityContainer>
      <p style={{lineHeight: '24px'}} className="ft-ps16 clr-ft-gray2-48">Quantity</p>
      <Counter>
        <button onClick={sub} disabled={(value <= MIN_NUMBER)}><IconRemoveCircle /></button>
        <p style={{margin: '0 16px', lineHeight: '48px'}} className={`ft-rb32 clr-ft-gray0-087`}>{value}</p>
        <button onClick={add} disabled={ (!exceedAllowed) && (isOverThrethold) }><IconAddCircle /></button>
      </Counter>
      { !limit ? null : 
        (!!exceedWarning) ?
        <p className="clr-ft-primary">{exceedWarning}</p>
        :
        (exceedWarning === null) ? null
        :
        <p>{`You still have ${limit - value} left`}</p>
      }
    </QuantityContainer>
  )
}
