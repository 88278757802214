import {Backdrop} from "@mui/material";
import React, {useEffect} from "react";
import styled from "styled-components";
import IconClose from "../../../components/Icons/IconClose";
import IconLink from "../../../components/Icons/IconLink";
import IconShareImage from "../../../components/Icons/IconShareImage";
import WhiteSpace from "../../../components/WhiteSpace";
import {LOCAL_URL, THEME, WEB_ROOT} from "../../../config";
import {CopyToClipboard} from "react-copy-to-clipboard";

interface InventFriendsModalProps {
  open: boolean
  nextTarget: number
  copyUrl: string
  onShowShareImageClicked: () => void
  onCopyLinkClicked: () => void
  onClose: ()=>void
}

const InventFriendsModal = (props: InventFriendsModalProps) => {

  return (
    <Backdrop
      open={props.open}
      onClick={() => {
        props.onClose()
      }}
    >
      <Container>
        <div className='card'>
          <div className='title'>邀请好友一起领券</div>
          {props.nextTarget > 0 ? (
            <div>
              <WhiteSpace/>
              <div className='desc'>邀请{props.nextTarget}位好友参与，即可成功领券</div>
            </div>
          ) : null}
          <WhiteSpace height={24}/>
          <div className='buttons'>
            <div className='button' onClick={()=>{
              props.onShowShareImageClicked()
            }}>
              <IconShareImage/>
              <div>生成分享图</div>
            </div>
            <div className='button' onClick={()=>{
              props.onCopyLinkClicked()
            }}>
              <CopyToClipboard
                text={
                  props.copyUrl
                }
              >
                <div onClick={()=>{
                  props.onCopyLinkClicked()
                }}>
                  <IconLink/>
                  <div>复制链接</div>
                </div>

              </CopyToClipboard>

            </div>
          </div>
        </div>
        <div className='close' onClick={()=>{
          props.onClose()
        }}>
          <IconClose/>
        </div>
      </Container>
    </Backdrop>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80%;

  .card {
    padding: 24px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      color: #07C160;
    }

    .desc {
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.gray["#12-045"]};
    }

    .buttons {
      display: flex;
      .button {
        margin-left: 8px;
        margin-right: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        color: ${THEME.color.gray["#12-045"]};
        svg{
          margin-bottom: 4px;
        }
      }
    }
  }

  .close {
    margin-top: 24px;
  }
`

export default InventFriendsModal
