import React, {ReactElement, useEffect, useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import {THEME} from "../../../config";

type ModalProps = {
  show: boolean
  title: string
  content?: string | ReactElement
  confirmText?: string
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
  href?: string
}

function TTModal(props: ModalProps) {

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={props.show}
      onClose={() => {
        if (props.onCancel) {
          props.onCancel()
        }
      }}
    >

      <Container>
        <div className='title' id="child-modal-title">{props.title}</div>
        <div className='content' id="child-modal-description">{props.content}</div>
        <div className='button-area'>
          {props.onCancel && (
            <div className='left'
                 onClick={() => {
                   props.onCancel()
                 }}
            >{props.cancelText || '取消'}
            </div>
          )}
          {(props.onConfirm || props.href) && (
            <a className='right'
               href={props.href || undefined}
               onClick={() => {
                 if (props.onConfirm){
                   props.onConfirm()
                 }
               }}
            >{props.confirmText || '确定'}
            </a>
          )}
        </div>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  border: none;
  padding-top: 16px;
  background-color: #F2F2F2;
  border-radius: 7px;
  outline: none;

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    text-align: center;
  }

  .content {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    padding: 16px 16px 0 16px;
    color: ${THEME.color.gray["#12-045"]};
    white-space: pre-line;
  }

  .button-area {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(60, 60, 67, 0.36);

    .left {
      text-align: center;
      flex: 1;
      font-weight: 600;
      font-size: 18px;
      color: #007AFF;
      line-height: 50px;
      border-right: 1px solid rgba(60, 60, 67, 0.36);

    }

    .right {
      text-align: center;
      flex: 1;
      font-weight: 600;
      color: #007AFF;
      font-size: 18px;
      line-height: 50px;
      text-decoration: none;
    }
  }

`

export default TTModal
