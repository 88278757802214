import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 1rem;
  background-color: white;
  min-height: 100vh;

  & div.action {
    margin: 1rem 0 2rem;
    display: flex;
    justify-content: space-between;
  }
  & div.result {
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    gap: .5rem;
    margin: 1rem 0;
  }
  & div.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
  & div.gallery img {
    width: 100%;
  }
  & div.gallery img.selected {
    /* border: 2px solid grey; */
  }
  & p {
    width: 100%;
    margin: 1rem 0;
    text-align: right;
    word-wrap: break-word;
    word-break: break-all;
  }
`

export const Button = styled.button`
  padding: .2rem .5rem;
`
