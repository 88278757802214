import { OrderPaymentStatusType, OrderDeliveryStatusType } from "../../context";

type ListItemType<T> = { label: string; value: T };

export const PAYMENT_STATUS_LIST: ListItemType<OrderPaymentStatusType>[] = [
  { value: "ALL",           label: "全部", },
  { value: "CREATED",       label: "待付款", },
  { value: "PER_PAID",      label: "待确认", },
  { value: "PAID",          label: "已付款", },
  { value: "CANCEL_APPLY",  label: "申请取消", },
  { value: "EXPIRED",       label: "已失效", },
  // { value: "CANCELED",      label: "已取消", },
];

export const DELIVERY_STATUS_LIST : ListItemType<OrderDeliveryStatusType>[] = [
  { value: "ALL",         label: "全部", },
  { value: "UNDELIVERED", label: "未发货", },
  { value: "DELIVERED",   label: "已发货", },
];
