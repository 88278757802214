import React, { useRef, useState, useEffect, useImperativeHandle } from 'react'
import { IconCheck } from '../SVGIcons'
import { IToastFunctions, ToastMsgType, IToastMsg, DEFAULT_TOAST_TYPE, DEFAULT_TOAST_MS } from './type'
import { Container, Wrapper } from './styled'

export const Toast = ({id, ms, variant, onClose, children}: {
  id: number;
  ms: number;
  variant: ToastMsgType;
  onClose?: () => void;
  children: any;
}) => {
  useEffect(() => {
    // console.log('toast effect', id);
    setTimeout(() => {
      if(!!onClose) onClose();
    }, ms)
  }, [])

  // console.log('toast render', id)
  return (
    <Wrapper className={variant} onClick={onClose}>
      { (variant === 'info')    && <IconCheck /> }
      { (variant === 'warning') && <IconCheck /> }
      { (variant === 'error')   && <IconCheck /> }
      { (variant === 'success') && <IconCheck /> }
      <span>{children}</span>
    </Wrapper>
  );
}
const ToastContainer = React.forwardRef((props, ref) => {
  const [toastsList, setToastsList] = useState<IToastMsg[]>([])
  useImperativeHandle(ref, () => {
    return {
      info    : (msg: string, ms?: number) => setToastsList(tl => [ ...tl, { msg, ms, type: 'info' } ]),
      warning : (msg: string, ms?: number) => setToastsList(tl => [ ...tl, { msg, ms, type: 'warning' } ]),
      error   : (msg: string, ms?: number) => setToastsList(tl => [ ...tl, { msg, ms, type: 'error' } ]),
      success : (msg: string, ms?: number) => setToastsList(tl => [ ...tl, { msg, ms, type: 'success' } ]),
    }
  })

  useEffect(() => {
    if (!toastsList?.length) return;
    if (toastsList.filter(t => !!t?.msg).length === 0) setToastsList([]);
  }, [toastsList, setToastsList])
  
  const onClose = (index: number) => () => {
    // console.log('onClose', index);
    setToastsList((x) => {
      x[index] = { msg: '' };
      return [...x];
    });
  }

  return (
    !toastsList.length ? null :
    <Container>
    { toastsList.map((item, index) => (
      !item.msg ? null :
      <Toast key={index} id={index} 
        variant={item.type || DEFAULT_TOAST_TYPE}
        ms={item.ms || DEFAULT_TOAST_MS}
        onClose={onClose(index)}
      >
        {item.msg}
      </Toast>
    )) }
    </Container>
  )
})

const fnToastMsgDefault = (type: ToastMsgType) => (msg: string) => (console.log(`This is default ${type} function ${msg}`));
export const toast: IToastFunctions = {
  info    : fnToastMsgDefault('info'),
  warning : fnToastMsgDefault('warning'),
  error   : fnToastMsgDefault('error'),
  success : fnToastMsgDefault('success'),
}

export const ToastManager = () => {
  const ref = useRef<any>()

  useEffect(() => {
    // console.log('ref', ref)
    toast.info    = ref.current?.info     || toast.info;
    toast.warning = ref.current?.warning  || toast.warning;
    toast.error   = ref.current?.error    || toast.error;
    toast.success = ref.current?.success  || toast.success;
  }, [])

  return (
    <ToastContainer ref={ref} />
  )
}
