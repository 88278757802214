import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
`
const TabContainer = styled.div`
  display: inline-block;
  padding: 2px;
  color: rgba(0,0,0,0.45);
  font-size: 15px;
  line-height: 21px;
  &.active {
    color: ${props => props.theme.color.primary.HEX};
    border-bottom: 2px solid ${props => props.theme.color.primary.HEX};
  }

  & ~ & {
    margin-left: 1rem;
  }
`

export const Tab = ({children, ...props}) => {
  return (
    <TabContainer {...props}>{children}</TabContainer>
  )
}
export const Tabs = ({children, ...props}) => {
  return (
    <Wrapper {...props} >
      { children }
    </Wrapper>
  )
}
