import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import * as React from "react";
import IconTrolley from "../../components/Icons/IconTrolley";
import {IconTags} from "../../Layout"
import WechatShareGuide from "../../components/WechatShareGuide";
import {getShipStr, removeEmoji} from "../../utils/common";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import {LOCAL_URL, THEME} from "../../config";
import Avatar from "@mui/material/Avatar";
import iconPhone from "../../svg/icon_phone_nobg.svg";
import iconShare from "../../svg/icon_share_nobg.svg";
import GroupBuyCard from "../../business/components/GroupBuyCard";
import {EntityContext} from "../../context";
import Header from "../../components/Header";
import useMerchant from "../../hooks/useMerchant";
import {getParams, requestPost} from "../../lib";
import {useHistory} from "react-router-dom";
import EmptyView from "../../components/EmptyView";
import {getImageByIdWithSize} from "../../utils/ImageUtils";
import iconEdit from "../../svg/icon_edit_white.svg";
import WhiteSpace from "../../components/WhiteSpace";
import {useEffect, useState} from "react";
import mitt, {EVENT} from "../../utils/Event";
import {Button, Divider, Switch} from "../../components";
import {defaultBg} from "../PersonalCenter/addMerchant";
import {initValidPromotionData} from "./helper";

export interface IDetailProps {
}

export default function DetailWithBG(props: IDetailProps) {

  const [showShareWechatGuide, setShowShareWechatGuide] = useState(false);
  const {model: entityModel, query} = React.useContext(EntityContext);
  const merchantData = entityModel["MERCHANT"].response?.data ?? {};
  const {groups} = merchantData || [];
  const [primaryColor, setPrimaryColor] = useState<string>(THEME.color.primary.default)
  const history = useHistory();
  const [promoList, setPromoList] = useState<any[]>([])

  const {id} = getParams(history.location.search);

  const {currentMerchantId} = useMerchant();
  React.useEffect(() => {
    if (id) {
      query("MERCHANT", {id});
      return;
    }
    if (currentMerchantId) {
      query("MERCHANT", {id: currentMerchantId});
    }
  }, [currentMerchantId, id]);

  const initialPromoList = async () => {
    const result = await initValidPromotionData(merchantData.id)
    if (result && result?.length >= 0) {
      setPromoList(result)
    }
  }
  React.useEffect(() => {
    if ('number' === typeof merchantData?.id) {
      initialPromoList()
    }
  }, [merchantData])

  useEffect(() => {
    if (merchantData && merchantData) {
      mitt.emit(EVENT.SET_SHARE_CONTENT, {
        title: removeEmoji(merchantData.meta?.wechatTitle || merchantData.name),
        url: `https://tuan.shenmanz.com/public/merchant/detail?id=${merchantData.id}`,
        content: removeEmoji(merchantData.meta?.wechatContent || merchantData.address),
        image: merchantData.meta?.wechatImage ? getImageByIdWithSize(merchantData.meta?.wechatImage) : getImageByIdWithSize(merchantData.logo),
      });
      setPrimaryColor(merchantData.meta?.themeColor ?? THEME.color.primary.default)
    }
  }, [merchantData]);

  const editMerchant = () => {
    history.push(LOCAL_URL["ADD_MERCHANT"], {merchant: merchantData});
  };

  return (
    <PageLayout pageName="merchantDetail">
      <Header title="店铺主页"
              backgroundColor={primaryColor}
              right={merchantData.isAdmin ? (
                <img
                  onClick={() => {
                    if (merchantData.isAdmin) {
                      editMerchant();
                    }
                  }}
                  style={{width: 24, height: 24, marginRight: 16}}
                  src={iconEdit}
                />
              ) : null}
      />
      <Container iconColor={primaryColor}>
        <img className='main-bg' src={merchantData.meta?.backgroundImage ?? defaultBg}></img>
        <div className='header'>
          <Avatar
            className='avatar'
            src={getImageByIdWithSize(merchantData.logo, undefined, 240)}
          />
          <WhiteSpace height={16}/>
          <div className='merchant-name'>{merchantData.name}</div>
          <WhiteSpace height={16}/>
          <div className='merchant-operate'>
            <a
              href={
                merchantData.id === 1
                  ? LOCAL_URL["CUSTOMER_SERVICE"]
                  : `tel:${merchantData.phone}`
              }
            >
              <img className="phone" src={iconPhone}/>
            </a>
            <a
              onClick={() => {
                setShowShareWechatGuide(true)
              }}
            >
              <img className="phone" src={iconShare}/>
            </a>
          </div>
          <WhiteSpace height={16}/>
          <Divider/>
          <WhiteSpace height={16}/>
          <div className='brief-intro'>
            {merchantData.meta?.briefIntro || `欢迎光临${merchantData.name}`}
          </div>
          <WhiteSpace height={20}/>
        </div>
      </Container>
      {(promoList && promoList.length > 0 || merchantData.isAdmin) ? (
        <EventCard
          mainColor={primaryColor} Icon={(<IconTags/>)}
          title='店铺活动' title2={promoList?.[0]?.name || '暂无活动'}
          right={(
            <Button size='medium' reverseColor primaryColor={primaryColor}
                    onClick={() => {
                      if (!merchantData) return;
                      history.push(LOCAL_URL["PROMOTION_LIST"], {
                        merchantData
                      })
                    }}
            >立即参加</Button>
          )}
        />
      ) : null}
      {merchantData && merchantData.shoppingMall ? (
        <div>
          <ShoppingCardContainer>
            {merchantData.isAdmin ? (
              <div className='card'>
                <div className='left'>
                  <div className='top'>在线网店开关</div>
                  <div
                    className='bottom'>{merchantData.shoppingMall.status === 'PROCESSING' ? '营业中' : '关店中，用户无法下单'}</div>
                </div>
                <div className='right'>
                  <Switch
                    size={31}
                    totalWidth={50}
                    value={merchantData.shoppingMall.status === 'PROCESSING'}
                    onChange={async () => {
                      await requestPost('merchant/shoppingMall/online', {
                        merchantId: merchantData.id,
                        status: merchantData.shoppingMall.status === 'PROCESSING' ? 'PENDING' : 'PROCESSING'
                      })
                      await query("MERCHANT", {id: merchantData.id});
                    }}/>
                </div>
              </div>
            ) : null}
          </ShoppingCardContainer>
          {(merchantData.isAdmin || (merchantData.shoppingMall.status === 'PROCESSING')) ? (
            <EventCard
              mainColor={primaryColor}
              Icon={(<IconTrolley/>)}
              title='在线网店'
              title2={`本店支持${getShipStr(merchantData.shoppingMall)}`}
              right={(
                <Button
                  reverseColor
                  primaryColor={primaryColor}
                  size='medium'
                  onClick={() => {
                    if (merchantData.shoppingMall.status === 'PROCESSING' || merchantData.isAdmin) {
                      history.push(LOCAL_URL["SHOPPING_DETAIL"] + "?groupId=" + merchantData.shoppingMall.id);
                    }
                  }}
                >{!merchantData.isAdmin && merchantData.shoppingMall.status === 'OFFLINE' ? '关店中' : '进入网店'}</Button>
              )}
            />
          ) : null}
        </div>
      ) : null}
      <GroupBuyContainer>
        <div className="group-title">{groups && groups.length > 0 ? '全部拼团' : ''}</div>
        {
          groups && groups.length > 0 ? (
            groups.map((g) => <GroupBuyCard key={g.id} {...g} primaryColor={primaryColor}/>)
          ) : (
            // <EmptyContainer>
            <EmptyView desc="还没有发布过团购" hideBackground/>
          )
          // </EmptyContainer>
        }
        {((!groups || groups.length === 0) && merchantData?.isAdmin) && (
          <div style={{
            marginTop: 72,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Button onClick={() => {
              history.push(LOCAL_URL['SWITCH_MERCHANT'], {url: LOCAL_URL['CREATE_GROUP_BUY']});
            }}> 发布团购 </Button>
          </div>
        )}
      </GroupBuyContainer>
      <WhiteSpace height={56}/>
      <WechatShareGuide
        visible={showShareWechatGuide}
        onOkClick={() => setShowShareWechatGuide(false)}
      />
    </PageLayout>
  );
}


const ShoppingCardContainer = styled.div`
  padding: 16px;

  .card {
    padding: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .left {
      flex: 1;

      .top {
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        color: ${THEME.color.gray["#10-085"]};
        padding-bottom: 4px;
      }

      .bottom {
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: ${THEME.color.gray["#12-045"]};
      }
    }

    .right {
      width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
`


const Container = styled.div`
  .main-bg {
    width: 100%;
  }

  .phone {
    background-color: ${props => props.iconColor ?? THEME.color.primary.default};
    border-radius: 12px;
    height: 24px;
    width: 24px;
  }

  .header {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin: -32px 16px 0;
    position: relative;
  }

  .avatar {
    height: 64px;
    width: 64px;
    margin-top: -32px;
  }

  .merchant-name {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: ${THEME.color.gray["#10-085"]};
  }

  .merchant-operate {
    width: 70px;
    display: flex;
    justify-content: space-between;
  }

  .brief-intro {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    padding-left: 16px;
    padding-right: 16px;
    color: ${THEME.color.gray["#12-045"]};
  }
`

const GroupBuyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

  .group-title {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
  }
`;

interface EventCardProps {
  mainColor?: string,
  Icon?: ReactJSXElement,
  title: string
  title2: string,
  right: ReactJSXElement
}


const EventCard = (props: EventCardProps) => {
  return (
    <EventCardContainer primaryColor={props.mainColor} {...props}>
      <div className='card'>
        {props.Icon ? (
          <div className='left'>
            {props.Icon}
          </div>
        ) : null}

        <div className='title'>
          <div className='top'>{props.title}</div>
          <div
            className='bottom'>{props.title2}</div>
        </div>
        <div className='right'>
          {props.right}
        </div>
      </div>
    </EventCardContainer>
  )
}


const EventCardContainer = styled.div`
  padding: 0 16px 16px;

  .card {
    padding: 16px 16px 16px 0;
    margin-bottom: 4px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.primaryColor ?? THEME.color.primary.default};

    .left {
      width: 64px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .title {
      padding-left: ${props => !!props.Icon ? '8px' : '16px'};
      flex: 1;

      .top {
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        padding-bottom: 4px;
        color: white;
      }

      .bottom {
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        line-height: 21px;
        color: white;
      }
    }

    .right {
      width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`
