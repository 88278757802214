import styled from "styled-components";
import numeral from "numeral";
import {getImageByIdWithSize} from "../../utils/ImageUtils";
import { IProductCardProps } from './ProductCard.type';
import { AddToCartButton } from './ProductCard';
import { ListPrice } from './ProductCard.Price'

const Wrapper = styled.div`
  & ~ & {
    margin-top: 1.5rem;
  }
`;
const ImageBlock = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: white;
  background-image: url(${props => props.imgUrl});
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
`
const DetailsBlock = styled.div`
  padding: 1.5rem;
  border-top: 1px solid rgba(0,0,0,0.09);

  & div.soldout {
    align-self: flex-end;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
  }
`
const TextArea = styled.div`
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: rgba(0,0,0,0.45);

  & p.title {
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
  }
  & p.desc {
    margin-top: .5rem;
  }
  & span.more-info {
    margin-left:.5rem;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: rgba(0,0,0,0.45);
  }
`

const Details = ({
  title, specification, description,
  soldQuantity, available, maxOrderQuantity,
  price, originalPrice,
}) => {
  return (
    <TextArea className="debugx">
      <p className="title">{title}</p>
      <p className="desc">{specification}</p>
      <p className="desc">{description}</p>
      <p className="desc" style={{marginBottom: '16px'}}>
        {!!soldQuantity && <span>已售{soldQuantity}</span>}
        { (available > -1) && (
          <span style={{marginLeft: !!soldQuantity ? '1rem':''}}>库存{available}</span>
        )}
        { (!!maxOrderQuantity) && (
          <span className="more-info">
            限购{maxOrderQuantity}份
          </span>
        )}
      </p>

      <ListPrice price={price} originalPrice={originalPrice} />

    </TextArea>
  )
}

const IMAGE_SIZE = 327 * 3;

export const ProductCardLargeImage: React.FC<IProductCardProps> = ({
  title, price, originalPrice,
  images, specification, description,
  soldQuantity, available,
  primaryColor,
  mAmount,
  onAdd, onRemove,
  setShowImageDialog,
  maxOrderQuantity, quotaUsed,
  // ...rest
}) => {
  const noMoreQuota = (!!maxOrderQuantity) && ((mAmount + (quotaUsed || 0)) >= maxOrderQuantity);
  return (
    <Wrapper className="shadow04 debugx">
      {images && images.length > 0 && (
        <ImageBlock
          onClick={() => setShowImageDialog(true)}
          imgUrl={getImageByIdWithSize(images[0], undefined, IMAGE_SIZE, IMAGE_SIZE)}
        />
      )}

      <DetailsBlock className="flex-between">
        <Details
          title={title}
          specification={specification}
          description={description}
          soldQuantity={soldQuantity}
          available={available}
          price={price}
          originalPrice={originalPrice}
          maxOrderQuantity={maxOrderQuantity}
        />

        { (available > 0 || available === -1) ? (
            <AddToCartButton
              noMoreQuota={noMoreQuota}
              onRemove={onRemove}
              onAdd={onAdd}
              amount={mAmount}
              primaryColor={primaryColor}
              available={available === -1 ? 99999999 : available}
            />
            ) : (
            <div className="soldout">售罄</div>
          )}

      </DetailsBlock>
    </Wrapper>
  );
};
