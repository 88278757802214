import React from "react";
import styled from "styled-components";
import { THEME } from "../../config";

const MAX_WIDTH = THEME.size.maxWidth.body;
const SIDE_SPACE = THEME.size.SIDE_MARGIN_WIDTH;
const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;

export const FixedArea = styled.div`
  position: fixed;
  top: ${(props) => props.top || "0"};
  left: ${(props) => props.left || "0"};
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
`;

export const Section = styled.section`
  width: 100%;
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background: ${(props) => props.background || "transparent"};

  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    &.mobile-only {
      display: none;
    }
  }
`;
export const StretchColumn = styled.div`
  margin: 0 ${SIDE_SPACE};
  background: ${(props) => props.foreground || "transparent"};
  &.right-through {
    margin: 0 0 0 ${SIDE_SPACE};
  }
  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    width: 100%;
    max-width: ${THEME.size.maxWidth.section};
    margin: 0 auto;
    &.right-through {
      margin: 0 auto;
    }
  }
`;

interface SegmentProps {
  children?: any;
  variant?: "full" | "normal" | "right-through" | string | undefined;
  className?: string;
  [propName: string]: any;
}
export const Segment: React.FC<SegmentProps> = ({
  children,
  variant,
  className,
  ...restProps
}) =>
  variant === "full" ? (
    <Section {...restProps} className={className}>
      {children}
    </Section>
  ) : variant === "right-through" ? (
    <Section {...restProps} className={`${className}`}>
      <StretchColumn {...restProps} className="right-through">
        {children}
      </StretchColumn>
    </Section>
  ) : (
    <Section {...restProps} className={className}>
      <StretchColumn {...restProps}>{children}</StretchColumn>
    </Section>
  );
