import styled from 'styled-components';
import { IconBars } from '../Icons';

const Container = styled.div`
  position: relative;
  
  display: inline-block;
  height: 24px;
  width: 24px;

  cursor: pointer;
  z-index: 999;

  & svg {
    height: 100%;
    fill: #fff;
  }
`;

export const BarsButton = (props) => (
  <Container {...props} >
    <IconBars />
    {props.children}
  </Container>
)
