import OrderCard from "./components/OrderCard";
import {THEME} from "../../config";
import {Button} from "../../components";

interface IOrderListProps {
  hasMore?: boolean;
  loadMore: () => void;
  orders: any[];
  groupInfo: any;
  refresh: () => void;
};

export const OrderList: React.FC<IOrderListProps> = (props) => {
  if (!props.orders || props.orders.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 80,
        }}
      >
        <img
          style={{width: 93, height: 64}}
          src="https://res.cloudinary.com/shenmanz/image/upload/v1647477886/tuangou/icon/no_order.png"
          alt=""
        />
        <div
          style={{
            paddingTop: 8,
            color: THEME.color.gray["#12-045"],
            fontSize: 12,
          }}
        >
          还没有订单哦
        </div>
      </div>
    );
  }
  return (
    <div style={{backgroundColor: "white", marginTop: 16}}>
      {props.orders.map((item, index) => {
        return (
          <OrderCard
            key={index}
            group={props.groupInfo}
            actions={[]}
            type="MERCHANT"
            order={item}
            onRefresh={() => {
              props.refresh();
            }}
          />
        );
      })}
      {props.hasMore && (
        <div
          style={{
            marginTop: 16,
            paddingLeft: 16,
            paddingRight: 16,
            marginBottom: 16,
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              if (props.loadMore) {
                props.loadMore();
              }
            }}
          >
            加载更多
          </Button>
        </div>
      )}
    </div>
  );
};
