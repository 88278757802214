import { toast } from '../../../Layout'

/**
 * https://stackoverflow.com/questions/1981349/regex-to-replace-multiple-spaces-with-a-single-space
 * Given that you also want to cover tabs, newlines, etc, just replace \s\s+ with ' ':
 * string = string.replace(/\s\s+/g, ' ');
 * 
 * If you really want to cover only spaces (and thus not tabs, newlines, etc), do so:
 * string = string.replace(/  +/g, ' ');
 */

const dealWithConsecutiveSpaces = (text: string) => text.replace(/  +/g, ' ');
const unifyLineBreak = (text:string) => text.replace('\r\n', '\n').replace('\r', '\n');
const parseMoney = (text:string) => {
  const [p1, p2] = text.split('.');
  const dec = (p2?.substring(0,2) || '').padEnd(2, '0');
  return parseInt(p1) * 100 + ( !p2 ? 0 : parseInt(dec) )
}

interface IRowData {
  title: string;
  price: number;    // 整数，最小单位：分
  specification: string;
  category?: string;
}

const REQUIRED_FIELDS_NUMBER = 3
const PRICE_FIELD_INDEX = 1
export const parseBatchInput: (text: string) => IRowData[]
= (text) => {
  if (!text) return null;
  const rows = unifyLineBreak( dealWithConsecutiveSpaces(text) ).split('\n').filter((str: string) => !!str).map((str: string) => [ ...str.split(' ').filter((x:string) => !!x) ])
  for (let i=0;i<rows.length;i++) {
    if (rows[i].length < REQUIRED_FIELDS_NUMBER) {
      toast.error(`#${i} 数据缺失`)
      return null
    }
    if ( isNaN( Number( rows[i][PRICE_FIELD_INDEX] ))) {
      toast.error(`#${i} 金额数据错误`)
      return null
    }
  }
  return rows.map((row: string[]) => ({
    title         : row[0],
    price         : parseMoney(row[PRICE_FIELD_INDEX]),
    specification : row[2],
    category      : row[3],
  }))
}
