export const SYS_APP_NAME = {
  ADMIN     : 'SYS_APP_DESKTOP_ADMIN',
  LOVINLOCAL: 'SYS_APP_LOVINLOCAL',
}
export const SYS_APP_VERSION = {
  version: 'v1.0.0 (W26)',
  build: '20220421A',
}

export const WECHAT_FOLLOW_URL = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIzODg2ODQ4MA==&scene=124#wechat_redirect"

export const FIRBASE_CONFIG = {
  apiKey: "AIzaSyBifjiZLiy87hoF6Ck_a7iGngXV0bs0r-M",
  authDomain: "daji-20439.firebaseapp.com",
  projectId: "daji-20439",
  storageBucket: "daji-20439.appspot.com",
  messagingSenderId: "895278516388",
  appId: "1:895278516388:web:6b8183901dc512842d3e31",
  measurementId: "G-RTRDWQPGJB"
};
