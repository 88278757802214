import React, { Component, useContext, useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { LOCAL_URL } from "./config";
import { paramsCache, buildSitePageUrl } from "./lib";
import { UserContext } from "./context";
import CategoryListingPage from "./pages/Category/groupCategory";
import ShoppingManagePage from "./pages/shopping/managePage";
import ProductsManagePage from "./pages/shopping/productsManagePage";
import PersonalCenterPage from "./pages/PersonalCenter";
import CreateGroupBuyPage from "./pages/CreateGroupBuy";
import BackgroundImage from "./pages/PersonalCenter/backgroundImage";
import ThemeColorPage from "./pages/PersonalCenter/themeColor";
import MerchantCategoryListingPage from "./pages/Category/storeCategory";
import CreateProductPage from "./pages/Products/create";
import AddPickUpPointerPage from "./pages/PickUp/addPickUpPointer";
import SelectAddress from "./pages/Common/selectAddress";
import GroupBuyDetailPage from "./pages/CreateGroupBuy/detail";
import MyAddressPage from "./pages/PersonalCenter/address";
import AddAddressPage from "./pages/PersonalCenter/addAddress";
import ProductTemplatesPage from "./pages/Products/productTemplates";
import ProductCategoryListPage from "./pages/Products/Category/List";
import { domain, wxWebAppId } from "./config/wechat";
import { Drawer } from "@mui/material";
import styled from "styled-components";
import { useWindowSize } from "./hooks";
import OrderConfirmPage from "./pages/Payment/OrderConfirm";
import OrderAddressSelectPage from "./pages/Payment/AddressSelect";
import ChoosePayment from "./pages/Payment/ChoosePayment";
import DonePage from "./pages/Payment/Done";
import GroupListPage from "./pages/CreateGroupBuy/list";
import AddMerchantPage from "./pages/PersonalCenter/addMerchant";
import OrderManagement from "./pages/Manage/Order";
import InviteMemberPage from "./pages/PersonalCenter/inviteMember";
import AcceptInvitingPage from "./pages/PersonalCenter/acceptInviting";
import SwitchMerchantPage from "./pages/PersonalCenter/switchMerchant";
import MyOrdersPage from "./pages/PersonalCenter/myOrders";
import WelcomePage from "./pages/Welcome";
import UploadReceiptPage from "./pages/Payment/UploadReceipt";
import TransAccount from "./pages/PersonalCenter/TransAccount";
import AddMerchantSuccessPage from "./pages/PersonalCenter/addMerchantSuccess";
import Remark from "./pages/Manage/Remark";
import Delivery from "./pages/Manage/Delivery";
import AddDelivery from "./pages/Manage/AddDelivery";
import WaitingConfirm from "./pages/Payment/WaitingConfirm";
import {setRedirectUrl} from "./utils/persistence";
import OrderDetailPage from "./pages/Order/detail";
import SalesStatistPage from "./pages/Manage/SalesStatistPage";
import ExportOrder from "./pages/Manage/ExportOrder";
import ReceiptViewPage from "./pages/Payment/ReceiptView";
import TypePage from "./pages/CreateGroupBuy/TypePage";
import SelectPage from "./pages/CreateGroupBuy/SelectPage";
import { WechatShare } from './pages/Wechat/'
import {SettingPage} from "./pages/Channel/settingPage";

export const PrivateRoutes = ({ location }) => {
  const history = useHistory();
  const { isAnonymous, isReady } = useContext(UserContext);
  //const [showLogin, setShowLogin] = useState(false)
  const { size } = useWindowSize();

  // useEffect(() => {
  //   if (isAnonymous) {
  //     setShowLogin(true)
  //   }
  // }, [])
  useEffect(() => {
    if (!!isReady && !!isAnonymous) {
      setRedirectUrl(window.location.href)
      history.push(LOCAL_URL.USER_LOGIN);
    }
  }, [isAnonymous, isReady]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [location])

  return !isReady ? (
    <p>Initializing...</p>
  ) : isAnonymous ? (
    <Redirect
      to={{
        pathname: LOCAL_URL.USER_LOGIN,
        state: {
          redirect: location?.pathname,
          context: location?.state?.context,
        },
      }}
    />
  ) : (
    // <Redirect to={{ pathname: `${LOCAL_URL.WELCOME}/login` }} />
    // <Redirect to={{ pathname: `${LOCAL_URL.WELCOME}/register` }} />
    <>
      {isAnonymous && process.env.NODE_ENV === "production" && (
        <Redirect to={LOCAL_URL.USER_LOGIN} />
      )}
      <Route path={LOCAL_URL["TEST_WECHAT_SHARE"]} component={WechatShare} />
      <Route path={LOCAL_URL["WELCOME"]} exact component={WelcomePage} />
      <Route
        path={LOCAL_URL["PERSONAL_CENTER"]}
        component={PersonalCenterPage}
      />
      <Route
        path={LOCAL_URL["CREATE_GROUP_BUY"]}
        component={CreateGroupBuyPage}
      />
      <Route
        path={LOCAL_URL["SHOPPING_MANAGE_PAGE"]}
        component={ShoppingManagePage}
      />
      <Route
        path={LOCAL_URL["SHOPPING_MANAGE_PRODUCTS_PAGE"]}
        component={ProductsManagePage}
      />
      <Route
        path={LOCAL_URL["CATEGORY"]}
        component={CategoryListingPage}
      />
      <Route
        path={LOCAL_URL["MERCHANT_CATEGORY"]}
        component={MerchantCategoryListingPage}
      />
      <Route
        path={LOCAL_URL["CREATE_GROUP_BUY_TYPE"]}
        component={TypePage}
      />
      <Route
        path={LOCAL_URL["CREATE_GROUP_BUY_SELECT"]}
        component={SelectPage}
      />
      <Route path={LOCAL_URL["CREATE_PRODUCT"]} component={CreateProductPage} />
      <Route
        path={LOCAL_URL["ADD_PICK_UP_POINTER"]}
        component={AddPickUpPointerPage}
      />
      <Route path={LOCAL_URL["SELECT_ADDRESS"]} component={SelectAddress} />
      <Route path={LOCAL_URL["SELECT_BACKGROUND"]} component={BackgroundImage} />
      <Route path={LOCAL_URL["SELECT_THEME_COLOR"]} component={ThemeColorPage} />
      <Route path={LOCAL_URL["TRANS_ACCOUNT"]} component={TransAccount} />
      <Route path={LOCAL_URL["MY_ADDRESS"]} component={MyAddressPage} />
      <Route path={LOCAL_URL["ADD_ADDRESS"]} component={AddAddressPage} />
      <Route path={LOCAL_URL["PRODUCT_TEMPLATE"]} component={ProductTemplatesPage} />
      <Route path={LOCAL_URL["PRODUCT_CATEGORY_LIST"]} component={ProductCategoryListPage} />
      <Route path={LOCAL_URL["ORDER_CONFIRM"]} component={OrderConfirmPage} />
      <Route path={LOCAL_URL["WAITING_CONFIRM"]} component={WaitingConfirm} />
      <Route path={LOCAL_URL["CHOOSE_PAYMENT"]} component={ChoosePayment} />
      <Route path={LOCAL_URL["PAYMENT_DONE"]} component={DonePage} />
      <Route path={LOCAL_URL["RECEIPT_VIEW"]} component={ReceiptViewPage} />
      <Route
        path={LOCAL_URL["ORDER_SELECT_ADDRESS"]}
        component={OrderAddressSelectPage}
      />
      <Route path={LOCAL_URL["GROUP_LIST"]} component={GroupListPage} />
      <Route path={LOCAL_URL["ADD_MERCHANT"]} component={AddMerchantPage} />
      <Route path={LOCAL_URL["MANAGE_ORDER"]} component={OrderManagement} />
      <Route path={LOCAL_URL["GROUP_STATIST"]} component={SalesStatistPage} />
      <Route path={LOCAL_URL["ORDER_EXPORT"]} component={ExportOrder} />
      <Route path={LOCAL_URL["ORDER_DETAIL"]} component={OrderDetailPage} />
      <Route path={LOCAL_URL["INVITE_MEMBER"]} component={InviteMemberPage} />
      <Route
        path={LOCAL_URL["ACCEPT_INVITING"]}
        component={AcceptInvitingPage}
      />
      <Route
        path={LOCAL_URL["SWITCH_MERCHANT"]}
        component={SwitchMerchantPage}
      />
      <Route path={LOCAL_URL["MY_ORDERS"]} component={MyOrdersPage} />
      <Route
        path={LOCAL_URL["ADD_MERCHANT_SUCCESS"]}
        component={AddMerchantSuccessPage}
      />
      <Route
        path={LOCAL_URL["ADD_REMARK"]}
        component={Remark}
      />
      <Route
        path={LOCAL_URL["DELIVERY_PAGE"]}
        component={Delivery}
      />
      <Route
        path={LOCAL_URL["DELIVERY_ADD_PAGE"]}
        component={AddDelivery}
      />
      <Route
        path={LOCAL_URL["CHANNEL_SETTING"]}
        component={SettingPage}
      />
    </>
  );
};
interface Props {
  width: number;
}
