import * as React from "react";
import styled from "styled-components";
import Header, { FloatBottom } from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import EmptyView, { EmptyContainer } from "../../components/EmptyView";
import { Button } from "../../components";
import { useHistory } from "react-router-dom";
import { LOCAL_URL, THEME } from "../../config";
import { EntityContext } from "../../context";
import { Radio } from "../../forms/Radio";
import iconEdit from "../../svg/icon_edit.svg";
import iconRubbishBin from "../../svg/icon_rubbish.svg";
import iconAdd from "../../svg/icon_add.svg";
import useMerchant from "../../hooks/useMerchant";
import IconAdd from "../../components/Icons/IconAdd";

export interface IAddressProps {}

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAddressCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  .formattedAddress {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.65);
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .extend-info {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 6px;
  }
  .address-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 4px;
  }
`;

const AddButton = styled.div`
  background-color: #fff;
  height: 48px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.gray["#12-045"]};
  div {
    margin-left: 8px;
  }
  margin-top: 16px;
`;

const AddProductButton = styled.div`
  display: flex;
  padding-right: 16px;
  .text {
    color: #fff;
    margin-left: 6px;
  }
`;

export const AddressCard = (props: any) => {
  const { address, selected, onClick, onDeleteClick, disable, selectMode } = props;
  const {
    id,
    contactName,
    formattedAddress,
    extendInfo,
    telephone,
    notes,
    addressName,
  } = address;

  const history = useHistory();

  return (
    <StyledAddressCard
      onClick={() => {
        if (onClick){
          onClick(id);
        }
      }}
    >
      <div className="flex-row">
        <div>
          {addressName && <div className="address-name">{addressName}</div>}
          <div className="formattedAddress">{formattedAddress}</div>
        </div>
        {selectMode && <Radio value={selected} />}
      </div>
      <div className="extend-info">{extendInfo}</div>
      <div className="extend-info">{notes}</div>
      <div className="flex-row">
        <div className="extend-info">
          {`${contactName ? contactName : ""} ${telephone}`}
        </div>
        {(!disable || selectMode) && (
          <div className="flex-row">
            <img
              src={iconEdit}
              onClick={() => {
                if (address.type === "PICKUP") {
                  history.push(LOCAL_URL["ADD_PICK_UP_POINTER"], { address });
                } else {
                  history.push(LOCAL_URL["ADD_ADDRESS"], { address });
                }
              }}
            />
            <div style={{ marginLeft: 8 }} />
            <img
              src={iconRubbishBin}
              onClick={() => {
                onDeleteClick(id);
              }}
            />
          </div>
        )}
      </div>
    </StyledAddressCard>
  );
};

export default function Address(props: IAddressProps) {
  const history = useHistory<any>();
  const type: "DELIVERY" | "PICKUP" = history.location?.state?.type || "PICKUP";
  const selectMode = history.location?.state?.selectMode;
  const from = history.location?.state?.from ?? 'GROUP';
  const storeName = 'GROUP_BUY'

  let title;
  let url;

  if (type === "DELIVERY") {
    title = "收货地址";
    url = LOCAL_URL["ADD_ADDRESS"];
  } else {
    title = "自提点";
    url = LOCAL_URL["ADD_PICK_UP_POINTER"];
  }

  const {
    model: entityModel,
    query,
    create,
    remove,
    replace,
  } = React.useContext(EntityContext);

  const addressData = entityModel["ADDRESS"].response?.data;

  const pickupAddresses =
    entityModel[storeName].response?.pickupAddresses || [];

  const { currentMerchantId } = useMerchant();

  React.useEffect(() => {
    if (currentMerchantId) {
      query("ADDRESS", { type, merchantId: currentMerchantId });
    }
  }, [currentMerchantId]);

  const [selectedAddressIds, setSelectedAddresssIds] = React.useState(
    pickupAddresses.map((p) => p.id)
  );

  const onConfirm = () => {
    replace(storeName, {
      pickupAddresses: addressData.filter((a) =>
        selectedAddressIds.includes(a.id)
      ),
    });
    if (from === 'GROUP'){
      history.push(LOCAL_URL["CREATE_GROUP_BUY"], {
        pickupAddress: Array.from(selectedAddressIds),
      });
    }else if (from === 'STORE') {
      history.goBack()
    }
  };

  return (
    <PageLayout pageName="myAddress">
      <Header
        title={title}
        right={
          <AddProductButton onClick={() => history.push(url)}>
            <IconAdd />
            <span className="text">添加{title}</span>
          </AddProductButton>
        }
      />
      {addressData && addressData.length > 0 ? (
        <AddressContainer>
          {addressData.map((address) => (
            <AddressCard
              key={address.id}
              address={address}
              selectMode={selectMode}
              selected={selectedAddressIds.includes(address.id)}
              onClick={(id) => {
                if (selectedAddressIds.includes(id)) {
                  setSelectedAddresssIds([
                    ...selectedAddressIds.filter((i) => i !== id),
                  ]);
                } else {
                  setSelectedAddresssIds([...selectedAddressIds, id]);
                }
              }}
              onDeleteClick={async (id) => {
                await remove("ADDRESS", { id });
                query("ADDRESS", { type });
              }}
            />
          ))}
          {!selectMode && (
            <div style={{ margin: 16 }}>
              <AddButton onClick={() => history.push(url)}>
                <img src={iconAdd} />
                <div>添加新{title}</div>
              </AddButton>
            </div>
          )}
        </AddressContainer>
      ) : (
        <EmptyContainer>
          <EmptyView desc={`还没有添加${title}`} />
          {!selectMode && (
            <div style={{ margin: 80 }}>
              <Button fullWidth onClick={() => history.push(url)}>
                添加{title}
              </Button>
            </div>
          )}
        </EmptyContainer>
      )}
      {selectMode && (
        <div style={{ margin: 8 }}>
          <FloatBottom>
            <Button fullWidth size="large" color="red" onClick={onConfirm}>
              确定
            </Button>
          </FloatBottom>
        </div>
      )}
    </PageLayout>
  );
}
