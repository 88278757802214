import styled from "styled-components";
import { THEME } from "../../config";

export const OrderTab = styled.div`
  margin-top: 16px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;

  .search-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .tab-title {
    padding-top: 16px;
    padding-bottom: 4px;
    font-size: 15px;
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;

    .tab-item {
      width: ${((window.innerWidth > 768 ? 768 : window.innerWidth) - 16) / 6}px;
      flex-shrink: 0;
      white-space: nowrap;
      text-align: center;
      position: relative;

      &:nth-child(1) {
        .label-wrap {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }

      &:nth-last-child(1) {
        .label-wrap {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;

          ::after {
            display: none;
          }

          .label {
            border-right: none;
          }
        }
      }

      .count {
        text-align: center;
        font-size: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .count-label {
          color: white;
          line-height: 16px;
          border-radius: 50%;
          height: 16px;
          width: 16px;
          background-color: ${THEME.color.primary.default};
        }
      }

      .label-wrap {
        background-color: rgba(118, 118, 128, 0.12);
        padding: 2px;

        ::after {
          content: '';
          width: 1px;
          height: 20px;
          background-color: #ccc;
          position: absolute;
          right: 0;
          top: 25px;
        }

        .label {
          line-height: 32px;
          font-size: 12px;
        }

        .label-selected {
          //margin-left: 1px;
          //margin-right: 2px;
          background-color: white;
          border-radius: 8px;
          color: #87C596;
          border: 0.5px solid rgba(0, 0, 0, 0.04);
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
        }
      }
    }
  }

  .result {
    color: ${THEME.color.gray["#12-045"]};
    font-size: 14px;
    margin-top: 16px;
    padding-bottom: 16px;

    .number {
      color: ${THEME.color.primary.default};
    }
  }
`

export const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  .title {
    display: flex;
    flex-direction: row;

    .left {
      flex: 1;
      font-size: 17px;
      line-height: 28px;
      font-weight: 500;
    }

    .right {
      justify-content: flex-end;
      display: flex;
      align-items: center;
    }
  }

  .statist {
    display: flex;
    flex-direction: row;

    .border {
      border-right: 1px solid rgba(0, 0, 0, 0.09);
    }

    .red {
      color: ${THEME.color.primary.default};
    }

    .item {
      flex: 1;

      .top {
        text-align: center;
        font-size: 17px;
        line-height: 24px;
        align-items: center;
        height: 34px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .label {
        text-align: center;
        font-size: 12px;
        line-height: 24px;
        color: ${THEME.color.gray["#12-045"]};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .order-list {
    background-color: white;
  }
`
