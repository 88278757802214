import styled from 'styled-components';
import { IconClear, SVGPremiumIcon } from '../../components';
import { THEME } from '../../config';

const Header = styled.div`
  padding: 12px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${THEME.color.gray['#1-32']};

  color: ${THEME.color.secondary.default};

  & svg {
    width: 48px;
  }
  position: relative;
`;
const FormHeader = styled(Header)`
  text-align: 'center';
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DetailsHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const CloseBlock = styled.div`
  position: absolute;
  right: 16px;
  cursor: pointer;
  & svg {
    width: 24px;
    fill: #fff;
  }
`;

export const PremiumFormHeader = ({ onClose }) => {
  return (
    <FormHeader>
      <SVGPremiumIcon />
      <span style={{marginLeft: '8px'}} className="ft-ps18">PREMIUM OFFERS</span>
      <CloseBlock onClick={onClose}>
        <IconClear />
      </CloseBlock>
    </FormHeader>
  )
}

export const PremiumDetailsHeader = ({ onClose }) => {
  return (
    <DetailsHeader>
      <span className="ft-pb12">PREMIUM OFFERS</span>
      <CloseBlock onClick={onClose}>
        <IconClear />
      </CloseBlock>
    </DetailsHeader>
  )
}
