import styled from 'styled-components';
import { THEME } from '../../config';

const Container = styled.div`
  height: 72px;
  border-radius: 8px;
  line-height: 36px;
`;
const Content = styled.div`
  height: 50%;
  color: ${THEME.color.gray['#0-087']};
`;
const Button = styled.div`
  height: 50%;
  background: ${THEME.color.primary.default};
  border-radius: 0 0 8px 8px;
  color: #fff;
  cursor: pointer;
`;

const copy2ClipBoard = (content) => {
  const textarea = document.createElement('textarea');
  textarea.textContent = content;
  document.body.appendChild(textarea);

  const selection = document.getSelection();
  const range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);

  // console.log('copy success', document.execCommand('copy'));
  selection.removeAllRanges();

  document.body.removeChild(textarea);
}

export const CopyButton = ({content, text}) => {
  return (
    <Container className="shadow02" >
      <Content dangerouslySetInnerHTML={{__html: content}} />
      <Button className="ft-pb14" onClick={() => copy2ClipBoard(content)}>{text}</Button>
    </Container>
  )
}
