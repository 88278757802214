import {getAnalytics, logEvent, setUserProperties} from "firebase/analytics";

export const getComponentWidth = (instance) => (
  (!instance) ? null 
  :
  parseInt(window.getComputedStyle(instance).getPropertyValue("width"))
)

export const scrollToPageTop = () => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
}

export const isWeiXinBrowser = () => {
  return /MicroMessenger/i.test(navigator.userAgent);
  /**
  https://www.cnblogs.com/dxqNet/p/6515119.html
  
  if(/android/i.test(navigator.userAgent)){
      document.write("This is Android'browser.");//这是Android平台下浏览器
  }
  if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
      document.write("This is iOS'browser.");//这是iOS平台下浏览器
  }
  if(/Linux/i.test(navigator.userAgent)){
      document.write("This is Linux'browser.");//这是Linux平台下浏览器
  }
  if(/Linux/i.test(navigator.platform)){
      document.write("This is Linux operating system.");//这是Linux操作系统平台
   */
}

