import styled from 'styled-components'
import {LOCAL_URL} from "../../config";
import {Button as ButtonBase} from '../../Layout'
import { IPromotionInfo, PromotionStatusLabel } from './common'
import {useHistory} from "react-router-dom";

const Button = styled(ButtonBase)`
  position: absolute;
  right: 1rem;
  bottom: 1.125rem;
  width: auto;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: white;

  font-size: 15px;
  color: rgba(0, 0, 0, 0.45);

  & ~ & {
    margin-top: 1rem;
  }

  & div.brief {
    margin-top: 4px;
    width: 80%;
  }

  & div.due {
    margin-top: 8px;
  }

  & span.title {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.85);
  }

  & span.status {
    color: rgba(204, 21, 67, 1);
  }
`

export const PromotionCard: React.FC<IPromotionInfo> = ({name, status, description, dueDate, id}) => {
  const history = useHistory();
  return (
    <Wrapper onClick={() => {
      if (id) {
        history.push(`${LOCAL_URL.PROMOTION_DETAIL}?promotionId=${id}`)
      }
    }}>
      <div className='title flex-between align-center'>
        <span className='title'>{name}</span>
        <span className='status'>{PromotionStatusLabel[status] || status}</span>
      </div>
      <div className='brief ellipsis'>
        <span>{description}</span>
      </div>
      <div className='due'>
        <span>截止{dueDate}</span>
      </div>
      <Button className="small">查看详情</Button>
    </Wrapper>
  )
}
