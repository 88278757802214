import { AppStorage } from './persistance';

export class DataCache {
  private _key: string;
  private _storage: any;
  constructor(cacheKey: string, storage: any) {
    this._key = cacheKey;
    this._storage = new AppStorage(storage);
  }

  set   (value: string) { this._storage.setKeyValue(this._key, value); }
  clear ()              { this._storage.removeKey(this._key); }
  get   ()              { return this._storage.getKeyValue(this._key); }
}
