import React from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { useWindowSize } from '../../hooks';
import { IconClear } from '../Icons';
import { THEME } from '../../config';
import { ScreenMaskDrawer, ScreenMaskDialog } from './Mask';

const useStyles = makeStyles({
  paper: {
    background: "transparent",
  },
});

const Frame = styled.div`
  padding-top: 24px;
`;
const Container = styled.div`
  position: relative;
`;
const ButtonClear = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  width: 2em;
  background: transparent;
  border: none;
  & svg {
    fill: #fff;
    height: 24px;
  }
`;

interface QuickMaskDialogProps {
  children?: any;
  open?: boolean;
  onClose: () => void;
  [propName: string]: any;
}
export const QuickMaskDialog:React.FC<QuickMaskDialogProps> = ({children, open, onClose}) => {
  return (
    <Dialog 
      PaperProps={{ style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}}
      onClose={onClose} 
      open={open}
    >
      <Frame>
        <Container>

          <ButtonClear onClick={onClose} ><IconClear/></ButtonClear>

          { children }

        </Container>
      </Frame>
    </Dialog>

  )
}

interface QuickTransparentDrawerProps {
  children?: any;
  onClose: () => void;
  [propName: string]: any;
}
export const QuickTransparentDrawer: React.FC<QuickTransparentDrawerProps> = ({children, onClose}) => {
  const classes = useStyles();
  const { isPCMode } = useWindowSize();

  return (
    isPCMode ?
    <Dialog open={true}
      PaperProps={{ style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}}
      onClose={onClose} 
    >
    <div style={{width: '375px'}}>
    { children }
    </div>
    </Dialog>
    :
    <Drawer anchor="bottom" open={true}
      classes={{ paper: classes.paper }}
      style={{ background: "transparent" }}
      onClose={onClose} 
    >
    { children }
    </Drawer>
  )
}

export const QuickMaskDrawer = (props) => {
  const { isPCMode } = useWindowSize();
  return (
    isPCMode ?
    <ScreenMaskDialog {...props} />
    :
    <ScreenMaskDrawer {...props} />
  )
}
