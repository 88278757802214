import React, {useState} from "react";
import styled from 'styled-components'
import {notify} from "../../components";
import {FloatBottom} from "../../components/Header";
import {LOCAL_URL} from "../../config";
import {Layout, Header, Button, SubmitButtonArea as ButtonArea} from '../../Layout'
import {requestPost} from "../../lib";
import RedeemCouponWarning from "../Promotion/components/RedeemCouponWarning";
import {COUPON_STATUS_LABEL, CouponStatusEnum} from './common'
import {MerchantInfoCard} from './MerchantInfoCard'
import {useHistory, useLocation} from 'react-router-dom'

const Wrapper = styled.div`
  padding: .75rem 1rem;

  & div.block {
    background-color: white;
    border-radius: 8px;
    font-size: 12px;
    line-height: 18px;
  }

  & div.status {
    padding: 1.5rem;
    background-color: ${props => props.theme.color.primary.HEX};
    color: white;
  }

  & div.description {
    padding: 1.5rem 1.5rem 2.5rem;
    color: rgba(0, 0, 0, 0.45);
  }

  & p.bold {
    font-weight: 500;
  }

  & p.large {
    font-size: 18px;
    line-height: 24px;
  }

  & p.title {
    margin-bottom: 8px;
  }

  & p {
    margin-top: 8px;
  }
`
export default function Page() {
  const [showRedeemModal, setShowRedeemModal] = useState(false)
  const location = useLocation()
  const {
    id, status, dueDate, name,
    user, promotion, merchant, redeemMerchant,
  } = location.state?.data || {};
  const history = useHistory()
  const redeem = async () => {
    try {
      const {data} = await requestPost('coupon/redeem', {
        couponId: id
      })
      if (data) {
        history.replace(LOCAL_URL.COUPON_REDEEM, {
          data: {
            ...data,
            merchant: merchant
          }
        })
      }
    } catch (e) {
      notify.error('核销失败')
    }
  }

  return (
    <Layout style={{position: 'relative'}}>
      <Header title={location.state?.preference?.name || "优惠券"}/>
      <Wrapper className="flex-start direction-col" style={{gap: '.75rem'}}>
        {!!COUPON_STATUS_LABEL[status] &&
          <div className='block status'>
            <p className='bold large'>{COUPON_STATUS_LABEL[status]}</p>
            <p>您已成功领取本券，详见使用规则</p>
          </div>
        }
        <div className='block description'>
          <p className='bold large' style={{color: 'rgba(0,0,0,0.85)', marginBottom: '16px'}}>
            {name}
          </p>
          {[
            `有效期至${dueDate}`,
            `仅限${merchant?.name}在线网店使用`,
            `本活动最终解释权归${merchant?.name}所有`
          ].map((p: string, index: number) => (
            <p key={index}>{p}</p>
          ))}
        </div>
        <MerchantInfoCard info={merchant}></MerchantInfoCard>
      </Wrapper>
      {!redeemMerchant && status !== CouponStatusEnum.EXPIRED ? (
        <FloatBottom>
          <Button onClick={() => {
            setShowRedeemModal(true)
          }}>使用本券</Button>
        </FloatBottom>
      ) : null}
      <RedeemCouponWarning
        open={showRedeemModal}
        onOK={() => {
          redeem()
        }}
        onCancel={() => {
          setShowRedeemModal(false)
        }}
      />
    </Layout>
  )
}
