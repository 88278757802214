import { useState, useEffect } from 'react'
import styled from 'styled-components'
import {LOCAL_URL} from "../../config"
import { requestGet } from '../../lib'
import { useLocationMerchantDataChecker } from '../../hooks'
import { EmptyBlock, Layout, Header, IconCirclePlus, IconClipboard, IconBoxPen } from '../../Layout'
import { REQ_URL } from './helper'
import { PreferenceCard } from './Card'

const Wrapper = styled.div`
  padding: 20px 1rem;
`

export default function Page() {
  const [items, setItems] = useState<any>([])
  const { location, history, merchantId, merchantData } = useLocationMerchantDataChecker()

  const fetchData = async () => {
    const result = await requestGet(`${REQ_URL}?merchantId=${merchantId}&sorter=createdAt,DESC`, {})
    console.log(result?.data)
    if ((result.code === 0) && (!!result.data?.length)) {
      setItems(result.data)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const gotoCreatePreference = () => {
    history.push(LOCAL_URL.PREFERENCE_DETAILS, {
      merchantData
    })
  }
  const gotoEditPreference = (item:any) => () => {
    history.push(LOCAL_URL.PREFERENCE_DETAILS, {
      merchantData,
      editData: item,
    })
  }
  const gotoCouponList = (item:any) => () => {
    history.push(LOCAL_URL.COUPON_LIST, {
      merchantData,
      preference: item,
    })
  }

  return (
    <Layout>

      <Header title="优惠券" icon={
        <div onClick={gotoCreatePreference}>
          <IconCirclePlus style={{display: 'block'}} fill='white' />
        </div>
      } />

      <Wrapper>
        { items.map((item, index) => (
          <PreferenceCard key={index} {...item} >
            <div className='flex-end align-center' style={{gap: '20px'}}>
              <p className='flex-start align-center' style={{gap: '2px'}} onClick={gotoCouponList(item)}>
                <IconClipboard />
                <span>数据统计</span>
              </p>
              <p className='flex-start align-center' style={{gap: '2px'}} onClick={gotoEditPreference(item)}>
                <IconBoxPen />
                <span>编辑</span>
              </p>
            </div>
          </PreferenceCard>
        )) }
        { !items.length &&
          <EmptyBlock style={{marginTop: '6rem'}}></EmptyBlock>
        }
      </Wrapper>

    </Layout>
  )
}
