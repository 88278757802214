import React from 'react';
import styled from 'styled-components';
import { THEME } from '../../config';

const CLASS = {
  // variant
  'contained' : 'shadow02',
  'frame'     : 'frame',
  // size
  'large'     : 'large ft-pb18',
  'medium'    : 'medium ft-pb14',
  'small'     : 'small ft-pb14',
}
const CLASS_CONTAINED = {
  // color
  'red'       : 'red clr-bg-primary clr-ft-fff',
  'white'     : 'white clr-bg-fff clr-ft-primary',
  'gray'      : 'gray clr-bg-gray8-bd clr-ft-fff',
  'dark'      : 'black clr-bg-gray2-48 clr-ft-fff',
  'black'     : 'black clr-bg-gray0-087 clr-ft-fff',
}
const CLASS_FRAME = {
  // color
  'red'       : 'red clr-ft-primary',
  'white'     : 'white clr-ft-fff',
  'gray'      : 'gray clr-ft-gray0-087',
  'black'     : 'black clr-ft-000',
}

const ButtonContainer = styled.button`
  width: ${(props) => !!props.fullWidth ? '100%' : ( props.width || 'auto' )};
  border: none;
  border-radius: .5rem;
  margin: ${(props) => props.margin || '0'};
  padding: 0 1rem;
  white-space: nowrap;
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  background-color: ${props=>props.reverseColor ? ('white') : (props.primaryColor ?? THEME.color.primary.default)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props=>props.reverseColor ? (props.primaryColor ?? THEME.color.primary.default) : ('white') };
  &:focus {
    outline: none;
  }

  &.small {
    font-size: 14px;
    height: 2rem;
    line-height: 2rem;
  }
  &.gray{
    background-color: #ccc;
  }
  &.medium {
    height: 2.25rem;
    line-height: 2.25rem;
  }
  &.large {
    height: 3rem;
    line-height: 3rem;
  }
  &.frame {
    background: transparent;
    border: 1px solid ${(props) => (props.color === 'black') ? '#000' : THEME.color.primary.default };
  }

  & svg {
    height: ${(props) => (props.size === 'small') ? '1rem' : (props.size === 'medium') ? '1.2rem' : '1.6rem'};
    fill: ${(props) => (props.color === 'red') ? '#fff' : THEME.color.primary.default};
  }
`;

interface ButtonProps {
  reverseColor?: boolean
  children?: any;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  color?: 'red' | 'white' | 'black' | 'gray' | 'dark';
  variant?: 'contained' | 'frame';
  primaryColor?: string;
  onClick?: ()=>void;
  [propName: string]: any;
}
export const Button:React.FC<ButtonProps> = ({children, ...props}) => (
  <ButtonContainer
    {...props}
    className={
      props.disabled ?
      [ CLASS['contained'], CLASS[props.size || 'large'], CLASS_CONTAINED['gray'] ].join(' ')
      :
      props.variant === 'frame' ?
      [CLASS['frame'], CLASS[props.size || 'large'], CLASS_FRAME[props.color || 'red']].join(' ')
      :
      [CLASS['contained'], CLASS[props.size || 'large'], CLASS_CONTAINED[props.color || 'red']].join(' ')
    }
    onClick={()=>{
      if (props.disabled){
        return
      }
      if (props.onClick){
        props.onClick()
      }
    }}
  >
  {children}
  </ButtonContainer>
)

export const SubmitButton = ({ children, ...props }) => {
  return (
    <Button type='submit' size="large" color={props.primaryColor ?? "red"} fullWidth={true} {...props}>{children}</Button>
  )
}
