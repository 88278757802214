import React, { useRef, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { THEME, LOCAL_URL } from "../config";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  z-index: 999999999;
`;

const MaskLayer = styled.div``;
const Dialog = styled.div`
  width: 70%;
  background: #f2f2f2;
  border-radius: 8px;

  color: #000;
  line-height: 20px;
  text-align: center;
`;
const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(60, 60, 67, 0.36);
`;
const Button = styled.div`
  color: ${(props) => props.color || THEME.color.accent.blue};
  cursor: pointer;
  padding: 12px;
  text-align: center;
`;

interface DialogParams {
  title: string;
  message?: string;
  paragraph?: string;
  okText?: string;
  cancelText?: string;
  iconRender?: () => void;
  onOK?: () => void;
}
export function useDialog() {
  const [params, setParams] = useState<DialogParams | null>(null);

  const showDialog = (payload: DialogParams) => setParams(payload);
  const hideDialog = () => setParams(null);
  console.log(params)
  const renderDialog = () =>
    !params ? null : (
      <Container className="flex-center align-center">
        <Dialog>
          {!params?.iconRender ? null : params.iconRender()}
          <div
            style={{
              padding: !params?.iconRender ? "16px 16px 12px" : "8px 16px 12px",
            }}
          >
            <p className="ft-rb16" style={{ lineHeight: "24px" }}>
              {params.title}
            </p>
            {!params?.iconRender ? null : (
              <div style={{ padding: "0px 0px 16px 0px" }}></div>
            )}
            {!!params.message && <p className="ft-rr14">{params.message}</p>}
            {!!params.paragraph && (
              <p
                className="ft-rr14 clr-ft-gray3-76"
                style={{ marginTop: "16px", textAlign: "left" }}
              >
                {params.paragraph}
              </p>
            )}
            {!params?.iconRender ? null : (
              <div style={{ padding: "0px 0px 16px 0px" }}></div>
            )}
          </div>
          <Line />
          {!!params?.cancelText ? (
            <div
              className="flex-between debugx"
              style={{ height: "48px", width: "100%" }}
            >
              <div
                style={{
                  height: "100%",
                  width: "50%",
                  borderRight: "1px solid rgba(60,60,67,.36)",
                }}
                className="debugx"
              >
                <Button
                  className="ft-rb16 debugx"
                  color={THEME.color.gray["#0-087"]}
                  onClick={hideDialog}
                >
                  {params.cancelText}
                </Button>
              </div>
              <div style={{ height: "100%", width: "50%" }} className="debugx">
                <Button
                  className="ft-rb16 debugx"
                  color={
                    !params?.iconRender ? null : THEME.color.primary.default
                  }
                  onClick={() => {
                    if (!!params.onOK) params.onOK();
                    else hideDialog();
                  }}
                >
                  {params.okText || "OK"}
                </Button>
              </div>
            </div>
          ) : (
            <Button
              className="ft-rb16"
              color={!params?.iconRender ? null : THEME.color.primary.default}
              onClick={() => {
                if (!!params.onOK) params.onOK();
                else hideDialog();
              }}
            >
              {params.okText || "OK"}
            </Button>
          )}
        </Dialog>
      </Container>
    );

  return {
    renderDialog,
    showDialog,
    hideDialog,
  };
}
