import styled from "styled-components";
import numeral from "numeral";
import { THEME } from '../../config'

const Wrapper = styled.div`
  color: ${THEME.color.primary.default};
  font-weight: 400;

  & span.sign {
    font-size: 15px;
    line-height: 20px;
    margin-right: 1px;
  }
  & span.big {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-right: 1px;
  }
  & span.small {
    font-size: 10px;
    line-height: 16px;
  }
  & span.grey {
    margin-left: 8px;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: line-through;
    color: rgba(0,0,0,0.45);
  }
`

export const ListPrice = ({price, originalPrice} : {
  price:number;
  originalPrice:number;
}) => {
  const [n1, n2] = numeral(price).divide(100).format("$0.00").replace('$', '').split('.')
  return (
    <Wrapper className="flex-start">
      <div className="flex-start align-start">
        <span className="sign">$</span>
        <span className="big">{n1}</span>
        <span className="small">{n2}</span>
      </div>

      { (!!originalPrice && (originalPrice > price)) && (
        <span className="grey">
          {numeral(originalPrice).divide(100).format("$0.00")}
        </span>
      )}
    </Wrapper>
  )
}
