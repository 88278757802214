import { keyframes } from 'styled-components';

export const SpinFrames = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const BreathingFrames = keyframes`
  from {
    background: #eee;
  }
  to {
    background: #ccc;
  }
`;

export const SwingFrames = keyframes`
  0% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
`;

export const ZoomInOutFrames = (sizeA, sizeB) => keyframes`
  
  0% {
    width: ${sizeB};
    height: ${sizeB};
  }
  10% {
    width: ${sizeB};
    height: ${sizeB};
  }
  20% {
    width: ${sizeB};
    height: ${sizeB};
  }
  30% {
    width: ${sizeB};
    height: ${sizeB};
  }
  40% {
    width: ${sizeA};
    height: ${sizeA};
  }
  50% {
    width: ${sizeA};
    height: ${sizeA};
  }
  60% {
    width: ${sizeA};
    height: ${sizeA};
  }
  70% {
    width: ${sizeB};
    height: ${sizeB};
  }
  80% {
    width: ${sizeA};
    height: ${sizeA};
  }
  90% {
    width: ${sizeB};
    height: ${sizeB};
  }
  100% {
    width: ${sizeA};
    height: ${sizeA};
  }
`;

export const PrintOutFrames = keyframes`
  from  { transform: translate(-50%, 0); }
  to    { transform: translate(-50%, 100%); }
`;
