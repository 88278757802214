import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {Button} from "../../components";
import Header from "../../components/Header";
import WhiteSpace from "../../components/WhiteSpace";
import {THEME} from "../../config";
import {EntityContext} from "../../context";
import {Radio} from "../../forms/Radio";
import PageLayout from "../Global/PageLayout";

const defaultImage = [
  {primary: THEME.color.primary.default},
  {primary:'#53BCB7'},
  {primary:'#FF8000'},
  {primary:'#649A76'}
]

function ThemeColorPage() {
  const {
    model: entityModel,
    replace,
  } = React.useContext(EntityContext);
  const globalData = entityModel["GLOBAL_DATA"].response;
  const history = useHistory<any>();
  const [selectedImage, setSelectImage] = useState(globalData.meta_themeColor)

  return (
    <PageLayout pageName="background_image no nav">
      <Header
        title='主题色'
      />
      <Container>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            padding: 16
          }}
        >
          {defaultImage.map(item => {
            return (
              <div
                key={item.primary}
                style={{
                  width: '50%',
                  padding: 4,
                  minHeight: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                onClick={() => {
                  setSelectImage(item.primary)
                }}
              >
                <div
                  style={{
                    maxWidth: '100%',
                    height: 100,
                    backgroundColor: item.primary
                  }}
                />
                <div
                  style={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Radio value={selectedImage === item.primary}/>
                  <span style={{fontSize: 12, color: THEME.color.gray["#11-065"]}}>设为主题色</span>
                </div>
              </div>
            )
          })}
        </div>
        <div className="bottom-float">
          <Button
            fullWidth
            onClick={() => {
              replace("GLOBAL_DATA", {
                ...globalData,
                meta_themeColor: selectedImage,
              });
              history.goBack()
            }}
          >
            确认修改
          </Button>
        </div>

      </Container>
      <WhiteSpace height={56}/>
    </PageLayout>
  )
}

export default ThemeColorPage

const Container = styled.div`
  
  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`;

const Input = styled("input")({
  display: "none",
});
