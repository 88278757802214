import { ReactElement } from 'react';
import styled from 'styled-components'
import { IconRadioCheck, IconRadioUncheck } from '../../Layout'
import { SCOPE_SETTING_DICT } from './common'
import { IApplicationScope } from './type.entity'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: white;
  
  font-size: 15px;
  line-height: 21px;
  color: rgba(0,0,0,0.45);

  & ~ & {
    margin-top: 1rem;
  }
  & p.title {
    font-size: 17px;
    color: rgba(0,0,0,0.85);
  }
  & p.description {
    margin-top: 1.5rem;
    white-space: pre-wrap;
  }
`
const ActionsArea = styled.div`
  margin-top: 12px;
  color: rgba(0,0,0,0.45);
  font-size: 15px;
  line-height: 21px;

  & svg {
    display: block;
  }
`
interface ICardProps {
  name: string;
  description: string;
  isChecked?: boolean;
  applicationScope?: IApplicationScope;
  onChange?: (v:boolean) => void;
  children?: ReactElement;
}
export const PreferenceCard:React.FC<ICardProps> = ({children, name, description, isChecked, onChange, applicationScope}) => {
  const handleChange = () => {
    onChange?.(!isChecked)
  }

  const setting = applicationScope?.setting;
  const text = (!setting?.length) ? '' :
               (setting.length === 1) ? `仅限${SCOPE_SETTING_DICT[setting[0]]}使用` :
               `${setting?.map((key) => SCOPE_SETTING_DICT[key]).join('、')}通用`
  return (
    <>
    <Wrapper className='flex-between align-center' onClick={!onChange ? undefined : handleChange}>
      <div>
        <p className='title'>{name}</p>
        {/* <p className='description'>
          {description}
        </p> */}
        <p className='description'>
          {text}
        </p>
      </div>
      { (isChecked === true) ? <IconRadioCheck /> : 
        (isChecked === false) ? <IconRadioUncheck /> :
        null
      }
    </Wrapper>
    <ActionsArea>
      {children}
    </ActionsArea>
    </>
)
}
