import styled from "styled-components";
import { THEME } from "../../config";

const BASE_LAYER_HEIGHT = THEME.size.BASE_LAYER_HEIGHT;

const Stack: any = styled.div`
  position: relative;
`;

const MainLay = styled.div`
  /*
    注意：
    MainLay 不是 一个 position = absolute 的绝对 ‘层’
    而是一个当前父级容器的内容容器

    position: absolute; 
    left: ${(props) => props.left || 0};
    top: ${(props) => props.top || 0};
    z-index: ${(props) => props.level || 1};
  */
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background: ${(props) => props.background || "transparent"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
`;

const UnderLay = styled.div`
  position: absolute;
  z-index: -10;
  width: 100%;
  height: ${(props) => props.height || '100%'};
  min-height: ${(props) => props.minHeight || '0'};
  background: ${(props) => props.background || "#fff"};
`;

const BaseLay = styled.div`
  position: absolute;
  z-index: ${(props) => props.level || -1};
  width: 100%;
  height: ${(props) => props.height || BASE_LAYER_HEIGHT};
  background: ${(props) => props.background || THEME.color.primary.default};
`;

const OverLay = styled.div`
  position: absolute;
  z-index: ${(props) => props.level || 999999999};
  height: ${(props) => props.height || '100%'};
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
`;

export const Layer = {
  Stack,
  UnderLay,
  BaseLay,
  MainLay,
  OverLay,
};
