import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IconCirclePlus } from '../Layout'
import { IFormInputProps } from './type'
import { RuleInput } from './Input.rule'
import { IPromotionRule, IPromotionRules } from '../pages/Promotion/type.entity'
const Wrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: transparent;
  color: rgba(0,0,0,0.75);
  font-size: 15px;
  line-height: 21px;
`

const Button = styled.button`
  margin-top: 1rem;
  width: 100%;
  height: 4rem;
  background-color: transparent;
  color: rgb(204, 21, 67);
  border: 1px solid rgb(204, 21, 67);
  border-radius: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;

  & svg {
    display: block;
    margin-right: 4px;
  }
`

const defaultRules = {
  total: 0,
  rules: [],
}

interface IRulesInputProps
extends IFormInputProps {
  gotoSelect4RuleId: (id:number) => void;
}
export const RulesInput: React.FC<IRulesInputProps> = ({value, onChange, gotoSelect4RuleId}) => {
  const [promoRules, setPromoRules] = useState<IPromotionRules>(value)

  useEffect(() => {
    if (!value?.total || !value?.rules?.length) setPromoRules(defaultRules)
  }, [])
  useEffect(() => {
    if (!!promoRules?.total && !!promoRules?.rules?.length) onChange(promoRules)
  }, [promoRules])

  const onRuleChange = (id:number) => (v:IPromotionRule) => {
    promoRules.rules[id] = { ...v }
    setPromoRules({
      total: promoRules.rules.length,
      rules: [...promoRules.rules]
    })
  }

  const onCreateNewRule = (e: React.MouseEvent) => {
    e.preventDefault()

    const len = promoRules.rules?.length;
    const min = !len ? 0 : (promoRules.rules[len-1].followerNumber ?? 0)
    const rule:IPromotionRule = {
      followerNumber: min + 10,
      preference: null,
    }
    const rules = [...promoRules.rules, rule]
    setPromoRules({
      total: rules.length,
      rules,
    })
  }
  const onDelete = (index:number) => (e:React.MouseEvent) => {
    e.preventDefault()
    promoRules.rules.splice(index, 1)
    setPromoRules({
      total: promoRules.rules.length,
      rules: [...promoRules.rules]
    })
  }
  // console.table(promoRules?.rules)
  return (
    <Wrapper className="flex-center align-center direction-col">
      { !!promoRules?.rules?.length && promoRules.rules?.map((item: IPromotionRule, index:number) => (
        <RuleInput key={index} index={index} value={item} 
          onChange={onRuleChange(index)}
          onDelete={onDelete(index)}
          gotoSelect4RuleId={gotoSelect4RuleId}
        />
      )) }

      <Button className="flex-center align-center" onClick={onCreateNewRule}>
        <IconCirclePlus />
        添加奖品设置
      </Button>
    </Wrapper>
  )
}
