import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useDialog} from "../../../hooks";
import { IconEdit, IconTrash, IconArrowUp, IconArrowDown, IconRadioUncheck, IconRadioCheck } from '../../../Layout'

const Wrapper = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0,0,0,0.09);

  & div.line1 {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: rgba(0,0,0,0.85);
  }
  & div.line2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: rgba(0,0,0,0.65);
  }
`
const IconButton = styled.div`
  display: inline-block;
  margin-left: 1rem;
`

interface ICategoryCardProps {
  title: string;
  count: number;

  noMoveUp: boolean;
  noMoveDown: boolean;
  onMove?: (direction: -1 | 1) => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onSelect?: () => void;
  selected?: boolean;
}

export const CategoryCard: React.FC<ICategoryCardProps> = ({
  title, count,
  onEdit, onDelete,
  noMoveUp, noMoveDown,
  selected, onSelect,
  onMove
}) => {
  const {renderDialog, showDialog, hideDialog} = useDialog();

  const toConfirm = () => {
    showDialog({
      title: `确定删除分类：${title}?`,
      message: '将从该团购的所有商品中删除该分类',
      cancelText: "取消",
      okText: "确定",
      onOK: () => {
        hideDialog()
        onDelete()
      },
    });
  }
  return (
    <Wrapper className="flex-start direction-col" style={{gap: '.5rem'}}>
      <div className="line1 flex-between align-center">
        <p>{title}</p>
        { !!onSelect &&
          <div>
          { !!selected ?
            <IconButton onClick={onSelect}> <IconRadioCheck /> </IconButton> :
            <IconButton onClick={onSelect}> <IconRadioUncheck /> </IconButton>
          }
          </div>
        }
      </div>
      <div className="line2 flex-between align-center">
        <div className='count'>商品数:{count}</div>
        <div>
          { !!onEdit &&
            <IconButton onClick={onEdit}> <IconEdit /> </IconButton>
          }
          { !!onDelete &&
            <IconButton onClick={toConfirm}> <IconTrash /> </IconButton>
          }
          { !noMoveUp &&
            <IconButton onClick={() => onMove(-1)}> <IconArrowUp  /> </IconButton>
          }
          { !noMoveDown &&
            <IconButton onClick={() => onMove(1)}> <IconArrowDown /> </IconButton>
          }
        </div>
      </div>
      {renderDialog()}
    </Wrapper>
  )
}
