import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { requestGet } from '../../lib'
import { Tabs, Tab, EmptyBlock } from '../../Layout'
import { REQ_URL, buildQueryString, IQueryParams } from './helper' 
import { CouponStatusEnum, COUPON_STATUS_LABEL, ICouponInfo, parseData, statisticData } from './common'
import { CouponCardModeType, CouponCard } from './Card'
import { mockdata } from './mock'

const Wrapper = styled.div`
  padding: 0 1rem;
`
const COUPON_STATUS = [CouponStatusEnum.ALL, CouponStatusEnum.VALID, CouponStatusEnum.USED, CouponStatusEnum.EXPIRED]
const generateFilter = (status: CouponStatusEnum) => (item:ICouponInfo) => (
  (status === CouponStatusEnum.ALL) ? true :
  (item.status === status)
)

export const CouponList = ({mode, queryParams}:{
  mode: CouponCardModeType;
  queryParams?: IQueryParams;
}) => {
  const [tab, setTab] = useState<CouponStatusEnum>(CouponStatusEnum.ALL)
  const [items, setItems] = useState<ICouponInfo[]>([])

  const fetchData = async () => {
    const url = `${REQ_URL}${buildQueryString(queryParams)}`
    const result = await requestGet(url, {})
    // console.log(url, result)
    if ((result.code === 0) && (!!result.data?.length)) {
      setItems(parseData(result.data))
    }
    // setItems(parseData(mockdata))
  }
  useEffect(() => {
    fetchData()
  }, [])

  const statistics = statisticData(items)
  const listFilter = generateFilter(tab)
  return (
    <>
      <Tabs style={{margin: '2rem 0 1.5rem', padding: '0 0 0 1rem'}}>
      { COUPON_STATUS.map((status: CouponStatusEnum, index:number) => (
        <Tab key={index} className={tab === status ? 'active' : ''}
          onClick={() => setTab(status)}
        >
          {COUPON_STATUS_LABEL[status]}({
            (status === CouponStatusEnum.ALL) ? statistics.all :
            (status === CouponStatusEnum.VALID) ? statistics.valid :
            (status === CouponStatusEnum.USED) ? statistics.used :
            (status === CouponStatusEnum.EXPIRED) ? statistics.expired :
            ''
          })
        </Tab>
      )) }
      </Tabs>

      <Wrapper className="debugx">
        { items.filter(listFilter).map((item, index) => (
          <CouponCard key={index} mode={mode} {...item} />
        )) }
        { !items.length &&
          <EmptyBlock style={{marginTop: '6rem'}}></EmptyBlock>
        }
      </Wrapper>
    </>
  )
}
