import React from "react";
import styled from "styled-components";
import { THEME } from "../config";
import {
  IconRadioButtonCheckedCircle,
  IconRadioButtonDisabled,
} from "../components";

const Container = styled.div`
  display: flex;
  align-items: center;
  &.inline {
    display: inline-flex;
  }
`;
const IconBlock = styled.div`
  background: ${(props) =>
    props.checked
      ? THEME.color.primary.default
      : props.disabled
      ? "#C9C9C9"
      : "transparent"};
  border: 2px solid
    ${(props) =>
      props.checked || props.disabled ? "none" : THEME.color.gray["#3-76"]};
  border-radius: 9px;
  height: 18px;
  width: 18px;
  margin-right: 10px;

  & svg {
    display: ${(props) => (!props.checked ? "none" : "block")};
    width: 100%;
    height: 100%;
    fill: #fff;
    stroke: #fff;
    //strokeWidth: 2px;
  }
`;

interface CheckProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  label?: string;
  disabled?: boolean;
  [propName: string]: any;
}
export const Check: React.FC<CheckProps> = ({
  onChange,
  value,
  label,
  disabled,
  ...restProps
}) => {
  const onClick = () => {
    // console.log('onclick')
    if (disabled) return;
    if (!onChange) return;
    onChange(!value);
  };
  return (
    <Container onClick={onClick} {...restProps}>
      <IconBlock disabled={disabled} checked={disabled ? false : !!value}>
        {disabled ? (
          <IconRadioButtonDisabled />
        ) : !value ? null : (
          <IconRadioButtonCheckedCircle />
        )}
      </IconBlock>
      <span className="ft-rr16 clr-ft-gray0-087">{label}</span>
    </Container>
  );
};
