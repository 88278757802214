import React from "react";
import styled from "styled-components";
import {THEME} from "../../../config";

interface CouponCardProps {
  themeColor?: string
  name: string
  desc1: string
  desc2: string
  status: string
  onClick?: ()=>void
}

function CouponCard(props: CouponCardProps){
  const {name, desc1, desc2, status, onClick} = props

  return (
    <Container
      themeColor={props.themeColor ?? THEME.color.primary.default}
      onClick={onClick}
    >
      <div className='left'>
          <div className='name'>{name}</div>
          <div className='desc1'>{desc1}</div>
          <div className='desc2'>{desc2}</div>
      </div>
      <div className='right'>
        <div className='status'>{status}</div>
        <div className='button'>
          查看详情
        </div>
      </div>
    </Container>
  )
}

export default CouponCard

const Container = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 16px;
  min-height: 106px;
  .left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    .name{
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: ${THEME.color.gray["#10-085"]};
    }
    .desc1{
      margin-top: 4px;
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.gray["#12-045"]};
    }
    .desc2{
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.gray["#12-045"]};
    }

  }
  .right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100px;
    .status{
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.primary.default};
    }
    .button{
      background-color: ${props=>props.themeColor};
      border-radius: 4px;
      padding: 8px;
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: white;
    }
  }
`
