import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { IconKBArrowL, IconKBArrowR } from './Icons';

export const GalleryContainer = styled.div`
  position: relative;
  width: ${(props) => props.width || '100%'};

  & img {
    width: 100%;
    height: ${(props) => props.height || 'none'};
    object-fit: cover;
    background-position: center;
  }
  & p {
    text-align: right;
    margin: 8px 12px 0;
  }
  & svg {
    width: 32px;
    fill: #fff;
  }
  & div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: rgba(0,0,0,0.4);
  }
  & div.left {
    left: 16px;
  }
  & div.right {
    right: 16px;
  }
`;

interface GalleryProps {
  images?: Array<string>;
  width?: string;
  height?: string;
  showPageNumber?: boolean;
}
export const Gallery: React.FC<GalleryProps> = ({images, width, height, showPageNumber}) => {
  const [id, setId] = useState(0);
  const size = useMemo(() => images.length, [images]);
  
  return (
    <GalleryContainer width={width} height={height} >
      <img src={images[id]} loading="lazy" alt={`menu${id}`} />
      { showPageNumber && <p className="ft-rr12 clr-ft-fff">{`${id+1} of ${size}`}</p> }
      { (id <= 0) ? null : 
        <div className="left" onClick={() => setId(id - 1)}><IconKBArrowL /></div> 
      }
      { (id >= (size-1)) ? null : 
        <div className="right" onClick={() => setId(id + 1)}><IconKBArrowR /></div> 
      }
    </GalleryContainer>
  )
}
