import styled from 'styled-components';
import { THEME } from '../../config';

export const TicketContainer = styled.div`
  background: ${THEME.color.primary.variant};
  border-radius: 8px;
  padding: 16px 0 12px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

`;

export const TicketColumn = styled.div`
  height: 100%;
  width: ${props => props.width || 'auto'};
  color: #fff;
  &.dash-right {
    border-right: 2px dashed #e8d6bd;
    position: relative;
  }
`;

const SVGHole = ({color, size, half}) => (
  <svg width={size} viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <circle r="5" cx="5" cy={ half === 'top' ? "5" : "0"} stroke="none" strokeWidth="0" fill={color || '#fff'}/>
  </svg>
)
export const TicketHole = (props) => (
  <>
  <div style={{position: 'absolute', top: '0', right: '0', transform: 'translate(50%, -20px)'}}>
    <SVGHole size="24" half="bottom" color={props.color} />
  </div>
  <div style={{position: 'absolute', bottom: '0', right: '0', transform: 'translate(50%, 16px)'}}>
    <SVGHole size="24" half="top" color={props.color} />
  </div>
  </>
)
