import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { LOCAL_URL } from '../../config'
import { Button } from '../../Layout'
import { formatApplicationScope } from '../Preference/helper'
import { ICouponInfo, CouponStatusEnum, COUPON_STATUS_LABEL } from './common'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.09);
  
  font-size: 15px;
  line-height: 21px;
  color: rgba(0,0,0,0.45);

  &.card {
    border: none;
    border-radius: 8px;
    background-color: white;
  }

  & ~ & {
    margin-top: 1px;
  }
  &.card ~ &.card {
    margin-top: .75rem;
  }
  & img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  & p {
    margin-top: .25rem;
  }
  & p.title {
    line-height: 24px;
    font-size: 17px;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
  }
  & div.status {
    color: ${props => props.theme.color.primary.HEX};
  }
`
const StatusTag = ({status}:{
  status: CouponStatusEnum
}) => (
  <div className='status'>
  { COUPON_STATUS_LABEL[status] || status }
  </div>
)

const Line2 = ({redeemMerchant, dueDate}:{
  redeemMerchant: any;
  dueDate: string;
}) => {
  return (
    <p>{
      !!redeemMerchant?.name ? 
      `核销：${redeemMerchant.name}` : 
      `有效期：${dayjs(dueDate).format('YYYY年MM月DD日')}`}
    </p>
  )
}
export type CouponCardModeType = 'BY-PREFERENCE' | 'BY-USER' | 'BY-PROMOTION';
interface ICouponCardProps extends ICouponInfo {
  mode: CouponCardModeType;
}
export const CouponCard:React.FC<ICouponCardProps> = (props: any) => {
  const { 
    mode, 
    status, dueDate, name, applicationScope,
    user, userAvatarUrl, redeemMerchant, promotion,
  } = props;
  const history = useHistory()

  const gotoDetails = () => {
    history.push(LOCAL_URL.COUPON_DETAILS, { data: props })
  }
  return (
    (mode === 'BY-USER') ?
    <Wrapper className='card flex-between align-top debugx'>
      <div className='flex-between align-top' style={{gap: '.5rem'}}>
        <div>
          <p className='title'>{name}</p>
          <p>{formatApplicationScope(applicationScope?.setting)}</p>
          <Line2 redeemMerchant={redeemMerchant} dueDate={dueDate} />
        </div>
      </div>
      <div className="flex-between align-end direction-col">
        <StatusTag status={status} />
        <Button className="small" onClick={gotoDetails}>查看详情</Button>
      </div>
    </Wrapper>
    :
    (mode === 'BY-PREFERENCE') ?
    <Wrapper className='flex-between align-top debugx' >
      <div className='flex-between align-top' style={{gap: '.5rem'}}>
        <img src={userAvatarUrl} alt="avatar" />
        <div>
          <p className='title'>{user?.name}</p>
          <p>来源：{promotion?.name}</p>
          <Line2 redeemMerchant={redeemMerchant} dueDate={dueDate} />
        </div>
      </div>
      <StatusTag status={status} />
    </Wrapper>
    :
    null
  )
}
