import React from 'react';
import styled from 'styled-components';
import { THEME } from '../config';
import {
  SVGPremiumIcon,
  SVGBorderOffer,
  SVGBorderMemberSmall,
  SVGBorderMemberMedium,
  SVGBorderMemberWidth, SVGBorderCoffeePassWidth
} from './Icons';

const Container = styled.div`
  display: inline-block;
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  line-height: ${(props) => props.height || 'auto'};
  text-align: center;
  color: ${THEME.color.accent.gold};
  /* background: #333; */
  /* border: 1px solid #f00; */
  /* border: 1px solid ${THEME.color.accent.gold}; */
  position: relative;
  z-index: 10;
`;
const Border = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
`;
const Symbol = styled.div`
  position: absolute;
  top: ${(props) => (props.size === 'medium') ? '-9px' : '-7px'};;
  left: -22px;

  & svg {
    width: ${(props) => (props.size === 'medium') ? '48px' : '40px'};
  }
`;
const Content = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 9;
`;

interface MemberLabelProps {
  width: string;
  height: string;
  size: 'small' | 'medium';
  [propName: string]: any;
}
export const MemberLabel: React.FC<MemberLabelProps> = ({children, width, height, size}) => (
  <Container width={width} height={height} >
    <Border>
      { (size === 'medium') ? <SVGBorderMemberMedium /> : <SVGBorderMemberSmall /> }
    </Border>

    <Content>
      {children}
    </Content>

    <Symbol size={size}>
      <SVGPremiumIcon />
    </Symbol>
  </Container>
)

export const MembershipPageLabel: React.FC<OfferLabelProps> = ({children, width, height}) => (
  <Container width={width} height={height} >
    <Border>
      <SVGBorderMemberWidth />
    </Border>
    <Content>
      {children}
    </Content>
  </Container>
)

export const CoffeePassPageLabel: React.FC<OfferLabelProps> = ({children, width, height}) => (
  <Container width={width} height={height} >
    <Border>
      <SVGBorderCoffeePassWidth />
    </Border>
    <Content>
      {children}
    </Content>
  </Container>
)

interface OfferLabelProps {
  width: string;
  height: string;
  [propName: string]: any;
}
export const OfferLabel: React.FC<OfferLabelProps> = ({children, width, height}) => (
  <Container width={width} height={height} >
    <Border>
      <SVGBorderOffer />
    </Border>

    <Content>
      {children}
    </Content>
  </Container>
)
