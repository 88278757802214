import styled from 'styled-components'

interface DialogProps
extends React.HTMLAttributes<HTMLDivElement> {
  [propName: string]: any;
}
const Wrapper = styled.div<DialogProps>`
  position: absolute;
  background-color: white;
  left: 50%;
  transform: translateX(-50%);
  border-radius: .75rem;

  & ul {
    list-style: none;
  }
`

export const MenuItem = styled.li`
  width: 100%;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  & ~ & {
    border-top: .5px solid rgba(60, 60, 67, 0.36);
  }
  &.cancel {
    color: rgba(0, 0, 0, 0.45);
  }
`
export const Menu: React.FC<DialogProps> = ({children, ...props}) => {
  return (
    <Wrapper {...props}>
      <ul className='flex-center align-center direction-col'>
        {children}
      </ul>
    </Wrapper>
  )
}
