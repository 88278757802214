import { PERSISTANCE } from '../config'
import { DataCache } from './data.cache'

export * from './common'
export * from './request'
export * from './datetime'
export * from './helper'
export * from './store.helper'
export * from './merchant'
export * from './webpage'
export * from './navgeo'

export const pageCache = new DataCache(PERSISTANCE.PAGE_CACHE, sessionStorage)
export const returnResultOfPageFunction = new DataCache(PERSISTANCE.PAGE_FUNC_RET_RESULT, sessionStorage)

export const emailForOrderExport = new DataCache(PERSISTANCE.ORDER_EXPORT_EMAIL, localStorage)

export const splashScreen = new DataCache(PERSISTANCE.SPLASH_SCREEN, sessionStorage)
/**
  any string
 */

export const userCache = new DataCache(PERSISTANCE.KEY_USER, localStorage)
/**
  { 
    token,
    profile: {

    }
  }
 */

/**
  这里很奇怪，如果使用 sessionStorage， REDEEM_EXCEED 场景下的支付就会失败，因为支付跳转回来之后，取不到 cache 里面的数据了。
  而其他支付场景可以取到 cache 里面的数据
 */
export const paymentCache = new DataCache(PERSISTANCE.KEY_PAYMENT, localStorage)
/**
  {
    pageLocation,
    paymentResult: {
      transaction,
      paymentRediretUrl
    },
  }
 */

export const inStoreBillCache = new DataCache(PERSISTANCE.KEY_IN_STORE_BILL, localStorage)
/**
  !!! V2.0 !!!
  {
    source,
    ShoppingMall,
    deal,
    allDeals,
  }
 */

export const bankCardPaymentCache = new DataCache(PERSISTANCE.KEY_BANK_CARD_PAYMENT, localStorage)
/**
  !!! V2.0 !!!
  {
    source,
    order,
    orderLocation
  }
 */
export const paymentReturnCache = new DataCache(PERSISTANCE.KEY_PAYMENT_RETURN, localStorage)
/**
  !!! V2.0 !!!
  {
  }
 */



export const storeCache = new DataCache(PERSISTANCE.KEY_STORE, sessionStorage)
export const orderDetailsCache = new DataCache(PERSISTANCE.KEY_ORDER_DETAILS, sessionStorage)



export const paramsCache = new DataCache(PERSISTANCE.PARAMS, sessionStorage)
/**
 * 存储 url参数，原因：根域名会跳转，导致参数丢失
 * **/

export const mapViewCache = new DataCache(PERSISTANCE.KEY_MAP_VIEW, sessionStorage)
/**
 * 存储 MapView page 的 view mode
 * 
 **/

export const currentmerchantCache = new DataCache(PERSISTANCE.CURRENT_MERCHANT, sessionStorage)

export const showGuideCache = new DataCache(PERSISTANCE.KEY_SHOW_GUIDE, sessionStorage)


