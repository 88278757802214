import styled from 'styled-components'

interface ISwitchProps {
  value: boolean;
  onChange: (v: boolean) => void;
}
const Wrapper = styled.div<ISwitchProps>`
  width: 51px;
  height: 31px;
  border-radius: 15px;
  position: relative;
  background-color: ${ props => props.active ? '#34C759' : '#ddd'};
`
const Dot = styled.div`
  background-color: white;
  border-radius: 14px;
  width: 27px;
  height: 27px;
  position: absolute;
  top: 2px;
  left: 3px;
  right: auto;
  &.active {
    left: auto;
    right: 3px;
  }
`
export const Switch: React.FC<ISwitchProps> = ({value, onChange}) => {
  return (
    <Wrapper active={value} onClick={() => onChange(!value)}>
      <Dot className={ !!value ? 'active':'' } />
    </Wrapper>
  )
}
