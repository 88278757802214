import React, { useRef, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconArrowForward } from '../Icons';
import { THEME } from '../../config';

const DragConfirmContainer = styled.div`
  width: 100%;
  height: 56px;
  line-height: 48px;
  border-radius: 1.75rem;
  background-color: ${THEME.color.accent.green};
  border: 3px solid ${THEME.color.accent.green};
  overflow: hidden;

  position: relative;
`;
/**
  在 container 内部有两个组件
  一个是 Text，另一个是 Slider
  因此，有两个选择：
  一个是 Text 正常，Slider 采用绝对定位（position: absolute)
  另一个是 Slider 正常，Text 采用绝对定位（position: absolute)
  如果是前者，可能会引发 Bug: search safari rtl scroll for more
 */
const ConfirmText = styled.div`
  position: absolute;
  right: 0;
  padding-right: 1em;
  color: ${(props) => `rgba(255,255,255, ${1 - props.ratio})`};
`;
const DragSlider = styled.div`
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.75rem;

  display: inline-flex;
  overflow-x: scroll;
  direction: rtl;
  white-space: nowrap;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & div {
    width: calc(100% - 3.25rem);
    flex-shrink: 0;
    border-radius: 1.75rem;
    background: transparent;
  }
  & div.middle {
    width: 3.25rem;
    color: ${THEME.color.accent.green};
    background: #fff;
    display: flex;
    align-items: center;
  }
  & svg {
    fill:  ${THEME.color.accent.green};
    width: 2rem;
    margin-right: .5em;
  }
`;

export const DragConfirmButton = ({onConfirm}) => {
  const refDiv = useRef(null);
  const refSlider = useRef(null);
  const sliderOrigionLeftPos = useRef(null);
  const [ratio, setRatio] = useState(0);

  useEffect(() => {
    // console.log(refDiv);
    sliderOrigionLeftPos.current = refSlider?.current?.scrollLeft;
  }, []);

  const onScroll = (e) => {
    const { target } = e || {};

    /**
      注意：
      安卓手机浏览器和苹果手机浏览器上
      scroll 的坐标系不同
      因此，判断滚动从左到右的方法，需要特殊处理
     */

    const offsetDistance = Math.abs(target?.scrollLeft - sliderOrigionLeftPos?.current);
    const totalWidth = refDiv?.current?.offsetWidth;
    // console.log(
    //   totalWidth,
    //   sliderOrigionLeftPos?.current,
    //   target?.scrollLeft,
    //   offsetDistance,
    // )
    // if ( offsetDistance === totalWidth ) { // 想等时认为 confirm
    if ((offsetDistance * 10) > (totalWidth * 8)) { // 位置超过 80% 认为是 confirm
      // console.log('!!! confirmed !!!');
      if (!!onConfirm) onConfirm();
    }
    else {
      setRatio(offsetDistance/totalWidth);
    }
  }
  return (
    <DragConfirmContainer>
      <ConfirmText ratio={ratio} className="ft-pb18">Yes, I confirm</ConfirmText>

      <DragSlider ref={refSlider} onScroll={onScroll}>
        {/* 
          css direction rtl 会导致 子元素会依次从右到左排列 
          似乎连z字符串 ‘<<<’ 都变成了 ‘>>>’ 
        */}
        <div ref={refDiv} />
        <div className='middle'><IconArrowForward /></div>
        <div />
      </DragSlider>
      
    </DragConfirmContainer>
  )
}

