import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { LOCAL_URL, THEME } from "../../config";
import { paramsCache, buildSitePageUrl } from "../../lib";
import { UserContext } from "../../context";
import { useWindowSize } from "../../hooks";
import { Divider, Layer, Navbar, Region } from "../../components";
import { Header } from "./Header";
// import {Footer} from './Footer';
import { PopActions, PopType } from "./PopActions";
import {setRedirectUrl} from "../../utils/persistence";
// import CustomIntercomLauncher from './CustomIntercomLauncher';

const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;

const HEADER_BAR_HEIGHT_NORMAL = parseInt(THEME.size.HEADER_BAR_HEIGHT);

export const PCModeContainer = styled.div`
  @media screen and (max-width: ${VIEW_MODE_THRETHOLD}) {
    display: none;
  }
`;
export const MobileModeContainer = styled.div`
  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    display: none;
  }
`;

interface LayerProps {
  height?: number | string;
  [propName: string]: any;
}

interface PageLayoutProps {
  children: any;

  pageName: string;
  baseLay?: LayerProps;
  underLay?: LayerProps;
  overLay?: LayerProps;

  renderBreadCrumbs?: () => void;
  renderBack?: () => void;
}

export default function PageLayout(props: PageLayoutProps) {
  const {
    pageName,
    children,
    baseLay,
    underLay,
    overLay,
    renderBreadCrumbs,
    renderBack,
  } = props;
  // console.log(pageName, location)

  const history = useHistory();
  const params = useParams<any>();
  const { model: userModel, isAnonymous } = useContext(UserContext);
  const [open, setOpen] = useState<PopType | null>(null);
  const [initStep, setInitStep] = useState<number>(0);
  const { isPCMode } = useWindowSize();

  const gotoLoginPage = () => {
    setRedirectUrl(window.location.href)
    history.push(LOCAL_URL.USER_LOGIN);
  };
  const onBarsClick = () => {
    if (!isAnonymous) setOpen("DRAWER_NAV_BAR");
    else if (isPCMode) setOpen("DIALOG_LOGIN");
    else setOpen("DRAWER_LOGIN");
  };
  const toResetPassword = () => {
    setOpen("DIALOG_PWD_RST");
  };
  const toRegister = (initStep?: number) => {
    setOpen("DIALOG_REGISTER");
    setInitStep(initStep || 0);
  };
  // const onLocate = () => {
  //   setOpen('DIALOG_LOCATION');
  // }
  const onFooterClick = () => {
    setOpen("MASK_COMING_SOON");
  };

  useEffect(() => {
    // console.log('PageLayout effect')
    // console.log(params)

    if (params?.action === "register") {
      toRegister();
    } else if (params?.action === "login") {
      onBarsClick();
    }
  }, [params]);

  const onClose = () => {
    if (!!open) setOpen(null);
    if (!!params?.action)
      history.push(buildSitePageUrl(LOCAL_URL.WELCOME, paramsCache));
  };

  return (
    <>
      {isPCMode ? (
        <PCModeContainer className="relative-position">
          <Layer.Stack>
            {!!underLay?.height &&
              ("number" === typeof underLay.height ? (
                <Layer.UnderLay
                  height={`${underLay.height}px`}
                  minHeight={underLay.minHeight}
                />
              ) : "string" === typeof underLay.height ? (
                <Layer.UnderLay
                  height={underLay.height}
                  minHeight={underLay.minHeight}
                />
              ) : null)}
            {!!baseLay?.height && "number" === typeof baseLay.height && (
              <Layer.BaseLay
                height={`${baseLay.height + HEADER_BAR_HEIGHT_NORMAL}px`}
                background={baseLay.background}
              />
            )}

            <Layer.MainLay
              className="relative-position"
              style={{ minHeight: "100vh" }}
            >
              {pageName.indexOf("receipt") < 0 && (
                <Region background={THEME.color.primary.default}>
                  <Header
                    height={`${HEADER_BAR_HEIGHT_NORMAL}px`}
                    onBarsClick={onBarsClick}
                    onLogin={gotoLoginPage}
                    onRegister={toRegister}
                  />
                  {!!renderBreadCrumbs && renderBreadCrumbs()}
                </Region>
              )}

              {children}

              {pageName.toLowerCase().indexOf("payment") < 0 &&
                pageName.toLowerCase().indexOf("map") < 0 && (
                  <>
                    <Divider className="space" padding="0 0 336px 0" />{" "}
                    {/** 占位：footer 的高度 */}
                    <Region
                      background={THEME.color.primary.default}
                      className="absolute-position"
                      style={{ bottom: "0" }}
                    ></Region>
                  </>
                )}
            </Layer.MainLay>

            {!!overLay?.render && (
              <Layer.OverLay>{overLay.render()}</Layer.OverLay>
            )}
          </Layer.Stack>
        </PCModeContainer>
      ) : (
        <MobileModeContainer>
          <Layer.Stack>
            {!!underLay?.height &&
              ("number" === typeof underLay.height ? (
                <Layer.UnderLay
                  height={`${underLay.height}px`}
                  minHeight={underLay.minHeight}
                />
              ) : "string" === typeof underLay.height ? (
                <Layer.UnderLay
                  height={underLay.height}
                  minHeight={underLay.minHeight}
                />
              ) : null)}
            {!!baseLay?.height && "number" === typeof baseLay.height && (
              <Layer.BaseLay
                height={`${
                  baseLay.height +
                  (pageName === "home" ? HEADER_BAR_HEIGHT_NORMAL : 0)
                }px`}
                background={baseLay.background}
              />
            )}

            <Layer.MainLay className="relative-position">
              {[""].includes(pageName) && (
                <Region background={THEME.color.primary.default}>
                  <Header
                    height={`${HEADER_BAR_HEIGHT_NORMAL}px`}
                    hideLogo
                    onBarsClick={onBarsClick}
                    onLogin={gotoLoginPage}
                    onRegister={toRegister}
                  />
                </Region>
              )}

              {children}

              {/** 占位：Navbar 的高度 */}
              {/* <Divider className="space" padding="0 0 48px 0" />  */}
            </Layer.MainLay>

            {!!overLay?.render && (
              <Layer.OverLay>{overLay.render()}</Layer.OverLay>
            )}
          </Layer.Stack>

          {!!renderBack && renderBack()}
          {[
            "create group buy",
            "addAddress",
            "createProduct",
            "groupBuyDetail",
            "myAddress",
            "productTemplate",
            "inviteMemberPage",
            "acceptInviting",
            "addMerchant",
          ].includes(pageName)
            ? null
            : pageName.indexOf("no nav") < 0 && <Navbar page={pageName} />}
        </MobileModeContainer>
      )}
      <PopActions
        initStep={0}
        pageName={pageName}
        isPCMode={isPCMode}
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        onBarsClick={onBarsClick}
      />
    </>
  );
}
