import { ThemeProvider } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { THEME } from '../config/theme2'

export const CustomTheme = ({children}) => {
  const location = useLocation()
  const { merchantData } = location.state || {}
  const primary = (!!merchantData?.meta?.themeColor) ? {
    HEX: merchantData?.meta.themeColor
  } : { ...THEME.color.primary }
  const theme = {
    ...THEME,
    color: {
      ...THEME.color,
      primary
    }
  }

  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  )
}
