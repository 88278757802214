import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { IFormInputProps } from './type'
import { Wrapper as BaseWrapper } from './styled'

const Wrapper = styled(BaseWrapper)`
  border-radius: 8px;
  
  color: rgba(0,0,0,0.45);
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;

  position: relative;

  & textarea {
    padding: .5rem;
    font-size: 12px;
    line-height: 24px;
    color: rgba(0,0,0,0.85);
    background-color: white;
    border-radius: 8px;
    border: none;
  }
`
const Button = styled.button`
  padding: .25rem 1.25rem;
  color: rgb(204,21,67);
  background-color: rgba(204, 21, 67, 0.12);
  border-radius: 16px;
  border: none;
`

interface ITextAreaInputProps
extends IFormInputProps {
  // onReset?: () => void;
  autoText?: () => string;
}
export const TextAreaInput: React.FC<ITextAreaInputProps> = ({required, label, placeholder, value, onChange, autoText}) => {
  const [text, setText] = useState(value)

  const handleChange = (v) => {
    setText(v)
    onChange(v)
  }
  const onTextChange = (e) => {
    handleChange(e.target.value)
  }
  const onRstClick = (e) => {
    e?.preventDefault()
    handleChange(autoText())
  }

  // useEffect(() => {
  //   if (!value) {
  //     const t = autoText()
  //     if (!!t) handleChange(t)
  //   }
  // }, [])
  useEffect(() => {
    if ( (!!value) && (value !== text) ) {
      setText(value)
    }
  }, [value])

  return (
    <Wrapper className="flex-start direction-col debugx"
      style={{gap: '6px'}}
    >
      <div className='flex-between align-center'>
        <label>{label}<span className='required'>{required ? ' *' : ''}</span></label>
        { !!autoText && <Button onClick={onRstClick}>自动生成</Button> }
      </div>
      <textarea rows={8} placeholder={placeholder} value={text} onChange={onTextChange} onBlur={onTextChange} />
    </Wrapper>
  )
}
