import React, {useContext, useEffect, useState} from "react";
import Header from "../../components/Header";
import {Check} from "../../forms/Check";
import {Radio} from "../../forms/Radio";
import PageLayout from "../Global/PageLayout";
import {EntityContext} from "../../context";
import {useHistory} from "react-router-dom";
import {usePayment} from "../../hooks";
import {Button, notify, Switch} from "../../components";
import styled from "styled-components";
import WhiteSpace from "../../components/WhiteSpace";
import numeral from "numeral";
import {LOCAL_URL, THEME} from "../../config";
import {Backdrop, CircularProgress} from "@mui/material";
import {getParams, requestGet, requestPost} from "../../lib";

function ChoosePayment() {
  const history: any = useHistory();
  const {Cashier, makePayment, processing, setBaseInfo, payBy} = usePayment();
  const [coupons, setCoupons] = useState<any[]>([])
  const [selectedCouponId, setSelectedCouponId] = useState<number>()
  const [useDiscount, setUseDiscount] = useState(true)
  const {model: entityModel, query, create} = useContext(EntityContext);
  const [realPay, setRealPay] = useState(0)
  const orderData = entityModel["ORDER"].response.data;

  const updateOpenId = async (code: string) => {
    try {
      await requestGet('auth/baixingOpenId', {
        code: code
      })
    } catch (e) {
      notify.error('支付请求失败, 请尝试其他支付方式')
    }

  }

  useEffect(() => {
    const timer = setInterval(() => {
      updateOrderStatus();
    }, 1000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  useEffect(() => {
    getOrderData();
  }, []);

  const updateOrderStatus = async () => {
    let orderId = null
    if (history.location.state) {
      orderId = history.location.state.id
    }
    if (!orderId) {
      if (history.location.search) {
        orderId = getParams(history.location.search).orderId
      }
    }
    try {
      const {data} = await requestGet("order/orderStatusById", {
        id: orderId,
      });
      if (data.status === "PAID") {
        history.replace(LOCAL_URL["PAYMENT_DONE"], {
          no: data.no,
          id: data.id,
          groupId: data.groupId,
        });
      } 
    }catch (e) {
      
    }
    
  };

  const getOrderData = async () => {
    let orderId = null
    if (history.location.state) {
      orderId = history.location.state.id
    }
    if (!orderId) {
      if (history.location.search) {
        orderId = getParams(history.location.search).orderId
      }
    }
    if (history.location.search) {
      const {code} = getParams(history.location.search)
      if (code) {
        await updateOpenId(code)
      }
    }
    const {data} = await query("ORDER", {id: orderId});
    if (data.status === "PAID") {
      history.replace(LOCAL_URL["PAYMENT_DONE"], {
        no: data.no,
        id: orderId,
        groupId: data.groupId,
      });
      return
    }
    if (data.availableCoupon && data.availableCoupon.length > 0) {
      const result = getCouponDiscountArray(data.availableCoupon, data.totalPrice)
      setCoupons(result.coupons)
      setSelectedCouponId(result.bestDiscountId)
    }

    if (data.finance) {
      const method = data.finance.paymentMethod;
      if (method && method.length > 0) {
        setBaseInfo({
          orderId: data.id,
          methods: method,
          canOnline: data.group.onlinePayment,
          finance: data.finance,
          hasOpenId: data.hasNZOpenid,
          canCash: data.group.canCash,
          canTrans: data.group.canTranPayment
        })
      }
    }
  };

  const getCouponDiscountArray = (coupons: any[], totalPrice) => {
    const result = []
    let maxDiscount = 0
    let maxDiscountId = coupons[0].id
    for (const coupon of coupons) {
      let discount = 0
      if (coupon.type === 'DISCOUNT') {
        discount = totalPrice * coupon.amount / 100
      } else if (coupon.type === 'DEDUCT') {
        discount = coupon.amount
      }
      if (discount > maxDiscount) {
        maxDiscountId = coupon.id
        maxDiscount = discount
      }
      result.push({
        id: coupon.id,
        desc1: coupon.name,
        amount: discount
      })
    }
    return {
      coupons: result,
      bestDiscountId: maxDiscountId
    }
  }

  useEffect(() => {
    if (orderData?.totalPrice) {
      let paid = orderData?.totalPrice
      if (payBy !== 'CASH' && payBy !== 'TRANSFER' && useDiscount) {
        if (selectedCouponId && coupons.length > 0) {
          const target = coupons.filter(item => item.id === selectedCouponId)[0]
          paid = target.amount < orderData.totalPrice ? orderData.totalPrice - target.amount : 1
        }
      }
      setRealPay(paid)
    }
  }, [orderData?.totalPrice, selectedCouponId, payBy, useDiscount])

  if (!orderData) {
    return null;
  }


  return (
    <PageLayout pageName="no nav choosePayment">
      <Header title="选择支付方式"/>
      <Container>
        <div className="price">
          {numeral(orderData.totalPrice / 100).format("$0,0.00")}
        </div>
        <div className="group">{orderData.group.title}</div>
        <Cashier/>
        {orderData.availableCoupon && orderData.availableCoupon.length > 0 ? (
          <div>
            <WhiteSpace/>
            <CouponSelector
              componentDisabled={payBy === 'CASH' || payBy === 'TRANSFER'}
              coupons={coupons}
              onClicked={(id) => {
                setSelectedCouponId(id)
              }}
              selectedId={selectedCouponId}
              onOpen={(open) => {
                setUseDiscount(open)
              }}
            />
          </div>
        ) : null}

        <WhiteSpace/>
        <div style={{
          fontSize: 10,
          fontWeight: 400,
          lineHeight: '14px',
          color: 'rgba(0,0,0,0.45)'
        }}>
          <div>*如发生退款，需扣除银行手续费：微信支付扣1.5%，网银转账和人工转账扣2%，信用卡扣3.5%。</div>
        </div>
        <div className="bottom-float">
          <Button
            fullWidth
            onClick={() => {
              if (!payBy){
                notify.warning('请选择支付方式')
                return
              }
              if (!processing) {
                makePayment(useDiscount ? selectedCouponId : null);
              }
            }}
          >
            {payBy ? ((payBy === 'CASH' || payBy === 'TRANSFER') ? '下一步' : `支付 ${numeral(realPay / 100).format("$0,0.00")}`) : '请选择支付方式'}

          </Button>
        </div>
      </Container>
      <Backdrop
        sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={processing}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="inherit"/>
          <div style={{color: "white", marginTop: 8}}>处理中...</div>
        </div>
      </Backdrop>
      <WhiteSpace height={80}/>
    </PageLayout>
  );
}

export default ChoosePayment;

const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  .price {
    text-align: center;
    font-size: 36px;
    line-height: 50px;
    margin-top: 24px;
  }

  .group {
    font-size: 12px;
    line-height: 17px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
    color: ${THEME.color.gray["#12-045"]};
  }

  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`;

interface CouponSelectorProps {
  componentDisabled: boolean
  coupons: {
    id: number,
    desc1: string,
    amount: number,
  }[],
  onClicked: (couponId: number) => void
  selectedId: number,
  onOpen: (open: boolean) => void
}

const CouponSelector = (props: CouponSelectorProps) => {
  const [enable, setEnable] = useState(true)

  const {coupons, onClicked, selectedId, onOpen, componentDisabled} = props
  return (
    <CouponSelectorContainer>
      <div className='card'>
        <div className='left'>使用优惠券</div>
        <div className='right'>
          <Switch
            size={16}
            totalWidth={26}
            noChange={componentDisabled}
            value={componentDisabled ? false : enable}
            onChange={async (e) => {
              if (componentDisabled) {
                setEnable(false)
              }
              setEnable(e)
              onOpen(e)
            }}/>
        </div>
      </div>
      {(enable && !componentDisabled) ? coupons.map((item, index) => {
        return (
          <div
            className='card'
            key={item.desc1 + index}
            style={{
              borderTop: '1px solid rgb(238, 238, 238)'
            }}
          >
            <div className='left'>
              <span className='desc1'>{item.desc1}</span>
              <span className='desc2'>(-{numeral(item.amount).divide(100).format('$0,0.00')})</span>
            </div>
            <div className='right'>
              <Radio
                value={selectedId === item.id}
                onChange={() => {
                  onClicked(item.id)
                }}
              />
            </div>
          </div>
        )
      }) : null}
    </CouponSelectorContainer>
  )
}

const CouponSelectorContainer = styled.div`
  background-color: white;
  border-radius: 8px;

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    margin-left: 8px;
    margin-right: 8px;

    .left {
      color: ${THEME.color.gray["#10-085"]};
      padding-left: 16px;
      font-weight: 400;
      font-size: 15px;

      .desc1 {
      }

      .desc2 {
        color: ${THEME.color.primary.default};
        margin-left: 4px;
      }
    }

    .right {
      padding-right: 12px;
    }
  }
`
