import styled from 'styled-components'
import { THEME } from '../../config'
import { formatMoney } from '../../lib';
import { Switch } from '../Switch'

const Container = styled.div`
  border-radius: 8px;
  border: 2px solid ${THEME.color.secondary.default};
  overflow: hidden;
  
  padding: 8px;

  background: #333;
  color: ${THEME.color.secondary.default};

  position: relative;
`;

const SwitchBlock = styled.div`
  position: absolute;
  right: 8px;
  bottom: 0;
`;

/**
  background: ${`
    radial-gradient(circle at bottom right, ${HOLE_COLOR} ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) top,
    radial-gradient(circle at top    right, ${HOLE_COLOR} ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) center 40%,
    radial-gradient(circle at bottom right, ${BG_COLOR}   ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) bottom;
  `};
  background-size: 100% 30%, 100% 25%, 100% 45%;
  background-repeat: no-repeat;
  border-radius: 20px;
 */

interface CardPremiumSwitchProps {
  onClick?: () => void;
  maxDiscountAmount: number;
  isFree?: boolean;
  [propName: string]: any;
}
export const CardPremiumSwitch: React.FC<CardPremiumSwitchProps> = ({onClick, maxDiscountAmount, isFree, ...restProps}) => (
  <Container {...restProps}>
    <p style={{lineHeight: '22px'}} className="ft-rr14">Premium Members instantly <span className="ft-rb24 clr-ft-fff">SAVE ${formatMoney(maxDiscountAmount)}</span></p>
    { isFree ?
      <p style={{lineHeight: '16px'}} className="ft-rr14">Start your <span className="ft-rb14 clr-ft-fff">FREE</span> Premium Trial to save today!</p>
      :
      <p style={{lineHeight: '16px'}} className="ft-rr14">Upgrade to Premium and save today!</p>
    }
    <SwitchBlock>
      <Switch onChange={onClick} value={false} background="#bfbfbf" size={24} totalWidth={39} />
    </SwitchBlock>
  </Container>
)
