import * as React from "react";
import {useEffect, useRef} from "react";
import {QRCodeSVG} from "qrcode.react";
import {CircularProgress} from "@mui/material";
import styled from "styled-components";
import html2canvas from "html2canvas";

export interface IGroupShareDialog {
  url?: string
}

const SGroupShareDialog = styled.div`
  .container{
    .share-image{
      width: 120px;
      height: 120px;
      display: none;
    }
    .qrcode-container {
      margin-top: 16px;
      display: flex;
      width: 120px;
      flex-direction: column;
      align-items: center;
    }
  }

`;

export default function QRImage({

                                  url
                                }: IGroupShareDialog) {
  const ref = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [loading, setlLoading] = React.useState(false);

  const loadImageAsync = async () => {

    html2canvas(ref.current, {
      width: 120,
      height: 120,
      allowTaint: true,
      useCORS: true,
    })
      .then((canvas) => {
        imgRef.current.src = canvas.toDataURL();
        imgRef.current.style.display = "block";
        ref.current.style.display = "none";
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setlLoading(false);
      });
  };

  useEffect(() => {
    if (
      ref.current === null ||
      imgRef.current === null ||
      imgRef.current.hasChildNodes()
    ) {
      return;
    }
    loadImageAsync();
  }, [ref]);

  return (
    <SGroupShareDialog>
      <div className="container">
        {loading && <CircularProgress style={{alignSelf: "center"}}/>}
        <img className="share-image" ref={imgRef}/>
        <div className="price-container">
          <div ref={ref} className="qrcode-container">
            <QRCodeSVG
              size={120}
              value={url}
            />
          </div>
        </div>
      </div>
    </SGroupShareDialog>
  );
}
