import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import IconHome from './Icons/IconHome';

export interface IHeaderRightProps {
}

const Root = styled.div`
    display: flex;
    align-items: center;
    margin-right: 16px;
    .title {
        color: #fff;
        font-size: 12px;
        margin-left: 4px;
        white-space: nowrap;
    }
`

export default function HeaderRight(props: IHeaderRightProps) {
  const history = useHistory();
  return (
    <Root onClick={() => history.push('/')}>
      <IconHome />
      <div className='title'>
        回首页
      </div>
    </Root>
  );
}
