import {yupResolver} from "@hookform/resolvers/yup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Card, CardActions, Collapse} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import * as React from "react";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import styled from "styled-components";

import * as yup from "yup";
import {Button, InputField, ListInputField, ListItem,} from "../../components";
import Header, {FloatBottom} from "../../components/Header";
import {IconKBArrowR} from "../../components/Icons";
import LoadingDialog from "../../components/LoadingDialog";
import WhiteSpace from "../../components/WhiteSpace";
import {LOCAL_URL, THEME} from "../../config";
import {EntityContext, UserContext} from "../../context";
import {getImageByIdWithSize, uploadImage,} from "../../utils/ImageUtils";
import PageLayout from "../Global/PageLayout";

export const defaultBg = 'https://res.cloudinary.com/shenmanz/image/upload/v1674781703/tuangou/icon/backgroundImage1.png'

export interface IAddMerchantProps {
}

const StyledForm = styled.form`
  padding: 8px;

  .tips-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .tips {
    text-align: center;
    margin-bottom: 24px;
  }

  a {
    margin: 0px 8px 0px 8px;
  }
`;
const Divider = styled.div`
  height: 8px;
`;
const AddNewProduct = styled.div`
  background-color: #fff;
  height: 64px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.gray["#12-045"]};

  div {
    margin-left: 8px;
  }

  margin-top: 16px;
`;
const Line = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.09);
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 8px 8px 8px;
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  .title {
    font-size: 15px;
    color: ${THEME.color.gray["#10-085"]};
  }

  .title .high-light {
    color: ${THEME.color.primary.default};
  }

  .sub-title {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 4px;
  }

  .switch {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .radio {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

type FormData = {
  id?: number;
  name: string;
  address?: string;
  phone: string;
  logo: string;
  'meta_backgroundImage': string
  'meta_briefIntro': string
  'meta_wechatTitle': string
  'meta_wechatContent': string
  'meta_wechatImage': string
  'meta_themeColor': string
};

const Input = styled("input")({
  display: "none",
});

const SyledListItem = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;
const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin: 0.5em 0 0.25em;
`;

const schema = yup.object().shape({
  name: yup.string().required("店名不能为空"),
  phone: yup.string().required("电话不能为空"),
  logo: yup.string().required("头像不能为空"),
});

export default function AddMerchant(props: IAddMerchantProps) {
  const history = useHistory<any>();
  const [uploading, setUploading] = useState(false)
  const merchant = history.location?.state?.merchant;

  const {
    model: entityModel,
    refreshGlobalDependence,
    query,
    create,
    remove,
    update,
    replace,
    flush,
  } = React.useContext(EntityContext);

  const {model, isAnonymous, location} = React.useContext(UserContext);

  const {profile} = model;

  const {avatar} = profile;

  let merchantData;

  const globalData = entityModel["GLOBAL_DATA"].response;

  if (merchant && !globalData.id) {
    merchantData = merchant;
  } else {
    merchantData = globalData;
  }

  const {register, handleSubmit, reset, control, errors} = useForm<FormData>({
    defaultValues: {
      id: merchantData ? merchantData?.id : undefined,
      name: merchantData && merchantData.name ? merchantData.name : "",
      address: merchantData && merchantData.address ? merchantData.address : "",
      phone: merchantData && merchantData.phone ? merchantData.phone : "",
      logo: merchantData && merchantData.logo ? merchantData.logo : avatar,
      meta_backgroundImage: merchantData.meta?.backgroundImage ?? defaultBg,
      meta_briefIntro: merchantData.meta?.briefIntro,
      meta_wechatTitle: merchantData.meta?.wechatTitle,
      meta_wechatContent: merchantData.meta?.wechatContent,
      meta_wechatImage: merchantData.meta?.wechatImage,
      meta_themeColor: merchantData.meta?.themeColor ?? THEME.color.primary.default,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (globalData && globalData.meta_backgroundImage) {
      control.setValue('meta_backgroundImage', globalData.meta_backgroundImage)
      control.setValue('meta_briefIntro', globalData.meta_briefIntro)
      control.setValue('meta_wechatContent', globalData.meta_wechatContent)
      control.setValue('meta_wechatImage', globalData.meta_wechatImage)
      control.setValue('meta_wechatTitle', globalData.meta_wechatTitle)
      control.setValue('meta_themeColor', globalData.meta_themeColor)
      control.setValue('name', globalData.name)
      control.setValue('address', globalData.address)
      control.setValue('phone', globalData.phone)
      control.setValue('logo', globalData.logo)
    }
  }, [globalData])

  const onSumbit = async (data) => {
    let id;
    data.meta = {}
    data.meta.backgroundImage = data.meta_backgroundImage
    data.meta.briefIntro = data.meta_briefIntro
    data.meta.wechatTitle = data.meta_wechatTitle
    data.meta.wechatContent = data.meta_wechatContent
    data.meta.wechatImage = data.meta_wechatImage
    // todo
    data.meta.themeColor = data.meta_themeColor
    if (data.id) {
      id = data.id;
      await update("MERCHANT", data);
      await refreshGlobalDependence();
      flush("GLOBAL_DATA");
      query("MERCHANT", {id: data.id});
      history.goBack();
    } else {
      id = (await create("MERCHANT", data)).id;
      await refreshGlobalDependence();
      flush("GLOBAL_DATA");
      history.replace(LOCAL_URL["ADD_MERCHANT_SUCCESS"], {id});
    }
  };

  return (
    <PageLayout pageName="addMerchant">
      <Header title='店铺资料'/>
      <StyledForm onSubmit={handleSubmit(onSumbit)}>
        <input ref={register} name="id" style={{display: "none"}}/>
        <StyledCard>
          <ListInputField
            register={register}
            name="name"
            label="店名"
            required
            placeholder="请输入店名"
            bottomDivider
            errors={errors}
          />
          <Controller
            control={control}
            name="address"
            render={({value}) => (
              <ListItem
                onPress={() => {
                  replace("GLOBAL_DATA", {
                    ...control.getValues(),
                    from: "merchant",
                  });
                  history.push(LOCAL_URL["SELECT_ADDRESS"]);
                }}
                title="地址"
                hint="请选择"
                comments={value}
              />
            )}
          />
          <Line/>
          <ListInputField
            register={register}
            name="phone"
            required
            label="电话"
            placeholder="请输入联系电话"
            bottomDivider
            errors={errors}
          />

          <Controller
            control={control}
            name="logo"
            render={({value, onChange: onValueChange}) => (
              <label className="btn-add" htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={async (e) => {
                    if (e.target.files?.length > 0) {
                      setUploading(true)
                      const public_id = await uploadImage(e.target.files[0]);
                      onValueChange(public_id);
                      setUploading(false)
                    }
                  }}
                />
                <SyledListItem>
                  <div>
                    头像
                    <span style={{color: THEME.color.primary.default}}>
                      *
                    </span>
                  </div>
                  <div style={{flexGrow: 1}}></div>
                  <Avatar src={getImageByIdWithSize(value, undefined, 100)}/>
                  <span
                    className="ft-rr15 clr-ft-gray12-45"
                    style={{height: 26, width: 26}}
                  >
                    <IconKBArrowR/>
                  </span>
                </SyledListItem>
                <div>
                  {!errors || !errors["logo"] ? null : (
                    <ErrMsg className="ft-rr12">
                      {Object(errors["logo"]).message}
                    </ErrMsg>
                  )}
                </div>
              </label>
            )}
          />
        </StyledCard>
        <div style={{marginTop: 16}}></div>
        <Card>
          <CardActions disableSpacing onClick={() => {
            replace('GLOBAL_DATA', {
              showAdvancedSetting: !globalData.showAdvancedSetting
            })
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
              <div style={{
                fontSize: 15,
                color: THEME.color.gray["#12-045"],
                flex: 1
              }}>
                <span style={{color: '#000000', fontWeight: 400}}>高级设置</span>（背景图、店铺简介等）
              </div>
              <div style={{width: 25}}>
                {globalData.showAdvancedSetting ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </div>
            </div>

          </CardActions>

        </Card>
        <Collapse in={globalData.showAdvancedSetting} timeout="auto" unmountOnExit>
          <WhiteSpace height={24}/>
          <StyledCard>
            <Controller
              control={control}
              name="meta_backgroundImage"
              render={({value, onChange: onValueChange}) => (
                <div onClick={() => {
                  replace("GLOBAL_DATA", {
                    ...control.getValues(),
                    from: "merchant",
                  });
                  history.push(LOCAL_URL["SELECT_BACKGROUND"]);
                }}>
                  <SyledListItem>
                    <div>
                      背景图
                    </div>
                    <div style={{flexGrow: 1}}></div>
                    <Avatar variant="square" src={getImageByIdWithSize(value, undefined, 100)}/>
                    <span
                      className="ft-rr15 clr-ft-gray12-45"
                      style={{height: 26, width: 26}}
                    >
                    <IconKBArrowR/>
                  </span>
                  </SyledListItem>
                </div>
              )}
            />
            <Line/>
            <Controller
              control={control}
              name="meta_themeColor"
              render={({value, onChange: onValueChange}) => (
                <div onClick={() => {
                  replace("GLOBAL_DATA", {
                    ...control.getValues(),
                    from: "merchant",
                  });
                  history.push(LOCAL_URL["SELECT_THEME_COLOR"]);
                }}>
                  <SyledListItem>
                    <div>
                      主题色
                    </div>
                    <div style={{flexGrow: 1}}></div>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        backgroundColor: value
                      }}
                    />
                    <span
                      className="ft-rr15 clr-ft-gray12-45"
                      style={{height: 26, width: 26}}
                    >
                    <IconKBArrowR/>
                  </span>
                  </SyledListItem>
                </div>
              )}
            />
            <Line/>
            <InputField
              textarea
              label="店铺简介"
              register={register}
              type="text"
              name="meta_briefIntro"
              placeholder="请输入店铺简介，最多140字"
            />
          </StyledCard>
          <WhiteSpace height={24}/>
          <label>
            店铺主页微信分享设置
          </label>
          <WhiteSpace/>
          <StyledCard>
            <InputField
              label="标题"
              register={register}
              type="text"
              name="meta_wechatTitle"
              placeholder="请输入标题"
            />
            <Line/>
            <InputField
              textarea
              label="副标题"
              register={register}
              type="text"
              name="meta_wechatContent"
              placeholder="请输入副标题"
            />
            <Line/>
            <Controller
              control={control}
              name="meta_wechatImage"
              render={({value, onChange: onValueChange}) => (
                <label className="btn-add" htmlFor="icon-button-file-3">
                  <Input
                    accept="image/*"
                    id="icon-button-file-3"
                    type="file"
                    onChange={async (e) => {
                      if (e.target.files?.length > 0) {
                        setUploading(true)
                        const public_id = await uploadImage(e.target.files[0]);
                        onValueChange(public_id);
                        setUploading(false)
                      }
                    }}
                  />
                  <SyledListItem>
                    <div>
                      分享图
                    </div>
                    <div style={{flexGrow: 1}}></div>
                    {value ? (
                      <Avatar variant="square" src={getImageByIdWithSize(value, undefined, 100)}/>
                    ) : (
                      <span style={{color: THEME.color.gray["#12-045"]}}>无</span>
                    )}
                    <span
                      className="ft-rr15 clr-ft-gray12-45"
                      style={{height: 26, width: 26}}
                    >
                    <IconKBArrowR/>
                  </span>
                  </SyledListItem>
                </label>
              )}
            />
          </StyledCard>

        </Collapse>
        <FloatBottom>
          <Button fullWidth type="submit" size="large" color="red">
            确定
          </Button>
        </FloatBottom>
      </StyledForm>
      <LoadingDialog visible={uploading}/>
    </PageLayout>
  );
}
