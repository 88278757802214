import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import {
  BackButton,
  Button,
  InputField,
  Layer,
  LinkSpan,
  ListInputField,
  ListItem,
  Switch,
} from "../../components";
import PageLayout from "../Global/PageLayout";
import { LOCAL_URL, THEME } from "../../config";
import { useHistory } from "react-router-dom";
import Header, { FloatBottom } from "../../components/Header";
import { EntityContext } from "../../context";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IAddAddressProps {}

const StyledForm = styled.form`
  padding: 8px;
  .tips-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }
  .tips {
    text-align: center;
    margin-bottom: 24px;
  }
  a {
    margin: 0px 8px 0px 8px;
  }
`;
const Divider = styled.div`
  height: 8px;
`;
const AddNewProduct = styled.div`
  background-color: #fff;
  height: 64px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.gray["#12-045"]};
  div {
    margin-left: 8px;
  }
  margin-top: 16px;
`;
const Line = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.09);
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 8px 8px 8px;
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  .title {
    font-size: 15px;
    color: ${THEME.color.gray["#10-085"]};
  }
  .title .high-light {
    color: ${THEME.color.primary.default};
  }
  .sub-title {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 4px;
  }
  .switch {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .radio {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin: 0.5em 0 0.25em;
`;

type FormData = {
  id?: number;
  addressName?: string;
  contactName?: string;
  formattedAddress?: string;
  extendInfo?: string;
  telephone?: string;
  notes: string;
  type: "DELIVERY" | "PICKUP";
};

const schema = yup.object().shape({
  contactName: yup.string().required("收货人姓名不能为空"),
  telephone: yup.string().required("联系电话不能为空"),
});

export default function AddAddress(props: IAddAddressProps) {
  const history = useHistory<any>();

  const {
    model: entityModel,
    query,
    create,
    remove,
    update,
    replace,
    flush,
  } = React.useContext(EntityContext);

  const globalData = entityModel["GLOBAL_DATA"].response;

  let addressData;
  if (history.location.state && history.location.state.address) {
    addressData = history.location.state.address;
  }

  let initData;

  if (addressData && !globalData.id) {
    initData = addressData;
  } else {
    initData = globalData;
  }

  const { register, handleSubmit, reset, control, errors } = useForm<FormData>({
    defaultValues: {
      id: initData ? initData.id : undefined,
      type: "DELIVERY",
      contactName: initData ? initData.contactName : "",
      formattedAddress: initData ? initData.formattedAddress : "",
      extendInfo: initData ? initData.extendInfo : "",
      telephone: initData ? initData.telephone : "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSumbit = async (data) => {
    if (data.id) {
      await update("ADDRESS", data);
    } else {
      await create("ADDRESS", data);
    }
    flush("GLOBAL_DATA");
    await query("ADDRESS", { type: "DELIVERY" });
    history.goBack();
  };

  return (
    <PageLayout pageName="addAddress">
      <Header title="添加收货地址" />
      <StyledForm onSubmit={handleSubmit(onSumbit)}>
        <StyledCard>
          <input ref={register} name="id" style={{ display: "none" }} />
          <input ref={register} name="type" style={{ display: "none" }} />
          <Controller
            control={control}
            name="formattedAddress"
            render={({ value }) => (
              <>
                <ListItem
                  onPress={() => {
                    replace("GLOBAL_DATA", control.getValues());
                    history.push(LOCAL_URL["SELECT_ADDRESS"]);
                  }}
                  title="收货地址"
                  comments={value || "请选择"}
                />
                <div>
                  {!errors || !errors["formattedAddress"] ? null : (
                    <ErrMsg className="ft-rr12">
                      {Object(errors["formattedAddress"]).message}
                    </ErrMsg>
                  )}
                </div>
              </>
            )}
          />
          <Line />
          <ListInputField
            register={register}
            name="extendInfo"
            label="地址备注"
            placeholder="如果地址不易找到，请添加备注"
            bottomDivider
          />
          <ListInputField
            register={register}
            name="contactName"
            label="姓名"
            placeholder="请填写收货人姓名"
            bottomDivider
            required
            errors={errors}
          />
          <ListInputField
            register={register}
            name="telephone"
            label="联系电话"
            required
            placeholder="请输入联系电话"
            errors={errors}
          />
        </StyledCard>
        <div style={{ marginTop: 16 }}></div>
        <FloatBottom>
          <Button fullWidth type="submit" size="large" color="red">
            确定
          </Button>
        </FloatBottom>
      </StyledForm>
    </PageLayout>
  );
}
