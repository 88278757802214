import * as React from "react";
import styled from "styled-components";
import {getImageByIdWithSize, uploadImage} from "../utils/ImageUtils";
import iconPlus from "../svg/akar-icons_plus.svg";
import iconDelete from "../svg/icon_delete.svg";
import LoadingDialog from "../components/LoadingDialog";
import {notify} from "./notification";

export interface IImageUploaderProps {
  fileList: string[];
  onValueChanged?: (fileList: string[]) => void;
  maxLength?: number;
  row?: number;
  uniqueId?: string;
}

//const imageWidth = (window.screen.width - 16) / 4 - 16;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .img-container {
    margin: 8px;
    position: relative;
  }

  .delete {
    width: 16px;
    height: 16px;
    position: absolute;
    right: -8px;
    top: -8px;
  }

  .prod-img {
    width: ${(props) => props.imageWidth}px;
      //height: ${(props) => props.imageWidth}px;
  }

  .btn-add {
    width: ${(props) => props.imageWidth}px;
    height: ${(props) => props.imageWidth}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
    margin: 8px;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const Input = styled("input")({
  display: "none",
});

export default function ImageUploader(props: IImageUploaderProps) {
  const {fileList, maxLength = 3, onValueChanged, row = 4, uniqueId} = props;
  const [uploading, setUploading] = React.useState(false);
  return (
    <Root imageWidth={(window.screen.width - 16) / row - 16}>
      {fileList?.map((file) => (
        <div className="img-container" key={file}>
          <img
            className="prod-img"
            src={getImageByIdWithSize(file, undefined, 240, 240)}
          />
          {(fileList?.length > 1 || row > 1) && (
            <img
              onClick={() => {
                const newFileList = fileList.filter((s) => s !== file);
                if (onValueChanged) {
                  onValueChanged(newFileList);
                }
              }}
              className="delete"
              src={iconDelete}
            />
          )}
        </div>
      ))}
      { ('number' === typeof fileList?.length) && (fileList.length < maxLength) && (
        <label
          className="btn-add"
          htmlFor={uniqueId ? uniqueId : "icon-button-file"}
        >
          <Input
            multiple="multiple"
            accept="image/*"
            id={uniqueId ? uniqueId : "icon-button-file"}
            type="file"
            onChange={async (e) => {
              const {files} = e.target
              if (files.length > 0) {
                if (files.length + fileList.length > maxLength) {
                  notify.error(`最多上传${maxLength}张图片`)
                  return
                }
                setUploading(true);
                const newList = []
                for (const imageFile of files) {
                  const public_id = await uploadImage(imageFile);
                  newList.push(public_id)
                }
                setUploading(false);
                const newFileList = [...fileList, ...newList];
                onValueChanged(newFileList);
              }
            }}
          />
          <img src={iconPlus}/>
        </label>
      )}
      <LoadingDialog visible={uploading}/>
    </Root>
  );
}
