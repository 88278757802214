import React, {useEffect, useState} from 'react'

function WhiteSpace(props: { height?: number }) {
  const [height, setHeight] = useState(16)
  useEffect(() => {
    if (props.height) {
      setHeight(props.height)
    }
  }, [props.height])
  return (
    <div style={{
      width: 1,
      height: height
    }}>

    </div>
  )
}

export default WhiteSpace
