import React, { useCallback, useState, useEffect } from 'react';
import { THEME } from '../config';

/**
const refX = useRef();
const answer = refX?.current && window.getComputedStyle(refX.current).getPropertyValue("width");
 */

const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;

export function useWindowSize () {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  })

  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return(() => {
      window.removeEventListener('resize', onResize);
    })
  }, [])

  return ({
    size,
    isPCMode: (!!size?.width) && (size.width > parseInt(VIEW_MODE_THRETHOLD)),
  })
}
