import React, { useEffect } from 'react';
import styled from 'styled-components';
import { THEME } from '../../config';
import { newMoment } from '../../lib';
import { useCountDownTimer } from '../../hooks';

const Container = styled.p`
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  color: ${(props) => props.disabled ? THEME.color.gray['#3-76'] : THEME.color.primary.default};
`;

const calcStopMoment = () => newMoment().add(59, 's').format('YYYY-MM-DD HH:mm:ss');

export const ReSendButton = ({onSend}) => {
  const { resetCountDownTimer, duration, isTimeout } = useCountDownTimer(calcStopMoment());

  useEffect(() => {
    if (!!onSend) onSend();
  }, [])

  const onClick = () => {
    if (!isTimeout) return;
    resetCountDownTimer(calcStopMoment())
    if (!!onSend) onSend();
  }

  return (
    <Container
      className="ft-rb14"
      disabled={!isTimeout}
      onClick={onClick}
    >
      RESEND CODE{ isTimeout ? '' : ` (${duration?.seconds().toString().padStart(2, '0') || '0'})`}
    </Container>
  )
}
