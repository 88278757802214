import { IPreferenceRecord } from './type.entity'
import { IFormFields } from './type.form'

export const converEntity2Fields:(record: IPreferenceRecord) => IFormFields
= (record) => ({
  name          : record.name,
  description   : record.description,
  type          : record.type,
  amount        : (record.type === 'DEDUCT') ? (record.amount / 100) : record.amount,
  minimumExpense: record.requirements.minimumExpense,
  scopes        : record.applicationScope.setting,
})
export const converFields2Entity:(fields: IFormFields, merchantId:number, id?:number) => IPreferenceRecord
= (fields, merchantId, id) => ({
  id,
  merchantId,
  name          : fields.name,
  description   : fields.description,
  type          : fields.type,
  amount        : (fields.type === 'DEDUCT') ? fields.amount * 100 : fields.amount,
  requirements  : { minimumExpense: fields.minimumExpense || 0 },
  applicationScope: { setting: !fields.scopes?.length ? ['ONLINE', 'IN-STORE'] : fields.scopes },
})

export const SCOPE_SETTING_DICT = {
  'ONLINE'  : '在线网店', 
  'IN-STORE': '实体店', 
}
export const SCOPE_SETTING_LIST = Object.keys(SCOPE_SETTING_DICT).map((key) => ({
  value: key,
  label: SCOPE_SETTING_DICT[key]
}))

export const COUPON_TYPE_DICT = {
  'DEDUCT'    :'满减券',
  'DISCOUNT'  :'折扣券',
  'GOODS'     :'兑换券',
}
export const COUPON_TYPE_LIST = Object.keys(COUPON_TYPE_DICT).map((key) => ({
  value: key,
  label: COUPON_TYPE_DICT[key]
}))

export const DEFAULT_AMOUNT = {
  discount: 5,
  deduction: 10,
  minExpense: 100
}

export const DEFAULT_RECORD: IPreferenceRecord = {
  merchantId: -1,   // as defaultData, merchantId is not useful
  name: "",
  description: "",
  type: 'DISCOUNT',
  amount: DEFAULT_AMOUNT.discount,
  requirements: { minimumExpense: DEFAULT_AMOUNT.minExpense },
  applicationScope: { setting: ['IN-STORE'] }
}
