import styled from 'styled-components';
import { THEME } from '../../config';

export const Divider = styled.div`
  width: ${(props) => `${props.width || '100%'}`} ;
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0'};
  border-top: ${(props) => `1px solid ${props.color || THEME.color.gray['#6-d9']}`} ;
  background: ${(props) => props.background || 'transparent'};
  &.vertical {
    width: 0;
    height: ${(props) => `${props.height || '100%'}`} ;
    border-left: ${(props) => `1px solid ${props.color || THEME.color.gray['#6-d9']}`};
    border-top: none;
  }
  &.space {
    border: none;
  }
`;

