import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import {Button, InputField} from "../../components";
import {requestDelete, requestGet, requestPost} from "../../lib";
import {useHistory} from "react-router-dom";
import EmptyView from "../../components/EmptyView";
import WhiteSpace from "../../components/WhiteSpace";
import {LOCAL_URL, THEME} from "../../config";
import {useRouter} from "../../hooks/router";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function Delivery() {
  const [info, setInfo] = useState([])
  const history: any = useHistory()
  const router = useRouter()

  const fetchData = async () => {
    const {data} = await requestGet('manage/getDeliveryByOrderId', {
      orderId: history.location.state.orderId
    })
    if (data) {
      setInfo(data)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <PageLayout pageName="no nav delivery">
      <Header title="快递信息"
              right={info.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: "white",
                    justifyContent: 'space-between',
                    width: 125,
                    marginRight: 16
                  }}
                  onClick={() => {
                    if (info && info.length === 1) {
                      const item = info[0]
                      if (!item.label && !item.no) {
                        router.push(
                          LOCAL_URL['DELIVERY_ADD_PAGE'],
                          {orderId: history.location.state.orderId, deliveryId: item.id}
                        )
                        return
                      }
                    }
                    router.push(
                      LOCAL_URL['DELIVERY_ADD_PAGE'],
                      {orderId: history.location.state.orderId}
                    )
                  }}
                >
                  <AddCircleOutlineIcon/> 添加快递信息
                </div>
              ) : null}/>
      <Container>
        {info && info.length > 0 ? (
          <div>
            {info.map(item => {
              if (!item.label && !item.no) {
                return null
              }
              return (
                <div style={{display: 'flex', flexDirection: "row"}} key={item.id}>
                  <div
                    style={{
                      flex: 1,
                      paddingTop: 8,
                      fontSize: 15,
                      color: THEME.color.gray["#0-087"],
                      marginBottom: 8,
                    }}
                    key={item.id}
                  >
                    {item.label || ""}：{item.no || ""}{" "}
                    <a href={item.queryUrl} style={{color: THEME.color.primary.default}}>点击查看</a>
                  </div>
                  <div style={{
                    justifyContent: "space-between",
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    width: 40,
                  }}>
                    <EditIcon
                      style={{height: 16, width: 16}}
                      onClick={() => {
                        router.push(
                          LOCAL_URL['DELIVERY_ADD_PAGE'],
                          {orderId: history.location.state.orderId, deliveryId: item.id}
                        )
                      }}
                    />
                    <DeleteForeverIcon
                      style={{height: 16, width: 16}}
                      onClick={async () => {
                        await requestDelete('manage/deleteDeliveryById', {
                          deliveryId: item.id
                        })
                        await fetchData()
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div style={{marginTop: 160}}>
            <EmptyView desc='该订单还没有添加快递信息'/>
            <WhiteSpace height={72}/>
            <Button
              size={"medium"}
              fullWidth
              onClick={async () => {
                router.push(
                  LOCAL_URL['DELIVERY_ADD_PAGE'],
                  {orderId: history.location.state.orderId}
                )
              }}
            >添加快递信息</Button>
          </div>
        )}
        {info.length > 0 && (
          <div className='bottom-float'>
            <Button
              fullWidth
              onClick={async () => {
                history.goBack()
              }}
            >完成</Button>
          </div>
        )}
      </Container>
    </PageLayout>
  )
}

export default Delivery

const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  background-color: white;
  min-height: ${window.innerHeight - 56}px;

  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`
