import { useEffect, useRef, useState } from "react";

function useImage({ src }) {
  let [image, setImage] = useState({ src: null, status: "loading" });
  let imageElement = useRef(null);
  let loadedUrls = useRef(new Set());

  useEffect(() => {
    const onload = () => {
      setImage((img) => {
        loadedUrls.current.add(img.src);
        return { ...img, status: "loaded" };
      });
    };
    const onerror = () => {
      setImage((img) => ({ ...img, status: "failed" }));
    };
    imageElement.current = document.createElement("img");
    imageElement.current.addEventListener("load", onload);
    imageElement.current.addEventListener("error", onerror);
    return function cleanup() {
      imageElement.current.removeEventListener("load", onload);
      imageElement.current.removeEventListener("error", onerror);
    };
  }, []);

  useEffect(() => {
    if (!src || loadedUrls.current.has(src)) {
      // No image, or already loaded.
      setImage({ src: src, status: "loaded" });
    } else {
      // New image to load.
      imageElement.current.src = src;
      setImage({ src: src, status: "loading" });
    }
  }, [src]);

  return {
    src: image.src,
    isLoading: image.status === "loading",
    isLoaded: image.status === "loaded",
    hasFailed: image.status === "failed",
  };
}

export default useImage;
