import * as React from "react";
import dayjs from 'dayjs';
import { EntityContext } from "../../context";
import GroupBuyCard from "../../business/components/GroupBuyCard";
import PageLayout from "../Global/PageLayout";
import Header from "../../components/Header";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { LOCAL_URL } from "../../config";
import useMerchant from "../../hooks/useMerchant";
import EmptyView, { EmptyContainer } from "../../components/EmptyView";
import { Button } from "../../components";

export interface IGroupListProps {}

const Root = styled.div`
  padding: 16px 16px 48px 16px;
`;

const StyledWrapedGroupBuyCard = styled.div`
  .op-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 16px;
    .btn {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.45);
      box-sizing: border-box;
      border-radius: 16px;
      padding: 3px 8px;
      font-size: 12px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.65);
      margin: 0 4px 0px 4px;
    }
  }
`;

const WrapedGroupBuyCard = (props) => {
  const { groupData, onCopyClick, onManagementClick, onEditGroupClick } = props;
  const { id } = groupData;
  return (
    <StyledWrapedGroupBuyCard>
      <GroupBuyCard {...groupData} />
      <div className="op-container">
        { !!onCopyClick &&
          <div
            className="btn"
            onClick={() => {
              onCopyClick(id);
            }}
          >
            复制
          </div>
        }
        { !!onManagementClick &&
          <div
            className="btn"
            onClick={() => {
              onManagementClick(id);
            }}
          >
            管理订单
          </div>
        }
        { !!onEditGroupClick &&
          <div
            className="btn"
            onClick={() => {
              onEditGroupClick(id);
            }}
          >
            编辑团购
          </div>
        }
      </div>
    </StyledWrapedGroupBuyCard>
  );
};

const SORT_ORDER = {
  'PROCESSING': 1,
  'PENDING': 2,
  'FINISHED': 3,
  'CANCELED': 4,
  'UNKNOWN': 9,
}
const fnSorter = (a:any, b:any) => {
  const statusOrderA = SORT_ORDER[a.status] ?? SORT_ORDER['UNKNOWN']
  const statusOrderB = SORT_ORDER[b.status] ?? SORT_ORDER['UNKNOWN']
  return (
    (statusOrderA < statusOrderB) ? -1 : 
    (statusOrderA > statusOrderB) ? 1 :
    (statusOrderA === statusOrderB) ? (
      dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
    ) : 0
  )
}

export default function GroupList(props: IGroupListProps) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const actions = searchParams?.get?.('actions')?.split(',') || ['copy', 'manage', 'edit'];

  const {
    model: entityModel,
    query,
    create,
    remove,
    update,
    replace,
    flush,
  } = React.useContext(EntityContext);

  const groupList = entityModel["GROUP_BUY_LIST"].response?.data;
  groupList?.sort?.(fnSorter);

  const { currentMerchantId } = useMerchant();

  React.useEffect(() => {
    if (currentMerchantId) {
      query("GROUP_BUY_LIST", { id: currentMerchantId });
    }
  }, [currentMerchantId]);

  return (
    <PageLayout pageName="GROUP_LIST_PAGE">
      <Header title="团购管理" />
      {groupList && groupList.length > 0 ? (
        <Root>
          {groupList.map((g) => (
            <WrapedGroupBuyCard
              key={g.id}
              groupData={g}
              onCopyClick={!actions.includes('copy') ? null : (id) => {
                flush("GROUP_BUY");
                history.replace(LOCAL_URL["CREATE_GROUP_BUY"], {
                  groupId: id,
                  isCopy: true,
                });
              }}
              onManagementClick={!actions.includes('manage') ? null : (id) => {
                history.push(LOCAL_URL["MANAGE_ORDER"], {
                  id: id,
                });
              }}
              onEditGroupClick={!actions.includes('edit') ? null : (id) => {
                flush("GROUP_BUY");
                history.replace(LOCAL_URL["CREATE_GROUP_BUY"], {
                  groupId: id,
                });
              }}
            />
          ))}
        </Root>
      ) : (
        <EmptyContainer>
          <EmptyView desc="还没有发布过团购" />
          <Button
            style={{ marginTop: 16 }}
            fullWidth
            onClick={() => history.push(LOCAL_URL["CREATE_GROUP_BUY"])}
          >
            发布团购
          </Button>
        </EmptyContainer>
      )}
    </PageLayout>
  );
}
