import styled from 'styled-components';
import { THEME } from '../config'

export const PremiumText = styled.h1`
  font-family: Lora;
  font-weight: 700;
  color: ${THEME.color.secondary.default};
  -webkit-text-fill-color: #000;
  -webkit-text-strokeWidth: 1px;
  font-size: ${props => props.size || '48px'};
  letter-spacing: ${props => props.spacing || '6px'};
`
