import React, {useEffect, useState} from 'react';
import {isiOS, isWeixinBrowser} from "../utils/common";
import {requestGet, requestPost} from "../lib";
import WhiteSpace from "../components/WhiteSpace";
import {Radio} from "../forms/Radio";
import {useRouter} from "./router";
import {domain, LOCAL_URL, wxBaixingAppId, wxWebAppId} from "../config";
import {notify} from "../components";

export type PaymentMethod =
  | 'WECHAT_NIUYAO'
  | 'ALIPAY'
  | 'CARD'
  | 'A2A'
  | 'TRANSFER'
  | 'CASH'
  | 'WECHAT_BAIXING'

export type PaymentMethodObj = {
  icon: string,
  text: string,
  text2: string,
  value: PaymentMethod
}

const paymentMethodLib = {
  'TRANSFER': {
    icon: 'https://res.cloudinary.com/shenmanz/image/upload/v1648240959/tuangou/icon/transfer.png',
    text: '人工转账附截图',
    text2: '无手续费',
    value: 'TRANSFER'
  },
  'CASH': {
    icon: 'https://res.cloudinary.com/shenmanz/image/upload/v1651809984/tuangou/icon/cash.png',
    text: '现金/到店支付',
    text2: '',
    value: 'CASH'
  },
  'WECHAT_NIUYAO': {
    icon: 'https://res.cloudinary.com/shenmanz/image/upload/v1647059286/tuangou/icon/WeChat.png',
    text: '微信支付',
    text2: '限免手续费',
    value: 'WECHAT_NIUYAO'
  },
  'ALIPAY': {
    icon: 'https://res.cloudinary.com/shenmanz/image/upload/v1647059286/tuangou/icon/alipay.png',
    text: '支付宝',
    text2: '限免手续费',
    value: 'ALIPAY'
  },
  'CARD': {
    icon: 'https://res.cloudinary.com/shenmanz/image/upload/v1647059286/tuangou/icon/card.png',
    text: '信用卡',
    text2: '限免手续费',
    value: 'CARD'
  },
  'A2A': {
    icon: 'https://res.cloudinary.com/shenmanz/image/upload/v1647059286/tuangou/icon/trans.png',
    text: '网银支付',
    text2: '限免手续费',
    value: 'A2A'
  },
  'WECHAT_BAIXING': {
    icon: 'https://res.cloudinary.com/shenmanz/image/upload/v1647059286/tuangou/icon/WeChat.png',
    text: '微信支付',
    text2: '限免手续费',
    value: 'WECHAT_BAIXING'
  },

}

export function usePayment() {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodObj[]>([])
  const [_orderId, _setOrderId] = useState<any>(null)
  const [processing, setProcessing] = useState(false)
  const [payBy, setPayBy] = useState<PaymentMethod | null>()
  const [baixingConfig, setBaixingConfig] = useState<any>(null)
  const [niuyaoConfig, setNiuyaoConfig] = useState<any>(null)
  const [hasNZOpenid, setHasNZOpenid] = useState<boolean>(false)
  const route = useRouter()
  const initNiuYaoWXJSSDK = async () => {
    if (isWeixinBrowser) {
      const {data} = await requestGet('jssdk', {
        // @ts-ignore
        url: isiOS ? window.entryUrl : window.location.href.split('#')[0]
      });
      if (data) {
        setNiuyaoConfig(data)
      }
    }
  }

  const setBaseInfo = (params: { orderId: string, methods: PaymentMethod[], finance: any, hasOpenId: boolean, canCash: boolean; canTrans: boolean, canOnline: boolean }) => {
    const {orderId, methods, finance, hasOpenId, canCash, canTrans, canOnline} = params
    setHasNZOpenid(hasOpenId)
    _setOrderId(orderId)
    if (methods && methods.length > 0) {
      const obj = []
      for (const method of methods) {
        const tempPaymentObj = paymentMethodLib[method]
        if (finance.feePaidBy === 'USER') {
          switch (method) {
            case "ALIPAY": {
              if (!finance.aliPayRate) {
                tempPaymentObj.text2 = '无手续费'
              } else {
                tempPaymentObj.text2 = `手续费${finance.aliPayRate + finance.rate}%`
              }
              if (canOnline) {
                obj.push(tempPaymentObj)
              }
              break
            }
            case "WECHAT_BAIXING": {
              if (!finance.weChatRate) {
                tempPaymentObj.text2 = '无手续费'
              } else {
                tempPaymentObj.text2 = `手续费${finance.weChatRate + finance.rate}%`
              }
              if (canOnline) {
                obj.push(tempPaymentObj)
              }
              break
            }
            case "WECHAT_NIUYAO": {
              if (!finance.weChatRate) {
                tempPaymentObj.text2 = '无手续费'
              } else {
                tempPaymentObj.text2 = `手续费${finance.weChatRate + finance.rate}%`
              }
              if (canOnline) {
                obj.push(tempPaymentObj)
              }
              break
            }
            case "A2A": {
              if (!finance.a2aRate) {
                tempPaymentObj.text2 = '无手续费'
              } else {
                tempPaymentObj.text2 = `手续费${finance.a2aRate + finance.rate}%`
              }
              if (canOnline) {
                obj.push(tempPaymentObj)
              }
              break
            }
            case "CARD": {
              if (!finance.cardRate) {
                tempPaymentObj.text2 = '无手续费'
              } else {
                tempPaymentObj.text2 = `手续费${finance.cardRate + finance.rate}%`
              }
              if (canOnline) {
                obj.push(tempPaymentObj)
              }
              break
            }
            case "TRANSFER": {
              tempPaymentObj.text2 = '无手续费'
              if (canTrans) {
                obj.push(tempPaymentObj)
              }
              break
            }
            case "CASH": {
              tempPaymentObj.text2 = ''
              if (canCash) {
                obj.push(tempPaymentObj)
              }
              break
            }
          }
        } else {
          tempPaymentObj.text2 = '无手续费'
          switch (method) {
            case "ALIPAY":
            case "WECHAT_BAIXING":
            case "WECHAT_NIUYAO":
            case "A2A":
            case "CARD": {
              if (canOnline) {
                obj.push(tempPaymentObj)
              }
              break
            }
            case "TRANSFER": {
              if (canTrans) {
                obj.push(tempPaymentObj)
              }
              break
            }
            case "CASH": {
              if (canCash) {
                obj.push(tempPaymentObj)
              }
              break
            }
          }
        }
      }
      setPaymentMethods(obj)
      setPayBy(obj[0].value)
    } else {
    }
  }

  const initBaixingWXJSSDK = async () => {
    if (isWeixinBrowser) {
      const {data} = await requestGet('jssdk', {
        // @ts-ignore
        url: isiOS ? window.entryUrl : window.location.href.split('#')[0],
        account: 'BAIXING'
      });
      if (data) {
        setBaixingConfig(data)
      }
    }
  }

  useEffect(() => {
    initNiuYaoWXJSSDK()
    initBaixingWXJSSDK()
    document.addEventListener(
      'WeixinJSBridgeReady',
      wxReady,
    );
    return () => {
      document.removeEventListener('WeixinJSBridgeReady', wxReady)
    }
  }, [])

  const wxReady = () => {
    console.log('ready')
  }

  const makePayment = async (couponId?: number) => {
    if (!payBy) {
      return
    }
    switch (payBy) {
      case "ALIPAY": {
        // todo
        notify.info('暂不支持支付宝')
        break
      }
      case "WECHAT_BAIXING": {
        if (!hasNZOpenid) {
          // todo 获取openId
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wxBaixingAppId}&redirect_uri=${encodeURI(
            `${domain}${LOCAL_URL["CHOOSE_PAYMENT"]}?orderId=${_orderId}`
          )}&response_type=code&scope=snsapi_base#wechat_redirect`;
          return
        }
        if (baixingConfig) {
          const wx = require('weixin-js-sdk');
          wx.config(baixingConfig)
          setProcessing(true)
          const data = await requestPost('payment/wx/baixing', {
            orderNo: _orderId,
            couponId: couponId ?? null
          })
          setProcessing(false)
          if (data && data.code === 0 && data.data.code === 206) {
            const obj = data.data.dataObject
            wx.chooseWXPay({
              timestamp: obj.timeStamp,
              nonceStr: obj.nonceStr,
              package: obj.packageValue,
              signType: obj.signType,
              paySign: obj.paySign,
              success: async function (res) {
                if (res.errMsg === 'chooseWXPay:ok') {

                }
              }
            });
            return;
          }
        }
        break
      }
      case "WECHAT_NIUYAO": {
        if (niuyaoConfig) {
          const wx = require('weixin-js-sdk');
          wx.config(niuyaoConfig)
          setProcessing(true)
          const data = await requestPost('payment/wx/niuyao', {
            orderNo: _orderId,
            products: '神马平台团购',
            tradeType: 'JSAPI',
            couponId: couponId ?? null
          })
          setProcessing(false)
          if (data && data.code === 0) {
            wx.chooseWXPay({
              ...data.data,
              success: async function (res) {
                if (res.errMsg === 'chooseWXPay:ok') {

                }
              }
            });
            return;
          }
        }
        break
      }
      case "A2A": {
        setProcessing(true)
        const {data} = await requestPost('payment/windcave', {
          orderNo: _orderId,
          tradeType: 'A2A',
          couponId: couponId ?? null
        })
        setProcessing(false)
        if (data) {
          window.location.replace(data);
        }
        break
      }
      case "CARD": {
        setProcessing(true)
        const {data} = await requestPost('payment/windcave', {
          orderNo: _orderId,
          tradeType: 'CARD',
          couponId: couponId ?? null
        })
        setProcessing(false)
        if (data) {
          window.location.replace(data);
        }
        break
      }
      case "TRANSFER": {
        setProcessing(true)
        const {data} = await requestPost('order/willPayByOffline', {
          orderId: _orderId,
          type: 'TRANSFER'
        })
        setProcessing(false)
        if (data){
          route.replace(LOCAL_URL["PAYMENT_UPLOAD"], {
            orderNo: _orderId,
          })
        }
        break
      }
      case "CASH": {
        setProcessing(true)
        const {data} = await requestPost('order/willPayByOffline', {
          orderId: _orderId,
          type: 'CASH'
        })
        setProcessing(false)
        if (data) {
          route.replace(LOCAL_URL["PAYMENT_DONE"], {
            id: _orderId,
          })
        }
        break
      }
    }
  }

  const renderCashier = () => (
    <div>
      <WhiteSpace/>
      <div style={{
        backgroundColor: "white",
        borderRadius: 8
      }}>
        {paymentMethods.map((payMethod, i) => (
            <div key={payMethod.value} onClick={() => setPayBy(payMethod.value)}>
              <div
                style={{
                  margin: "auto",
                  maxWidth: 320,
                  display: 'flex',
                  padding: '16px 8px',
                  alignItems: 'center',
                  height: 56
                }}
              >
                <div style={{marginRight: 8}}>
                  <img style={{width: 24, height: 24}} src={payMethod.icon}/>
                </div>
                <div style={{marginRight: 8}}>{payMethod.text}</div>
                {!!payMethod.text2 && <div style={{
                  fontSize: 10,
                  background: 'rgba(0, 0, 0, 0.25)',
                  borderRadius: 7.5,
                  padding: '2px 8px 2px 8px',
                  color: '#fff'
                }}>{payMethod.text2}</div>}
                <div style={{flexGrow: 2}}>
                  <Radio
                    value={payBy === payMethod.value}
                    style={{float: 'right'}}
                  />
                </div>
              </div>
              {i < paymentMethods.length - 1 && (
                <div
                  style={{borderBottom: '1px solid #EEEEEE', margin: '0 8px'}}
                />
              )}
            </div>
          )
        )}
      </div>

    </div>
  )

  return ({
    Cashier: renderCashier,
    makePayment,
    processing,
    setBaseInfo,
    payBy
  })

}
