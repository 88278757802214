import { create, StateCreator } from 'zustand'
import { createShoppingCartSlice, IShoppingCartSlice } from './shoppingCart'
import { createOrderInModifyingSlice, IModifyingOrderSlice } from './orderInModifying'

export type State = IShoppingCartSlice & IModifyingOrderSlice;
export type CreateSlice<T> = StateCreator<State, [], [], T>

export const useStore = create<State>((...a) => ({
  ...createShoppingCartSlice(...a),
  ...createOrderInModifyingSlice(...a),
}))
