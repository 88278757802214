import { IPromotionRecord } from './type.entity'
import { IFormFields } from './type.form'

export enum PromotionStatusEnum { All, Ongoing, Completed };
export const PromotionStatusLabel = ['全部', '进行中', '已结束' ];

export interface IPromotionInfo extends IPromotionRecord {
  status: PromotionStatusEnum;
}

export const converEntity2Fields:(record: IPromotionRecord) => IFormFields
= (record) => {
  const data: IFormFields = ({
    posterLink: record.posterLink,
    name: record.name,
    dueDate: record.dueDate,
    rules: record.rules,
    description: record.description,
    copywriting: record.copywriting,
    couponDueDate: {}
  })
  if (!!record.couponDueDate) {
    data.couponDueDate = { fixDate: record.couponDueDate }
  } else if (!!record.couponLiveTime) {
    data.couponDueDate = { lifeTime: record.couponLiveTime }
  } else {
    data.couponDueDate = null
  }
  return data;
}
export const converFields2Entity:(fields: IFormFields, merchantId:number, id?:number) => IPromotionRecord
= (fields, merchantId, id) => {
  const data = ({
    id,
    merchantId,
    posterLink    : fields.posterLink,
    name          : fields.name,
    dueDate       : fields.dueDate,
    rules         : fields.rules,
    description   : fields.description,
    copywriting   : fields.copywriting,
    couponDueDate : fields.couponDueDate.fixDate,
    couponLiveTime: fields.couponDueDate.lifeTime,
  })
  if (!data.couponDueDate)  delete data.couponDueDate;
  if (!data.couponLiveTime) delete data.couponLiveTime;
  return data;
}
