import * as React from 'react';
import styled from 'styled-components';
import { THEME } from '../config';
import iconEmpty from '../svg/icon_empty.svg';

export interface IEmptyViewProps {
    icon?: any;
    desc: string;
    hideBackground?: boolean
}

const Root = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #fff;
.desc {
    font-size: 12px;
    color: ${THEME.color.gray['#12-045']};
    margin-top: 8px;
}
`

export const EmptyContainer = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100vh - ${THEME.size.HEADER_BAR_HEIGHT});
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
`



export default function EmptyView({ icon, desc, hideBackground }: IEmptyViewProps) {
    return (
        <Root style={{backgroundColor: hideBackground ? THEME.color.background.gray : 'white'}}>
            <img src={icon || iconEmpty} />
            <div className="desc">
                {desc}
            </div>
        </Root>
    );
}
