import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { getImageByIdWithSize, uploadImage } from "../utils/ImageUtils";
import { IconCamera } from '../Layout'
import { IFormInputProps } from './type'

const getImageUrl = (id:string) => getImageByIdWithSize(id, /*'crop'*/ 'fill', 900, 500);

const Wrapper = styled.div`
  width: 100%;
  height: 12rem;
  border-radius: 8px;
  background-color: white;
  background-image: url(${props => props.imgUrl});
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
  color: rgba(0,0,0,0.75);
  font-size: .75rem;
  line-height: 1.2rem;
  /* white-space: pre-wrap; */
  text-align: center;

  position: relative;

  & div.mask {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    background-color: rgba(0,0,0,0.5);
    color: white;
  }

  & p.first {
    margin-top: .4rem;
  }

  & input {
    display: none;
  }
`

const previousValue = {
  imgUrl: ''
}
interface IImageInputProps
extends IFormInputProps {
  texts: string[];
}
export const ImageInput: React.FC<IImageInputProps> = ({texts, value, onChange}) => {
  const [imgId, setImgId]     = useState<string>(value || '')
  const [imgUrl, setImgUrl]   = useState<string>('')
  const [imgFile, setImgFile] = useState<File>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const ref = useRef<any>(null)

  const onIdChange = async (id:string) => {
    // console.log('### onIdChange', new Date())
    if (!id) return;
    previousValue.imgUrl = imgUrl
    try {
      const url = getImageUrl(id)
      // console.log('### url complete', new Date(), url)
      if (!url) throw new Error('getImageUrl fail');
      const response = await fetch(url)
      const blob = await response.blob()
      // console.log('### fetch complete', new Date())
      setImgUrl(URL.createObjectURL(blob))
      // setUrl(url)
      if (value !== id) {
        onChange(id)
      }
    }
    catch (err) {
      console.error(err)
      setImgUrl(previousValue.imgUrl)
    }
    finally {
      setLoading(false)
    }
  }

  const onFileChange = async (f:File) => {
    // console.log('### onFileChange', new Date())
    if (!f) return;
    // else
    previousValue.imgUrl = imgUrl
    setImgUrl(URL.createObjectURL(f))
    setLoading(true)
    try {
      const id = await uploadImage(f)
      // console.log('### upload complete', new Date(), id)
      if (!id) throw new Error('Upload fail');
      setImgId(id)
    }
    catch (err) {
      console.error(err)
      setImgUrl(previousValue.imgUrl)
      setLoading(false)
    }
  }
  useEffect(() => {
    // console.log('### effect imgId', new Date(), imgId)
    if (!!imgId) {
      onIdChange(imgId)
    }
  }, [imgId])
  useEffect(() => {
    // console.log('### effect imgFile', new Date(), imgFile)
    if (!!imgFile) {
      onFileChange(imgFile)
    }
  }, [imgFile])

  const openFileExplorer = () => {
    ref.current.click()
  }
  const onFileInputChange = (e) => {
    // console.log('### on file input change', new Date())
    if (!!e.target.files[0]) {
      setImgFile(e.target.files[0])
    }
  }

  return (
    <Wrapper className="flex-center align-center direction-col"
      onClick={openFileExplorer} imgUrl={imgUrl}
    >
      <input ref={ref} onChange={onFileInputChange} type="file" name="myImage" accept="image/png, image/gif, image/jpeg" />
      { loading ? 
        <div className="mask flex-center align-center direction-col">
          <h5>Loading...</h5>
        </div>
        : !!imgUrl ? null :
        <div>
          <IconCamera />
          { texts?.map((t, index) => (
            <p key={index} className={index === 0 ? 'first' : ''}>{t}</p>
          )) }
        </div>
      }
    </Wrapper>
  )
}
