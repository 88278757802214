import { CreateSlice } from './index'

type ProductAmountType = {
  id: number;
  amount: number;
}
export interface IShoppingCartSlice {
  shoppingCart: {
    availableProducts: any[];       // 可以放入购物车的所有产品信息
    selectedProducts: any[];        // 已经放入购物车的产品信息 + 购物车中的产品数量：amount
    productCount: {
      [id:number]: number;
    },
    totalAmount: number;
    totalPrice: number;
  }
  setAvailableProducts: (items: any[]) => void;
  setSelectedProductAmount: (productsAmount: ProductAmountType[], clearFirst?: boolean) => void;
}

export const createShoppingCartSlice: CreateSlice<IShoppingCartSlice> = (set) => ({
  shoppingCart: {
    availableProducts: [],
    selectedProducts: [],
    productCount: {},
    totalAmount: 0,
    totalPrice: 0,
  },
  setAvailableProducts: (items: any) => set((state) => {
    // console.log({items})
    state.shoppingCart.availableProducts = [ ...items ];
    return {
      ...state,
      shoppingCart: {
        ...state.shoppingCart
      },
    }
  }),
  setSelectedProductAmount: (productsAmount: ProductAmountType[], clearFirst?: boolean) => set((state) => {
    if (!!clearFirst) {
      state.shoppingCart.selectedProducts = []
    }
    for (let { id, amount } of productsAmount) {
      if (!clearFirst) {
        state.shoppingCart.selectedProducts = state.shoppingCart.selectedProducts.filter((x:any) => x.id !== id)
      }
      const item = state.shoppingCart.availableProducts.find((x:any) => x.id === id)
      if ((amount > 0) && (!!item)) {
        state.shoppingCart.selectedProducts.push({ ...item, amount })
      }
    }
    
    return {
      ...state,
      shoppingCart: {
        availableProducts : state.shoppingCart.availableProducts,
        selectedProducts  : [ ...state.shoppingCart.selectedProducts ],
        totalAmount       : state.shoppingCart.selectedProducts.reduce((accu, x) => (accu + x.amount), 0),
        totalPrice        : state.shoppingCart.selectedProducts.reduce((accu, x) => (accu + x.amount * x.price), 0),
        productCount      : state.shoppingCart.selectedProducts.reduce((accu, x) => {
          accu[x.id] = x.amount
          return accu
        }, {}),
      }
    }
  }),
})
