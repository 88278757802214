import * as React from "react";
import Avatar from "@mui/material/Avatar";
import PageLayout from "../Global/PageLayout";
import Header from "../../components/Header";
import styled from "styled-components";
import { EntityContext } from "../../context";
import useMerchant from "../../hooks/useMerchant";
import { LOCAL_URL, WEB_ROOT } from "../../config";
import { useDialog } from "../../hooks";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { getImageByIdWithSize } from "../../utils/ImageUtils";

export interface IInviteMemberProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 16px 16px 16px;
  .title {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }
  .desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 8px;
  }
  .members-container {
    display: flex;
    flex-direction: row;
    margin: 16px 0 45px 0;
  }
  .add-btn {
    width: 60px;
    height: 60px;
    background: #ffffff;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
  }
`;

const StyledMember = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .nickname {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    margin: 4px 0 4px 0;
  }
  .remove {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #cc1543;
  }
  margin-left: 12px;
  margin-right: 12px;
`;

const IconPlus = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 8H1M9 16V8V16ZM9 8V0V8ZM9 8H17H9Z"
      stroke="black"
      stroke-opacity="0.85"
      strokeWidth="2"
      stroke-linecap="round"
    />
  </svg>
);

const Member = ({ avatarUrl, name, onRemoveClick }) => {
  return (
    <StyledMember>
      <Avatar style={{ width: 60, height: 60 }} src={avatarUrl} />
      <div className="nickname">{name}</div>
      <div className="remove" onClick={onRemoveClick}>
        移除
      </div>
    </StyledMember>
  );
};

export default function InviteMember(props: IInviteMemberProps) {
  const {
    model: entityModel,
    query,
    create,
    remove,
  } = React.useContext(EntityContext);
  const userMerchants = entityModel["MERCHANT-USERS"].response?.data;

  const [inviteCode, setInviteCode] = React.useState<any>();
  const { currentMerchantId } = useMerchant();

  const { renderDialog, showDialog } = useDialog();

  React.useEffect(() => {
    const createCode = async () => {
      const resp = await create("INVITE_MEMBERS", {
        merchantId: currentMerchantId,
      });
      setInviteCode(resp);
    };
    if (currentMerchantId) {
      query("MERCHANT-USERS", { merchantId: currentMerchantId });
      createCode();
    }
  }, [currentMerchantId]);

  const copySuccess = async () => {
    showDialog({
      title: "邀请链接已复制",
      message: "请在微信粘贴链接发给好友，并让好友点击链接，接受成为管理员",
    });
  };

  const removeMember = async (userMerchantId: number) => {
    await remove("MERCHANT-USERS", { id: userMerchantId });
    if (currentMerchantId) {
      await query("MERCHANT-USERS", { merchantId: currentMerchantId });
    }
  };

  return (
    <PageLayout pageName="inviteMemberPage">
      <Header title="管理员" />
      <Root>
        <div className="title">设置管理员</div>
        <div className="desc">
          管理员可以替团长完成开团，编辑，订单管理等操作，但不能设置新店铺，删除或添加管理员。请谨慎设置。
        </div>
        <div className="members-container">
          {userMerchants &&
            userMerchants
              .filter((um) => um.role === "admin")
              .map((um) => (
                <Member
                  key={um.id}
                  avatarUrl={getImageByIdWithSize(um.avatar, undefined, 240)}
                  name={um.name}
                  onRemoveClick={() => removeMember(um.id)}
                />
              ))}
          {inviteCode && (
            <CopyToClipboard
              text={
                WEB_ROOT +
                LOCAL_URL["ACCEPT_INVITING"] +
                `?code=${inviteCode.admin}`
              }
            >
              <div className="add-btn" onClick={copySuccess}>
                <IconPlus />
              </div>
            </CopyToClipboard>
          )}
        </div>
        <div className="title">设置操作员</div>
        <div className="desc">
          操作员仅可以操作订单管理，如确认订单金额，设置为已付款，设置为已发货，去掉订单等。
        </div>
        <div className="members-container">
          {userMerchants &&
            userMerchants
              .filter((um) => um.role === "operator")
              .map((um) => (
                <Member
                  key={um.id}
                  avatarUrl={getImageByIdWithSize(um.avatar, undefined, 240)}
                  name={um.name}
                  onRemoveClick={() => removeMember(um.id)}
                />
              ))}
          {inviteCode && (
            <CopyToClipboard
              text={
                WEB_ROOT +
                LOCAL_URL["ACCEPT_INVITING"] +
                `?code=${inviteCode.operator}`
              }
            >
              <div className="add-btn" onClick={copySuccess}>
                <IconPlus />
              </div>
            </CopyToClipboard>
          )}
        </div>
        {renderDialog()}
      </Root>
    </PageLayout>
  );
}
