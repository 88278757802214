import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {LOCAL_URL, THEME} from "../../config";
import {buildSitePageUrl, newMoment, paramsCache} from "../../lib";
import {useCountDownTimer} from "../../hooks";
import {EntityContext, UserContext} from "../../context";

const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;
const HEADER_HEIGHT = THEME.size.HEADER_BAR_HEIGHT;

const HeaderArea = styled.div`
  height: ${(props) => props.height || HEADER_HEIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
  }
`;

const BrandBlock = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  & span {
    transform: translateY(2px);
  }
  & span svg {
    width: 136px;
    height: 24px;
    fill: #fff;
  }
`;
const BarsContainer = styled.div`
  display: ${(props) => (props.anonymous ? "inline-block" : "none")};
  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    display: inline-block;
  }
`;

const Search = styled.div`
  & svg {
    width: 2em;
  }
`;

const LocationInfo = styled.div`
  border-radius: 1.5em;
  color: #fff;

  display: flex;
  align-items: center;

  position: relative;
`;
const IndicatorContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  & p {
    max-width: 8em;
    margin: 0 4px;
  }
  & svg {
    width: 16px;
    fill: #fff;
  }
`;

const PopTipContainer = styled.div`
  position: absolute;
  z-index: 99;
  bottom: 0;
  right: 0;
  width: auto;
  transform: translateY(calc(100% + 8px));

  padding: 4px 10px 4px 21px;
  border-radius: 4px;
  background-color: ${THEME.color.gray["#0-087"]};
  color: #fff;
  white-space: nowrap;

  &:after {
    position: absolute;
    top: 0;
    left: 60%;
    transform: translateY(-95%);
    content: "";
    height: 0px;
    width: 0px;
    border-bottom: solid 12px ${THEME.color.gray["#0-087"]};
    border-left: solid 12px transparent;
    border-right: solid 12px transparent;
  }
`;

const PopTip = ({ children }) => <PopTipContainer>{children}</PopTipContainer>;

export const Header = ({
  onBarsClick,
  onLogin,
  onRegister,
  height = "",
  hideLogo = false,
}) => {
  const calcStopMoment = () =>
    newMoment().add(5, "s").format("YYYY-MM-DD HH:mm:ss");

  const history = useHistory();
  const { model: entityModel } = useContext(EntityContext);
  const { model: userModel, isAnonymous } = useContext(UserContext);
  const { resetCountDownTimer, isTimeout } = useCountDownTimer(
    !isAnonymous ? calcStopMoment() : null
  );
  // const { size: waitListSize } = useWaitingList();
  const location = userModel?.location;
  const address =
    location?.address?.formatted || userModel?.location?.address?.textMain;
  const serviceAvailable =
    !!address &&
    (address.toLowerCase().indexOf("auckland") > -1 ||
      address.indexOf("奥克兰") > -1);

  // console.log(address, serviceAvailable)

  useEffect(() => {
    if (!isAnonymous) resetCountDownTimer(calcStopMoment());
  }, [isAnonymous]);

  return (
    <HeaderArea height={height}>
      <BrandBlock>
        {/* <BarsContainer anonymous={isAnonymous}>
          <BarsButton onClick={onBarsClick} >
            {!!waitListSize && <DotNumber className="white">{waitListSize}</DotNumber>}
          </BarsButton>
          <span>&nbsp;&nbsp;&nbsp;</span>
        </BarsContainer> */}
        {!hideLogo && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(buildSitePageUrl(LOCAL_URL.WELCOME, paramsCache));
            }}
          >
          </span>
        )}
      </BrandBlock>

      {/* { (!isAnonymous) ? 
        <LocationInfo className="debugx">
          { (!!isTimeout || !!serviceAvailable) ? null
            :
            <PopTip>
              <p className="ft-rr12" style={{lineHeight: '18px'}} dangerouslySetInnerHTML={{__html: 
                (!location) ? 'Select an address,<br/>and view nearby stores.' : 'Sorry, <br/>We’re not available here.'
              }} />
            </PopTip>
          }
        </LocationInfo>
        :
        <Button size="small" color="white" onClick={onRegister} >Register</Button>
      } */}
    </HeaderArea>
  );
};
