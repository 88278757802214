import styled from 'styled-components'

export const Button = styled.button`
  cursor: pointer;
  color: white;
  background-color: ${props => props.theme.color.primary.HEX};
  padding: .5rem;
  border-radius: 4px;
  border: none;
  white-space: nowrap;
  font-size: 15px;
  line-height: 21px;
  width: 100%;

  &.small {
    width: auto;
    font-size: 12px;
    line-height: 16px;
  }
`

export const SubmitButtonArea = styled.div`
  margin-top: 4rem;
  padding: .5rem 1rem;
  background: white;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);

  &.fix-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
  &:disabled {
    background-color:  ${props => props.theme.color.bgDisabled.HEX};
  }
`
