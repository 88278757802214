import React from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import confirm_ok from "../../svg/confirm_ok.svg";
import styled from "styled-components";
import WhiteSpace from "../../components/WhiteSpace";
import {useHistory} from "react-router-dom";
import {THEME, WECHAT_FOLLOW_URL} from "../../config";

function WaitingConfirmPage() {
  const history: any = useHistory();

  return (
    <PageLayout pageName="no nav orderConfirm">
      <Header title="下单成功"/>
      <div>
        <Container style={{alignItems: "center"}}>
          <WhiteSpace height={50}/>
          <img src={confirm_ok} alt=""/>
          <WhiteSpace height={24}/>
          <div className="title2">恭喜您下单成功</div>
          <WhiteSpace height={12}/>
          <div className="order-no">
            订单号：{history.location.state.no}
          </div>
          <WhiteSpace height={12}/>
          <div className="message">
            您的订单包含称重类商品，需要店主确认实际重量和实际支付金额再付款。
          </div>
          <WhiteSpace height={24}/>
          <div className="desc">
            所有的订单通知将通过微信公众号“新西兰神马网”发送，请关注公众号并留意消息哦。
            <a href={WECHAT_FOLLOW_URL} target="_blank">
              立即点击关注
            </a>
          </div>
        </Container>
        <WhiteSpace height={80}/>
        <Button2
          onClick={() => {
            history.replace("/");
          }}
        >
          返回团购接龙
        </Button2>
      </div>
    </PageLayout>
  );
}

export default WaitingConfirmPage;

const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .title {
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    width: 100%;
    text-align: center;
  }

  .title2 {
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  .order-no {
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    text-decoration: none;
  }

  .message {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: ${THEME.color.primary.default};
    text-align: center;
  }

  .desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
  }
`;

const Button2 = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  background: #ffffff;
  color: ${THEME.color.primary.default};
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;

  text-align: center;
`;
