import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  z-index: 9999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.4);
`
interface IModalMaskProps {
  visible?: boolean;
  onClick?: () => void;
}
export const ModalMask: React.FC<IModalMaskProps> = ({children, visible, onClick}) => {
  return (
    (visible === false) ? null :
    <Wrapper onClick={onClick}>
      { children }
    </Wrapper>
  )
}
