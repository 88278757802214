import {Cloudinary} from 'cloudinary-core';

const cl = new Cloudinary({cloud_name: 'shenmanz', secure: true});

const API_BASE_URL = 'https://api.cloudinary.com/v1_1/shenmanz/image/upload';
const IMAGE_BASE_URL = 'https://res.cloudinary.com/shenmanz/image/upload';

export function getImageById(imageId: string, crop?: string) {
  if (!imageId) return '';
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url(`${imageId}`, {
    crop: crop ? crop : 'lfill',
    gravity: 'auto',
    quality: 'auto',
    fetch_format: 'auto',
  });
}

export const getResizedSharedImage = (url: string) => {
  if (!url) {
    return null
  }
  const split1 = url.split('/upload/')
  const split2 = url.split('/v1/')
  return `${split1[0]}/upload/c_lfill,h_933,w_933/${split2[1]}`
}

export const getImageAvatar = imageId => {
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url(imageId, {
    width: 150,
    height: 150,
    crop: 'thumb',
    gravity: 'face',
    fetch_format: 'auto'
  });
};

export function getImageByIdWithSize(
  imageId: string,
  crop?: string,
  width?: number,
  height?: number,
) {
  if (!imageId) return '';
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url(`${imageId}`, {
    crop: crop ? crop : 'lfill',
    gravity: 'auto',
    quality: 'auto',
    fetch_format: 'auto',
    width,
    height,
  });
}

export async function uploadImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append("upload_preset", "tuangou");
  formData.append('cloud_name', 'shenmanz');
  const response = await fetch(API_BASE_URL, {
    method: "POST",
    body: formData
  });
  const {public_id} = JSON.parse(await response.text());
  return public_id;
}
