import { useState } from 'react'
import { useHistory } from "react-router-dom"
import mitt, {EVENT} from "../../utils/Event"
import { notify } from '../../components'
import { IMAGES_URL } from './data'
import { Wrapper, Button } from './styled'
import { InputField } from './components'
import { useWechatShareStore } from './store'

export const WechatShare = () => {
  const history = useHistory()
  const sdkResult = useWechatShareStore((state:any) => state.sdkResult)
  const setResult = useWechatShareStore((state:any) => state.setResult)

  const [title, setTitle] = useState<string>('分享测试标题')
  const [imgUrl, setImgUrl] = useState<string>(IMAGES_URL[0])
  const [link, setLink] = useState<string>(window.location.href) // ('https://tuan.shenmanz.com/private/testing/wechat/share')
  const [content, setContent] = useState<string>('这里是微信分享定制功能测试')

  const goBack = () => {
    history.go(-1)
  }
  const goHome = () => {
    history.push('/')
  }
  const onSet = () => {
    setResult('')

    console.log({
      title, imgUrl, link, content
    })

    mitt.emit(EVENT.SET_SHARE_CONTENT, {
      title,
      url: link,
      content: content,
      image: imgUrl,
    });
  }
  // console.log(history)
  // console.log(window.location)
  return (
    <Wrapper>
      <div className="action">
        <Button onClick={goBack}>Back</Button>
        <Button onClick={goHome}>Home</Button>
        <Button onClick={onSet}>Set</Button>
      </div>
      <div className='result debugx'>
        <Button onClick={() => setResult('')}>clear result</Button>
        <p className='debugx' style={{margin: 0, padding: 0, fontSize: '1.4rem', flex: 1}}>{sdkResult}</p>
      </div>
      <div className="fields">
        {/* <p style={{}}>{window.location.origin}</p>
        <p style={{}}>{history.location.pathname}</p>
        <p style={{}}>https://tuan.shenmanz.com/public/group-buy</p> */}
        <InputField label="Link"    name="link"     value={link}    onChange={(e) => setLink(e.target.value)} />
        <InputField label="Title"   name="title"    value={title}   onChange={(e) => setTitle(e.target.value)} />
        <InputField label="Content" name="contnet"  type="textarea" value={content} onChange={(e) => setContent(e.target.value)} />
        <InputField label="Image"   name="image"    value={imgUrl}  onChange={(e) => setImgUrl(e.target.value)} />
      </div>
      <div className="gallery">
      { IMAGES_URL.map((url:string, index:number) => (
        <img className={(url === imgUrl) ? 'selected shadow08':''} src={url} alt={'#' + index} key={index} onClick={() => {
          setImgUrl(url)
        }} />
      )) }
      </div>
    </Wrapper>
  )
}
