import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { getComponentWidth } from '../lib'
import { THEME } from '../config'
import { IconKBArrowL, IconKBArrowR } from './Icons';
import MyImageComponent from '../components/MyImageComponent'


const Container = styled.div`
  width: 100%;
  position: relative;
`;
const Window = styled.div`
  width: 100%;
  /* transition: all 2.6s; */
  scroll-behavior: smooth;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const List = styled.ul`
  width: ${props => `${(100 * props.size)}%`};
  display: inline-flex;
  list-style: none;
`;
const Item = styled.li`
  width: 100%;
  background-color: #eee;
  cursor: pointer;

  & img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    background-position: center;
  }
`;
const ArrowButton = styled.button`
  width: 16px;
  height: 16px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
  display: ${props => props.disabled ? 'none' : 'block'};

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.left {
    left: 16px;
  }
  &.right {
    right: 16px;
  }

  & svg {
    width: 24px;
    fill: rgba(255,255,255,0.6);
  }

`;
const Position = styled.ul`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);

  list-style: none;
  display: inline-flex;
  gap: 8px;
`;
const Dot = styled.li`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;

  &.active {
    background-color: white;
  }
`;

interface SlideDT {
  img: string;
  title?: string;
  comments?: string;
}
interface CarouselProps {
  onClick?: (slide: SlideDT) => void;
  items: Array<SlideDT>;
  delay?: number;
  noArrow?: boolean;

  children?: any;
}

export const Carousel2: React.FC<CarouselProps> = ({onClick, delay, items, noArrow, children}) => {
  const [pos, setPos] = useState({pos: 0})
  const refWin = useRef(null)
  const debounceTimerRef = useRef(null)
  const autoPlayTimerRef = useRef(null)
  const [counter, setCounter] = useState(0)

  const listSize = items?.length

  const onScroll = (e) => {
    if (!!debounceTimerRef.current) window.clearTimeout(debounceTimerRef.current)
    debounceTimerRef.current = window.setTimeout(() => {
      const offset  = e?.target?.scrollLeft
      const itemSize= getComponentWidth(refWin?.current)
      const bounce  = (100 * (offset % itemSize)) / itemSize 
      const newPos  = Math.round(offset/itemSize)
      // console.log('bounce', bounce)
      // console.log('cur pos', pos.pos)
      // console.log('new pos', newPos)
      // console.log('scroll', newPos)
      setPos({pos: newPos})
    }, 200)
  }

  const gotoSlide = (dir, disableRestart=false) => {
    // console.log('goto slide dir', dir, pos.pos)
    const newPos = pos.pos + dir
    if (!!disableRestart) {
      if (
        (newPos < 0) ||
        (newPos >= listSize )
      ) return;
    }
    else if (newPos < 0) setPos({pos: (listSize - 1)})
    else if (newPos >= listSize) setPos({pos: 0})
    else setPos({pos: newPos})
  }

  useEffect(() => {
    // console.log('pos effect', pos.pos)
    const itemSize= getComponentWidth(refWin?.current);
    refWin.current.scrollLeft = pos.pos * itemSize;
  }, [pos])
  useEffect(() => {
    // console.log('counter effect', counter)
    gotoSlide(1)
  }, [counter])

  useEffect(() => {
    if (listSize > 1) {
      autoPlayTimerRef.current = window.setInterval(() => {
        // console.log('interval')
        setCounter((c) => (c+1))
      }, delay || 3000)
    }

    return(() => {
      if (!!autoPlayTimerRef.current) window.clearInterval(autoPlayTimerRef.current)
      if (!!debounceTimerRef.current) window.clearTimeout(debounceTimerRef.current)
    })
  }, [])

  // console.log('#######render############')
  // console.log('pos', pos.pos)
  // console.log('counter', counter)
  // console.log('#######render############')
  return (
    <Container className="debugx">
      <Window ref={refWin} onScroll={onScroll} className="debugx">
        <List size={listSize}>
          {items?.map((item, index) => (
            <Item
              key={index}
              className="flex-center align-center"
              onClick={() => onClick?.(item)}
            >
              {/* <img src={item.img} loading="lazy" alt={`image #${index}`} /> */}
              <MyImageComponent
                src={item.img}
                loaderStyle={{position: 'relative', height: 160}}
              />
            </Item>
          ))}
        </List>
      </Window>

      {listSize > 1 && !noArrow && (
        <>
          <ArrowButton
            disabled={pos.pos === 0}
            onClick={() => gotoSlide(-1)}
            className="left"
          >
            <IconKBArrowL />
          </ArrowButton>
          <ArrowButton
            disabled={pos.pos === listSize - 1}
            onClick={() => gotoSlide(1)}
            className="right"
          >
            <IconKBArrowR />
          </ArrowButton>
        </>
      )}
      {listSize > 1 && (
        <Position>
          {items?.map((item, index) => (
            <Dot
              key={index}
              className={index === pos.pos ? "active" : ""}
              onClick={() => setPos({ pos: index })}
            />
          ))}
        </Position>
      )}

      {children}
    </Container>
  );
}
