import * as React from "react";
import styled from "styled-components";

export interface IWechatShareGuideProps {
  visible?: boolean;
  onOkClick?: () => void;
}

const Root = styled.div`
  position: fixed;
  z-index: ${(props) => props.level || 999999999};
  height: ${(props) => props.height || "100%"};
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  align-items: center;

  .arrow {
    /* position: absolute;
    top: 0;
    right: 16px; */
    align-self: flex-end;
    margin-right: 16px;
    margin-top: 4px;
  }
  .title {
  }
  .share-choose {
    margin-top: 16px;
  }
  .btn-ok {
    margin-top: 46px;
  }
`;

export default function WechatShareGuide({
  visible,
  onOkClick,
}: IWechatShareGuideProps) {
  return visible ? (
    <Root>
      <img className="arrow" src="/static/guide_arrow_right.svg" />
      <img className="title" src="/static/guide_wechat_title.svg" />
      <img className="share-choose" src="/static/guide_wechat_share.svg" />
      <img
        onClick={onOkClick}
        className="btn-ok"
        src="/static/guide_btn_ok.svg"
      />
    </Root>
  ) : null;
}
