import styled from 'styled-components'
import {LOCAL_URL} from "../../config";
import { Button } from '../../Layout'
import {getImageAvatar} from "../../utils/ImageUtils";
import {useHistory} from "react-router-dom";

const Wrapper = styled.div`
  padding: 1.2rem 1.5rem 1.2rem;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  background-color: white;
  color: rgba(0,0,0,0.85);

  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

`

export const MerchantInfoCard = ({info}) => {
  const history = useHistory()
  return (
    !info ? null :
    <Wrapper className='flex-between align-center' style={{gap: '12px'}}>
      <div className='flex-start align-center' style={{gap: '.75rem'}}>
        <img src={getImageAvatar(info?.logo)} alt="logo" />
        <p>{info?.name}</p>
      </div>
      <Button className="small"
        onClick={()=>{
          history.push(LOCAL_URL["MERCHANT_DETAIL"] + `?id=${info.id}`)
        }}
      >进入店铺</Button>
    </Wrapper>
  )
}
