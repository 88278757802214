import React from "react";
import styled from "styled-components";
import {THEME} from "../../../config";

export type DeliverType = 'PICKUP' | 'DELIVERY'

type SwitcherProps = {
  current: DeliverType,
  onChange:(current: DeliverType)=>void
}

function Switcher(props:SwitcherProps){

  return(
    <Container>
      <div
        className={`content ${props.current === 'DELIVERY' ? 'selected' : ''}`}
        onClick={()=>{
          if (props.onChange){
            props.onChange("DELIVERY")
          }
        }}
      >配送
      </div>
      <div
        className={`content ${props.current === 'PICKUP' ? 'selected' : ''}`}
        onClick={()=>{
          if (props.onChange){
            props.onChange("PICKUP")
          }
        }}
      >

        自提
      </div>
    </Container>
  )
}

const Container = styled.div`
  background-color: rgb(245,245,245);
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 44px;
  border-radius: 22px;
  overflow: hidden;
  padding-left: 2px;
  padding-right: 2px;
  .content{
    margin-top: 2px;
    flex: 1;
    font-size: 17px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }
  
  .selected{
    background-color: white;
    color: #0CB845;
    border-radius: 22px;
    border: 0.5px solid rgba(0, 0, 0, 0.09);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  }
`

export default Switcher
