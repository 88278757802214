import {getImageAvatar} from "../../utils/ImageUtils";

type UserDataType = {
  id:number;
  name:string;
  avatar:string;
  [propName:string]:any;
}

export function checkAnonymousViewData(isAnonymous:boolean, user:UserDataType) {
  const showAsAnonymous = (!!isAnonymous)
  const userIdOffset = (user.id ?? 0) % 8  // there are 8 images for anonymous users
  const avatarLink = showAsAnonymous ?
    `https://res.cloudinary.com/shenmanz/image/upload/v1682648654/tuangou/icon/anonymous-avatar-${userIdOffset}.png`
    :
    !!user.avatar ? getImageAvatar(user.avatar) : ''
    ;
  const userName = showAsAnonymous ? `大吉用户**${userIdOffset}` : (user.name || '')

  return {
    avatarLink, userName
  }
}
