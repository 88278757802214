import Checkbox from '@mui/material/Checkbox';
import SettingsIcon from '@mui/icons-material/Settings';
import {Backdrop, CircularProgress, Tab, Tabs} from "@mui/material";
import numeral from "numeral";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {Button, Input} from "../../components";
import EmptyView from "../../components/EmptyView";
import Header, {FloatBottom} from "../../components/Header";
import IconAdd from "../../components/Icons/IconAdd";
import IconAddBatch from "../../components/Icons/IconAddBatch";
import {LOCAL_URL, THEME} from "../../config";
import {EntityContext} from "../../context";
import {Check} from "../../forms/Check";
import {useDialog} from "../../hooks";
import useMerchant from "../../hooks/useMerchant";
import {requestPost} from "../../lib";
import iconEdit from "../../svg/icon_edit.svg";
import iconRubbishBin from "../../svg/icon_rubbish.svg";
import {randomString} from "../../utils/common";
import {getImageByIdWithSize} from "../../utils/ImageUtils";
import {DEFAULT_CATEGORY_NAME} from "../Category/groupCategory";
import AnimatedList from "../CreateGroupBuy/Components/AnimationList";
import {easeInOutQuint} from "../CreateGroupBuy/detail";
import PageLayout from "../Global/PageLayout";
import {BatchImport} from './BatchImport';

export interface IProductTemplatesProps {
}

const AddProductButton = styled.div`
  display: flex;
  padding-right: 16px;

  .text {
    color: #fff;
    margin-left: 6px;
  }
`;

const StyledProductCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 16px 16px 0px 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);

  .prod-img {
    width: 80px;
    height: 80px;
    margin-right: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .title {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.gray["#10-085"]};
      flex-grow: 1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .spec {
      font-size: 12px;
      line-height: 17px;
      color: ${THEME.color.gray["#12-045"]};
    }

    .desc {
      font-size: 12px;
      line-height: 17px;
      color: ${THEME.color.gray["#12-045"]};
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .price {
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: ${THEME.color.primary.default};
    }

    .priceCNY {
      font-size: 14px;
      line-height: 17px;
      color: ${THEME.color.gray["#12-045"]};
    }

    .orgin-price {
      font-size: 12px;
      line-height: 17px;
      text-decoration-line: line-through;
      margin-left: 6px;
      color: ${THEME.color.gray["#12-045"]};
    }
  }

  .stock-info {
    /* position: absolute;
      right: 8px;
      top: 0; */
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .flex-row-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .flex-row-x {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .op-btn {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
    border-radius: 4px;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 2px 0 2px;
    box-sizing: border-box;
    padding: 1px 8px;
    line-height: 16px;
    font-size: initial;

    span {
      font-size: 10px;
    }
  }
`;

export const ProductCard = (props) => {
  const history = useHistory();
  const {isCurrentMerchantSupportCNY} = useMerchant();

  const {
    product,
    onClick,
    onDeleteClick,
    selected,
    showEditContainer,
    selectMode,
    showOpContainer,
    onRemoveFromGroup,
    onEditClick,
    onCopyClick,
    gpId,
    idx,
    size,
    onSwap,
    soldQuantity,
    available,
    dialogHook,
  } = props;
  const {
    id,
    title,
    description,
    images,
    price,
    originalPrice,
    priceCNY,
    defaultStock,
    specification,
  } = product;

  const {showDialog, hideDialog} = dialogHook || {};
  return (
    <StyledProductCard
      onClick={() => {
        if (!showEditContainer) {
          return;
        }
        onClick(id);
      }}
    >
      <div className="flex-row-x">
        {images && images.length > 0 && (
          <img
            className="prod-img"
            src={getImageByIdWithSize(images[0], undefined, 240, 240)}
          />
        )}
        <div className="content">
          <div className="flex-row">
            <span className="title">{title}</span>
            {selectMode && <Check value={selected} onChange={() => {
            }}/>}
          </div>
          <div className="spec">{specification}</div>
          <div className="desc">{description}</div>
          <div className="flex-row">
            <div>
              {price ? (
                <span className="price">
                {numeral(price).divide(100).format("$0,0.00")}
              </span>
              ) : null}
              {!!originalPrice && (
                <span className="orgin-price">
                  {numeral(originalPrice).divide(100).format("$0,0.00")}
                </span>
              )}
              {isCurrentMerchantSupportCNY() && !!priceCNY && (
                <div>
                  <span className="priceCNY">
                    ￥{numeral(priceCNY).divide(100).format("0,0.00")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="stock-info">
          {soldQuantity ? <div>已售{soldQuantity}</div> : null}
          {available > -1 && (
            <div style={{marginTop: 3}}>可售{available}</div>
          )}
          {available === -1 && <div style={{marginTop: 3}}>可售 不限</div>}
        </div>
      </div>
      {showEditContainer && (
        <div className="flex-row-right">
          <img
            src={iconEdit}
            onClick={() => {
              history.push(LOCAL_URL["CREATE_PRODUCT"], {product, from: 'template'});
            }}
          />
          <div style={{marginLeft: 8}}/>
          <img
            src={iconRubbishBin}
            onClick={() => {
              showDialog?.({
                title: "确定删除?",
                cancelText: "取消",
                okText: "确定",
                onOK: () => {
                  hideDialog?.();
                  onDeleteClick(id);
                },
              });
            }}
          />
        </div>
      )}
      {showOpContainer && (
        <div className="flex-row-end">
          <div className="op-btn" onClick={() => onEditClick(gpId)}>
            <span>编辑</span>
          </div>
          <div className="op-btn" onClick={() => onCopyClick(gpId)}>
            <span> 复制</span>
          </div>
          {idx > 0 && (
            <div
              className="op-btn"
              onClick={() => {
                onSwap(idx, idx - 1);
              }}
            >
              <span>上移</span>
            </div>
          )}
          {idx < size - 1 && (
            <div
              className="op-btn"
              onClick={() => {
                onSwap(idx, idx + 1);
              }}
            >
              <span>下移</span>
            </div>
          )}
          <div
            className="op-btn"
            onClick={() => {
              showDialog?.({
                title: "确定删除?",
                cancelText: "取消",
                okText: "确定",
                onOK: () => {
                  hideDialog?.();
                  onRemoveFromGroup(gpId);
                },
              });
            }}
          >
            <span>删除</span>
          </div>
        </div>
      )}
    </StyledProductCard>
  );
};

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const EmptyContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 56px);
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
`;

export default function ProductTemplates(props: IProductTemplatesProps) {
  const history = useHistory<any>();
  const from = history?.location?.state?.from ?? 'GROUP';
  const selectMode = history.location?.state?.selectMode;
  const [currentTab, setCurrentTab] = useState<any>(0)
  const listRef = useRef<any>()
  const [scrollToItem, setScrollToItem] = useState(0)
  const [scrollingAnimation, setScrollingAnimation] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [showLoading, setShowLoading] = useState(false)
  const dialogHook = useDialog();
  const {renderDialog, showDialog, hideDialog} = dialogHook || {};
  const {
    model: entityModel,
    query,
    remove,
    replace,
  } = React.useContext(EntityContext);

  const [showedCategory, setShowedCategory] = useState<any[]>([])
  const [showedProductsList, setShowedProductsList] = useState<any[]>([])
  const [showBatchImport, setShowBatchImport] = useState(false)

  const productionTemplateData =
    entityModel["PRODUCTION_TEMPLATE"].response?.data?.products;
  const category =
    entityModel["PRODUCTION_TEMPLATE"].response?.data?.category;
  let groupToProductions =
    entityModel["GROUP_BUY"].response?.groupToProductions;
  let groupBuyData =
    entityModel["GROUP_BUY"].response
  //const productions = groupToProductions?.map((gp) => gp.productionTemplate);

  const [selectedProductIds, setSelectedProductIds] = React.useState<Set<Number>>(new Set([]));

  const {currentMerchantId} = useMerchant();

  const initial = () => {
    if (currentMerchantId) {
      query("PRODUCTION_TEMPLATE", {id: currentMerchantId});
    }
  }
  React.useEffect(() => {
    initial()
  }, [currentMerchantId]);

  const updateGroupBuyData = () => {
    //const existsPId = productions.map((p) => p.id);
    const category = entityModel["GROUP_BUY"].response?.category ?? []
    const filteredCategory = category.filter(item => item.title !== DEFAULT_CATEGORY_NAME)
    replace("GROUP_BUY", {
      category: filteredCategory,
      groupToProductions: [...groupToProductions, ...getSelectProducts()],
    });
  };

  const getSelectProducts = () => {
    if (!selectedProductIds || selectedProductIds.size === 0){
      return []
    }
    return Array.from(selectedProductIds).map((pId) => {
      const productionTemplate = productionTemplateData.filter(
        (pt) => pt.id === pId
      )[0];

      if (productionTemplate.category) {
        for (const _cate of productionTemplate.category) {
          if (category.filter(item => item.title === _cate.title).length === 0) {
            category.push(_cate)
          }
        }
      }
      return {
        id: randomString(32),
        productionTemplate,
        available: productionTemplate.defaultStock,
        category: productionTemplate.category
      };
    });
  }

  useEffect(() => {
    let lastCount = 0
    const categoryProducts = []
    // 将所有产品，按照分类重新排序
    const newCategory = []
    if (productionTemplateData) {
      const filteredData = productionTemplateData.filter(item => item.title.includes(searchText))
      for (const cate of category) {
        const containProducts = []
        for (const product of filteredData) {
          if (product.category) {
            if (product.category.filter(cateItem => cateItem.title == cate.title).length > 0) {
              containProducts.push(product)
            }
          }
        }
        cate.count = containProducts.length
        cate.lastCount = lastCount
        lastCount += containProducts.length
        categoryProducts.push(...containProducts)
        newCategory.push(cate)
      }
      // 处理未分类产品
      const unCategoryProds = []
      for (const product of filteredData) {
        if (!product.category || product.category.length === 0) {
          unCategoryProds.push(product)
        }
      }
      if (unCategoryProds.length > 0) {
        const cate: any = {
          title: DEFAULT_CATEGORY_NAME,
          index: -1
        }
        cate.count = unCategoryProds.length
        cate.lastCount = lastCount
        lastCount += unCategoryProds.length
        categoryProducts.push(...unCategoryProds)
        newCategory.push(cate)
      }
    }
    setShowedCategory(newCategory)
    setShowedProductsList(categoryProducts)
  }, [productionTemplateData, category, searchText])

  const renderProductCard = ({index, style}) => {
    const product = showedProductsList[index]
    return (
      <div key={product.id} style={style}>
        <ProductCard
          key={product.id}
          product={product}
          selectMode={selectMode}
          showEditContainer={true}
          // address={address}
          selected={selectedProductIds.has(product.id)}
          onClick={(id) => {
            const newSet = new Set(selectedProductIds);
            if (selectedProductIds.has(id)) {
              newSet.delete(id);
            } else {
              newSet.add(id);
            }
            setSelectedProductIds(newSet);
          }}
          onDeleteClick={async (id) => {
            if (selectedProductIds.has(id)) {
              const newSet = new Set(selectedProductIds);
              newSet.delete(id);
              setSelectedProductIds(newSet);
            }
            await remove("PRODUCTION_TEMPLATE", {id});
            query("PRODUCTION_TEMPLATE", {id: currentMerchantId});
          }}
          dialogHook={dialogHook}
        />
      </div>
    )
  }
  return (
    <PageLayout pageName="productTemplate">
      <Header
        right={selectMode ? (
          <div>
            {from === 'STORE' ? (
              <div style={{
                color: "white",
                marginRight: 32,
                display: "flex",
                flexDirection: 'row',
                alignItems: 'center'
              }}>
                <Checkbox
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      const ids = showedProductsList?.map(item => item.id)
                      setSelectedProductIds(new Set(ids))
                    } else {
                      setSelectedProductIds(new Set())
                    }
                  }}
                  style={{
                    color: 'white'
                  }}
                  checked={selectedProductIds.size === showedProductsList.length}
                />全选
              </div>
            ) : null}
          </div>
        ) : (
          <div className='flex-end'>
            <AddProductButton
              onClick={() => {
                console.log('show')
                setShowBatchImport(true)
              }}
            >
              <IconAddBatch/>
              <span className="text">批量创建</span>
            </AddProductButton>
            <AddProductButton
              onClick={() => history.push(LOCAL_URL["CREATE_PRODUCT"], {
                from: 'template',
              })}
            >
              <IconAdd/>
              <span className="text">添加新商品</span>
            </AddProductButton>
          </div>
        )}
      />
      <SearchAreaContainer>
        <Input
          className='search'
          size="small"
          placeholder='搜索商品名称'
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value)
          }}
        />
        <div className='setting'
             onClick={() => {
               //  history.push(LOCAL_URL["MERCHANT_CATEGORY"] + `?merchantId=${currentMerchantId}`)
               history.push(LOCAL_URL["PRODUCT_CATEGORY_LIST"])
             }}
        >
          <SettingsIcon fontFamily='small'/>
          管理分类
        </div>
      </SearchAreaContainer>
      {showedCategory?.length > 1 || (showedCategory?.length === 1 && showedCategory[0].title !== DEFAULT_CATEGORY_NAME) ? (
        <div
          style={{
            flex: 1,
            width: '100%',
            backgroundColor: 'white'
          }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={(e, c) => {
              setCurrentTab(c)
              let index: number = 0
              for (let i = 0; i < c; i++) {
                index += showedCategory[i].count
              }
              if (listRef.current && !scrollingAnimation) {
                setScrollToItem(index)
                setScrollingAnimation(true)
                setTimeout(() => {
                  setScrollingAnimation(false)
                }, 1000)
              }
            }}
          >
            {showedCategory.map(item => {
              return (
                <Tab style={{minWidth: 'inherit', paddingLeft: 16, paddingRight: 16}} label={item.title}
                     key={item.title}/>
              )
            })}
          </Tabs>
        </div>
      ) : null}
      {showedProductsList && showedProductsList.length > 0 ? (
        <AnimatedList
          ref={listRef}
          itemCount={showedProductsList.length}
          itemSize={(index) => {
            return 120
          }}
          duration={1000}
          data={showedProductsList}
          easing={easeInOutQuint}
          onAnimationComplete={() => {
            setScrollingAnimation(false)
          }}
          scrollToItem={scrollToItem}
          height={window.screen.height - 162}
          useIsScrolling
          onScroll={(scrollOffset) => {
            if (showedCategory && showedCategory.length > 0 && !scrollingAnimation) {
              const itemHeight = 100
              const itemCount = numeral(scrollOffset).divide(itemHeight).value()
              showedCategory.forEach((item, index) => {
                if (itemCount >= item.lastCount && itemCount <= (item.count + item.lastCount)) {
                  if (currentTab !== index) {
                    setCurrentTab(index)
                  }
                }
              })
            }
          }}
        >
          {renderProductCard}
        </AnimatedList>
      ) : (
        <EmptyContainer>
          <EmptyView desc="还没有添加过商品"/>
          <div style={{height: 72}}/>
          <Button onClick={() => history.push(LOCAL_URL["CREATE_PRODUCT"], {
            from: 'template',
          })}>
            添加商品
          </Button>
        </EmptyContainer>
      )}
      {selectMode &&
        productionTemplateData &&
        productionTemplateData.length > 0 && (
          <FloatBottom>
            <Button
              fullWidth
              size="large"
              color="red"
              onClick={async () => {
                if (from === 'GROUP') {
                  updateGroupBuyData();
                  history.replace(LOCAL_URL["CREATE_GROUP_BUY"], {
                    products: Array.from(selectedProductIds),
                  });
                } else if (from === 'STORE') {
                  const category = entityModel["GROUP_BUY"].response?.category ?? []
                  const filteredCategory = category.filter(item => item.title !== DEFAULT_CATEGORY_NAME)
                  if (selectedProductIds.size > 20) {
                    showDialog?.({
                      title: "选择的商品过多，预计需要较多处理时间",
                      cancelText: "取消",
                      okText: "确定",
                      onOK: async () => {
                        hideDialog?.();
                        setShowLoading(true)
                        const {data} = await requestPost('merchant/shoppingMall/addProducts', {
                          groupId: groupBuyData.id,
                          products: getSelectProducts()
                        })
                        setShowLoading(false)
                        replace("GROUP_BUY", {
                          category: filteredCategory,
                          groupToProductions: [...groupToProductions, ...data],
                        });
                        history.goBack()
                      },
                    })
                  } else {
                    setShowLoading(true)
                    const {data} = await requestPost('merchant/shoppingMall/addProducts', {
                      groupId: groupBuyData.id,
                      products: getSelectProducts()
                    })
                    replace("GROUP_BUY", {
                      category: filteredCategory,
                      groupToProductions: [...groupToProductions, ...data],
                    });
                    setShowLoading(false)
                    history.goBack()
                  }
                }
              }}
            >
              确定
            </Button>
          </FloatBottom>
        )}
      {renderDialog?.()}
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={showLoading}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>

      <BatchImport visible={showBatchImport} onClose={() => {
        initial()
        setShowBatchImport(false)
      }}/>

    </PageLayout>
  );
}

export const SearchAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 16px;
  justify-items: center;
  background-color: white;

  .search {
    flex: 1;
    margin: 8px 0;
  }

  .setting {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: ${THEME.color.gray["#12-045"]};
    font-size: 14px;
  }
`
