import React from 'react';
import styled from 'styled-components';
import {LOCAL_URL, WEB_ROOT} from "../config";
import {QRCodeSVG} from "qrcode.react";

const Container = styled.div`
  margin-top: 32px;
  padding: 16px;
  line-height: 22px;
  width: 100%;
  font-size: 15px;
  p{
    margin-top: 16px;
  }
  .qr{
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;
export default function () {
  return (
    <Container>
      <p>尊敬的用户，</p>
      <p>您好，本站暂时仅支持在微信内使用，请您搜索“新西兰神马网”公众号，或使用微信扫描以下二维码使用。谢谢！</p>
      <div className='qr'>
        <div>
          <QRCodeSVG
            size={120}
            value={'https://tuan.shenmanz.com/'}
          />
        </div>

      </div>

    </Container>
  )
}
