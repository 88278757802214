import styled from "styled-components";
import { THEME } from '../../config'
import {getImageByIdWithSize} from "../../utils/ImageUtils";
import { IProductCardProps } from './ProductCard.type';
import { AddToCartButton } from './ProductCard';
import { ListPrice } from './ProductCard.Price'

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 16px;
`

const ImageBlock = styled.div`
  flex: 1;
  
  & img {
    border: 1px solid rgba(0,0,0,0.09);
    border-radius: 8px;
    width: 100%;
  }
`
const TextBlock = styled.div`
  flex: 2;

  & p {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: rgba(0,0,0,0.45);
  }
  & p.title {
    margin-top: 0;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: rgba(0,0,0,0.85);
  }
`
const ActionArea = styled.div`
`

export const ProductCardNormal: React.FC<IProductCardProps> = ({
  title, price, originalPrice,
  images, specification, description,
  soldQuantity, available,
  primaryColor,
  mAmount,
  maxOrderQuantity, quotaUsed,
  onAdd, onRemove,
  setShowImageDialog,
}) => {
  const noMoreQuota = (!!maxOrderQuantity) && ((mAmount + (quotaUsed || 0)) >= maxOrderQuantity);
  return (
    <Wrapper>
      { !!images?.length && (
        <ImageBlock>
          <img
            onClick={() => setShowImageDialog(true)}
            className="prod-img"
            src={getImageByIdWithSize(images[0], undefined, 240, 240)}
          />
        </ImageBlock>
      )}

      <TextBlock>
        <p className="title">{title}</p>
        <p className="">{specification}</p>
        <p className="">{description}</p>
        <p className="flex-between">
          <span>
            { !!soldQuantity &&       <span style={{marginRight: '8px'}}>已售{soldQuantity}</span> }
            { ( available > -1) &&    <span>库存{available}</span> }
          </span>
          { (!!maxOrderQuantity) &&   <span>限购{maxOrderQuantity}份</span> }
        </p>

        <div className="flex-between debugx" style={{marginTop: '16px'}}>

          <ListPrice price={price} originalPrice={originalPrice} />

          { (available > 0 || available === -1) ?
            <ActionArea>
              <AddToCartButton
                noMoreQuota={noMoreQuota}
                onRemove={onRemove}
                onAdd={onAdd}
                amount={mAmount}
                primaryColor={primaryColor}
                available={available === -1 ? 99999999 : available}
              />
            </ActionArea>
            :
            <p className="soldout">售罄</p>
          }

        </div>

      </TextBlock>

    </Wrapper>
  );
};
