import { Box } from './common'

export const Color = () => (
  <div style={{fontSize: '.8rem', color: '#fff'}}>
    <Box className="clr-bg-primary">
      <p>Primary</p>
      <p>#CC1543</p>
    </Box>

    <Box className="clr-bg-primary-variant">
      <p>Primary Variant</p>
      <p>#CC1543 75%</p>
    </Box>
    <Box className="clr-bg-secondary">
      <p>Secondary</p>
      <p>#D6B294</p>
    </Box>
    <Box className="clr-bg-gray7-f5" style={{color: '#000'}}>
      <p>Background Gray</p>
      <p>#F5F5F5</p>
    </Box>
    <Box className="clr-bg-gray0-087">
      <p>Black 87%</p>
      <p>#000 87%</p>
    </Box>
    <Box className="clr-bg-gray1-32">
      <p>Gray 1</p>
      <p>#323232</p>
    </Box>
    <Box className="clr-bg-gray2-48">
      <p>Gray 2</p>
      <p>#484848</p>
    </Box>
    <Box className="clr-bg-gray3-76">
      <p>Gray 3</p>
      <p>#767676</p>
    </Box>
    <Box className="clr-bg-gray4-97">
      <p>Gray 4</p>
      <p>#979797</p>
    </Box>
    <Box className="clr-bg-gray5-025">
      <p>Gray 5</p>
      <p>#000 25%</p>
    </Box>
    <Box className="clr-bg-gray6-d9">
      <p>Gray 6</p>
      <p>#D9D9D9</p>
    </Box>
    <Box className="clr-bg-green">
      <p>Green</p>
      <p>#53A35D</p>
    </Box>
    <Box className="clr-bg-yellow">
      <p>Yellow</p>
      <p>#EBA33D</p>
    </Box>
    <Box className="clr-bg-blue">
      <p>Blue</p>
      <p>#2E7CF6</p>
    </Box>

  </div>
)
