import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components';
import Header from '../../components/Header';
import { LOCAL_URL } from '../../config';
import { EntityContext } from '../../context';
import { useDialog } from '../../hooks';
import { getParams } from '../../lib';
import PageLayout from '../Global/PageLayout';

export interface IAcceptInvitingProps {
}

export default function AcceptInviting(props: IAcceptInvitingProps) {

    const { model: entityModel, update, query } = React.useContext(EntityContext);

    const history = useHistory();
    const { code } = getParams(history.location.search);
    const { renderDialog, showDialog } = useDialog();

    const inviteMembersData = entityModel['INVITE_MEMBERS']?.response?.data;

    const acceptInviting = async () => {
        const resp = await update('ACCEPT-INVITING', { code });
        if (resp?.userId) {
            showDialog({ title: `您已成为${inviteMembersData?.merchantName}${inviteMembersData?.inviteMember.role === 'admin' ? '管理员' : '操作员'}`, message: '您可以可以替团长完成开团，编辑，订单管理等操作', onOK: () => history.replace(LOCAL_URL["WELCOME"]) });
        }
    }

    const queryAcceptInviting = async (code) => {
        await query("INVITE_MEMBERS", { code });
    }

    React.useEffect(() => {
        if (code) {
            // acceptInviting(code);
            queryAcceptInviting(code);
        }
    }, [code]);

    return (
        <PageLayout pageName='acceptInviting'>
            <Header title='接受邀请' />
            {inviteMembersData && <>
                <div style={{ textAlign: 'center', marginTop: 16 }}>{inviteMembersData.merchantName}邀请您成为{inviteMembersData.inviteMember.role === 'admin' ? '管理员' : '操作员'}</div>
                <div style={{ margin: 16 }}>您可以可以替团长完成开团，编辑，订单管理等操作，请接受并授权微信登录完成设置</div>
                <div style={{ margin: 16 }}>
                    <Button fullWidth size="large" color="red" onClick={() => {
                        acceptInviting();
                    }}>接受邀请</Button>
                </div>
            </>}
            {renderDialog()}
        </PageLayout>
    );
}
