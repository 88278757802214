import * as React from "react";
import { EntityContext } from "../../context";
import PageLayout from "../Global/PageLayout";
import Header from "../../components/Header";
import OrderCard from "../Manage/components/OrderCard";

import EmptyView, { EmptyContainer } from "../../components/EmptyView";

export interface IMyOrdersProps {}

export default function MyOrders(props: IMyOrdersProps) {
  const { model: entityModel, query } = React.useContext(EntityContext);
  const orders = entityModel["MY_ORDERS"].response?.data;

  React.useEffect(() => {
    query("MY_ORDERS");
  }, []);

  return (
    <PageLayout pageName="no nav">
      <Header title="我的订单" />
      {orders && orders.length > 0 ? (
        orders.map((o, index) => (
          <OrderCard
            isMine
            type="USER"
            actions={[]}
            onRefresh={() => {
              query("MY_ORDERS");
            }}
            group={o.group}
            merchant={o.merchant}
            key={o.id}
            order={o}
          />
        ))
      ) : (
        <EmptyContainer>
          <EmptyView desc="还没有订单记录" />
        </EmptyContainer>
      )}
    </PageLayout>
  );
}
