const IconPhone = (props:{color?: string}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill={props.color ?? "#CC1543"} />
    <path
      d="M17.3333 6.66669H6.66667V8.00002H17.3333V6.66669ZM18 13.3334V12L17.3333 8.66669H6.66667L6 12V13.3334H6.66667V17.3334H13.3333V13.3334H16V17.3334H17.3333V13.3334H18ZM12 16H8V13.3334H12V16Z"
      fill="white"
    />
  </svg>
);
export default IconPhone;
