import {yupResolver} from "@hookform/resolvers/yup";
import {Backdrop, Button, CircularProgress} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import {Input, notify} from "../../components";
import Header, {FloatBottom} from "../../components/Header";
import WhiteSpace from "../../components/WhiteSpace";
import {LOCAL_URL, THEME} from "../../config";
import {EntityContext} from "../../context";
import useMerchant from "../../hooks/useMerchant";
import {requestPost} from "../../lib";
import iconAdd from "../../svg/icon_add.svg";
import {DEFAULT_CATEGORY_NAME} from "../Category/groupCategory";
import PageLayout from "../Global/PageLayout";
import {ProductCard, SearchAreaContainer} from "../Products/productTemplates";
import {useDialog} from "../../hooks";

export interface ICreateGroupBuyProps {
}

const StyledForm = styled.form`
  padding: 8px;

  .tips-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .tips {
    text-align: center;
    margin-bottom: 24px;
  }

  a {
    margin: 0px 8px 0px 8px;
  }

  .flex-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.primary.default};
    }
  }
`;
const Divider = styled.div`
  height: 8px;
`;
const AddNewProduct = styled.div`
  background-color: #fff;
  height: 64px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.primary.default};

  div {
    margin-left: 8px;
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  .title {
    font-size: 15px;
    color: ${THEME.color.gray["#10-085"]};
  }

  .title .high-light {
    color: ${THEME.color.primary.default};
  }

  .sub-title {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 4px;
  }

  .switch {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .radio {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin: 0.5em 0 0.25em;
`;

const schema = yup.object().shape({
  title: yup.string().required("拼团名称不能为空"),
  phone: yup.string().required("手机号不能为空"),
  groupToProductions: yup.array().min(1, "至少选择一个商品"),
  deliveryNotes: yup.string().when("canDelivery", {
    is: true,
    then: (schema) => schema.required("配送说明不能为空"),
  }),
  pickupAddresses: yup.array().when("canPickup", {
    is: true,
    then: (schema) => schema.min(1, "至少选择一个自提地址"),
    otherwise: (schema) => schema.min(0),
  }),
  canDelivery: yup.bool().when("canPickup", {
    is: false,
    then: (schema) => schema.isTrue("至少选择一种配送方式"),
    //otherwise: (schema) => schema.isFalse("至少选择一种配送方式"),
  }),
  bankAccount: yup.string().when("canTranPayment", {
    is: true,
    then: (schema) => schema.required("收款账号不能为空"),
  }),
  // canPickup: yup.bool().when("canDelivery", {
  //   is: false,
  //   then: (schema) => schema.isTrue("至少选择一种配送方式"),
  // }),
});

export default function ProductsManagePage(props: ICreateGroupBuyProps) {
  const history = useHistory<any>();
  const [searchText, setSearchText] = useState<string>('')
  const {currentMerchantId, getCurrentMerchant} = useMerchant();
  const [currentMerchant, setCurrentMerchant] = useState<any>({});
  const [finalData, setFinalData] = useState<any[]>([])
  const [showLoading, setShowLoading] = useState(false)
  const dialogHook = useDialog();
  const {renderDialog} = dialogHook || {};
  const {register, handleSubmit, reset, control, watch, errors} = useForm({
    defaultValues: {
      canDelivery: false,
      canPickup: false,
      pickupAddresses: [],
      groupToProductions: [],
      deliveryNotes: "",
      title: "",
      description: "",
      onlinePayment: false,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    model: entityModel,
    query,
    create,
    remove,
    update,
    replace,
    flush,
  } = React.useContext(EntityContext);

  const groupBuyData = entityModel["GROUP_BUY"]?.response;

  const saveState = () => {
    replace('GROUP_BUY', {
      product: null
    })
    replace("GROUP_BUY", control.getValues());
  };

  useEffect(() => {
    const current = getCurrentMerchant()
    setCurrentMerchant(current);
  }, []);

  const restoreState = async () => {
    const merchant = getCurrentMerchant();
    let phone;
    if (typeof groupBuyData.phone === "undefined") {
      if (merchant?.phone && merchant?.phone !== 'NA') {
        phone = merchant.phone;
      }
    } else {
      phone = groupBuyData.phone;
    }

    let bankAccount;
    let bankAccountName;
    if (typeof groupBuyData.bankAccount === "undefined") {
      bankAccount =
        merchant && merchant.finance && merchant.finance.accountNoForClient
          ? merchant.finance.accountNoForClient
          : "";
      bankAccountName =
        merchant && merchant.finance && merchant.finance.accountNameForClient
          ? merchant.finance.accountNameForClient
          : "";
    } else {
      bankAccount = groupBuyData.bankAccount;
      bankAccountName = groupBuyData.bankAccountName;
    }
    reset({
      ...groupBuyData,
      pickupAddresses: groupBuyData.pickupAddresses
        ? groupBuyData.pickupAddresses
        : [],
      phone,
      canCash: groupBuyData.canCash || false,
      bankAccount,
      bankAccountName,
    });
  };

  const selectProduct = () => {
    saveState();
    history.push(LOCAL_URL["PRODUCT_TEMPLATE"], {
      selectMode: true,
      from: 'STORE'
    });
  };

  const manageCategory = () => {
    saveState()
    history.push(LOCAL_URL["CATEGORY"], {
      type: 'GROUP',
      data: {
        groupId: groupBuyData.id,
        category: groupBuyData.category,
        productions: groupBuyData.groupToProductions
      }
    });
  }

  const products = history?.location?.state?.products;
  const pickupAddress = history?.location?.state?.pickupAddress;
  const groupId = history?.location?.state?.groupId;
  const isCopy = history?.location?.state?.isCopy;

  React.useEffect(() => {
    restoreState();
  }, [products, pickupAddress]);

  const queryGroupBuyAndRestoreState = async () => {
    let groupBuyData = entityModel["GROUP_BUY"]?.response;
    if (!!!groupBuyData || Number(groupBuyData?.id) !== groupId) {
      await query("GROUP_BUY", {id: groupId});
      groupBuyData = entityModel["GROUP_BUY"]?.response?.data;
      reset(groupBuyData);
      replace("GROUP_BUY", groupBuyData);
    }
  };

  React.useEffect(() => {
    if (groupId) {
      queryGroupBuyAndRestoreState();
    }
  }, [groupId]);

  const removeProduct = async (id) => {
    const groupBuyData = entityModel["GROUP_BUY"]?.response;
    const {groupToProductions} = groupBuyData;

    control.setValue(
      "groupToProductions",
      groupToProductions.filter((gp) => gp.id !== id)
    );
    replace("GROUP_BUY", {
      groupToProductions: groupToProductions.filter((gp) => gp.id !== id),
    });

    await requestPost('group/productOperate', {
      id: id,
      operate: 'DELETE'
    })
  };

  const swapProduct = async (category, idx1, idx2) => {
    const newResult = []
    for (const finalCate of finalData) {
      if (finalCate.title === category.title) {
        const products = finalCate.products
        const temp1 = products[idx1];
        const temp2 = products[idx2];
        products[idx1] = products[idx2];
        products[idx2] = temp1;
        newResult.push({
          ...finalCate,
          products: products
        })
        await requestPost('group/productSwap', {
          id1: temp1.id,
          id2: temp2.id,
        })
      } else {
        newResult.push(finalCate)
      }
    }
    setFinalData(newResult)

  };


  useEffect(() => {
    const categoryProducts = []
    let category = groupBuyData.category ?? []
    if (groupBuyData.groupToProductions) {
      let filteredProduct = groupBuyData.groupToProductions
      if (searchText) {
        filteredProduct = filteredProduct.filter(item => item.productionTemplate.title.includes(searchText))
      }
      for (const cate of category) {
        if (cate.title === DEFAULT_CATEGORY_NAME) {
          continue
        }
        const containProducts = []
        for (const product of filteredProduct) {
          if (product.category) {
            if (product.category.filter(cateItem => cateItem.title == cate.title).length > 0) {
              containProducts.push(product)
            }
          }
        }
        categoryProducts.push({
          ...cate,
          products: containProducts
        })
      }
      // 处理未分类产品
      const unCategoryProds = []
      for (const product of filteredProduct) {
        if (!product.category || product.category.length === 0) {
          unCategoryProds.push(product)
        }
      }
      if (unCategoryProds.length > 0) {
        categoryProducts.push({
          title: DEFAULT_CATEGORY_NAME,
          index: -1,
          products: unCategoryProds
        })
      }
    } else {
      categoryProducts.push({
        products: []
      })
    }
    setFinalData(categoryProducts)
  }, [groupBuyData, searchText])

  return (
    <div>
      <PageLayout pageName="create group buy">
        <Header title="管理商品" onBackClicked={() => {
          history.goBack()
        }}/>
        <StyledForm>
          <input ref={register} name="id" style={{display: "none"}}/>
          <input
            ref={register}
            name="bankAccountName"
            style={{display: "none"}}
          />
          <Divider/>
          <div className="flex-space-between">
            <label>
              <span style={{color: THEME.color.gray["#11-065"]}}>商品列表</span>
              <span style={{color: THEME.color.primary.default}}>*</span>
            </label>
            <span className="text" onClick={manageCategory}>
              管理分类
            </span>
          </div>
          <WhiteSpace/>
          <SearchAreaContainer style={{background: 'none'}}>
            <Input
              className='search'
              size="small"
              placeholder='搜索商品名称'
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
            />
          </SearchAreaContainer>
          <div>
            {!errors || !errors["groupToProductions"] ? null : (
              <ErrMsg className="ft-rr12">
                {Object(errors["groupToProductions"]).message}
              </ErrMsg>
            )}
          </div>
          {finalData.length === 1 ? (
            <Controller
              control={control}
              name="groupToProductions"
              render={({value}) =>
                value && value.length > 0 ? (
                  <div
                    style={{
                      background: "#fff",
                      padding: "8px 0 8px 0",
                      marginTop: 8,
                    }}
                  >
                    {value.map((gp, index) => (
                      <ProductCard
                        dialogHook={dialogHook}
                        gpId={gp.id}
                        showOpContainer
                        key={gp.id}
                        idx={index}
                        size={value.length}
                        soldQuantity={gp.soldQuantity}
                        available={gp.available}
                        product={gp.productionTemplate}
                        onRemoveFromGroup={(id) => {
                          removeProduct(id);
                        }}
                        onEditClick={(id) => {
                          saveState();
                          const {groupToProductions} = groupBuyData;
                          const groupToProduction = groupToProductions.filter(
                            (gp) => gp.id === id
                          )[0];
                          const productionTemplate =
                            groupToProduction.productionTemplate;
                          history.push(LOCAL_URL["CREATE_PRODUCT"], {
                            fromGroupPage: true,
                            groupId: groupToProduction.groupId,
                            saveToGroup: true,
                            values: {
                              ...productionTemplate,
                              soldQuantity: groupToProduction.soldQuantity,
                              available: groupToProduction.available,
                              groupToProductionId: groupToProduction.id,
                              defaultStock: (groupToProduction.available ?? 0) + (groupToProduction.soldQuantity ?? 0)
                            },
                          });
                        }}
                        onCopyClick={(id) => {
                          saveState();
                          const {groupToProductions} = groupBuyData;
                          const groupToProduction = groupToProductions.filter(
                            (gp) => gp.id === id
                          )[0];
                          delete groupToProduction.templateId
                          const productionTemplate =
                            groupToProduction.productionTemplate;

                          let groupToProductionId = new Date().getTime();

                          replace("GROUP_BUY", {
                            groupToProductions: [
                              ...groupToProductions,
                              {
                                ...groupToProduction,
                                id: groupToProductionId,
                              },
                            ],
                          });

                          history.push(LOCAL_URL["CREATE_PRODUCT"], {
                            fromGroupPage: true,
                            saveToGroup: true,
                            values: {
                              ...productionTemplate,
                              groupToProductionId,
                              soldQuantity: 0,
                              available: productionTemplate.defaultStock,
                            },
                          });
                        }}
                        onSwap={(idx1, idx2) => swapProduct(null, idx1, idx2)}
                      />
                    ))}
                  </div>
                ) : null
              }
            />
          ) : (
            <Controller
              control={control}
              name="groupToProductions"
              render={() => {
                return (
                  <div>
                    {finalData.map(category => {
                      return (
                        <div key={category.title}>
                          <div style={{
                            borderLeftColor: THEME.color.primary.default,
                            borderLeftWidth: 2,
                            borderLeftStyle: "solid",
                            paddingLeft: 8,
                            marginTop: 16
                          }}>{category.title}</div>
                          {category.products.length > 0 ? category.products.map((gp, index) => {
                            return (
                              <div key={gp.id}>
                                <ProductCard
                                  dialogHook={dialogHook}
                                  gpId={gp.id}
                                  showOpContainer
                                  idx={index}
                                  size={category.products.length}
                                  soldQuantity={gp.soldQuantity}
                                  available={gp.available}
                                  product={gp.productionTemplate}
                                  onRemoveFromGroup={(id) => {
                                    removeProduct(id);
                                  }}
                                  onEditClick={(id) => {
                                    saveState();
                                    const {groupToProductions} = groupBuyData;
                                    const groupToProduction = groupToProductions.filter(
                                      (gp) => gp.id === id
                                    )[0];
                                    const productionTemplate =
                                      groupToProduction.productionTemplate;

                                    replace("GROUP_BUY", {
                                      product: groupToProduction
                                    });
                                    history.push(LOCAL_URL["CREATE_PRODUCT"], {
                                      fromGroupPage: true,
                                      saveToGroup: true,
                                      groupId: groupToProduction.groupId,
                                      values: {
                                        ...productionTemplate,
                                        category: groupToProduction.category,
                                        soldQuantity: groupToProduction.soldQuantity,
                                        available: groupToProduction.available,
                                        groupToProductionId: groupToProduction.id,
                                        defaultStock: (groupToProduction.available ?? 0) + (groupToProduction.soldQuantity ?? 0)
                                      },
                                    });
                                  }}
                                  onCopyClick={(id) => {
                                    saveState();
                                    const {groupToProductions} = groupBuyData;
                                    const groupToProduction = groupToProductions.filter(
                                      (gp) => gp.id === id
                                    )[0];
                                    delete groupToProduction.templateId
                                    const productionTemplate =
                                      groupToProduction.productionTemplate;

                                    let groupToProductionId = new Date().getTime();

                                    replace("GROUP_BUY", {
                                      groupToProductions: [
                                        ...groupToProductions,
                                        {
                                          ...groupToProduction,
                                          id: groupToProductionId,
                                        },
                                      ],
                                      product: groupToProduction
                                    });

                                    history.push(LOCAL_URL["CREATE_PRODUCT"], {
                                      fromGroupPage: true,
                                      saveToGroup: true,
                                      groupId: groupToProduction.groupId,
                                      values: {
                                        category: groupToProduction.category,
                                        ...productionTemplate,
                                        groupToProductionId: null,
                                        soldQuantity: 0,
                                        available: productionTemplate.defaultStock,
                                      },
                                    });
                                  }}
                                  onSwap={(idx1, idx2) => swapProduct(category, idx1, idx2)}
                                />
                              </div>
                            )
                          }) : (
                            <div style={{
                              padding: 16,
                              textAlign: 'center',
                            }}>
                              -暂无产品-
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )
              }}/>
          )}
          <FloatBottom>
            <div style={{
              display: "flex",
              flex: 1
            }}>
              <AddNewProduct
                onClick={() => {
                  saveState();
                  history.push(LOCAL_URL["CREATE_PRODUCT"], {
                    groupId: groupId,
                    fromGroupPage: true,
                    saveToGroup: true
                  });
                }}
              >
                <img src={iconAdd}/>
                <div>创建新商品</div>
              </AddNewProduct>
              <AddNewProduct
                onClick={selectProduct}
              >
                <img src={iconAdd}/>
                <div>从商品库添加</div>
              </AddNewProduct>
            </div>
          </FloatBottom>
          <Divider/>
        </StyledForm>
        <Backdrop
          sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={showLoading}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
      </PageLayout>
      {renderDialog()}
    </div>
  );
}
