import React from 'react'
import { ISVGIconProps } from './type'

export const IconArrowLeft: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd" 
    width={width || '24'} fill={fill || 'white'} {...rest}
  >
    <path d="M9.41421 12.4853L16.4853 19.5563L15.0711 20.9706L7.29289 13.1924C6.90237 12.8019 6.90237 12.1687 7.29289 11.7782L15.0711 4L16.4853 5.41421L9.41421 12.4853Z"/>
  </svg>
)

export const IconArrowRight: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16" fillRule="evenodd" clipRule="evenodd" 
    fillOpacity="0.85"
    width={width || '16'} fill={fill || 'black'} {...rest}
  >
    <path d="M9.72451 7.67664L5.01046 2.9626L5.95327 2.01979L11.1387 7.20524C11.3991 7.46559 11.3991 7.8877 11.1387 8.14805L5.95327 13.3335L5.01046 12.3907L9.72451 7.67664Z"/>
  </svg>
)

export const IconArrowUp: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16" fillOpacity="0.45" 
    width={width || '16'} fill={fill || 'black'} {...rest}
  >
    <path d="M8.88384 15H7.11616V4.39392L2.25505 9.25504L1 7.99998L8 0.999985L15 7.99998L13.7449 9.25504L8.88384 4.39392V15Z" />
  </svg>
)

export const IconArrowDown: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16" fillOpacity="0.45"
    width={width || '16'} fill={fill || 'black'} {...rest}
  >
    <path d="M7.11616 1.00002L8.88384 1.00002L8.88384 11.6061L13.7449 6.74496L15 8.00002L8 15L1 8.00002L2.25505 6.74496L7.11616 11.6061L7.11616 1.00002Z" />
  </svg>
)
