import * as React from "react";
import styled from "styled-components";
import {CircularProgress} from "@mui/material";
import {Layer} from "../components/Layout";

export interface ILoadingDialogProps {
  visible?: boolean;
  children?: any
}

const Root = styled.div`
  position: fixed;
  z-index: ${(props) => props.level || 999999999};
  height: ${(props) => props.height || "100%"};
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
`;

const StyledContainer = styled.div`
  align-self: center;
  width: 90%;
  height: 152px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 66%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    font-size: 15px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.45);
  }
`;

export default function LoadingDialog({visible, children}: ILoadingDialogProps) {
  return visible ? (
    <Root>
      <StyledContainer>
        <CircularProgress/>
        {children ? (
          <div>
            {children}
          </div>
        ) : (
          <div className="text">上传中...</div>
        )}
      </StyledContainer>
    </Root>
  ) : null;
}
