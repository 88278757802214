import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import dayjs from "dayjs";
import numeral from "numeral";
import {LOCAL_URL, THEME, WEB_ROOT} from "../config";
import {requestGet, requestPost} from "../lib";
import {isAndroid} from "../utils/common";
import { useStore } from '../store'
import {AddressInfo, OrderOperation} from "../pages/Manage/components/OrderCard";
import TTModal from "../pages/Manage/components/Modal";
import ReceiptInfo from "../pages/Manage/components/ReceiptInfo";
import {useRouter} from "./router";

type OrderType = {
  isMerchant: boolean;
  privateRemarks: any[]
  groupIndex: number;
  actions: any[];
  no: string;
  id: number;
  user: any;
  remarks: any[];
  delivery: any[];
  totalPrice: number;
  action: any[];
  totalCNYPrice: number;
  shipType: "PICKUP" | "DELIVERY";
  createdAt: string;
  name: string;
  phone: string;
  group: any;
  groupId: number;
  address: AddressInfo;
  productions: any[];
  transactions: any[];
  note: string;
  status:
    | "CREATED" // 创建订单
    | "PRE_PAID" // 已经上传付款截图，待确认
    | "PAID" // 已经付款
    | "DONE" // 订单已完成
    | "CANCELED" // 订单已取消
    | "EXPIRED" // 订单已关闭
  cancelStatus: "NA" | "WAITING_CANCEL_APPROVE" | "APPROVED";
  refundStatus: "NA" | "WAITING_TRANSFER" | "DONE";
  merchant?: any;
  merchantId: number
}

const ACTION_MENU: any = {
  CANCEL: {label: "取消订单", value: "cancel"},
  MODIFY_ORDER: {value: "modify_order", label: "修改订单"},
  // MODIFY_PRODUCT: {value: "modify_product", label: "修改订单"},
  // MODIFY_ADDRESS: {value: "modify_address", label: "修改地址"},
  ADD_REMARK: {value: "add_remark", label: "添加备注"},
  ADD_DELIVERY: {value: "add_delivery", label: "添加快递信息"},
  PAY: {value: "pay_now", label: "立即支付"},
  CANCEL_APPLY: {value: "cancel_apply", label: "申请取消订单"},
  CANCEL_CANCEL_APPLY: {
    value: "cancel_cancel_apply",
    label: "撤销取消订单",
  },
  SET_AS_PAID: {value: "set_as_paid", label: "设为已付款"},
  SET_AS_DELIVERED: {value: "set_as_delivered", label: "设为已发货"},
  SET_AS_PICKUP: {value: "set_as_delivered", label: "设为已取货"},
  PRICE_CONFIRM: {value: "price_confirm", label: "确认金额"},
  MODIFY_PRICE: {value: "modify_price", label: "修改金额"},
  REMIND_USER_PICK_UP: {value: "remind_user_pick_up", label: "提醒取货"},
};

export type OrderOperationType =
  | "modify_order"
  | "cancel"
  | "pay_now"
  | "cancel_apply"
  | "cancel_cancel_apply"
  | "set_as_paid"
  | "set_as_delivered"
  | "add_remark"
  | "add_delivery"
  | "add_receipt"
  | "confirm_sms"
  | "remind_user_pick_up"

export function useOrderUIComponents() {

  const [orderData, setOrderData] = useState<OrderType | null>(null)
  const [type, setType] = useState<'USER' | 'MERCHANT'>('USER')
  const router = useRouter()
  const history = useHistory()
  const setSelectedProductAmount  = useStore((state) => state.setSelectedProductAmount)
  const setOrderInModifying       = useStore((state) => state.setOrderInModifying)

  const initByOrderId = async (orderId) => {
    const {data} = await requestGet('order/detail', {
      id: orderId
    })
    if (data) {
      setOrderData(data)
      if (data.isMerchant) {
        setType("MERCHANT")
      } else {
        setType("USER")
      }
    }
  }

  /**
   * 返回订单状态字符串
   * **/
  const getOrderStatusStr = (_order?: any) => {
    const data = _order || orderData
    if (!data) {
      return ''
    }
    if (data.cancelStatus === 'WAITING_CANCEL_APPROVE') {
      return '申请取消'
    }
    if (data.cancelStatus === 'APPROVED') {
      return '已取消'
    }
    if (data.refundStatus === 'WAITING_TRANSFER') {
      return '待退款'
    }
    if (data.refundStatus === 'DONE') {
      return '已退款'
    }
    switch (data.status) {
      case "CANCELED": {
        return "已取消";
      }
      case "PRE_PAID": {
        return "付款待确认";
      }
      case "PAID": {
        return "已付款";
      }
      case "DONE": {
        return "已付款";
      }
      case "CREATED": {
        return "待付款";
      }
      case "EXPIRED": {
        return "已失效";
      }
    }
  }

  /**
   * 返回订单送货状态字符串
   * **/
  const getOrderShippingStr = (_order?: any) => {
    if (isShipped(_order)) {
      return '已发货'
    } else {
      return '未发货'
    }
  }

  /**
   * 返回订单产品UI
   * **/
  const ProductsCard = () => {
    if (!orderData) {
      return null
    }
    return (
      <BaseCard3>
        <div className="order-list">
          <div className="product header">商品</div>
          <div className="amount header">数量</div>
          <div className="price header">价格</div>
        </div>
        {orderData.productions.map((item) => {
          return (
            <div className="order-list" key={item.title}>
              <div
                className="product"
              >
                {item.title}
              </div>
              <div
                className="amount"
              >
                {item.amount}
              </div>
              <div
                className="price"
              >
                {numeral((item.price * item.amount) / 100).format(
                  "$0,0.00"
                )}
              </div>
            </div>
          );
        })}
        {orderData.note && (
          <div className='note'>
            备注: {orderData.note}
          </div>
        )}
        <div className='total-price'>
          总计 <span
          style={{color: THEME.color.primary.default, paddingLeft: 8}}>{numeral((orderData.totalPrice) / 100).format(
          "$0,0.00"
        )}</span>
        </div>
      </BaseCard3>
    )
  }

  /**
   * 订单基本信息UI
   * **/
  const BaseInfoCard = () => {
    if (!orderData) {
      return null
    }
    return (
      <BaseCard2>
        <div className='item' onClick={() => {
          if (orderData.group.type === 'DEFAULT') {
            history.push(LOCAL_URL["GROUP_BUY_DETAIL"] + "?groupId=" + orderData.groupId);
          } else if (orderData.group.type === 'STORE') {
            history.push(LOCAL_URL["SHOPPING_DETAIL"] + "?groupId=" + orderData.groupId);
          }
        }}>
          <div className='left'>
            团购名称
          </div>
          <div className='right'>
            {orderData.group.type === 'DEFAULT' ? (orderData.group.title) : null}
            {orderData.group.type === 'STORE' ? (`${orderData.merchant.name}在线网店`) : null}<ChevronRightIcon/>
          </div>
        </div>
        {!orderData.isMerchant && (
          <div className='item' onClick={() => {
            history.push(LOCAL_URL["MERCHANT_DETAIL"] + `?id=${orderData.merchantId}`)
          }}>
            <div className='left'>
              团长主页
            </div>
            <div className='right'>
              {orderData.merchant.name}<ChevronRightIcon/>
            </div>
          </div>
        )}
        <div className='item'>
          <div className='left'>
            跟团号
          </div>
          <div className='right'>
            {orderData.groupIndex}
          </div>
        </div>
        <div className='item'>
          <div className='left'>
            订单号
          </div>
          <div className='right'>
            SMT{orderData.id}
          </div>
        </div>
        <div className='item'>
          <div className='left'>
            下单时间
          </div>
          <div className='right'>
            {dayjs(orderData.createdAt).format('YYYY/MM/DD HH:mm')}
          </div>
        </div>
      </BaseCard2>
    )
  }

  /**
   * 订单配送信息UI
   * **/
  const ShipInfoCard = () => {
    if (!orderData) {
      return null
    }
    return (
      <ShipInfo>
        <div className='type'>
          {orderData.shipType === 'PICKUP' ? '自提' : '配送'}
        </div>
        <div className='name_phone'>
          <span>{orderData.address.contactName}</span><span className='phone'>{orderData.address.telephone}</span>
        </div>
        <div className='address'>{orderData.address.formattedAddress}</div>
        {orderData.address.notes && (
          <div className='note'>{orderData.address.notes}</div>
        )}
      </ShipInfo>
    )
  }

  /**
   * 订单支付方式
   * **/
  const getPaymentMethodStr = (transaction: any) => {
    if (!transaction) {
      return ''
    }
    switch (transaction.paymentMethod) {
      case 'TRANSFER': {
        return '手工转账附截图'
      }
      case 'CASH': {
        return '现金/到店支付'
      }
      case 'WECHAT_NIUYAO':
      case 'WECHAT_BAIXING': {
        return '微信支付'
      }
      case 'A2A': {
        return '网银转账'
      }
      case 'CARD': {
        return '信用卡'
      }
    }
    return '未知'
  }


  /**
   * 订单支付信息UI
   * **/
  const PaymentCard = () => {
    if (!orderData) {
      return null
    }
    if (!isPaid() || !orderData.transactions || orderData.transactions.length === 0) {
      return null
    }
    const finishedTransaction = orderData.transactions.filter(item => item.status = 'FINISHED')
    if (finishedTransaction.length > 0) {
      let method = getPaymentMethodStr(finishedTransaction[0])
      return (
        <CommonCard>
          <div className='title'>
            付款方式
          </div>
          <div className='method'>
            {method}
          </div>
        </CommonCard>
      )
    }
  }

  /**
   * 订单备注信息UI
   * **/
  const RemarkCard = () => {
    if (!orderData) {
      return null
    }
    if ((!orderData.remarks || orderData.remarks.length === 0) && (!orderData.privateRemarks || orderData.privateRemarks.length === 0)) {
      return null
    }
    return (
      <div>
        {(orderData.remarks && orderData.remarks.length > 0) && (
          <CommonCard>
            <div className='title'>
              团长备注{orderData.isMerchant && (<span>（团员可见）</span>)}
            </div>
            {orderData.remarks.map(item => {
              return (
                <div className='method' key={item.id}>
                  {item.content}
                </div>
              )
            })}
          </CommonCard>
        )}
        {(orderData.isMerchant && orderData.privateRemarks.length > 0) && (
          <CommonCard>
            <div className='title'>
              私密备注（仅团长和管理员可见）
            </div>
            {orderData.privateRemarks.map(item => {
              return (
                <div className='method' key={item.id}>
                  {item.content}
                </div>
              )
            })}
          </CommonCard>
        )}
      </div>

    )
  }

  /**
   * 订单操作历史信息UI
   *
   * **/
  const HistoryInfoCard = (props: { order?: any, onRefresh: () => void, type?: 'USER' | 'MERCHANT' }) => {
    const [showAllAction, setShowAllAction] = useState(false);
    const _orderData = props.order || orderData
    if (!_orderData.actions) {
      return null;
    }
    let type: 'USER' | 'MERCHANT' = props.type || (_orderData.isMerchant ? 'MERCHANT' : 'USER')
    let otherActions = [..._orderData.actions].reverse()
    if (_orderData.status === 'PRE_PAID') {
      otherActions = otherActions.filter(item => item.actionType !== 'PRE_PAID')
    }
    const ActionItem = (props: any) => {
      if (props.action.actionType === 'PRE_PAID') {
        const notifyMessage = `你好，你参加的接龙：${_orderData.group.title}，还没有上传付款截图，请付款后及时上传。` + "%0a" + `账户名：${_orderData.group.bankAccountName}` + "%0a" + `账号：${_orderData.group.bankAccount}` + "%0a" + `Reference：${_orderData.groupIndex}` + "%0a" + `金额：${numeral(_orderData.totalPrice).divide(100).format('$0,0.00')}` + "%0a" + `付款后，请点击以下链接上传付款截图：${WEB_ROOT}${LOCAL_URL['PAYMENT_UPLOAD']}?orderNo=${_orderData.id}` + "%0a" + `谢谢.` + "%0a" + `${_orderData.merchant.name}`
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 12,
            }}
          >
          <span
            style={{color: THEME.color.gray["#12-045"],}}>{`${dayjs(props.action.updatedAt).format('YYYY-MM-DD HH:mm')} ${_orderData?.user?.name}更新了`}</span>
            <a style={{textDecoration: 'underline', color: 'inherit'}} onClick={() => {
              router.push(LOCAL_URL["RECEIPT_VIEW"], {
                status: _orderData.status,
                image: props.action.meta?.receiptUrl,
                orderId: _orderData.id,
                role: props.role,
                userMobile: _orderData.address.telephone,
                message: notifyMessage
              })
            }}>付款截图</a>
          </div>
        )
      }
      return (
        <div
          style={{
            fontSize: 12,
            color: THEME.color.gray["#12-045"],
            lineHeight: "18px",
          }}
        >
          <div dangerouslySetInnerHTML={{__html: props.action.content}}></div>
        </div>
      )
    }


    if (!showAllAction && otherActions.length > 1) {
      const action: any = otherActions[0];
      return (
        <div>
          <ReceiptInfo order={_orderData} onRefresh={props.onRefresh} group={_orderData.group} role={type}/>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 12,
              color: THEME.color.gray["#12-045"],
            }}
          >
            <ActionItem action={action} {...props}/>
            <div
              onClick={() => {
                setShowAllAction(true)
              }}
              style={{
                textDecoration: "underline",
                textAlign: "right",
                flex: 1,
                marginTop: 4,
              }}
            >
              查看记录
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <ReceiptInfo order={_orderData} onRefresh={props.onRefresh} group={_orderData.group} role={type}/>
          {otherActions.map((item, index) => {
            return (
              <ActionItem action={item} key={item.id} {...props}/>
            );
          })}
        </div>
      );
    }
  }

  /**
   * 物流信息UI
   * **/
  const DeliveryInfoCard = () => {
    if (!orderData) {
      return null
    }
    if (orderData.shipType !== 'DELIVERY' || !orderData.delivery || orderData.delivery.length === 0) {
      return null
    }
    if (orderData.delivery.length === 1 && !orderData.delivery[0].label && !orderData.delivery[0].no) {
      return null
    }
    return (
      <CommonCard>
        <div className='title'>
          物流信息
        </div>
        {orderData.delivery.map(item => {
          return (
            <div
              style={{
                paddingTop: 8,
                fontSize: 12,
                color: THEME.color.gray["#10-085"],
                marginBottom: 8,
              }}
              key={item.id}
            >
              {item.label || ""}：{item.no || ""}{" "}
              <a href={item.queryUrl} style={{color: THEME.color.primary.default}}>点击查看</a>
            </div>
          )
        })}

      </CommonCard>
    )
  }


  /**
   * 返回订单可用的操作按钮
   * **/
  const ActionList = (props: { order?: any, type?: 'USER' | 'MERCHANT', onRefresh?: () => void }) => {
    const [processing, setProcessing] = useState(false)
    const [showPaidModal, setShowPaidModal] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showModifyModal, setShowModifyModal] = useState(false)
    const [showDeliveredModal, setShowDeliveredModal] = useState(false)
    const [showReminderModal, setShowReminderModal] = useState(false)
    let hasSMSConfirmBtn = false
    let lastTransactions = null
    const _orderData = orderData || props.order
    if (!_orderData) {
      return null
    }
    const type: 'USER' | 'MERCHANT' = props.type || (_orderData.isMerchant ? 'MERCHANT' : 'USER')
    if (_orderData.transactions) {
      lastTransactions = _orderData.transactions[_orderData.transactions.length - 1]
    }
    const onModifyOrderConfirm = () => {
      const productsAmount = _orderData?.productions?.map((x:any) => ({id: x.id, amount: x.amount}))
      if (!!productsAmount?.length) {
        setOrderInModifying(_orderData)
        setSelectedProductAmount(
          productsAmount,
          true,
        )
      }
    }
    const operationRequest = async (operation: string) => {
      if (processing) {
        return;
      }
      setProcessing(true);
      const data = await requestPost("manage/operate", {
        orderId: _orderData.id,
        operate: operation,
        role: type
      });
      if (data) {
        if (props.onRefresh) {
          props.onRefresh()
        }
        await initByOrderId(_orderData.id)
      }
      setProcessing(false);
      return data;
    };
    const operationMenu: { label: string; value: OrderOperationType }[] = [];
    switch (type) {
      case "MERCHANT": {
        if (_orderData.cancelStatus === "WAITING_CANCEL_APPROVE") {
          if (!isShipped(_orderData)) {
            operationMenu.push(ACTION_MENU.SET_AS_DELIVERED)
          }
          operationMenu.push(ACTION_MENU.ADD_REMARK)
          operationMenu.push(ACTION_MENU.CANCEL);
          break;
        }
        if (isShipped(_orderData)) {
          operationMenu.push(ACTION_MENU.ADD_DELIVERY);
        }
        switch (_orderData.status) {
          case "CREATED": {
            operationMenu.push(ACTION_MENU.CANCEL);
            break
          }
          case "PRE_PAID": {
            if (lastTransactions && lastTransactions.paymentMethod === 'CASH') {
              hasSMSConfirmBtn = true
            }
            if (!isShipped(_orderData)) {
              if (_orderData.shipType === "DELIVERY") {
                operationMenu.push(ACTION_MENU.SET_AS_DELIVERED);
              } else {
                operationMenu.push(ACTION_MENU.SET_AS_PICKUP);
                operationMenu.push(ACTION_MENU.REMIND_USER_PICK_UP)
              }
            }
            operationMenu.push(ACTION_MENU.SET_AS_PAID);
            operationMenu.push(ACTION_MENU.CANCEL);
            break;
          }
          case "PAID": {
            if (!isShipped(_orderData)) {
              if (_orderData.shipType === "DELIVERY") {
                operationMenu.push(ACTION_MENU.SET_AS_DELIVERED);
              } else {
                operationMenu.push(ACTION_MENU.SET_AS_PICKUP);
                operationMenu.push(ACTION_MENU.REMIND_USER_PICK_UP)
              }
            }
            operationMenu.push(ACTION_MENU.CANCEL);
            break;
          }
        }
        if (_orderData.status !== 'EXPIRED') {
          operationMenu.push(ACTION_MENU.ADD_REMARK)
        }
        break;
      }
      case "USER": {
        if (_orderData.cancelStatus === "WAITING_CANCEL_APPROVE") {
          operationMenu.push(ACTION_MENU.CANCEL_CANCEL_APPLY);
        } else {
          switch (_orderData.status) {
            case "CREATED": {
              operationMenu.push(ACTION_MENU.PAY);
              if (!isShipped(_orderData)) {
                if (_orderData.cancelStatus === "NA") {
                  operationMenu.push(ACTION_MENU.MODIFY_ORDER);
                }
                operationMenu.push(ACTION_MENU.CANCEL);
              }
              break;
            }
            case "PRE_PAID": {
              if (!isShipped(_orderData)) {
                if (_orderData.cancelStatus === "NA") {
                  operationMenu.push(ACTION_MENU.MODIFY_ORDER);
                }
                operationMenu.push(ACTION_MENU.CANCEL);
              }
              break;
            }
            case "PAID": {
              if (!isShipped(_orderData)) {
                operationMenu.push(ACTION_MENU.CANCEL_APPLY);
              }
              break;
            }
          }
        }

      }
    }
    // console.log(operationMenu, type, _orderData)

    const operationClicked = async (value: OrderOperationType) => {
      if (type === "USER") {
        switch (value) {
          case "modify_order": {
            setShowModifyModal(true)
            break;
          }
          case "cancel_cancel_apply": {
            break;
          }
          case "cancel_apply":
          case "cancel": {
            setShowCancelModal(true);
            break;
          }
          case "add_receipt": {
            router.push({
              pathname: LOCAL_URL["PAYMENT_UPLOAD"],
              state: {
                orderNo: _orderData.id,
              },
            });
            break
          }
          case "pay_now": {
            if (_orderData.group.onlinePayment) {
              router.push({
                pathname: LOCAL_URL["CHOOSE_PAYMENT"],
                state: {
                  id: _orderData.id,
                },
              });
            } else {
              router.push({
                pathname: LOCAL_URL["PAYMENT_UPLOAD"],
                state: {
                  orderNo: _orderData.id,
                },
              });
            }
            break;
          }
        }
      } else if (type === "MERCHANT") {
        switch (value) {
          case "set_as_delivered": {
            setShowDeliveredModal(true);
            break;
          }
          case "remind_user_pick_up": {
            setShowReminderModal(true);
            break;
          }
          case "set_as_paid": {
            setShowPaidModal(true);
            break;
          }
          case "cancel": {
            setShowCancelModal(true);
            break;
          }
          case 'add_remark': {
            router.push({
              pathname: LOCAL_URL["ADD_REMARK"],
              state: {
                orderId: _orderData.id,
              },
            });
            break
          }
          case 'add_delivery': {
            router.push({
              pathname: LOCAL_URL["DELIVERY_PAGE"],
              state: {
                orderId: _orderData.id,
              },
            });
            break
          }
        }
      }
    };

    const notifyMessage = `你好，你参加的接龙：${_orderData.group.title}，接龙号${_orderData.groupIndex}，选择了现金到付，即将为您备货，如确认该订单，请回复“确认”，如取消订单，请回复“取消”。%0a谢谢%0a${_orderData.merchant.name}`
    const pickUpReadyMessage = `您好，您参加的接龙：${_orderData.group.title}，接龙号${_orderData.groupIndex}，货已备好，您随时可以来取。%0a感谢您的支持!%0a${_orderData.merchant.name}`

    if (operationMenu.length > 0) {
      return (
        <div>
          {hasSMSConfirmBtn && (
            <div style={{
              textAlign: "right",
              marginTop: 8
            }}>
              <a
                href={`sms:${props.order?.phone || props.order?.address?.telephone}${isAndroid ? '?' : '&'}body=${isAndroid?encodeURIComponent(notifyMessage.replace(/%0a/g, '\n')):notifyMessage}`}
                style={{
                  textDecoration: "none",
                  marginTop: 16,
                  padding: "3px 8px",
                  borderRadius: 24,
                  border: "1px solid rgba(0, 0, 0, 0.45)",
                  lineHeight: "18px",
                  fontSize: 12,
                  marginLeft: 4,
                  marginRight: 4,
                  color: THEME.color.gray["#12-045"],
                }}
              >
                发送短信确认订单
              </a>
            </div>
          )}
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            flexWrap: 'wrap'
          }}>
            {operationMenu.map((item) => {
              return (
                <div
                  key={item.value}
                  onClick={() => {
                    operationClicked(item.value);
                  }}
                  style={{
                    marginTop: 16,
                    padding: "3px 8px",
                    borderRadius: 24,
                    border: "1px solid rgba(0, 0, 0, 0.45)",
                    lineHeight: "18px",
                    fontSize: 12,
                    marginLeft: 4,
                    marginRight: 4,
                    color: THEME.color.gray["#12-045"],
                  }}
                >
                  {item.label}
                </div>
              );
            })}
            <TTModal
              show={showPaidModal}
              title="确定设为已付款？"
              content="设置后不可更改"
              onConfirm={async () => {
                await operationRequest("set_as_paid");
                setShowPaidModal(false);
              }}
              onCancel={() => {
                setShowPaidModal(false);
              }}
            />
            <TTModal
              show={showCancelModal}
              title="确定取消订单？"
              content={
                type === "MERCHANT"
                  ? "如果用户已经支付，需要手动给买家退款"
                  : "设置后不可更改"
              }
              onConfirm={async () => {
                await operationRequest("cancel");
                setShowCancelModal(false);
              }}
              onCancel={() => {
                setShowCancelModal(false);
              }}
            />
            <TTModal
              show={showModifyModal}
              title="确定修改订单？"
              content={null}
              onConfirm={async () => {
                onModifyOrderConfirm()
                setShowModifyModal(false);
              }}
              onCancel={() => {
                setShowModifyModal(false);
              }}
            />
            <TTModal
              show={showDeliveredModal}
              title={_orderData.shipType === 'PICKUP' ? '确认设为已取货' : '确认设为已发货'}
              content="设置后不可更改"
              onConfirm={async () => {
                await operationRequest("set_as_delivered");
                setShowDeliveredModal(false);
              }}
              onCancel={() => {
                setShowDeliveredModal(false);
              }}
            />
            <TTModal
              href={`sms:${props.order?.phone || props.order?.address?.telephone}${isAndroid ? '?' : '&'}body=${isAndroid?encodeURIComponent(pickUpReadyMessage.replace(/%0a/g, '\n')):pickUpReadyMessage}`}
              show={showReminderModal}
              title={'确认发送提醒消息'}
              content="向用户发送取货提醒消息"
              onConfirm={async () => {
                await operationRequest("remind_user_pick_up");
                setShowReminderModal(false);
              }}
              onCancel={() => {
                setShowReminderModal(false);
              }}
            />

          </div>
        </div>
      );
    } else {
      if (hasSMSConfirmBtn) {
        return (
          <div style={{
            textAlign: "right",
            marginTop: 8
          }}>
            <a
              href={`sms:${props.order?.phone || props.order?.address?.telephone}${isAndroid ? '?' : '&'}body=${isAndroid?encodeURIComponent(notifyMessage.replace(/%0a/g, '\n')):notifyMessage}`}
              style={{
                textDecoration: "none",
                marginTop: 16,
                padding: "3px 8px",
                borderRadius: 24,
                border: "1px solid rgba(0, 0, 0, 0.45)",
                lineHeight: "18px",
                fontSize: 12,
                marginLeft: 4,
                marginRight: 4,
                color: THEME.color.gray["#12-045"],
              }}
            >
              发送短信确认订单
            </a>
          </div>
        )
      }
      return null;
    }
  };


  /**
   * 订单是否已支付
   * **/
  const isPaid = () => {
    if (!orderData) {
      return false
    }
    return orderData.status === 'PAID' || orderData.status === 'DONE';
  }

  const isShipped = (_order?: any) => {
    const data = _order || orderData
    if (!data) {
      return false
    }
    if (!data.delivery) {
      return false
    }
    const statusSet = new Set(data.delivery.map(item => item.status))
    return statusSet.has('DONE') || statusSet.has('PROCESSING') || data.isShipped;
  }

  return {
    initByOrderId: initByOrderId,
    isReady: !!orderData,
    statusStr: getOrderStatusStr,
    shippingStatusStr: getOrderShippingStr,
    BaseInfoCard,
    ProductsCard,
    ShipInfoCard,
    PaymentCard,
    getPaymentMethodStr,
    RemarkCard,
    isPaid: isPaid(),
    DeliveryInfoCard,
    ActionInfoCard: HistoryInfoCard,
    ActionList,
    orderData: orderData,
  }
}

const BaseCard2 = styled.div`
  background-color: white;
  font-size: 15px;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;

    .left {
      color: ${THEME.color.gray["#12-045"]};
    }

    .right {
      color: ${THEME.color.gray["#10-085"]};
      display: flex;
      align-items: center;
    }
  }
`

const BaseCard3 = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;

  .header {
    font-size: 15px;
    color: ${THEME.color.gray["#12-045"]};
  }

  .order-list {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    color: ${THEME.color.gray["#10-085"]}
    font-weight: 400;

    .product {
      flex: 1;
      text-align: left;
    }

    .amount {
      width: 50px;
      text-align: center;
    }

    .price {
      text-align: right;
      width: 50px;
    }
  }

  .note {
    font-size: 12px;
    font-weight: 500;
    margin-top: 16px;
  }

  .total-price {
    margin-top: 8px;
    font-size: 12px;
    text-align: right;
    color: ${THEME.color.gray["#12-045"]};
  }
`

const ShipInfo = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  font-size: 15px;
  line-height: 21px;
  color: ${THEME.color.gray["#10-085"]};
  margin-top: 16px;

  .type {
    font-weight: 500;
    margin-top: 8px;
  }

  .name_phone {
    margin-top: 8px;

    .phone {
      margin-left: 16px;
    }
  }

  .address {
    margin-top: 8px;
    color: ${THEME.color.gray["#11-065"]};
  }

  .note {
    margin-top: 8px;
    color: ${THEME.color.gray["#11-065"]};
  }
`

export const CommonCard = styled.div`
  margin-top: 16px;
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  font-size: 15px;
  line-height: 21px;
  color: ${THEME.color.gray["#10-085"]};

  .title {
    font-weight: 500;
    margin-top: 8px;
  }

  .method {
    margin-top: 8px;
  }
`
