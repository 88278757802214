import { useRef } from 'react'
import {requestPost} from "../../../lib";
import { Button } from '../../../components'
import { IconCircleX, toast } from '../../../Layout'
import useMerchant from "../../../hooks/useMerchant";
import { MaskLayer, Wrapper, IconButton } from './styled'
import { parseBatchInput } from './helper'

const Description = () => (
  <div>
    <p>
      一行一个商品，名称、价格、规格、分类用空格分开，名称中间不要有空格、表情或特殊符号，示例：
    </p>
    <p>
      鸡蛋 16.9 30个/盘 特价
    </p><p>
      白菜 2 1kg 蔬菜
    </p>
  </div>
)

interface IBatchImportProps {
  visible: boolean;
  onClose: () => void;
}
export const BatchImport: React.FC<IBatchImportProps> = ({visible, onClose}) => {
  const {currentMerchantId: merchantId} = useMerchant();
  const ref = useRef<any>(null)

  const onConfirm = async () => {
    if (!ref.current.value) {
      toast.error('无效输入')
      return
    }
    const payload = {
      merchantId,
      data: parseBatchInput(ref.current.value),
    }
    ref.current.value = ''

    const result = await requestPost('production-template/import', payload)
    // console.log(result)
    if (result?.code === 0) {
      onClose()
    } else {
      toast.error('导入失败')
    }
  }
  return (
    !visible ? null :
    <MaskLayer>
      <Wrapper className="flex-between direction-col">
        <h1>批量添加商品</h1>
        <Description />
        <textarea ref={ref} style={{flex: 1}} placeholder='请按照以上示例格式输入要添加的商品'></textarea>
        <Button fullWidth={true} size="small" onClick={onConfirm}>
          确认添加
        </Button>
      </Wrapper>
      <IconButton onClick={onClose}>
        <IconCircleX />
      </IconButton>
    </MaskLayer>
  )
}
