import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME } from '../../config'

const UNIT        = 'px';
const SIZE        = 16;
const TOTAL_WIDTH = 26;

const Container = styled.div`
  width: ${(props) => !!props.totalWidth? `${props.totalWidth}${UNIT}` : `${TOTAL_WIDTH}${UNIT}`};
  min-height: ${(props) => !!props.size ? `${props.size}${UNIT}` : `${SIZE}${UNIT}`};
  border-radius: ${(props) => !!props.size ? `${props.size/2}${UNIT}` : `${SIZE/2}${UNIT}`};
  background: ${(props) => props.checked ? THEME.color.accent.green : (props.background || THEME.color.gray['#5-025']) };

  position: relative;
  display: inline-flex;
`;
const Stick = styled.div`
  width: ${(props) => !!props.size ? `${props.size - 4}${UNIT}` : `${SIZE - 4}${UNIT}`};
  height: ${(props) => !!props.size ? `${props.size - 4}${UNIT}` : `${SIZE - 4}${UNIT}`};
  border-radius: ${(props) => !!props.size ? `${(props.size - 4)/2}${UNIT}` : `${(SIZE - 4)/2}${UNIT}`};
  position: absolute;
  top: 2px;
  left: ${(props) => !props.checked ? '2px' : 'auto'};
  right: ${(props) => !!props.checked ? '2px' : 'auto'};
  background: #fff;
  transition: all .3s;
`;

interface SwitchProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  background?: string;
  size?: number;
  totalWidth?: number;
  noChange?: boolean;
}
export const Switch: React.FC<SwitchProps> = ({onChange, value, background, size, totalWidth, noChange}) => {
  const [checked, setChecked] = useState(!!value);
  const onClick = () => {
    if (!!noChange) return;
    if (!!onChange) onChange(!checked);
    setChecked(!checked);
  }

  useEffect(() => {
    if (value !== checked) setChecked(value);
  }, [value])
  
  useEffect(() => {
    if (value !== checked) setChecked(value);
  }, [value])
  
  return (
    <Container onClick={onClick} checked={checked} background={background} size={size} totalWidth={totalWidth}>
      <Stick checked={checked} size={size} totalWidth={totalWidth} />
    </Container>
  )
}
