import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { IconRadioUncheck, IconRadioCheck } from '../Layout'
import { IFormInputProps } from './type'
import { Wrapper as BaseWrapper } from './styled'

const Wrapper = styled(BaseWrapper)`
  color: rgba(0,0,0,0.45);
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;

  & div.block {
    color: rgba(0,0,0,0.85);
    font-weight: 400;

    border-radius: 8px;
    background-color: white;
    border-radius: 8px;
    border: none;
    overflow: hidden;
  }
`
const Option = styled.div`
  padding: 12px 8px;

  & input {
    height: 29px;
    border: none;
    border-radius: 8px;
    background-color: #D9D9D9;
  }
  & input[type=number] {
    width: 3rem;
    margin: 0 .5rem;
    padding: 0 .3rem;
    text-align: center;
  }
  & input[type=date] {
    margin-right: .5rem;
    padding: 0 .5rem;
    text-align: center;
  }

  & ~ & {
    border-top: 1px solid rgba(0,0,0,0.09);
  }

  & svg {
    display: block;
  }
`

interface DueDateSettingProps {
  lifeTime?: number;
  fixDate?: string;
}
type DueDateSettingKeyType = 'fixDate' | 'lifeTime';
interface DueDateInputProps extends IFormInputProps{
  defaultValues: DueDateSettingProps;
}

const extractLifeTime = (value: DueDateSettingProps) => {
  return (('number' === typeof value?.lifeTime) && (value.lifeTime > 0 )) ? value.lifeTime.toString() : ''
}
const extractFixDate = (value: DueDateSettingProps) => {
  return (dayjs(value?.fixDate).isValid() && dayjs(value?.fixDate).isAfter(dayjs())) ? value.fixDate : ''
}
const packSetting = (nb:string, dt:string, op:DueDateSettingKeyType) => {
  if (op === 'fixDate') {
    if (dayjs(dt).isValid && dayjs(dt).isAfter(dayjs())) {
      return {
        fixDate: dt
      }
    }
  } else if (op === 'lifeTime') {
    const lifeTime = parseInt(nb)
    if (!isNaN(lifeTime) && (lifeTime > 0)) {
      return {
        lifeTime 
      }
    }
  }
  return null
}

const CheckBlock = ({checked, onClick}:{
  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <div style={{display: 'inline-block', paddingLeft: '2rem'}} className="check-block debugx"
      onClick={onClick}
    >
      { checked ? <IconRadioCheck /> : <IconRadioUncheck /> }
    </div>
  )
}

const OptionalSetting = ({type, text, currentOption, optionKey, value, onChange, onBlur, onSwitch}:{
  type: 'date' | 'number';
  text: string[];
  currentOption: DueDateSettingKeyType;
  optionKey: DueDateSettingKeyType;
  value: string;
  onChange: (e:any) => void;
  onBlur: (k: DueDateSettingKeyType) => () => void;
  onSwitch: (k: DueDateSettingKeyType) => () => void;
}) => {
  const ref=useRef<any>(null)

  const onThisFocus = onSwitch(optionKey)
  const onFocus = () => {
    onThisFocus()
    if (type === 'date') {
      ref.current?.showPicker?.()
    }
  }
  const onKeyDown = (e:any) => {
    if (e.code === 'Enter') {
      ref.current.blur()
    }
  }
  return (
    <Option className='flex-between align-center'>
      <div>
        <span>{text[0]}</span>
        <input type={type} ref={ref}
          value={value}
          onChange={onChange}
          onBlur={onBlur(optionKey)} onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
        <span>{text[1]}</span>
      </div>
      <CheckBlock checked={(optionKey === currentOption)} onClick={onSwitch(optionKey)} />
    </Option>
  )
}

export const DueDateInput: React.FC<DueDateInputProps> = ({required, label, value, onChange, defaultValues}) => {
  const [nbInput, setNBInput] = useState<string>(extractLifeTime(value) || extractLifeTime(defaultValues) || '7')
  const [dtInput, setDTInput] = useState<string>(extractFixDate(value)  || extractFixDate(defaultValues) || dayjs().add(1, 'M').format('YYYY-MM-DD'))
  const [option, setOption]   = useState<DueDateSettingKeyType>(!!value.fixDate ? 'fixDate' : 'lifeTime')
  const [previous, setPrevious] = useState<DueDateSettingProps>({
    lifeTime: parseInt(nbInput),
    fixDate:  dtInput,
  })

  const handleChange = (nb:string, dt:string, op:DueDateSettingKeyType) => {
    const setting = packSetting(nbInput, dtInput, option)
    if (!!setting) {
      setPrevious({ ...previous, ...setting })
      onChange(setting)
    }
  }
  useEffect(() => {
    handleChange(nbInput, dtInput, option)
  }, [option, nbInput, dtInput])
  const onDateChange = (e) => { setDTInput(e.target.value) }
  const onNumberChange = (e) => { setNBInput(e.target.value) }
  const onSwitch = (key: DueDateSettingKeyType) => () => { setOption(key) }
  const onBlur = (key: DueDateSettingKeyType) => () => {
    const setting = packSetting(nbInput, dtInput, option)
    if (!setting) {
      if (key === 'fixDate') {
        setDTInput(previous.fixDate)
      } else {
        setNBInput(previous.lifeTime.toString())
      }
    }
  }

  return (
    <Wrapper className="flex-start direction-col debugx" style={{gap: '8px'}} >
      <label>{label}<span className='required'>{required ? ' *' : ''}</span></label>
      <div className='block'>
        <OptionalSetting type="number"
          text={['领券后', '天过期']}
          optionKey="lifeTime"
          currentOption={option}
          value={nbInput} onChange={onNumberChange}
          onBlur={onBlur} onSwitch={onSwitch}
        />
        <OptionalSetting type="date"
          text={['', '过期']}
          optionKey="fixDate"
          currentOption={option}
          value={dtInput} onChange={onDateChange}
          onBlur={onBlur} onSwitch={onSwitch}
        />
      </div>
    </Wrapper>
  )
}
/**
 * ！！！ 这里不能做到动态跟随外部日期调整而动态更新内部参考，否则会乱掉 ！！！
 */
// useEffect(() => {
//   if (
//     (document.activeElement === refNb.current) || 
//     (document.activeElement === refDt.current)
//   ) {
//   } else {
//     console.log('###### effect ext default set ######', defaultValues)
//   }
// }, [defaultValues])
