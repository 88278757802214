// ->
export const IconArrowForward = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
  </svg>
)
// v
export const IconKBArrowD = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
  </svg>
)
// <
export const IconKBArrowL = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
  </svg>
)
// >
export const IconKBArrowR = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
  </svg>
)
// ^
export const IconKBArrowU = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/>
  </svg>
)
