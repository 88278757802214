import styled from 'styled-components'

export const Box = styled.div`
  width: 8em;
  height: 6em;
  padding: .4em;
  margin: 2em 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  &.small {
    height: 3em;
  }
`;
