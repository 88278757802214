import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components'
import { SubmitButtonArea, SubmitButton } from '../../Layout'
import {
  IFormValues, IFormProps, 
  NumberInput, TextInput, TextAreaInput, ChoiceInput,
} from '../../FormElements'
import { autoDescription, autoPreferenceName } from './helper'
import { SCOPE_SETTING_LIST, COUPON_TYPE_LIST } from './common'
import { useEffect } from 'react'

const Wrapper = styled.div`
  padding: 1rem 1rem 6rem;
`

const NoShow = styled.div``

const VALID_SCHEMA = yup.object({
  'type'          : yup.string().required(`类型不能为空`),
  'name'          : yup.string().required(`券名不能为空`),
  'description'   : yup.string().required(`使用说明不能为空`),
  'amount'        : yup.number().integer('优惠必须是整数').min(1, '优惠必须是正数'),
  'minimumExpense': yup.number().integer('最低消费必须是整数').min(0, '最低消费必须不能是负数'),
}).required();

export const Form: React.FC<IFormProps> = ({merchantName, defaultValues, validationSchema, submitHandler}) => {
  const {
    handleSubmit, control, watch, setValue, trigger, // register, setError, clearErrors, reset, getValues,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    // shouldFocusError: true,
    defaultValues,
    resolver: yupResolver(VALID_SCHEMA),
    // resolver: !validationSchema ? undefined : yupResolver(validationSchema),
  })
  // const history = useHistory()

  const allFields = watch()
  // console.log('allFields', allFields)
  // console.log('valid', isValid, 'dirty', isDirty)
  // console.log({isValid, errors})
  useEffect(() => {
    // console.log('effect', allFields, isValid, errors)
    if ( allFields.type === 'GOODS' ) {
      let updated = false;
      if (!allFields.amount) {
        setValue('amount', 10)
        updated = true;
      }
      if (allFields.scopes?.length !== 1 || allFields.scopes[0] !== 'IN-STORE') {
        setValue('scopes', ['IN-STORE'])
        updated = true;
      }
      if (updated) {
        trigger()
      }
    }
  }, [allFields])

  const toResetDescription = () => {
    return autoDescription({
      merchantName,
      scopes: allFields.scopes
    })
  }
  const toSetName = () => autoPreferenceName(allFields.type, allFields.amount, allFields.minimumExpense)

  const onSubmit = async (data: IFormValues) => {
    if (!submitHandler) return
    submitHandler(data)
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper className="flex-start direction-col" style={{gap: '18px'}}>
        <Controller control={control} // defaultValue={""}
          name="type"
          render={(field) => (
            <ChoiceInput {...field} ref={undefined} label="类型"
              required
              type='SINGLE'
              options={COUPON_TYPE_LIST} 
            />
          )}
        />
        <div style={{backgroundColor: 'white', borderRadius: '8px'}}>
          <Controller control={control} // defaultValue={""}
            name="minimumExpense"
            render={(field) => (
              (allFields.type !== 'DEDUCT') ? <NoShow /> :
              <NumberInput {...field} ref={undefined} label="消费金额满" type="number" />
            )}
          />
          { (allFields.type === 'DEDUCT') && 
            <hr style={{border: 'none', borderTop: '1px solid rgba(0,0,0,0.09)' }} />
          }
          <Controller control={control} // defaultValue={""}
            name="amount"
            render={(field) => (
              (allFields.type === 'GOODS') ? <NoShow /> :
              <NumberInput {...field} ref={undefined} type="number" required
                label= {(allFields.type === 'DEDUCT') ? "扣减金额" : "折扣力度（%OFF）"} />
            )}
          />
        </div>
        <Controller control={control} // defaultValue={""}
          name="name"
          render={(field) => (
            <TextInput {...field} ref={undefined} 
              label="券名" placeholder="请输入优惠券名称"
              type="text" required
              autoText={toSetName}
            />
          )}
        />
        <Controller control={control} // defaultValue={""}
          name="scopes"
          render={(field) => (
            (allFields.type === 'GOODS' ) ? <NoShow /> :
            <ChoiceInput {...field} ref={undefined}
              required
              label="使用范围（可多选）" 
              type='MULTIPLE'
              options={SCOPE_SETTING_LIST}
            />
          )}
        />
        <Controller control={control} // defaultValue={""}
          name="description"
          render={(field) => (
            <TextAreaInput {...field} ref={undefined}
              required
              label="使用说明" 
              placeholder="请输入使用说明"
              autoText={toResetDescription}
            />
          )}
        />
      </Wrapper>

      <SubmitButtonArea className="fix-bottom">
        <SubmitButton type="submit" disabled={!isValid || !isDirty}>
          确认提交
        </SubmitButton>
      </SubmitButtonArea>
    </form>
  )
}
