import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME } from '../../config'

const Container = styled.div`
  width: 100%;
  padding: 8px 0;
  border-radius: 8px;
  background: ${THEME.color.gray['#6-d9']};

  position: relative;
`;
const TextLayer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const Stick = styled.div`
  width: ${props => `${props.width}%`};
  height: calc(100% - 4px);
  border-radius: 8px;

  position: absolute;
  top: 2px;
  left: ${(props) => (props.offset === 0) ? '2px' : `calc(${props.offset * props.width}% - 2px)` };
  background: #fff;
  transition: all .3s;
`;

interface SwitchOptionProps {
  onClick: () => void;
  children: any;
  [propName: string]: any;
}
export const SwitchOption: React.FC<SwitchOptionProps> = ({onClick, children}) => (
  <div style={{width: '100%', textAlign: 'center'}}>
    <span className="ft-rm16 clr-ft-gray0-087" onClick={onClick}>{children}</span>
  </div>
)

interface SwitchProps {
  stickWidth?: number;
  offset: number;
  children: any;
  [propName: string]: any;
}
export const SwitchSlider: React.FC<SwitchProps> = ({stickWidth, offset, children, ...restProps}) => {
  return (
    <Container {...restProps} >
      <Stick offset={offset} width={stickWidth || 50} />
      <TextLayer>{children}</TextLayer>
    </Container>
  )
}
