import React from "react";
import styled from 'styled-components'

type CardProps = {
  title?: string
  children?: React.ReactNode
}

function Card({title, children}: CardProps) {
  return (
    <Container>
      {title && (
        <div style={{
          fontSize: 17,
          fontWeight: 500,
          marginBottom: 16
        }}>

          {title}
        </div>

      )}
      {children && (
        <div style={{
          fontSize: 12
        }}>
          {children}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
`

export default Card
