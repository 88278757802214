import { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { requestGet } from '../../lib'
import { useLocationMerchantDataChecker } from '../../hooks'
import { Tabs, Tab, EmptyBlock } from '../../Layout'
import { PromotionStatusEnum, PromotionStatusLabel, IPromotionInfo } from './common'
import { REQ_URL } from './helper'
import { PromotionCard } from './Card'

const Wrapper = styled.div`
  padding: 0 1rem;
`

const TabStatusList = [PromotionStatusEnum.All, PromotionStatusEnum.Ongoing, PromotionStatusEnum.Completed]
const generateFilter = (status: PromotionStatusEnum) => (item:IPromotionInfo) => (
  (status === PromotionStatusEnum.All) ? true :
  (item.status === status)
)

export const PromotionList = () => {
  const [tab, setTab] = useState<PromotionStatusEnum>(PromotionStatusEnum.All)
  const [items, setItems] = useState<IPromotionInfo[]>([])
  const { merchantId } = useLocationMerchantDataChecker()

  const initData = async () => {
    const result = await requestGet(`${REQ_URL}?merchantId=${merchantId}&sorter=createdAt,DESC`, {})
    if ((result.code === 0) && (!!result.data?.length)) {
      setItems(result.data.map((x)=> ({
        ...x,
        dueDate: dayjs(x.dueDate).format('YYYY年MM月DD日'),
        status: dayjs(x.dueDate).isBefore(dayjs()) ? PromotionStatusEnum.Completed : PromotionStatusEnum.Ongoing
      })))
    }
  }

  useEffect(() => {
    initData()
  }, [])

  const fnFilter = generateFilter(tab);
  // console.table(items)
  return (
    <Wrapper>

      <Tabs style={{margin: '2rem 0 1.5rem', padding: '0 0 0 0rem'}}>
      { TabStatusList.map((t, index:number) => (
        <Tab key={index} className={(tab === t) ? 'active' : ''}
          onClick={() => setTab(t)}
        >
          {PromotionStatusLabel[t]}
        </Tab>
      )) }
      </Tabs>

      { items.filter(fnFilter).map((item, index:number) => (
        <PromotionCard key={index} {...item} />
      )) }
      { !items.length &&
        <EmptyBlock style={{marginTop: '6rem'}}></EmptyBlock>
      }

    </Wrapper>
  )
}
