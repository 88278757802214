import {useLocationMerchantDataChecker} from '../../hooks'
import {Header, Layout, toast} from '../../Layout'
import {requestPut} from '../../lib'
import {converEntity2Fields, converFields2Entity} from './common';
import {Form} from './Form'
import {REQ_URL} from './helper'
import {IFormFields} from './type.form'

export default function EditingPage () {
  const { history, location, merchantId, merchantData } = useLocationMerchantDataChecker()

  const onSubmit = async (fields: IFormFields) => {
    const data = converFields2Entity(fields, merchantId, location.state?.formData?.id)
    const result = await requestPut(REQ_URL, data)
    // console.log(fields, data, result)
    if((result.code === 0) && ('number' === typeof result.data.id)) {
      toast.success('编辑成功')
      // because previous page may be preference select list page
      history.goBack()
    } else {
      toast.error('保存失败')
    }
  }

  return (
    <Layout>
      <Header title="编辑活动" />
      <Form
        defaultValues={converEntity2Fields(location.state?.formData)}
        submitHandler={onSubmit}
        merchantData={merchantData}
      >
      </Form>
    </Layout>
  )
}
