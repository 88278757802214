import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from '../Layout'

export const useLocationMerchantDataChecker = () => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const searchParams = new URLSearchParams(location.search);
  const merchantData= location?.state?.merchantData;
  const merchantName= location?.state?.merchantData?.name ?? '?';
  const merchantId  = location?.state?.merchantData?.id ?? null;
  // console.log(merchantData)

  useEffect(() => {
    if (merchantId === null) {
      history.go(-1)
      toast.error('参数缺失')
    }
  }, [])

  return ({
    history,location,params,searchParams,
    merchantData, merchantId, merchantName,
  })
}
