import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Button, CheckBox} from "../../components";
import Header from "../../components/Header";
import LoadingDialog from "../../components/LoadingDialog";
import WhiteSpace from "../../components/WhiteSpace";
import {THEME} from "../../config";
import {EntityContext, UserContext} from "../../context";
import {Radio} from "../../forms/Radio";
import {requestGet, requestPost} from "../../lib";
import {getImageById, getImageByIdWithSize, uploadImage} from "../../utils/ImageUtils";
import PageLayout from "../Global/PageLayout";
import {useHistory} from "react-router-dom";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const defaultImage = [
  'https://res.cloudinary.com/shenmanz/image/upload/v1674781703/tuangou/icon/backgroundImage1.png',
  'https://res.cloudinary.com/shenmanz/image/upload/v1674781703/tuangou/icon/backgroundImage3.png',
  'https://res.cloudinary.com/shenmanz/image/upload/v1674781703/tuangou/icon/backgroundImage2.png'
]

function BackgroundImage() {
  const {
    model: entityModel,
    replace,
  } = React.useContext(EntityContext);
  const globalData = entityModel["GLOBAL_DATA"].response;
  const [uploading, setUploading] = useState(false)
  const {model: userModel} = useContext(UserContext);
  const history = useHistory<any>();
  const [images, setImages] = useState([])
  const [selectedImage, setSelectImage] = useState(globalData.meta_backgroundImage)

  const getMerchantBg = async () => {
    const {data} = await requestGet("merchant/backgroundImages", {
      userId: userModel.profile.id
    });
    if (data) {
      setImages([...defaultImage, ...data.map(item => getImageByIdWithSize(item.imageUrl, undefined, 171 * 3, 92 * 3))])
    }
  };

  const saveNewImage = async (url: string) => {
    const {data} = await requestPost("merchant/add-backgroundImages", {
      userId: userModel.profile.id,
      imageUrl: url
    });
    console.log(data)
  }

  useEffect(() => {
    getMerchantBg()
  }, [])

  return (
    <PageLayout pageName="background_image no nav">
      <Header
        title='背景图'
      />
      <Container>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            padding: 16
          }}
        >
          {images.map(item => {
            return (
              <div
                key={item}
                style={{
                  width: '50%',
                  padding: 4,
                  minHeight: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                onClick={() => {
                  setSelectImage(item)
                }}
              >
                <img
                  style={{
                    maxWidth: '100%'
                  }}
                  src={item}
                />
                <div
                  style={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Radio value={selectedImage === item}/>
                  <span style={{fontSize: 12, color: THEME.color.gray["#11-065"]}}>设为背景图</span>
                </div>
              </div>
            )
          })}
          <label
            style={{
              marginTop: 4,
              width: '50%',
              display: 'flex',
              // flex: 1,
              minHeight: 92,
              backgroundColor: THEME.color.gray["#6-d9"],
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 29
            }}
            htmlFor="icon-button-file">
            <Input
              accept="image/*"
              id="icon-button-file"
              type="file"
              onChange={async (e) => {
                setUploading(true)
                const public_id = await uploadImage(e.target.files[0]);
                await saveNewImage(public_id);
                await getMerchantBg()
                setUploading(false)
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CameraAltIcon style={{color: THEME.color.gray["#11-065"]}}/>
              <span style={{color: THEME.color.gray["#11-065"], fontSize: 12, marginTop: 4}}>上传照片</span>
            </div>
          </label>
        </div>
        <div className="bottom-float">
          <Button
            fullWidth
            onClick={() => {
              replace("GLOBAL_DATA", {
                ...globalData,
                meta_backgroundImage: selectedImage,
              });
              history.goBack()
            }}
          >
            确认修改
          </Button>
        </div>

      </Container>
      <WhiteSpace height={56}/>
      <LoadingDialog visible={uploading}/>
    </PageLayout>
  )
}

export default BackgroundImage

const Container = styled.div`

  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`;

const Input = styled("input")({
  display: "none",
});
