import React, { useMemo, useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "styled-components";
import {
  getParams,
  mapBackendMechant,
  filterGroupMerchants,
  splashScreen,
  userCache,
  paramsCache,
  buildSitePageUrl,
} from "../../lib";
import { EntityContext, UserContext } from "../../context";
import { THEME, LOCAL_URL, EXTERNAL_URL } from "../../config";
import PageLayout from "../Global/PageLayout";
import GroupBuyCard from "../../business/components/GroupBuyCard";
import EmptyView from "../../components/EmptyView";
import WhiteSpace from "../../components/WhiteSpace";
const NAVBAR_HEIGHT = THEME.size.NAVBAR_HEIGHT;



const showSplashScreen = (isAnonymous, setOpen) => {
  const flag = splashScreen.get();
  if (!!flag) return; // splash screen have been ever opened already.
  // else
  const cachedInfo = userCache.get();
  if (!cachedInfo) setOpen("INTRODUCTION");
  splashScreen.set("DONE");
};

export const EmptyContainer = styled.div`

`

const isBackFromOtherPage = (location) =>
  location?.state?.scenario === "WELCOME_BACK";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        // margin: "0px 16px 48px 16px",
        height: window.innerHeight - 48 - 48,
        overflowY: "scroll",
        paddingTop: 16,
        // paddingLeft: 16,
        // paddingRight: 16
      }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WelcomePage({ location }) {

  const { model: entityModel, query, create, remove, update, replace, flush } = React.useContext(EntityContext);

  //const groupBuyData = !entityModel['GROUP_BUY'].response?.data?.length ? null : entityModel['GROUP_BUY'].response?.data;
  const myGroupData = entityModel['MY_GROUPS'].response?.data;
  const myGroupsInOrders = entityModel['MY_GROUPS_IN_ORDERS'].response?.data;
  const recentlyViewedGroups = entityModel['RECENTLY_VIEWED_GROUPS'].response?.data;
  const history = useHistory()


  useEffect(() => {
    //query("GROUP_BUY");
    query('MY_GROUPS');
    query('MY_GROUPS_IN_ORDERS');
    query('RECENTLY_VIEWED_GROUPS');
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PageLayout pageName={"home"}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{ background: "#fff" }}
        >
          <Tab label="最近浏览" {...a11yProps(0)} />
          <Tab label="我参与的" {...a11yProps(1)} />
          <Tab label="我发布的" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <PageContainer>
        {!!recentlyViewedGroups && recentlyViewedGroups.length > 0 ? recentlyViewedGroups.map(g => <GroupBuyCard key={g.id} {...g} />) :
          <EmptyContainer>
            <EmptyView desc="还没有记录哦" hideBackground={true}/>
            <WhiteSpace height={40}/>
            <div style={{
              fontSize: 15,
              lineHeight: '21px',
              textAlign: "center"
            }}>
              <p>以下是示例店铺，仅供体验，请勿下单</p>
              <p>了解更多大吉信息或联系客服，<span style={{color: THEME.color.primary.default}} onClick={()=>{history.push(LOCAL_URL["ABOUT"])}}>请点击这里</span></p>
            </div>
            <WhiteSpace height={16}/>
            {!!recentlyViewedGroups && !!recentlyViewedGroups.defaultGroup && recentlyViewedGroups.defaultGroup.map(g => <GroupBuyCard key={g.id} {...g} />)}
          </EmptyContainer>
        }
        </PageContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <PageContainer>
        {!!myGroupsInOrders && myGroupsInOrders.length > 0 ? myGroupsInOrders.map(g => <GroupBuyCard key={g.id} {...g} />) :
          // <EmptyContainer>
          //   <EmptyView desc="暂无数据" />
          // </EmptyContainer>
          null
        }
      </PageContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <PageContainer>
        {!!myGroupData && myGroupData.length > 0 ? myGroupData.map(g => <GroupBuyCard key={g.id} {...g} />) :
          // <EmptyContainer>
          //   <EmptyView desc="暂无数据" />
          // </EmptyContainer>
          null
        }
      </PageContainer>
      </TabPanel>
    </PageLayout>
  );
}

const PageContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`
