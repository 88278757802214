import * as React from "react";
import {useEffect, useRef} from "react";
import {QRCodeSVG} from "qrcode.react";
import {CircularProgress} from "@mui/material";
import styled from "styled-components";
import numeral from "numeral";
import html2canvas from "html2canvas";
import {getImageByIdWithSize} from "../../utils/ImageUtils";
import {getPublicFromUrl} from "../../lib/util";

export interface IGroupShareDialog {
  avatar: string;
  name: string;
  image?: string;
  groupTitle: string;
  price: string;
  groupId: number;
  primaryColor?: string
  url?: string
}

const SGroupShareDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .btn-close {
      align-self: flex-end;
    }

    .share-image {
      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 343px;
      height: 522px;
      display: none;
    }

    .share-content {
      background-color: #fff;
      height: 522px;
      width: 343px;
      padding: 16px;
      align-self: center;
      display: flex;
      flex-direction: column;

      .avatar-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 40px;
        }

        .merchant-name {
          margin-left: 8px;
          font-size: 17px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .img-prod {
        margin-top: 16px;
        width: 311px;
        height: 311px;
        align-self: center;
      }

      .price-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left {
          margin-top: 16px;

          .group-title {
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
          }

          .price {
            font-weight: 500;
            font-size: 17px;
            line-height: 24px;
            //text-align: center;
            color: ${props => props.primaryColor ?? '#cc1543'};
          }
        }

        .qrcode-container {
          margin-top: 16px;
          display: flex;
          width: 64px;
          flex-direction: column;
          align-items: center;

          .qrcode-title {
            font-size: 10px;
            line-height: 17px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }


    }

    .save-img-desc {
      margin-top: 17px;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }
  }
`;

export default function ShareImage({
                                     avatar,
                                     name,
                                     image,
                                     groupTitle,
                                     price,
                                     primaryColor,
                                     url
                                   }: IGroupShareDialog) {
  const ref = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [loading, setlLoading] = React.useState(false);

  const loadImageAsync = async () => {
    const avatarUrl = getImageByIdWithSize(avatar, undefined, 240);

    let descImageUrl = image
      ? image
      : "https://res.cloudinary.com/shenmanz/image/upload/c_lpad,h_311,w_311/v1651723611/tuangou/Group_1773_3x.png";

    let images = ref.current.getElementsByTagName("img");
    setlLoading(true);

    images[0].src = avatarUrl;
    images[1].src = descImageUrl;
    // Wait for it to load.
    await new Promise((resolve) => {
      images[0].onload = resolve;
    });
    await new Promise((resolve) => {
      images[1].onload = resolve;
    });

    html2canvas(ref.current, {
      width: 343,
      height: 522,
      allowTaint: true,
      useCORS: true,
    })
      .then((canvas) => {
        imgRef.current.src = canvas.toDataURL();
        imgRef.current.style.display = "block";
        ref.current.style.display = "none";
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setlLoading(false);
      });
  };

  useEffect(() => {
    if (
      ref.current === null ||
      imgRef.current === null ||
      imgRef.current.hasChildNodes()
    ) {
      return;
    }
    loadImageAsync();
  }, [ref, image]);

  return (
    <SGroupShareDialog primaryColor={primaryColor}>
      <div className="container">
        {loading && <CircularProgress style={{alignSelf: "center"}}/>}
        <img className="share-image" ref={imgRef}/>
        <div ref={ref} className="share-content">
          <div className="avatar-container">
            <img className="avatar"/>
            <div className="merchant-name">{name}</div>
          </div>
          <img className="img-prod"/>
          <div className="price-container">
            <div className='left'>
              <div className="group-title">{groupTitle}</div>
              <div className="price">
                {numeral(price).divide(100).format("$0.00")}起
              </div>
            </div>
            <div className="qrcode-container">
              <QRCodeSVG
                size={64}
                value={url}
              />
              <div className="qrcode-title">扫码参加接龙</div>
            </div>
          </div>
        </div>
      </div>
    </SGroupShareDialog>
  );
}
