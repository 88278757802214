import * as React from "react";
import styled from "styled-components";

export interface IOrderManagementGuideProps {
  visible?: boolean;
  onOkClick?: () => void;
}

const Root = styled.div`
  position: fixed;
  z-index: ${(props) => props.level || 999999999};
  height: ${(props) => props.height || "100%"};
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .container {
    display: flex;
    flex-direction: row;
    margin-bottom: 90px;
  }

  .arrow {
    position: absolute;
    left: 16px;
  }
  .title {
  }

  .btn-ok {
    margin-top: 16px;
  }
`;

export default function OrderManagementGuide({
  visible,
  onOkClick,
}: IOrderManagementGuideProps) {
  return visible ? (
    <Root>
      <img className="title" src="/static/guide_management_title.svg" />
      <div className="container">
        <img className="arrow" src="/static/guide_arrow_down.svg" />
        <img
          onClick={onOkClick}
          className="btn-ok"
          src="/static/guide_btn_ok.svg"
        />
      </div>
    </Root>
  ) : null;
}
