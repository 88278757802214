import styled from "styled-components";
import {Button, Input, InputField, notify} from "../../components";
import * as React from "react";
import WhiteSpace from "../../components/WhiteSpace";
import {useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {QRCodeSVG} from "qrcode.react";
import ShareImage from "./PromotionImage";
import {getImageByIdWithSize, getResizedSharedImage} from "../../utils/ImageUtils";
import {mapProductItem} from "../CreateGroupBuy/detail";
import {requestPost} from "../../lib";
import QRImage from "./QRImage";

interface ChannelCardProps {
  index: number,
  name: string,
  viewerCount: number
  uuid: string
  group: any
}

export function ChannelCard(props: ChannelCardProps) {
  const [url, setUrl] = useState('')
  const [uuid, setUUID] = useState(props.uuid)
  const [channelName, setChannelName] = useState(props.name)
  const [shareImage, setShareImage] = useState('')
  const {merchant} = props.group

  const initShareContent = (_uuid: string) => {
    setUrl(`https://tuan.shenmanz.com/public/group-buy/detail?groupId=${props.group.id}&channel=${_uuid}`)
    const htmlObject = document.createElement('div');
    htmlObject.innerHTML = props.group.description;
    const imageObj = htmlObject.getElementsByTagName("img")[0]
    if (imageObj) {
      const imageUrl = imageObj.src
      setShareImage(getResizedSharedImage(imageUrl));
    }

  }
  const generateUrl = async () => {
    if (uuid) {
      initShareContent(uuid)
    } else {
      if (!channelName) {
        notify.info('请输入渠道名称')
        return
      }
      const {data} = await requestPost('channel/create', {
        name: channelName,
        groupId: props.group.id,
        merchantId: merchant.id
      })
      if (data && data.uuid) {
        setUUID(data.uuid)
        initShareContent(data.uuid)
      }
    }
  }
  const products = props.group.groupToProductions
    ? props.group.groupToProductions.map(mapProductItem)
    : [];
  return <Container>
    <div className='title'>
      <div className='index'>渠道 {props.index || '1'}</div>
      <div className='viewer'>点击量：{props.viewerCount || '0'}</div>
    </div>
    <WhiteSpace height={8}/>
    <Input
      style={{backgroundColor: 'white'}}
      value={channelName}
      disabled={!!uuid}
      type="text"
      name="name"
      placeholder="请输入渠道名称"
      onChange={e => {
        setChannelName(e.target.value)
      }}></Input>
    <WhiteSpace height={8}/>
    <div className='button'>
      <Button onClick={generateUrl}>{uuid ? '查看链接/二维码/海报' : '生成链接/二维码/海报'}</Button>
    </div>
    <WhiteSpace height={16}/>
    {url ? (<div className='container'>
      <div className='url-container'>
        <div className='title-area'>
          <span className='title'>链接：</span>
          <CopyToClipboard
            text={url}
          >
            <div className="copy" onClick={() => {
              notify.info('链接已经复制')
            }}>
              复制
            </div>
          </CopyToClipboard>
        </div>
        <div className='url'>{url}</div>
      </div>
      <WhiteSpace height={32}/>
      <div className='qrcode-container'>
        <QRImage url={url}/>
      </div>
      <WhiteSpace height={32}/>
      <ShareImage
        avatar={getImageByIdWithSize(merchant.logo, undefined, 240)}
        name={merchant.name}
        image={shareImage}
        url={url}
        groupTitle={props.group.title}
        groupId={props.group.id}
        price={
          products && products.length > 0
            ? products.length > 1
              ? products.sort((a, b) => a.price - b.price)[0].price
              : products[0].price
            : 0
        }
      />
      <WhiteSpace/>
      <div style={{textAlign: "center"}}>长按下载二维码或者海报</div>
    </div>) : null}
  </Container>
}

const Container = styled.div`
  padding-block: 16px;
  padding-bottom: 32px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .index {
      color: var(--black-45, rgba(0, 0, 0, 0.45));
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      flex: 1
    }

    .viewer {
      color: var(--black-85, rgba(0, 0, 0, 0.85));
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex: 1
    }
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .container {
    .url-container {
      .title-area {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .title {
          color: #000;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .copy {
          color: var(--primary, #CC1543);
          text-align: right;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }


      }

      .url {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        word-break: break-all;
      }
    }

    .qrcode-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

    }
  }


`
