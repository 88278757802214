import React, {useCallback, useEffect, useRef, useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import confirm_ok from "../../svg/confirm_ok.svg";
import styled from "styled-components";
import WhiteSpace from "../../components/WhiteSpace";
import {useHistory} from "react-router-dom";
import {LOCAL_URL, THEME, WEB_ROOT, WECHAT_FOLLOW_URL} from "../../config";
import {requestGet, requestPost} from "../../lib";
import numeral from "numeral";
import {getImageById, uploadImage} from "../../utils/ImageUtils";
import UploadReceiptView from "./Components/UploadReceiptView";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import html2canvas from "html2canvas";
import {QRCodeSVG} from "qrcode.react";
import {useRouter} from "../../hooks/router";
import LoadingDialog from "../../components/LoadingDialog";

const Input = styled("input")({
  display: "none",
});

function UploadReceiptPage() {
  const history: any = useHistory();
  const router = useRouter();
  const [orderId, setOrderId] = useState("");
  const [orderGroupIndex, setOrderGroupIndex] = useState("");
  const [groupId, setGroupId] = useState("");
  const [group, setGroup] = useState<any>({});
  const [account, setAccount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [showQrImage, setShowQrImage] = useState(false);
  const [price, setPrice] = useState(0);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [receiptUrl, setReceiptUrl] = useState<string>("");
  let imageurl = null;
  const ref = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const finalImageRef = useRef<HTMLImageElement>(null);
  const modelRef = useRef<HTMLImageElement>(null);
  const [uploading, setUploading] = useState(false);

  const onUploadSuccess = async (url) => {
    await requestPost("order/public/prepay", {
      orderId: orderId,
      imageUrl: url,
    });
  };

  const getAccountDataFormOrder = useCallback(async (orderNo: string) => {
    const {data} = await requestGet("order/public/bankAccount", {orderNo});
    if (data) {
      setOrderId(data.id);
      setGroupId(data.groupId);
      setGroup(data.group);
      setAccount(data.transInfo?.accountNo);
      setAccountName(data.transInfo?.accountName);
      setPrice(data.totalPrice);
      setOrderGroupIndex(data.groupIndex);
    }
  }, []);
  useEffect(() => {
    let orderNo = null;
    if (history.location && history.location.state) {
      orderNo = history.location.state.orderNo;
      setShowSaveBtn(true);
    }
    if (router.query && router.query.orderNo) {
      setShowSaveBtn(false);
      orderNo = router.query.orderNo;
    }
    if (orderNo) {
      getAccountDataFormOrder(orderNo);
    }
  }, [getAccountDataFormOrder]);
  return (
    <PageLayout pageName="no nav orderConfirm">
      <Header title={showSaveBtn ? "下单成功请付款" : "付款信息"}/>
      {receiptUrl ? (
        <div>
          <Container style={{alignItems: "center"}}>
            <WhiteSpace height={50}/>
            <img src={confirm_ok} alt=""/>
            <WhiteSpace height={24}/>
            <div className="title2">付款凭证上传成功，等待团长审核</div>
            <WhiteSpace height={12}/>
            <div className="order-no">
              <a
                onClick={() => {
                  router.push(LOCAL_URL["RECEIPT_VIEW"], {
                    status: "PRE_PAID",
                    image: receiptUrl,
                    role: "USER",
                    orderId: null,
                    userMobile: "",
                    message: "",
                  });
                }}
              >
                点击查看付款截图
              </a>
            </div>
            <WhiteSpace height={24}/>
            <div className="desc">
              所有的订单通知将通过微信公众号“新西兰神马网”发送，请关注公众号并留意消息哦。
              <a href={WECHAT_FOLLOW_URL} target="_blank">
                立即点击关注
              </a>
            </div>
          </Container>
          <WhiteSpace height={80}/>
          <Button2
            onClick={() => {
              if (group?.type === 'DEFAULT') {
                if (groupId) {
                  history.replace(
                    LOCAL_URL["GROUP_BUY_DETAIL"] + `?groupId=${groupId}`
                  );
                } else {
                  history.replace(LOCAL_URL["WELCOME"]);
                }
              } else {
                if (groupId) {
                  history.replace(
                    LOCAL_URL["SHOPPING_DETAIL"] + `?groupId=${groupId}`
                  );
                } else {
                  history.replace(LOCAL_URL["WELCOME"]);
                }
              }

            }}
          >
            {group?.type === 'DEFAULT' ? '返回团购接龙' : '返回在线网店'}
          </Button2>
        </div>
      ) : (
        <div style={{backgroundColor: "white"}}>
          <div ref={ref}>
            <UploadReceiptView
              showQRCode={false}
              orderId={orderId}
              orderIndex={orderGroupIndex}
              accountName={accountName}
              accountNo={account}
              amount={numeral(price).divide(100).format("$0,0.00")}
            />
          </div>
          {showSaveBtn && (
            <div>
              <WhiteSpace height={40}/>
              <Button2
                onClick={() => {
                  html2canvas(ref.current, {
                    width: window.innerWidth,
                    height: 450,
                    allowTaint: true,
                    useCORS: true,
                  })
                    .then((accountCanvas) => {
                      setShowQrImage(true);
                      if (imageRef) {
                        imageRef.current.src = accountCanvas.toDataURL();
                      }
                      html2canvas(modelRef.current, {
                        width: modelRef.current.width,
                        height: modelRef.current.height,
                        allowTaint: true,
                        useCORS: true,
                      }).then((finalCanvas) => {
                        finalImageRef.current.src = finalCanvas.toDataURL();
                        finalImageRef.current.style.display = "block";
                        modelRef.current.style.display = "none";
                      });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }}
              >
                保存付款信息到相册
              </Button2>
            </div>
          )}
          <WhiteSpace height={32}/>
          <label htmlFor="icon-button-file">
            <Input
              accept="image/*"
              id="icon-button-file"
              type="file"
              onChange={async (e) => {
                setUploading(true);
                const public_id = await uploadImage(e.target.files[0]);
                imageurl = getImageById(public_id);
                setReceiptUrl(imageurl);
                await onUploadSuccess(imageurl);
                setUploading(false);
              }}
            />
            <Button>上传付款截图</Button>
          </label>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showQrImage}
            onClose={() => {
              setShowQrImage(false);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <StyledModal>
              <img
                style={{
                  display: "none",
                  width: "100%",
                }}
                ref={finalImageRef}
                alt="image"
              />
              <div ref={modelRef}>
                <img
                  style={{
                    width: "100%",
                  }}
                  ref={imageRef}
                  alt="image"
                />
                <div className="qrcode-container">
                  <QRCodeSVG
                    size={120}
                    value={
                      WEB_ROOT +
                      LOCAL_URL["PAYMENT_UPLOAD"] +
                      `?orderNo=${orderId}`
                    }
                  />
                  <WhiteSpace/>
                  <div className="qrcode-title">微信扫一扫</div>
                  <div className="qrcode-title">上传付款截图</div>
                  <WhiteSpace height={16}/>
                </div>
              </div>
              <div
                style={{
                  fontSize: 17,
                  color: "white",
                  textAlign: "center",
                  paddingTop: 24,
                }}
              >
                长按图片保存至手机相册
              </div>
            </StyledModal>
          </Modal>
          <LoadingDialog visible={uploading}/>
        </div>
      )}
    </PageLayout>
  );
}

export default UploadReceiptPage;

const StyledModal = styled.div`
  position: absolute;
  left: 60px;
  right: 60px;
  top: 70px;
  overflow: hidden;
  border: none;
  outline: none;

  &:focus {
    border: none;
    outline: none;
  }

  .qrcode-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .qrcode-title {
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      color: rgba(0, 0, 0, 0.45);
    }
  }
`;

const Button = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  background: ${THEME.color.primary.default};
  color: white;
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;

  text-align: center;
`;

const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .title {
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    width: 100%;
    text-align: center;
  }

  .title2 {
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  .order-no {
    a {
      font-size: 15px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      text-decoration: none;
    }
  }

  .desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
  }
`;

const Button2 = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  background: #ffffff;
  color: ${THEME.color.primary.default};
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;

  text-align: center;
`;
