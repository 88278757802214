interface IThemeColor {
  HEX?: string; // '#767676'

  HSL?: string; // '120, 100%, 50%'
  H?:number;
  S?:number;
  L?:number;

  RGB?: string; // 255, 127, 31
  R?:number;
  G?:number;
  B?:number;

}
export interface IGlobalTheme {
  size: {
    header: {
      [propName:string]: string;
    }
  }
  color: {
    [name:string]: IThemeColor;
  }
}
export const THEME: IGlobalTheme = {
  size: {
    header: {
      height: '3rem',
    }
  },
  color: {
    primary: { HEX: '#cc1543', H: 204, S: 21, L: 67 },
    highlight: { RGB: `204,21,67` },
    bgNormal: { HEX: '#F7F7F7' },
    bgDisabled: { HEX: '#AAA' },
  }
}
