import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { THEME, LOCAL_URL } from '../config';
import {paramsCache, buildSitePageUrl, requestPost} from '../lib';
import {setRedirectUrl} from "../utils/persistence";
import { EntityContext, UserContext } from '../context';
import useMerchant from '../hooks/useMerchant';
import { useDialog } from '../hooks';
import { PopUpMenu } from './PopUpMenu'
import { IconHome, IconSearch, IconCoffee, IconAccount, IconMapView, IconPlus } from './Navigator.icons'

const POPUP_MENU_OPTIONS_DICT = {
  'clone-exist': '复制往期团购，快速开团',
  'brand-new': '创建新的团购',
}
const POPUP_MENU_OPTIONS_COLOR = {
  'clone-exist': 'Crimson',
  'brand-new': 'DarkOrange',
}
const POPUP_MENU_OPTIONS_LIST = Object.keys(POPUP_MENU_OPTIONS_DICT).reduce((accu:any[], key:string) => {
  accu.push({ value: key, label: POPUP_MENU_OPTIONS_DICT[key], color: POPUP_MENU_OPTIONS_COLOR[key] })
  return accu
}, ([] as any[]))

const NAVBAR_HEIGHT = THEME.size.NAVBAR_HEIGHT;
const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;

const Container = styled.div`
  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    display: none;
  }
`;
const BottomNavbar = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${NAVBAR_HEIGHT};
  background: #fff;
  z-index: 999;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const IconItem = styled.div`
  cursor: pointer;
  position: relative;
  & svg {
    height: 24px;
    fill: ${(props) => props.activated ? THEME.color.primary.default : '#AFAFAF'};
  }
  & div {
    font-size: 12px;
    color: ${(props) => props.activated ? THEME.color.primary.default : '#AFAFAF'};
  }
`;
const IconButton = styled(IconItem)`
`;
const IconMenu = styled(IconItem)`
  width: 136px;
  margin: 32px auto;
  color: ${(props) => props.activated ? THEME.color.primary.default : '#AFAFAF'};
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & span {
    margin-left: 8px;
  }
`;

export const DotNumber = styled.div`
  position: absolute;
  top: -6px;
  left: 14px;
  width: ${props => props.size || '16px'};
  height:  ${props => props.size || '16px'};
  border-radius: 100%;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;

  background: ${THEME.color.primary.default};
  color: #fff;

  &.white {
    color: ${THEME.color.primary.default};
    background: #fff;
  }
`;

const TARGET_URL = {
  'home': LOCAL_URL['WELCOME'],
  'map view': LOCAL_URL['MAP_VIEW'],
  'search': LOCAL_URL['SEARCH'],
  'orders': LOCAL_URL['MY_ORDERS'],
  'personal center': LOCAL_URL['PERSONAL_CENTER'],
  'create-group-buy': `${LOCAL_URL['CREATE_GROUP_BUY']}`,
  'create-group-buy-by-clone': `${LOCAL_URL['GROUP_LIST']}?actions=copy`,
  'coffee-pass': `${LOCAL_URL['CHANNEL_SLUG']}/coffee-pass`,
}
const gotoPage = (history, target) => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
  if (TARGET_URL[target] === LOCAL_URL.WELCOME) history.push(buildSitePageUrl(LOCAL_URL.WELCOME, paramsCache));
  else history.push(TARGET_URL[target]);
}

interface NavbarProps {
  page?: string;
}
export const Navbar: React.FC<NavbarProps> = ({ page }) => {
  const history = useHistory();
  // const { size: waitListSize } = useWaitingList();
  const { model: entityModel, query } = useContext(EntityContext);
  const { isReady, isAnonymous } = useContext(UserContext);
  const { merchants, appendMerchant } = useMerchant();
  const { showDialog, renderDialog } = useDialog();
  const {switchMerchant} = useMerchant();
  const [ showMenu, setShowMenu ] = useState(false)
  
  const onClick = async (target) => {
    if (target.indexOf('create-group-buy') === 0 ) {
      // showDialog({
      //   title: '注册商家？', message: '“发布团购”是商家功能，请注册成为商家后使用。', cancelText: '取消', okText: '免费注册', onOK: () => {
      //     history.push(LOCAL_URL['ADD_MERCHANT']);
      //   }
      // });
      // return;
      if (!merchants || merchants.length === 0){
        const {data} = await requestPost('merchant/createDefaultMerchant',{})
        appendMerchant(data)
        switchMerchant(data.id)
      }
    }

    if (
      (target.indexOf('create-group-buy') === 0) && 
      (merchants.length > 1)
    ) {
      history.push(LOCAL_URL['SWITCH_MERCHANT'], { 
        url: TARGET_URL[target]
      });
      return;
    }

    gotoPage(history, target);
  }

  const prepareToCreateNewGroupBy = () => {
    if (!!isReady && !!isAnonymous) {
      setRedirectUrl(window.location.href)
      history.push(LOCAL_URL.USER_LOGIN);
    } else {
      setShowMenu(true)
    }
  }
  const onMenuClick = (value:string) => {
    setShowMenu(false)
    if (value === 'brand-new') {
      onClick('create-group-buy')
    } else if (value === 'clone-exist') {
      onClick('create-group-buy-by-clone')
    }
  }

  // useEffect(() => {
  //   if (!entityModel['MY_CARDS'].ready) query('MY_CARDS');
  // }, [])

  // const anyBankCard = !!entityModel['MY_CARDS'].response?.data?.length;
  return (
    <>

    <Container>
      <BottomNavbar className="shadow02">
        <IconButton
          onClick={()=>{onClick('home')}}
          activated={page?.indexOf('home') === 0}
          size="large">
          <IconHome />
          <div>首页</div>
        </IconButton>
        <IconButton
          onClick={prepareToCreateNewGroupBy}
          activated={page?.indexOf('create-group-buy') === 0}
          size="large">
          <IconPlus />
          <div>发布</div>
        </IconButton>
        <IconButton
          onClick={()=>{onClick('personal center')}}
          activated={page === 'personal center' || page === 'GROUP_LIST_PAGE'}
          size="large">
          <IconAccount />
          <div>我的</div>
          {/* {!isAnonymous && !anyBankCard && <DotNumber size="11px"></DotNumber>}  */}
        </IconButton>
      </BottomNavbar>

      {/* 这里设置一个占位，让页面上最后会有一个高度，避免页面上的元素被 Navbar 遮盖 */}
      {/* { (page?.indexOf('MAP_MODE') < 0) && <div style={{height: NAVBAR_HEIGHT}}/> } */}
      {renderDialog()}
    </Container>

    <PopUpMenu title="请选择创建方式"
      visible={showMenu}
      onCancel={() => setShowMenu(false)}
      options={POPUP_MENU_OPTIONS_LIST}
      onClick={onMenuClick}
    >
    </PopUpMenu>

    </>
  );
}

const MenuContainer = styled.div`
  /* width: 297px; */
  margin: 20px auto;
  text-align: center;
  white-space: nowrap;

  & div.brand svg {
    height: 24px;
    fill: ${THEME.color.primary.default};
  }
`;

export const NavMenu = ({ page = 'home' }) => {
  const history = useHistory();
  // const { size: waitListSize } = useWaitingList();
  const { model: entityModel, query } = useContext(EntityContext);
  const { isAnonymous } = useContext(UserContext);

  const onClick = (target) => { gotoPage(history, target) }



  const anyBankCard = !!entityModel['MY_CARDS'].response?.data?.length;
  return (
    <div style={{ width: "297px" }}>
      <MenuContainer className="debugx">

        <IconMenu className="debugx" activated={page?.indexOf('home') === 0} onClick={()=>{onClick('home')}}>
          <IconHome />
          <span>Home</span>
        </IconMenu>
        <IconMenu className="debugx" activated={page === 'search'} onClick={()=>{onClick('search')}}>
          <IconSearch />
          <span>Search</span>
        </IconMenu>
        {/* 
      <IconMenu className="debugx" activated={page==='premium'} onClick={onClick('premium')}>
        <IconPremium />
        <span>Premium</span>
      </IconMenu>
      */}
        <IconMenu className="debugx" activated={page === 'coffee-pass'} onClick={()=>{onClick('coffee-pass')}}>
          <IconCoffee />
          <span>Coffee Pass</span>
        </IconMenu>
        <IconMenu className="debugx" activated={page?.indexOf('map view') === 0} onClick={()=>{onClick('map view')}}>
          <IconMapView />
          <span>Map View</span>
        </IconMenu>
        {/* <IconMenu className="debugx" activated={page === 'orders'} onClick={onClick('orders')}>
          <IconOrder />
          <span>Orders</span>
          {!!waitListSize && <DotNumber>{waitListSize}</DotNumber>}
        </IconMenu> */}
        <IconMenu className="debugx" activated={page === 'personal center'} onClick={()=>{onClick('personal center')}}>
          <IconAccount />
          <span>My Account</span>
          {!isAnonymous && !anyBankCard && <DotNumber size="11px"></DotNumber>}
        </IconMenu>
      </MenuContainer>
    </div>
  )
}
