import React from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import {useHistory} from "react-router-dom";
import {useRouter} from "../../hooks/router";
import styled from "styled-components";
import {THEME} from "../../config";
import WhiteSpace from "../../components/WhiteSpace";
import {requestPost} from "../../lib";
import {isAndroid} from "../../utils/common";


function ReceiptViewPage() {
  const history: any = useHistory();
  const {status, image, role, orderId, userMobile, message} = history.location.state
  return (
    <PageLayout pageName="no nav receiptView">
      <Header title={'付款截图'}/>
      {status === 'PRE_PAID' ? (
        <Container>
          <div className='title'>{role === 'USER' ? '付款截图' : '请核对付款截图的账号、金额'}</div>
          <img className='receipt-image' src={image} alt="image"/>
          {role === 'MERCHANT' && (
            <div style={{width: '100%'}}>
              <WhiteSpace height={32}/>
              <Button2 onClick={async ()=>{
                const data = await requestPost("manage/operate", {
                  orderId: orderId,
                  operate: 'set_as_paid',
                });
                if (data){
                  history.goBack();
                }
              }}>截图无误，设置为已付款</Button2>
              <WhiteSpace/>
              <a
                href={`sms:${userMobile}${isAndroid ? '?':'&'}body=${message}`}
                className='noti-btn'
              >截图有误，提醒用户上传正确截图</a>
            </div>
          )}
        </Container>
      ) : (
        <Container>
          <div className='title'>付款截图</div>
          <img className='receipt-image' src={image} alt="image"/>
        </Container>
      )}
    </PageLayout>
  );
}

export default ReceiptViewPage;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title{
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }
  .receipt-image {
    margin-top: 16px;
    max-width: 80%;
  }
  
  .noti-btn {
    display: block;
    width: 100%;
    background: #ffffff;
    color: ${THEME.color.primary.default};
    padding-top: 14px;
    padding-bottom: 14px;
    border: 1px solid #cc1543;
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-decoration: none;
    text-align: center;
  }
`

const Button2 = styled.div`
  width: 100%;
  background: #ffffff;
  color: ${THEME.color.primary.default};
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  
  text-align: center;
`;
