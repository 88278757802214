import React, { useState, useRef, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { IconCalendar } from '../Icons';
import { THEME } from '../../config';

const MAX_DAY = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const validDate = (str, pre) => {
  if (!str) return '';
  if (
    !!pre && 
    (str.length < pre.length)
  ) return '';

  let [mm,dd,yy] = str.split('/');
  // console.log(str, mm, dd, yy);

  const year  = !yy ? null : parseInt(yy);
  const month = !mm ? null : parseInt(mm);
  if (
    (!!mm) &&
    (
      (month > 12) || (month < 0)
    ) 
  ) {
    // console.log('m return null')
    return null;
  }
  const day = !dd ? null : parseInt(dd);
  if (
    (!!dd) &&
    (
      (day > MAX_DAY[month]) || (day < 0)
    )
   ) {
    //  console.log('d return null')
    return null;
  }
  
  // console.log(month, day, year);
  switch (str.length) {
  case 1:
    if (month >= 2) return (`0${mm}/`);
    break;
  case 2:
    if (str[1] === '/') return (`0${mm}/`);
    if (month >= 1) return (`${mm}/`);
    break;
  case 4:
    if (day >= 4) return (`${mm}/0${dd}/`);
    break;
  case 5:
    if (str[4] === '/') return (`${mm}/0${dd}/`);
    if (day >= 1) return (`${mm}/${dd}/`);
    break;
  }
  return ([mm,dd,yy].filter(x => !!x).join('/'));
}
const InputFieldContainer = styled.div`
  position: relative;

  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;

  & svg {
    width: 24px;
    fill: ${THEME.color.gray['#4-97']};
  }
`;
const InputContainer = styled.div`
  width: 100%;
  height: 32px;
  padding: 4px 4px 4px 8px;
  border-radius: .25em;
  border: 1px solid ${(props) => !props.error ? '#c7c8ca' : THEME.color.primary.default};
  background:#fff;

  font-weight: ${(props) => props.validInput ? '700' : '500'};
  font-size: ${(props) => props.validInput ? '32px' : '16px'};
  color: ${(props) => props.validInput ? THEME.color.gray['#0-087'] : THEME.color.gray['#5-025']};

  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;

  &:focus-within {
    border: 1px solid ${THEME.color.primary.default};
  }

  & input:-webkit-autofill,
  & input:-webkit-autofill:hover, 
  & input:-webkit-autofill:focus,
  & textarea:-webkit-autofill,
  & textarea:-webkit-autofill:hover,
  & textarea:-webkit-autofill:focus,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: ${THEME.color.gray['#0-087']};
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
const InputPostfix = styled.div`
  display: inline-flex;
  align-items: center;

`;
const InputStyled = styled.input`
  display: inline-block;
  width: ${(props) => (props.width || '100%')};
  flex-flow: 1;

  /* text-indent: 1em; */
  color: ${THEME.color.gray['#0-087']};

  background: transparent;
  border: none;
  outline: none;
`;
interface InputFieldProps {
  onChange?: (value: string | null | undefined) => void;
  value?: number | null | undefined;
  name: string;
  [propName: string]: any
}
export const InputDate: React.FC<InputFieldProps>  = ({onChange, value, name, ...restProps}) => {
  const [input, setInput] = useState(null)

  const onInputChange = (e) => {
    e.preventDefault();
    if (e.target.value === input) return;           // no change at all
    const newstr = validDate(e.target.value, input);
    // else 
    if (!!newstr || (newstr === '')) {
      onChange(newstr)
      setInput(newstr)
    }
    else {                                          // restore as before
      // console.log('set input', input)
      setInput(input || '');                        // 如果不加 || ''，新进入页面后，首次输入的非法字符会被接受 !!! @@@
    }
  }

  return (
    <InputFieldContainer {...restProps} >
      <InputContainer validInput={!!input} >
        <InputStyled className="ft-rr14"
          placeholder={'MM / DD / YY'} 
          value={input}
          onChange={onInputChange}
        />
        <InputPostfix><IconCalendar /></InputPostfix>
      </InputContainer>
    </InputFieldContainer>
  )
}
