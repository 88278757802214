import React from 'react';
import styled from 'styled-components';
import { IconGiftHeart } from './Icons';
import { THEME } from '../config';

const PromotionContainer = styled.div`
  position: absolute;
  top: 172px;
  width: calc(100% - 48px);
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 4px;
  z-index: 999999;
`;
const Content = styled.div`
  width: 100%;
  height: 64px;
  line-height: 20px;
  border-radius: 4px;
  background-color: ${THEME.color.primary.variant};

  display: flex;
  align-items: center;

  & svg {
    margin-left: 16px;
    width: 32px;
  }
  & div {
    margin-left: 16px;
  }
`;

export const Promotion = ({title, subTitle}) => (
  <PromotionContainer>
    <Content>
      <IconGiftHeart />
      <div>
        <p className="ft-rs16 clr-ft-fff">{title}</p>
        <p className="ft-rm12 clr-ft-gray6-d9">{subTitle}</p>
      </div>
      </Content>
  </PromotionContainer>
)
