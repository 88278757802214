import React from 'react'
import { ISVGIconProps } from './type'

export const IconCheck: React.FC<ISVGIconProps> = ({width, color, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    strokeWidth="3" strokeLinecap="round"
    viewBox="0 0 72 72" 
    width={width || '72'} fill={'none'} stroke={color || "white"} {...rest}
  >
    <path d="M23.5 37L32.5684 47.5002C32.785 47.7511 33.1779 47.7309 33.3607 47.4544C34.8669 45.177 40.7723 36.257 45 30M70 36C70 54.7777 54.7777 70 36 70C17.2223 70 2 54.7777 2 36C2 17.2223 17.2223 2 36 2C54.7777 2 70 17.2223 70 36Z"/>
  </svg>
)

export const IconRadioUncheck: React.FC<ISVGIconProps> = ({width, fill, color, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16" opacity="0.3" strokeWidth="1.2"
    width={width || '16'} fill={fill || "none"} stroke={color || 'black'} {...rest}
  >
    <path d="M7.90123 0.6C12.087 0.6 15.4 3.913 15.4 8.09877C15.4 12.0774 12.0967 15.4 7.90123 15.4C3.913 15.4 0.6 12.087 0.6 8.09877C0.6 3.90331 3.92261 0.6 7.90123 0.6Z" />
  </svg>
)

export const IconRadioCheck: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16" strokeWidth="1.2" fillRule="evenodd" clipRule="evenodd" 
    width={width || '16'} fill={fill || "#53BCB7"} {...rest}
  >
    <path d="M7.90123 0C12.4184 0 16 3.58163 16 8.09877C16 12.4184 12.4184 16 7.90123 16C3.58163 16 0 12.4184 0 8.09877C0 3.58163 3.58163 0 7.90123 0ZM4.70711 8.0071L6.90931 10.2093L11.7851 5.3335L12.4922 6.0406L7.61641 10.9164C7.22589 11.3069 6.59272 11.3069 6.2022 10.9164L4 8.71421L4.70711 8.0071Z" />
  </svg>
)

export const IconCirclePlus: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20" fillRule="evenodd" clipRule="evenodd" 
    width={width || '20'} fill={fill || "#CC1543"} {...rest}
  >
    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.75 9.25H15V10.75H10.75V15H9.25V10.75H5V9.25H9.25V5H10.75V9.25Z"/>
  </svg>
)

export const IconCircleX: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40" 
    width={width || '40'} fill={fill || "white"} {...rest}
  >
    <path d="M20 33.3333C12.65 33.3333 6.66665 27.35 6.66665 20C6.66665 12.65 12.65 6.66665 20 6.66665C27.35 6.66665 33.3333 12.65 33.3333 20C33.3333 27.35 27.35 33.3333 20 33.3333ZM20 3.33331C10.7833 3.33331 3.33331 10.7833 3.33331 20C3.33331 29.2166 10.7833 36.6666 20 36.6666C29.2166 36.6666 36.6666 29.2166 36.6666 20C36.6666 10.7833 29.2166 3.33331 20 3.33331ZM24.3166 13.3333L20 17.65L15.6833 13.3333L13.3333 15.6833L17.65 20L13.3333 24.3166L15.6833 26.6666L20 22.35L24.3166 26.6666L26.6666 24.3166L22.35 20L26.6666 15.6833L24.3166 13.3333Z"/>
  </svg>
)

export const IconQuestionMark: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16" fillOpacity="0.45"
    width={width || '16'} fill={fill || "black"} {...rest}
  >
    <path d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM8 12C7.85167 12 7.70666 11.956 7.58333 11.8736C7.45999 11.7912 7.36386 11.6741 7.30709 11.537C7.25033 11.4 7.23548 11.2492 7.26441 11.1037C7.29335 10.9582 7.36478 10.8246 7.46967 10.7197C7.57456 10.6148 7.7082 10.5434 7.85368 10.5144C7.99917 10.4855 8.14997 10.5003 8.28701 10.5571C8.42406 10.6139 8.54119 10.71 8.6236 10.8333C8.70602 10.9567 8.75 11.1017 8.75 11.25C8.75 11.4489 8.67098 11.6397 8.53033 11.7803C8.38968 11.921 8.19892 12 8 12ZM8.5 8.955V9C8.5 9.13261 8.44732 9.25979 8.35356 9.35355C8.25979 9.44732 8.13261 9.5 8 9.5C7.86739 9.5 7.74022 9.44732 7.64645 9.35355C7.55268 9.25979 7.5 9.13261 7.5 9V8.5C7.5 8.36739 7.55268 8.24021 7.64645 8.14645C7.74022 8.05268 7.86739 8 8 8C8.82688 8 9.5 7.4375 9.5 6.75C9.5 6.0625 8.82688 5.5 8 5.5C7.17313 5.5 6.5 6.0625 6.5 6.75V7C6.5 7.13261 6.44732 7.25979 6.35356 7.35355C6.25979 7.44732 6.13261 7.5 6 7.5C5.86739 7.5 5.74022 7.44732 5.64645 7.35355C5.55268 7.25979 5.5 7.13261 5.5 7V6.75C5.5 5.50938 6.62125 4.5 8 4.5C9.37875 4.5 10.5 5.50938 10.5 6.75C10.5 7.83625 9.64 8.74563 8.5 8.955Z" />
  </svg>
)

export const IconEmpty: React.FC<ISVGIconProps> = ({width, fill, ...rest}) => (
  <svg viewBox="0 0 93 64" xmlns="http://www.w3.org/2000/svg"
    width={width || "93"} fill="none" 
  >
    <path d="M92.8 0H0V64H92.8V0Z" fill="#F5F5F5"/>
    <path d="M0.64 62.72C0.286401 62.72 0 63.0067 0 63.36C0 63.7136 0.286401 64 0.64 64H92.16C92.5133 64 92.8 63.7136 92.8 63.36C92.8 63.0067 92.5133 62.72 92.16 62.72H0.64Z" fill="black" fillOpacity="0.45"/>
    <path d="M30.8799 31.5201H61.6601V24.8H30.8799V31.5201Z" fill="black" fillOpacity="0.09"/>
    <g opacity="0.100937">
      <path opacity="0.100937" d="M46.4121 15.0816L48.995 15.9275L49.2111 15.2677L47.6255 14.7485L47.6367 14.7144L49.8857 13.029L50.0588 12.5005L47.5612 11.6825L47.3451 12.3423L48.8505 12.8353L48.8388 12.8711L46.5846 14.5548L46.4121 15.0816Z" fill="black" fillOpacity="0.25"/>
    </g>
    <g opacity="0.389">
      <path opacity="0.389" d="M39.3543 18.3922L43.7559 19.8338L44.1241 18.7094L41.4221 17.8245L41.4412 17.7664L45.2739 14.8943L45.5688 13.9936L41.3125 12.5996L40.9443 13.724L43.5097 14.5642L43.4897 14.6252L39.6483 17.4945L39.3543 18.3922Z" fill="black" fillOpacity="0.25"/>
    </g>
    <g opacity="0.740165">
      <path opacity="0.740165" d="M48.9319 29.5722L55.8002 29.8294L55.866 28.0749L51.6497 27.917L51.6531 27.8263L55.9467 22.1624L55.9993 20.757L49.3576 20.5082L49.2919 22.2627L53.2951 22.4127L53.2915 22.5079L48.9843 28.1713L48.9319 29.5722Z" fill="black" fillOpacity="0.25"/>
    </g>
    <path d="M63.2579 31.9198V55.5528H29.4399V31.9198H63.2579Z" fill="#F5F5F5"/>
    <path d="M63.3926 61.7996H29.4323C28.6524 61.7996 28.1123 60.96 28.2924 60.1798L29.7324 55.9798H63.0326L64.4723 60.1798C64.7123 60.96 64.1724 61.7996 63.3926 61.7996ZM53.8524 32.2796H62.9123V54.8998H29.9123V32.2796H38.9724H53.8524ZM31.5324 26.0998L34.7123 28.3196L31.5324 29.9996V26.0998ZM61.2924 26.0998V29.9996L58.1126 28.3196L61.2924 26.0998ZM60.0924 25.5596L56.7324 27.9596C56.3724 28.1996 56.4326 28.6796 56.7926 28.8598L61.2323 31.1996H31.5923L36.0323 28.8598C36.3923 28.6796 36.4524 28.1398 36.0924 27.9596L32.7324 25.5596H60.0924ZM65.4924 59.8198L63.9324 55.3196V31.7398C63.9324 31.4998 63.8124 31.3798 63.6326 31.2598L62.3123 30.5398V25.1398C62.4323 24.7798 62.1324 24.4796 61.8323 24.4796H30.9923C30.6924 24.4796 30.3923 24.7798 30.4524 25.1398V30.5398C30.2124 30.7196 29.0124 31.2598 28.8924 31.5596C28.8924 31.6198 28.8323 31.7398 28.8323 31.7996V55.3798L27.2723 59.8796C26.9123 61.3798 27.9324 62.88 29.4323 62.88H63.3324C64.8323 62.88 65.9123 61.4396 65.4924 59.8198Z" fill="black" fillOpacity="0.45"/>
    <path d="M28.2934 60.1804L29.7334 55.9804H63.0333L64.4733 60.1804C64.7133 60.9606 64.1728 61.8006 63.3929 61.8006H29.4332C28.6534 61.8006 28.1132 60.9606 28.2934 60.1804ZM29.9132 32.2806H38.9728H53.8534H62.9129V54.9004H29.9132V32.2806ZM31.5334 26.1004L34.7132 28.3206L31.5334 30.0006V26.1004ZM58.1129 28.3206L61.2931 26.1004V30.0006L58.1129 28.3206ZM36.0329 28.8604C36.3929 28.6806 36.4528 28.1404 36.0928 27.9606L32.7328 25.5603H60.0928L56.7334 27.9606C56.3734 28.2006 56.4333 28.6806 56.7933 28.8604L61.2329 31.2006H31.5932L36.0329 28.8604ZM30.9776 24.4806C30.6828 24.4908 30.3942 24.7868 30.4534 25.1404V30.5404C30.2131 30.7206 29.0131 31.2604 28.8928 31.5606C28.8928 31.6204 28.8329 31.7404 28.8329 31.8006V55.3804L27.2732 59.8806C26.9132 61.3804 27.9334 62.8806 29.4332 62.8806H63.3328C64.8329 62.8806 65.9129 61.4406 65.4928 59.8204L63.9334 55.3206V31.7404C63.9334 31.5004 63.8128 31.3804 63.6329 31.2604L62.3133 30.5404V25.1404C62.431 24.7868 62.1433 24.4908 61.8486 24.4806H30.9776Z" fill="black" fillOpacity="0.45"/>
    <path d="M52.267 31.8748C51.5115 36.5125 49.0689 39.9708 46.3924 39.9708C43.7156 39.9708 41.273 36.5125 40.5172 31.8748H39.2222C40.033 37.2626 42.9342 41.2508 46.3924 41.2508C49.8503 41.2508 52.7515 37.2626 53.562 31.8748H52.267Z" fill="black" fillOpacity="0.45"/>
    <path d="M39.1826 59.5332H40.8018V57.9133H39.1826V59.5332Z" fill="black" fillOpacity="0.45"/>
    <path d="M45.5825 59.5332H47.2017V57.9133H45.5825V59.5332Z" fill="black" fillOpacity="0.45"/>
    <path d="M51.9819 59.5332H53.6018V57.9133H51.9819V59.5332Z" fill="black" fillOpacity="0.45"/>
    <path d="M46.3999 45.4332C45.2902 45.4332 44.3906 46.3328 44.3906 47.4425C44.3906 48.5523 45.2902 49.4519 46.3999 49.4519C47.5097 49.4519 48.4092 48.5523 48.4092 47.4425C48.4092 46.3328 47.5097 45.4332 46.3999 45.4332Z" fill="black" fillOpacity="0.25"/>
    <path d="M46.3999 45.4332C45.2902 45.4332 44.3906 46.3328 44.3906 47.4425C44.3906 48.5523 45.2902 49.4519 46.3999 49.4519C47.5097 49.4519 48.4092 48.5523 48.4092 47.4425C48.4092 46.3328 47.5097 45.4332 46.3999 45.4332Z" fill="#CC1543"/>
  </svg>
)
