import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {LOCAL_URL} from "../../config"
import { requestGet } from '../../lib'
import { useLocationMerchantDataChecker } from '../../hooks'
import { 
  EmptyBlock,
  Layout, Header, IconCirclePlus, 
  SubmitButtonArea, SubmitButton,
} from '../../Layout'
import { IPromotionRule } from '../Promotion/type.entity'
import { IPreferenceRecord } from './type.entity'
import { PreferenceCard } from './Card'
import { REQ_URL } from './helper'

const Wrapper = styled.div`
  padding: 20px 1rem 6rem;
`

export default function SelectPage () {
  const { location, history, merchantId } = useLocationMerchantDataChecker()
  const [items, setItems] = useState<IPreferenceRecord[]>([])
  const [rule, setRule] = useState<IPromotionRule>(null)
  const [ruleId] = useState<number | null>(location?.state?.ruleId ?? null)

  const initialRule = () => {
    if (
      (ruleId === null) || 
      (!location?.state?.formData?.rules?.rules?.[ruleId])
     ) {
      return false
    }
    setRule({
      ...location.state.formData.rules.rules[ruleId]
    })
    return true
  }
  const fetchData = async () => {
    const result = await requestGet(`${REQ_URL}?merchantId=${merchantId}&sorter=createdAt,DESC`, {})
    if ((result.code === 0) && (!!result.data?.length)) {
      setItems(result.data)
    }
  }
  useEffect(() => {
    if (initialRule() ) {
      fetchData()
    }
  }, [])

  const onSelect = (index:number) => (isSelected:boolean) => {
    if ( (!isSelected) || (!rule) ) {
      return
    }

    // console.log(rule, location.state)
    setRule({
      followerNumber: rule.followerNumber,
      preference    : items[index],
    })
  }

  const gotoPreferenceDetails = () => {
    history.push(LOCAL_URL.PREFERENCE_DETAILS, {
      ...location?.state
    })
  }

  const onConfirm = () => {
    if (!location?.state?.formData?.rules?.rules?.[ruleId]) return;

    location.state.formData.rules.rules[ruleId] = { ...rule };
    history.push(LOCAL_URL.PROMOTION_CREATE_NEW, {
      ...location.state
    })
  }

  return (
    <Layout>

      <Header title="选择优惠券" icon={
        <div onClick={gotoPreferenceDetails}>
          <IconCirclePlus style={{display: 'block'}} fill='white' />
        </div>
      } />

      <Wrapper>
        { items.map((item, index) => (
          <PreferenceCard key={index} {...item} 
            isChecked={rule?.preference?.id === item.id} 
            onChange={onSelect(index)}
          />
        )) }
        { !items.length &&
          <EmptyBlock style={{marginTop: '6rem'}}></EmptyBlock>
        }
      </Wrapper>

      <SubmitButtonArea className="fix-bottom">
        <SubmitButton disabled={ruleId === null} onClick={onConfirm}>
          确认选择
        </SubmitButton>
      </SubmitButtonArea>

    </Layout>
  )
}
