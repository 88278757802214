
export const calcPointsAward = (price, isPremiumMember) => {
	const bonusPoints = 5 * Math.floor((price || 0) / 100);
	return (!isPremiumMember ? bonusPoints : (2 * bonusPoints));
};

export const isEmpty = (obj: any) => ((!obj) || (Object.keys(obj).length === 0));
export const formatWithComma: (num: string) => string = (num) => (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
export const formatMoney: (amount: number) => string = (amount) => ((amount || 0) / 100).toFixed(2);
export const formatMoneyString: (str: string | null | undefined) => string | null | undefined = (str) => {
	if (!str?.length) return str;
	const [integer, decimal] = str.split('.');
	if (!!decimal?.length && decimal.length > 2) {
		return [integer, decimal.substr(0, 2)].join('.');    // 确保如果金额有小数，那么只有 2 位小数 !!!
	} else return str
}
export const parseMoneyInt: (str: string | null | undefined) => number = (str) => { // 字符串类型的金额，转成最小单位为 ‘分’ 的整数
	if (!str?.length) return 0;

	const [integer, decimal] = str.split('.');
	return (!decimal?.length) ? parseInt([integer, '00'].join(''))
		:
		(decimal?.length === 1) ? parseInt([integer, decimal, '0'].join(''))
			:
			parseInt([integer, decimal.substr(0, 2)].join(''))
		;
}

export const indexOfLocation = (location, locations: Array<any>) => {
	let index = -1;
	for (let loc of locations) {
		index++;
		if (!!loc.address.textMain) {
			if (
				(loc.address.textMain === location.address.textMain) &&
				(loc.position.lat === location.position.lat) &&
				(loc.position.lng === location.position.lng)
			) {
				return index;
			}
		} else {
			if (
				(loc.address.formatted === location.address.formatted) &&
				(loc.position.lat === location.position.lat) &&
				(loc.position.lng === location.position.lng)
			) {
				return index;
			}
		}
	}
	return -1;
}


/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
export const getParams = function (url: string) {
	const params: any = {};
	const parser = document.createElement('a');
	parser.href = url;
	const query = parser.search.substring(1);
	const vars = query.split('&');
	if (vars.length === 0) {
		return null
	}
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (pair[0]) {
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
	}
	return params;
};

export const getHashParams = function (hash: string) {
	const params: any = {};
	const query = hash.substring(1);
	const vars = query.split('&');
	if (vars.length === 0) {
		return null
	}
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (pair[0]) {
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
	}
	return params;
};

export const buildSitePageUrl = (url, paramsCache, action?: string) => {
  const params: any = paramsCache.get()
  let pObj = null;
  if (!!params) {
    try {
      pObj = JSON.parse(params)
    }
    catch(err) {
      // it doesn't matter
    }
  }
  const queryStr = !pObj ? null : Object.keys(pObj).map((key) => `${key}=${pObj[key]}`).join('&');
  const actionUrl= !action? url : `${url}/${action}`;

  return !queryStr ? actionUrl : `${actionUrl}?${queryStr}`;
}