import React, {useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import {Button, InputField} from "../../components";
import {requestGet, requestPost} from "../../lib";
import {useHistory} from "react-router-dom";
import WhiteSpace from "../../components/WhiteSpace";

function Remark() {
  const [remark, setRemark] = useState('')
  const [privateRemark, setPrivateRemark] = useState('')
  const history: any = useHistory()
  const [loading, setLoading] = useState(false)
  return (
    <PageLayout pageName="no nav orderManage">
      <Header title="添加备注"/>
      <Container>
        <InputField
          label='团长备注（团员可见）'
          textarea
          name="remark"
          placeholder="请输入备注"
          value={remark}
          onChange={(e) => {
            setRemark(e.target.value)
          }}
        />
        <WhiteSpace/>
        <InputField
          label='私密备注（仅团长和管理员可见）'
          textarea
          name="remark"
          placeholder="请输入备注"
          value={privateRemark}
          onChange={(e) => {
            setPrivateRemark(e.target.value)
          }}
        />
        <div className='bottom-float'>
          <Button
            fullWidth
            onClick={async () => {
              if (loading){
                return
              }
              setLoading(true)
              const {data} = await requestPost("manage/addInfoToOrder", {
                orderId: history.location.state.orderId,
                info:{
                  content: remark,
                  privateContent: privateRemark
                },
                type: 'REMARK'
              });
              setLoading(false)
              if (data){
                history.goBack()
              }
            }}
          >确认</Button>
        </div>
      </Container>
    </PageLayout>
  )
}

export default Remark

const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`
