import PageLayout from "../Global/PageLayout";
import Header from "../../components/Header";
import IconAdd from "../../components/Icons/IconAdd";
import * as React from "react";
import styled from "styled-components";
import WhiteSpace from "../../components/WhiteSpace";
import iconAdd from "../../svg/icon_add.svg";
import {THEME} from "../../config";
import {ChannelCard} from "./ChannelCard";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {requestGet} from "../../lib";

export function SettingPage() {
  const history: any = useHistory()
  const {group} = history.location.state
  const [channelList, setChannelList] = useState([])
  const fetchChannelList = async () => {
    const {data} = await requestGet('channel/list', {
      groupId: group.id
    })
    if (data) {
      setChannelList(data)
    }
  }

  useEffect(() => {
    fetchChannelList()
  }, [])

  return (
    <PageLayout pageName="no nav channel setting page">
      <Header title="渠道分销"/>
      <Container>
        <AddButton onClick={() => {
          setChannelList(
            [{
              group: group,
              name: `未命名渠道${channelList.length}`,
              uuid: '',
              viewCount: 0
            }, ...channelList]
          )
        }}>
          <img src={iconAdd} alt='add-button'/>
          <div>添加新渠道</div>
        </AddButton>
        {channelList ? (<div>
          {channelList.map((item, index) => {
            return (
              <ChannelCard
                group={group} index={index + 1} name={item.name} uuid={item.uuid} key={item.name}
                viewerCount={item.viewCount}/>
            )
          })}
        </div>) : null}
      </Container>
      <WhiteSpace/>
    </PageLayout>
  )
}

const Container = styled.div`
  padding-inline: 16px;
`

const AddButton = styled.div`
  background-color: #fff;
  height: 48px;
  border: 1px solid #cc1543;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.gray["#12-045"]};

  div {
    margin-left: 8px;
  }

  margin-top: 16px;
`;

