export const PERSISTANCE = {
  PAGE_CACHE: 'LOCAL_CACHE_PAGE_DATA_BACKUP_RESTORE',
  PAGE_FUNC_RET_RESULT: 'LOCAL_CACHE_PAGE_FUNCTION_RETURN_RESULT',
  ORDER_EXPORT_EMAIL: 'LOCAL_CACHE_ORDER_EXPORT_EMAIL',
  SPLASH_SCREEN: 'LOCAL_CACHE_SPLASH_SCREEN',

  KEY_USER: 'LOCAL_CACHE_USER',
  KEY_STORE: 'LOCAL_CACHE_STORE',
  KEY_ORDER_DETAILS: 'LOCAL_CACHE_ORDER_DETAILS',
  KEY_IN_STORE_BILL: 'LOCAL_CACHE_IN_STORE_BILL',
  KEY_BANK_CARD_PAYMENT: 'LOCAL_CACHE_BANK_CARD_PAYMENT',
  KEY_PAYMENT_RETURN: 'LOCAL_CACHE_PAYMENT_RETURN',
  KEY_MAP_VIEW: 'LOCAL_CACHE_MAP_VIEW',

  PARAMS: 'LOCAL_CACHE_PARAMS',

  KEY_PAYMENT: 'LOCAL_CACHE_PAYMENT',  // Todo: to be retired
  CURRENT_MERCHANT: 'CURRENT_MERCHANT',
  KEY_SHOW_GUIDE: 'SHOW_GUIDE',
};
