export class AppStorage{

  private _storage: any;
  constructor(storage: any) {
    this._storage = storage;
  }

  setKeyValue ( key: string, value: string) { this._storage?.setItem(key, value); }
  getKeyValue ( key: string )               { return this._storage?.getItem(key); }
  removeKey   ( key: string )               { this._storage?.removeItem(key); }
  flushAll    ( )                           { this._storage?.clear(); }
}
