import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {IconKBArrowR, ListInputField} from "../../../components";
import {LOCAL_URL, THEME} from "../../../config";
import WhiteSpace from "../../../components/WhiteSpace";
import iconAdd from "../../../svg/icon_add.svg";
import {useHistory} from "react-router-dom";

type customFieldTypeType = 'PICKUP' | 'DELIVERY'

type CustomFieldType = {
  title: string,
  type: customFieldTypeType[],
  select: 'SINGLE' | 'MULTI'
  meta: string[]
}

type CustomFieldProps = {
  index: number,
  from?: 'STORE' | 'GROUP'
  value: CustomFieldType,
  onBeforeNav: ()=>void,
  onChange:(value: CustomFieldType)=>void
  onDelete:()=>void
}


function CustomField(props: CustomFieldProps) {
  const [title, setTitle] = useState(props.value.title)
  const [type, setType] = useState(props.value.type)
  const [select, setSelect] = useState(props.value.select)
  const [meta, setMeta] = useState(props.value.meta)
  const history = useHistory()

  useEffect(()=>{
    if (props.value.type && props.value.type.length > 0){
      const result = []
      props.value.type.forEach(item=>{
        if (item === 'PICKUP'){
          result.push('自提')
        }else if (item === 'DELIVERY'){
          result.push('配送')
        }
      })
      setType(result)
    }
  }, [props.value.type])

  return (
    <div style={{marginBottom: 16}}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8
      }}>
        <div style={{
          color: THEME.color.gray["#12-045"]
        }}>
          自定义选项-{props.index + 1}
        </div>
        <SmallButton>
          <div
            className="remove"
            onClick={() => {
              if (props.onDelete){
                props.onDelete()
              }
            }}
          >
            删除
          </div>
        </SmallButton>
      </div>
      <StyledCard>
        <ListInputField
          value={title}
          label="标题"
          onChange={e => {
            setTitle(e.target.value)
            if (props.onChange) {
              props.onChange({
                ...props.value,
                title: e.target.value
              })
            }
          }}
          required
          placeholder="例如: 自提时间"
          bottomDivider
        />
        <WhiteSpace height={16}/>
        <StyledItem>
          <div
            className="flex-row"
          >
          <span className='title'>
            适用范围{" "}
            <span
              style={{color: THEME.color.primary.default}}>
              *
            </span>
          </span>
            <div className="flex-row" onClick={() => {
              if (props.onBeforeNav){
                props.onBeforeNav()
              }
              history.push(LOCAL_URL["CREATE_GROUP_BUY_TYPE"], {
                index: props.index,
                from: props.from ?? 'GROUP'
              });
            }}>
              <span>{type.length === 0 ? '请选择' : type.join('、')}</span>
              <div style={{width: 24, height: 24}}>
                <IconKBArrowR/>
              </div>
            </div>
          </div>
          <WhiteSpace height={8}/>
          <Line/>
          <WhiteSpace height={8}/>
        </StyledItem>
        <StyledItem>
          <div
            className="flex-row"
          >
          <span className='title'>
            类型{" "}
            <span
              style={{color: THEME.color.primary.default}}>
              *
            </span>
          </span>
            <div className="flex-row" onClick={() => {
              if (props.onBeforeNav){
                props.onBeforeNav()
              }
              history.push(LOCAL_URL["CREATE_GROUP_BUY_SELECT"], {
                index: props.index,
                from: props.from ?? 'GROUP'
              });
            }}>
              <span>{select === 'SINGLE' ? '单选' : (select === 'MULTI' ? '多选' : '请选择')}</span>
              <div style={{width: 24, height: 24}}>
                <IconKBArrowR/>
              </div>
            </div>
          </div>
          <WhiteSpace height={8}/>
          <Line/>
          {meta && meta.length > 0 && (
            meta.map((item, index) => {
              return (
                <OptionField
                  key={`${index}`}
                  total={meta.length}
                  value={item}
                  index={index + 1}
                  onChange={(value) => {
                    meta[index] = value
                    setMeta(meta)
                    if (props.onChange) {
                      props.onChange({
                        ...props.value,
                        meta: meta
                      })
                    }
                  }}
                  onDelete={() => {
                    const result = []
                    meta.forEach((_item, _index) => {
                      if (_index !== index) {
                        result.push(_item)
                      }
                    })
                    setMeta(result)
                  }}
                  isLast={index === (meta.length - 1)}

                />
              )
            })
          )}
          <Line/>
          <AddNew
            onClick={() => {
              setMeta([...meta, ''])
            }}
          >
            <img src={iconAdd} alt={'add'}/>
            <div>添加新选项</div>
          </AddNew>
        </StyledItem>
      </StyledCard>
    </div>

  )
}

const OptionField = (
  props: {
    value: string,
    index: number,
    total: number,
    onChange: (value: string) => void
    onDelete: () => void
    isLast: boolean
  }
) => {
  const [value, setValue] = useState(props.value)
  return (
    <div>
      <div>
        <ListInputField
          value={value}
          label={`选项${props.index}`}
          required
          placeholder="请输入选项名称"
          onChange={e => {
            setValue(e.target.value)
            if (props.onChange) {
              props.onChange(e.target.value)
            }
          }}
        />
        {props.total > 2 && (
          <SmallButton>
            <div
              className="remove"
              onClick={() => {
                if (props.onDelete) {
                  props.onDelete()
                }
              }}
            >
              删除选项
            </div>
          </SmallButton>
        )}
      </div>
      {!props.isLast && (<Line/>)}
    </div>
  )
}

const AddNew = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.gray["#12-045"]};
  padding-bottom: 8px;

  div {
    margin-left: 8px;
  }

  margin-top: 16px;
`;

const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 8px 8px 8px;

`;

const SmallButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .remove {
    display: inline-block;
    height: 16px;
    padding: 1px 8px 1px 8px;
    text-align: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.45);
    border-radius: 4px;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 10px;
  }

`

const StyledItem = styled.div`
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      color: ${THEME.color.gray["#0-087"]};
      font-size: 15px;
    }
  }
`;

const Line = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.09);
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export default CustomField
