import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
// import { IconClear } from './Icons'
import { Divider } from './Layout'
import { THEME } from '../config'
// https://blog.csdn.net/sinat_17775997/article/details/81462750


const IconTick = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 2.56C8.578 2.56 2.56 8.578 2.56 16C2.56 23.422 8.578 29.44 16 29.44C23.422 29.44 29.44 23.422 29.44 16C29.44 8.578 23.422 2.56 16 2.56ZM21.805 11.611L15.487 20.371C15.3987 20.4942 15.2823 20.5947 15.1474 20.664C15.0126 20.7332 14.8631 20.7694 14.7115 20.7694C14.5599 20.7694 14.4104 20.7332 14.2756 20.664C14.1407 20.5947 14.0243 20.4942 13.936 20.371L10.195 15.187C10.081 15.028 10.195 14.806 10.39 14.806H11.797C12.103 14.806 12.394 14.953 12.574 15.205L14.71 18.169L19.426 11.629C19.606 11.38 19.894 11.23 20.203 11.23H21.61C21.805 11.23 21.919 11.452 21.805 11.611Z" fill="#52C41A"/>
  </svg>
)

const IconInfo = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 2C8.26875 2 2 8.26875 2 16C2 23.7313 8.26875 30 16 30C23.7313 30 30 23.7313 30 16C30 8.26875 23.7313 2 16 2ZM15 9.25C15 9.1125 15.1125 9 15.25 9H16.75C16.8875 9 17 9.1125 17 9.25V17.75C17 17.8875 16.8875 18 16.75 18H15.25C15.1125 18 15 17.8875 15 17.75V9.25ZM16 23C15.6075 22.992 15.2337 22.8304 14.959 22.55C14.6842 22.2696 14.5303 21.8926 14.5303 21.5C14.5303 21.1074 14.6842 20.7304 14.959 20.45C15.2337 20.1696 15.6075 20.008 16 20C16.3925 20.008 16.7663 20.1696 17.041 20.45C17.3158 20.7304 17.4697 21.1074 17.4697 21.5C17.4697 21.8926 17.3158 22.2696 17.041 22.55C16.7663 22.8304 16.3925 22.992 16 23Z" fill="#FAAD14"/>
  </svg>
)

const NotificationContainer = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999999;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoticeContainer = styled.div`
  width: 218px;
  margin-top: 1em;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;

  /* position: relative;
  & button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
  }
  & button svg {
    width: 16px;
  } */

  & span {
    margin: 0 1em;
  }

  &.info {
    /* background: #e6f7ff; */
    /* border: 1px solid #91d5ff; */
    /* color: ${THEME.color.accent.blue}; */
  }
  &.success {
    /* background: #f6ffed; */
    /* border: 1px solid #b7eb8f; */
    /* color: ${THEME.color.accent.green}; */
  }
  &.warning {
    /* background: #fffbe6; */
    /* border: 1px solid #ffe58f; */
    /* color: ${THEME.color.accent.yellow}; */
  }
  &.error {
    /* background: #fff2f0; */
    /* border: 1px solid #ffccc7; */
    /* color: ${THEME.color.primary.default}; */
  }
  &.loading {
  }
`;

const Segment = styled.div`
  width: 100%;
  padding: 16px;
  text-align: center;

  & button {
    background: transparent;
    border: none;
    color: ${THEME.color.accent.blue};
  }
`;

/**
  通知组件
 */

const ICON = {
  info    : <IconInfo />,
  warning : <IconInfo />,
  error   : <IconInfo />,
  success : <IconTick />,
}
const Notice = ({type, content, duration, onClose, actionLabel, actionCallback}) => {
  const onAction = () => {
    if (!!actionCallback) actionCallback();
    if (!!onClose) onClose();
  }
  return(
    <NoticeContainer className={`${type} shadow04`}>
      <Segment>
        <p>{ICON[type]}</p>
        <p>{content}</p>
        {/* <button onClick={onClose}><IconClear /></button> */}
      </Segment>
      {
        !!actionLabel && 
        <>
          <Divider />
          <Segment>
            <button onClick={onAction}>{actionLabel}</button>
          </Segment>
        </>
      }
    </NoticeContainer>
  )
}

/**
  容器组件
 */
class Notification extends React.Component {
  state: any;
  constructor(props) {
    super(props)
    this.state = { notices: [] }
  }
 
  generateKey() {
    return `${new Date().getTime()}-${this.state.notices.length}`
  }
 
  addNotice(notice) {
    const { notices } = this.state
    notice.key = this.generateKey()
    if (!notices.map((n) => (n.key)).includes(notice.key)) {
        this.setState({ notices: [ notice, ...notices ] })
        if (notice.duration > 0) {
            setTimeout(() => {
              this.removeNotice(notice.key)
            }, notice.duration)
        }
    }
    return () => { this.removeNotice(notice.key) }
  }

  removeNotice(key) {
    this.setState((prevState: any) => ({
      notices: prevState.notices.filter((notice) => {
        if (notice.key === key) {
          if (notice.onClose) notice.onClose()
          return false
        }
        return true
      })
    }))
  }
  
  render() {
    // console.log(this.state.notices)
    return (
      ( (!this.state.notices) || (this.state.notices.length === 0) ) ? null :
      <NotificationContainer>
      { this.state.notices.map(notice => (
          <Notice {...notice} onClose={() => this.removeNotice(notice.key)} />
      )) }
      </NotificationContainer>
    )
  }
}

/**
  工厂类
 */ 
class NotificationFactory {
  domDiv: any;
  notification: any;

  constructor () {
    this.domDiv = document.createElement('div')
    document.body.appendChild(this.domDiv)
    this.notification = ReactDOM.render(<Notification />, this.domDiv)
  }

  addNotice (notice) {
    if (!this.notification) {
      console.error('notification is invalid!')
    }
    else this.notification?.addNotice(notice)
  }

  destroy () {
    ReactDOM.unmountComponentAtNode(this.domDiv)
    document.body.removeChild(this.domDiv)
  }
}

/**
  全局实例
 */
let thisFactory
const notice = (type, content, duration, onClose, actionLabel, actionCallback) => {
  if (!thisFactory) thisFactory = new NotificationFactory()
  return thisFactory?.addNotice({ type, content, duration, onClose, actionLabel, actionCallback })
}

export const notify = {
  info:     (content, duration = 3000, onClose=null, actionLabel=null, actionCallback=null) => notice('info',     content, duration, onClose, actionLabel, actionCallback),
  success:  (content, duration = 3000, onClose=null, actionLabel=null, actionCallback=null) => notice('success',  content, duration, onClose, actionLabel, actionCallback),
  warning:  (content, duration = 3000, onClose=null, actionLabel=null, actionCallback=null) => notice('warning',  content, duration, onClose, actionLabel, actionCallback),
  error:    (content, duration = 3000, onClose=null, actionLabel=null, actionCallback=null) => notice('error',    content, duration, onClose, actionLabel, actionCallback),
  loading:  (content, duration = 3000, onClose=null, actionLabel=null, actionCallback=null) => notice('loading',  content, duration, onClose, actionLabel, actionCallback),
}
