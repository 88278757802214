import dayjs from "dayjs";
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { IconSmileFace, Layout, Header } from '../../Layout'
import { MerchantInfoCard } from './MerchantInfoCard'

const Wrapper = styled.div`
  padding: 2.5rem 1.5rem 1rem;
`
const CodeBlock = styled.div`
  min-height: 9rem;
  padding: 3rem 0 1rem;
  background-color: ${props => props.theme.color.primary.HEX};
  border-radius: 8px;
  color: white;
  text-align: center;
  font-size: 18px;
  line-height: 21px;

  position: relative;
  & div.face {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 50%;
    transform: translate(-50%, -40%);
  }

  & p.code {
    margin-top: 1rem;
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
  }

`
const DetailsBlock = styled.div`
  border: 2px solid ${props => props.theme.color.primary.HEX};
  border-radius: 8px;
  padding: 2rem 0 3rem;
  text-align: center;
  
  position: relative;
  & p.owner {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    padding: 0 4rem;
    white-space: nowrap;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: ${props => props.theme.color.primary.HEX};
    background-color: ${props => props.theme.color.bgNormal.HEX};
  }
  & p.title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: rgba(0,0,0,0.75);
  }
  & p.time {
    margin-top: 3rem;
    font-size: 24px;
    line-height: 28px;
    color: ${props => props.theme.color.primary.HEX};
  }
  & p.number {
    margin-top: 2rem;
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    color: rgba(0,0,0,0.75);
  }
  & p.name {
    margin-top: .5rem;
    font-size: 15px;
    line-height: 21px;
    color: rgba(0,0,0,0.45);
  }
`

export default function Page() {
  const location = useLocation()
  const {data, merchant} = location.state;
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


  return (
    <Layout style={{position: 'relative'}}>
      <Header title={"核销成功"} />
      <Wrapper className="flex-start direction-col" style={{gap: '1.5rem'}}>
        <CodeBlock>
          <div className='face'>
            <IconSmileFace style={{display: 'block'}}></IconSmileFace>
          </div>
          <p>核销码</p>
          <p className="code">{data.redeemCode}</p>
        </CodeBlock>
        
        <DetailsBlock>
          <p className='title'>{merchant?.name}</p>
          <p className='time'>{dayjs(data.redeemTime).format('HH:mm A - DD')} {monthNames[dayjs(data.redeemTime).month()]}</p>
          <p className='number'>1</p>
          <p className='name'>{data.name}</p>
          <p className='owner'>大吉</p>
        </DetailsBlock>

        <MerchantInfoCard info={merchant}></MerchantInfoCard>
      
      </Wrapper>
    </Layout>
  )
}
