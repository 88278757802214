import React, {useEffect, useState} from "react";
import Header, {FloatBottom} from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import {LOCAL_URL, THEME} from "../../config";
import {Check} from "../../forms/Check";
import styled from "styled-components";
import {Divider} from "@mui/material";
import WhiteSpace from "../../components/WhiteSpace";
import {EntityContext} from "../../context";
import {useHistory} from "react-router-dom";
import {Button} from "../../components";

function TypePage() {
  const [type, setType] = useState<Set<'PICKUP' | 'DELIVERY'>>(new Set())
  const history = useHistory<any>()
  const {
    model: entityModel,
    replace,
  } = React.useContext(EntityContext);

  const index = history?.location?.state?.index;
  const from = history?.location?.state?.from ?? 'GROUP';
  const storeName = from === 'GROUP' ? 'GROUP_BUY' : 'STORE_CONFIG'

  useEffect(() => {
    let groupBuyData = entityModel[storeName]?.response;
    if (type && type.size > 0) {
      const customFields = groupBuyData.customFields
      const final = []
      for (let i = 0; i< customFields.length; i++){
        if (i === index){
          final.push({
            ...customFields[i],
            type: Array.from(type)
          })
        }else {
          final.push(customFields[i])
        }
      }
      replace(storeName, {
        customFields: final
      })
    }
  }, [type])

  return (
    <PageLayout pageName="no nav types">
      <Header
        title='适用范围'
        right={(
          <span
            style={{
              color: "white",
              marginRight: 16
            }}
            onClick={()=>{
              history.goBack()
            }}
        >
            确定
          </span>
        )}
      />
      <Container>
        <Item>
          <div>
            <div className="title">自提</div>
            <div className="sub-title"></div>
            <div className="radio">
              <Check
                onChange={(value) => {
                  const _type = new Set(type)
                  if (_type.has('PICKUP')) {
                    _type.delete('PICKUP')
                  } else {
                    _type.add('PICKUP')
                  }
                  setType(_type)
                }}
                value={type.has('PICKUP')}
              />
            </div>
          </div>
        </Item>
        <WhiteSpace/>
        <Divider/>
        <WhiteSpace/>
        <Item>
          <div>
            <div className="title">配送</div>
            <div className="sub-title"></div>
            <div className="radio">
              <Check
                onChange={(value) => {
                  const _type = new Set(type)
                  if (_type.has('DELIVERY')) {
                    _type.delete('DELIVERY')
                  } else {
                    _type.add('DELIVERY')
                  }
                  setType(_type)
                }}
                value={type.has('DELIVERY')}
              />
            </div>
          </div>
        </Item>
      </Container>
      <FloatBottom>
        <Button
          fullWidth
          size="large"
          color="red"
          onClick={()=>{
            history.goBack()
          }}
        >
          确定
        </Button>
      </FloatBottom>
    </PageLayout>
  )
}

export default TypePage

const Container = styled.div`
  padding: 16px;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  .title {
    font-size: 15px;
    color: ${THEME.color.gray["#10-085"]};
  }

  .title .high-light {
    color: ${THEME.color.primary.default};
  }

  .sub-title {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 4px;
  }

  .switch {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .radio {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
