import {TextField} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Button, Divider} from "../../components";
import Header from "../../components/Header";
import WhiteSpace from "../../components/WhiteSpace";
import {THEME} from "../../config";
import {EntityContext} from "../../context";
import {Radio} from "../../forms/Radio";
import {useDialog} from "../../hooks";
import {requestPost} from "../../lib";
import iconEdit from "../../svg/icon_edit.svg";
import iconRubbishBin from "../../svg/icon_rubbish.svg";
import iconUpEnable from "../../svg/icon_up_enable.svg";
import iconDownEnable from "../../svg/icon_down_enable.svg";
import iconUpDisable from "../../svg/icon_up_disable.svg";
import iconDownDisable from "../../svg/icon_down_disable.svg";
import PageLayout from "../Global/PageLayout";
import TTModal from "../Manage/components/Modal";
import {useHistory} from "react-router-dom";

type CategoryType = {
  id?: number
  title: string,
  count: number,
  index: number
}

type ProductInGroupType = {
  category: CategoryType[],
}

interface ICategoryListingPageProps {
  location: {
    state: {
      type: 'GROUP' | 'PRODUCT',
      data: {
        category?: CategoryType[],
        productions: ProductInGroupType[]
        product: any
        merchantId: number
      }
    }
  }
}

/**
 * 分类列表页，
 * 从团购进来：
 *  根据产品动态计算
 *
 *  从商品库进来：
 *  读取merchantID，网络请求分类数据和各个分类数量
 *
 *  返回值
 *
 * **/

export const DEFAULT_CATEGORY_NAME = '未分类'

function CategoryListingPage(props: ICategoryListingPageProps) {
  const history = useHistory<any>();
  const [name, setName] = useState<string>()
  const [showModal, setShowModal] = useState(false)
  const currentEditingCategory = useRef<CategoryType>()
  const [categoryGroupData, setCategoryGroupData] = useState<any>()
  const [selectedCategory, setSelectedCategory] = useState<any[]>([])
  const {
    replace,
    model: entityModel,
  } = React.useContext(EntityContext);

  const groupBuyData = entityModel["GROUP_BUY"]?.response;
  useEffect(() => {
    reFormatCategoryData()
    if (props.location.state.data?.product) {
      if (props.location.state.data.product.category && props.location.state.data.product.category.length > 0) {
        setSelectedCategory(props.location.state.data.product.category.map(item => item.title))
      } else {
        setSelectedCategory([DEFAULT_CATEGORY_NAME])
      }
    }
  }, [groupBuyData])

  const reFormatCategoryData = () => {
    // 1, format category data
    const categoryData = new Map<string, { count: number, id?: number }>()
    addedToCate(DEFAULT_CATEGORY_NAME, null)
    if (groupBuyData.category) {
      for (const cate of groupBuyData.category) {
        if (!cate) {
          continue
        }
        categoryData.set(cate.title, {count: 0, id: cate.id})
      }
    }
    // 2, format products data
    if (groupBuyData.groupToProductions) {
      for (const product of groupBuyData.groupToProductions) {
        if (product.category && product.category.length > 0) {
          for (const cate of product.category) {
            addedToCate(cate.title, cate.id)
          }
        }
      }
    }


    function addedToCate(title: string, id?: number) {
      if (categoryData.has(title)) {
        categoryData.set(title, {
          count: categoryData.get(title).count + 1,
          id: id
        })
      } else {
        categoryData.set(title, {
          count: 1,
          id: id
        })
      }
    }

    const result: any[] = []
    for (const key of categoryData.keys()) {
      result.push({
        title: key,
        count: categoryData.get(key).count,
        id: categoryData.get(key).id
      })
    }
    setCategoryGroupData(result)
  }

  const categoryOperation = async (event: OperationType, target: any, index: number) => {
    switch (event) {
      case "EDIT": {
        currentEditingCategory.current = target
        setName(target.title)
        setShowModal(true)
        break
      }
      case "DELETE": {
        // 删除分类
        const newCategory = groupBuyData.category.filter(item => item.title !== target.title)
        const newProductions = []
        if (groupBuyData.groupToProductions) {
          for (const product of groupBuyData.groupToProductions) {
            if (product.category) {
              product.category = product.category.filter(item => item.title !== target.title)
            }
            newProductions.push(product)
          }
        }
        replace('GROUP_BUY', {
          category: newCategory,
          groupToProductions: newProductions
        })
        await requestPost('group/category/operate', {
          id: target.id,
          operate: 'DELETE'
        })
        break
      }
      case "UP": {
        const category = groupBuyData.category
        const temp = category[index]
        category[index] = category[index - 1]
        category[index - 1] = temp
        replace('GROUP_BUY', {
          category: category
        })
        await requestPost('group/category/operate', {
          id: target.id,
          operate: 'UP'
        })
        break
      }
      case "DOWN": {
        const category = groupBuyData.category
        const temp = category[index]
        category[index] = category[index + 1]
        category[index + 1] = temp
        replace('GROUP_BUY', {
          category: category
        })
        await requestPost('group/category/operate', {
          id: target.id,
          operate: 'DOWN'
        })
        break
      }
    }
  }

  const submit = () => {
    switch (props.location.state.type) {
      case "GROUP": {
        replace("GROUP_BUY", {
          ...entityModel,
          category: categoryGroupData
        });
        break
      }
      case "PRODUCT": {
        const newCategory = []
        for (const cate of selectedCategory) {
          const target = categoryGroupData.filter(item => item.title === cate)
          if (target.length > 0) {
            newCategory.push(target[0])
          }
        }
        replace("GROUP_BUY", {
          ...entityModel,
          category: categoryGroupData,
          product: {
            ...props.location.state.data.product,
            category: newCategory
          },
        });
      }
    }
    history.goBack()
  }

  return (
    <PageLayout pageName="category no nav">
      <Header title="团购商品分类管理" right={(
        <HeaderRight onClick={() => {
          setShowModal(true)
        }}>
          <span className='plus'>+</span><span className='text'>创建新分类</span>
        </HeaderRight>
      )}/>
      <CategoryListingPageContainer>
        <ListContainer>
          {categoryGroupData ? categoryGroupData.map((item, index) => {
            return (
              <CategoryCard
                key={item.title}
                title={item.title}
                count={item.count}
                canOperation={item.title !== DEFAULT_CATEGORY_NAME}
                canSelected={props.location.state.type === 'PRODUCT'}
                isSelected={selectedCategory.filter(_item => _item === item.title).length > 0}
                index={index}
                total={categoryGroupData.length}
                onOperation={(event => {
                  categoryOperation(event, item, index - 1)
                })}
                onSelect={(selected) => {
                  if (selected) {
                    if (selectedCategory.length > 0 && item.title === DEFAULT_CATEGORY_NAME) {
                      return
                    }
                    setSelectedCategory([
                      item.title
                    ])
                  }
                }}
              />
            )
          }) : null}
        </ListContainer>
        <div className="bottom-float">
          <Button
            fullWidth
            onClick={submit}
          >
            确定
          </Button>
        </div>


      </CategoryListingPageContainer>
      <TTModal
        show={showModal}
        title='分类名称'
        onConfirm={async () => {
          if (!name) {
            return
          }
          // 判断是否重复
          const sameNameCategory = categoryGroupData.filter(item => item.title === name)
          if (sameNameCategory.length === 0) {
            if (currentEditingCategory.current) {
              // 编辑当前分类
              const result = []
              for (const cate of groupBuyData.category) {
                if (cate.title === currentEditingCategory.current.title) {
                  result.push({
                    ...currentEditingCategory.current,
                    title: name
                  })
                } else {
                  result.push(cate)
                }
              }
              const newProductions = []
              if (groupBuyData.groupToProductions) {
                for (const product of groupBuyData.groupToProductions) {
                  if (product.category) {
                    const newCategory = []
                    for (const cate of product.category) {
                      if (cate.title === currentEditingCategory.current.title) {
                        newCategory.push({
                          ...currentEditingCategory.current,
                          title: name
                        })
                      } else {
                        newCategory.push(cate)
                      }
                    }
                    product.category = newCategory
                  }
                  newProductions.push(product)
                }
              }
              replace('GROUP_BUY', {
                category: result,
                groupToProductions: newProductions
              })
              await requestPost('group/category/update', {
                category: {
                  ...currentEditingCategory.current,
                  title: name
                },
                groupId: groupBuyData.id
              })
            } else {
              // 添加新分类
              if (groupBuyData?.category) {
                replace('GROUP_BUY', {
                  category: [
                    ...groupBuyData?.category,
                    {
                      title: name,
                      count: 0,
                      id: null
                    }
                  ]
                })
              } else {
                replace('GROUP_BUY', {
                  category: [
                    {
                      title: name,
                      count: 0,
                      id: null
                    }
                  ]
                })
              }

              await requestPost('group/category/update', {
                category: {
                  title: name
                },
                groupId: groupBuyData.id
              })
            }
          }
          setName('')
          setShowModal(false)
        }}
        onCancel={() => {
          setShowModal(false)
        }}
        content={(
          <div style={{
            padding: 16
          }}>
            <TextField
              style={{width: '100%'}}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </div>
        )}
      />
    </PageLayout>
  )
}

export default CategoryListingPage

const HeaderRight = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;

  .plus {
    display: inline-block;
    text-align: center;
    font-size: 24px;
    height: 24px;
    line-height: 18px;
    width: 24px;
    border-radius: 12px;
    border: 1px solid white;
    margin-right: 8px;
  }
`

const CategoryListingPageContainer = styled.div`
  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`

const ListContainer = styled.div`
  padding: 16px;
  margin-bottom: 48px;
`

type OperationType = 'EDIT' | 'DELETE' | 'UP' | 'DOWN'

interface ICategoryCardProps {
  title: string
  count: number,
  index: number,
  total: number,
  canSelected?: boolean
  isSelected?: boolean,
  onSelect?: (selected: boolean) => void
  canOperation?: boolean
  onOperation?: (e: OperationType) => void
}

function CategoryCard(props: ICategoryCardProps) {
  const {renderDialog, showDialog, hideDialog} = useDialog();
  return (
    <CardContainer>
      <div className='line1'>
        <div className='title'>{props.title}</div>
        {props.canSelected ? (
          <Radio
            className='radio'
            value={props.isSelected}
            onChange={(selected) => {
              if (props.onSelect) {
                props.onSelect(selected)
              }
            }}
          ></Radio>
        ) : null}
      </div>
      <WhiteSpace height={8}/>
      <div className='line2'>
        <div className='count'>商品数:{props.count}</div>
        {props.canOperation ? (
          <div className='buttons'>
            <img
              className='edit-icon'
              src={iconEdit}
              onClick={() => {
                if (props.onOperation) {
                  props.onOperation('EDIT')
                }
              }}
            />
            <img
              className='delete-icon'
              src={iconRubbishBin}
              onClick={() => {
                if (props.onOperation) {
                  showDialog({
                    title: `确定删除分类-${props.title}?`,
                    message: '将从该团购的所有商品中删除该分类',
                    cancelText: "取消",
                    okText: "确定",
                    onOK: () => {
                      hideDialog();
                      props.onOperation('DELETE')
                    },
                  });

                }

              }}
            />
            <img
              className='up-icon'
              src={props.index <= 1 ? iconUpDisable : iconUpEnable}
              onClick={() => {
                if (props.onOperation && props.index > 0) {
                  props.onOperation('UP')
                }
              }}
            />
            <img
              className='down-icon'
              src={props.index === props.total - 1 ? iconDownDisable : iconDownEnable}
              onClick={() => {
                if (props.onOperation && props.index < props.total - 1) {
                  props.onOperation('DOWN')
                }
              }}
            />
          </div>
        ) : null}

      </div>
      <WhiteSpace/>
      <Divider/>
      {renderDialog()}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  margin-top: 8px;

  .line1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
    }

    .radio {

    }
  }

  .line2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .count {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: ${THEME.color.gray["#11-065"]};
    }

    .buttons {
      width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`
