import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import {Button, Divider, ListInputField} from "../../components";
import Card from "../Payment/Components/Card";
import {requestGet, requestPost} from "../../lib";
import {useHistory} from "react-router-dom";

function AddDelivery(){
  const [label, setLabel] = useState('')
  const [no, setNo] = useState('')
  const [url, setUrl] = useState('')
  const history:any = useHistory()

  useEffect(()=>{
    if (history.location.state.deliveryId){
      fetchDelivery()
    }
  }, [])

  const fetchDelivery = async ()=>{
    const {data} = await requestGet('manage/getDeliveryById', {
      id: history.location.state.deliveryId
    })
    if (data){
      setLabel(data.label)
      setNo(data.no)
      setUrl(data.queryUrl)
    }
  }

  const submit = async ()=>{
    const {data} = await requestPost("manage/addInfoToOrder", {
      orderId: history.location.state.orderId,
      info:{
        label,
        no,
        url,
        deliveryId: history.location.state.deliveryId
      },
      type: 'DELIVERY'
    });
    if (data){
      history.goBack()
    }
  }
  return(
    <PageLayout pageName="no nav delivery add">
      <Header title="添加快递信息"/>
      <Container>
        <Card>
          <ListInputField
            required
            name="label"
            label="快递公司"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value,);
            }}
            placeholder="如，NZPOST"
            bottomDivider
          />
          <ListInputField
            required
            name="no"
            label="快递单号"
            placeholder="如：MX1234567"
            value={no}
            bottomDivider
            onChange={(e) => {
              setNo(e.target.value)
            }}
          />
          <ListInputField
            required
            name="no"
            label="查询连接"
            placeholder="如：http:nzpost.co.nz/MX1234567"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value)
            }}
          />
        </Card>
        <div className='bottom-float'>
          <Button
            fullWidth
            onClick={async () => {
              await submit()
            }}
          >确认</Button>
        </div>
      </Container>
    </PageLayout>
  )
}

export default AddDelivery

const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  min-height: ${window.innerHeight - 56}px;
  .input-area{
    
  }
  .bottom-float {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`
