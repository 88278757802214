import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
  getImageAvatar,
  getImageById,
  uploadImage,
} from "../../../utils/ImageUtils";
import dayjs from "dayjs";
import {LOCAL_URL, THEME, WEB_ROOT, WECHAT_FOLLOW_URL} from "../../../config";
import numeral from "numeral";
import {isEmpty, requestGet, requestPost} from "../../../lib";
import {useRouter} from "../../../hooks/router";
import TTModal from "./Modal";
import Avatar from "@mui/material/Avatar";
import {useOrderUIComponents} from "../../../hooks/order";
import {useHistory} from "react-router-dom";
import ReceiptInfo from "./ReceiptInfo";
import {isAndroid} from "../../../utils/common";
import CallIcon from '@mui/icons-material/Call';
import { checkAnonymousViewData } from '../../CreateGroupBuy/anonymousView'

export type OrderOperation =
  | "cancel"
  | "modify_product"
  | "modify_address"
  | "pay"
  | "cancel_apply"
  | "cancel_cancel_apply"
  | "set_as_paid"
  | "set_as_delivered"
  | "price_confirm"
  | "modify_price"
  | "add_remark"
  | "add_delivery"
  | "add_receipt"
  | "confirm_sms";

export class AddressInfo {
  addressName: string;
  contactName: string;
  formattedAddress: string;
  extendInfo: string;
  telephone: string;
  notes: string;
}

type OrderCardProps = {
  group: any;
  type: "USER" | "MERCHANT";
  onRefresh: () => void;
  order: {
    groupIndex: number;
    actions: any[];
    no: string;
    id: number;
    user: any;
    remarks: any[];
    delivery: any[];
    totalPrice: number;
    action: any[];
    totalCNYPrice: number;
    shipType: "PICKUP" | "DELIVERY";
    createdAt: string;
    name: string;
    phone: string;
    address: AddressInfo;
    productions: any[];
    transactions: any[];
    note: string;
    status:
      | "CREATED" // 创建订单
      | "PRE_PAID" // 已经上传付款截图，待确认
      | "PAID" // 已经付款
      | "DELIVERING" // 已经发货---暂无后续状态
      | "DONE" // 订单已完成
      | "CANCELED" // 订单已取消
      | "EXPIRED"; // 订单已关闭
    cancelStatus: "NA" | "WAITING_CANCEL_APPROVE" | "APPROVED";
    refundStatus: "NA" | "WAITING_TRANSFER" | "DONE";
    merchant?: any;
    meta: any;
    isAnonymous?: boolean;
  };
  actions: any[];
  isMine?: boolean;
  merchant?: any
};
const OrderCard = (props: OrderCardProps) => {
  const {
    statusStr,
    getPaymentMethodStr,
    shippingStatusStr,
    ActionList,
    ActionInfoCard,
  } = useOrderUIComponents();
  const history = useHistory();
  const [orderInfo, setOrderInfo] = useState(props.order);

  const [lastTransactions, setLastTransactions] = useState();

  useEffect(() => {
    setOrderInfo(props.order);
  }, [props.order]);

  const getOrderPrice = () => {
    return numeral(orderInfo.totalPrice / 100).format("$0,0.00");
  };

  const refreshOrder = async () => {
    const {data} = await requestGet("order/detail", {
      id: orderInfo.id,
    });
    if (data) {
      setOrderInfo(data);
    }
  };

  useEffect(() => {
    setLastTransactions(
      orderInfo.transactions[orderInfo.transactions.length - 1]
    );
  }, [orderInfo]);

  const { avatarLink, userName } = checkAnonymousViewData(
    !!orderInfo.isAnonymous && (!props.isMine) && (props.type==="USER"),
    orderInfo.user || {}
  )

  return (
    <Container isMine={props.isMine}>
      <div className="header">
        {!props.isMine && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              history.push(LOCAL_URL.ORDER_DETAIL, {
                orderId: orderInfo.id,
              });
            }}
          >
            <div className="index">{orderInfo.groupIndex}</div>
            <div className="avatar">
              {avatarLink ? (
                <img src={avatarLink} alt=""/>
              ) : (
                <Avatar style={{width: 32, height: 32}}>
                  {userName
                    ? userName.substring(0, 1)
                    : ""}
                </Avatar>
              )}
            </div>
          </div>
        )}
        <div className="nameAndTime">
          <div className="name">
            { props.isMine ?
                (props.group?.type === 'DEFAULT' ? props.group?.title
                :
                `${props.merchant?.name}在线店铺`)
              :
              userName
            }
          </div>
          <div className="time">
            {props.isMine ? `跟团号：${orderInfo.groupIndex}` : ""}{" "}
            {dayjs(orderInfo.createdAt).format("DD/MM/YYYY HH:mm")}
          </div>
        </div>
        <div className="status-area">
          <div className="order">{statusStr(orderInfo)}</div>
          <div className="shipping">{shippingStatusStr(orderInfo)}</div>
        </div>
      </div>
      <div className="address">
        <div className="title">
        订单号：SMT{orderInfo.id}
        </div>
      </div>
      {orderInfo.shipType === "PICKUP" && (
        <div className="address">
          <div className="title">自提 · {orderInfo.address.addressName}</div>
          <div className="contact">{`${orderInfo.name} ${orderInfo.phone}`} <a
            href={`tel:${orderInfo.phone}`}><CallIcon/></a></div>
        </div>
      )}
      {orderInfo.shipType === "DELIVERY" && (
        <div className="address">
          <div className="title">配送</div>
          <div className="contact">
            {`${orderInfo.address.contactName} ${orderInfo.address.telephone}`} <a
            href={`tel:${orderInfo.address.telephone}`}><CallIcon/></a>
          </div>
          <div className="format-address">
            {orderInfo.address.formattedAddress}
          </div>
          {orderInfo.address.extendInfo && (
            <div className="format-address">{orderInfo.address.extendInfo}</div>
          )}
        </div>
      )}
      {orderInfo.meta && orderInfo.meta.customOptions && !isEmpty(orderInfo.meta.customOptions) && (
        Object.keys(orderInfo.meta.customOptions).map((item: string) => {
          const value = orderInfo.meta.customOptions[item]
          return (
            <div className='customFields' key={item}>
              {item}: {value}
            </div>
          )
        })
      )}
      <div className="products">
        {orderInfo.productions.map((item, index) => {
          return (
            <div className="product" key={index}>
              {item.title} x {item.amount}
            </div>
          );
        })}
        {orderInfo.note && <div className="note">备注：{orderInfo.note}</div>}
        {lastTransactions && (
          <div className="note">
            支付方式：{getPaymentMethodStr(lastTransactions)}
          </div>
        )}
      </div>
      <div className="price">{getOrderPrice()}</div>
      <div className="action">
        <ActionInfoCard
          order={orderInfo}
          type={props.type}
          onRefresh={refreshOrder}
        />
      </div>
      {props.type === "USER" && (
        <div className="follow-wechat">
          所有的订单通知将通过微信公众号‘新西兰神马大吉科技’发送，
          <a href={WECHAT_FOLLOW_URL} target="_blank">
            点击此处关注
          </a>
        </div>
      )}
      {orderInfo.delivery &&
        orderInfo.delivery.length > 0 &&
        orderInfo.delivery.map((item) => {
          if (item.label && item.label.length > 0) {
            return (
              <div
                style={{
                  marginLeft: 56,
                  paddingTop: 8,
                  fontSize: 12,
                  color: THEME.color.primary.default,
                  marginBottom: 8,
                }}
                key={item.id}
              >
                {item.label || ""}：{item.no || ""}{" "}
                <a href={item.queryUrl}>点击查看</a>
              </div>
            );
          }
          return null;
        })}
      {orderInfo.remarks &&
        orderInfo.remarks.length > 0 &&
        orderInfo.remarks.map((item) => (
          <div
            style={{
              marginLeft: 56,
              paddingTop: 8,
              fontSize: 12,
            }}
            key={item.content + item.id}
          >
            {item.type === "PRIVATE"
              ? `私密备注: ${item.content}`
              : `团长备注: ${item.content}`}
          </div>
        ))}
      <ActionList
        order={orderInfo}
        type={props.type}
        onRefresh={refreshOrder}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .index {
      width: 30px;
      font-size: 12px;
      color: ${THEME.color.gray["#10-085"]};
      text-align: left;
    }

    .avatar {
      width: 32px;

      img {
        border-radius: 16px;
        height: 32px;
        width: 32px;
      }
    }

    .nameAndTime {
      flex: 1;
      margin-left: 4px;
      font-size: 12px;

      .name {
        color: ${THEME.color.gray["#10-085"]};
        ${(props) =>
                props.isMine
                        ? `
          font-weight: 500;
          font-size: 15px;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.85);`
                        : ""}
      }

      .time {
        color: ${THEME.color.gray["#12-045"]};
      }
    }

    .status-area {
      //width: 60px;
      color: ${THEME.color.primary.default};
      font-size: 12px;

      .order {
      }

      .shipping {
        margin-top: 4px;
      }
    }
  }

  .address {
    font-size: 12px;
    margin-left: ${(props) => (props.isMine ? 4 : 56)}px;
    margin-top: 16px;
    line-height: 16px;

    .title {
      font-weight: 500;
      color: ${THEME.color.gray["#10-085"]};
    }

    .contact {
      font-weight: 400;
      color: ${THEME.color.gray["#10-085"]};
      display: flex;
      a {
        margin-left: 8px;
        height: 16px;
        width: 16px;
        display: inline-block;
        background-color: #649A76;
        color: white;
        border-radius: 8px;
        text-align: center;
        padding-top: 2px;
        text-decoration: none;

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }

    .format-address {
      color: ${THEME.color.gray["#12-045"]};
    }
  }

  .products {
    margin-top: 16px;
    margin-left: ${(props) => (props.isMine ? 4 : 56)}px;
    font-size: 12px;
    line-height: 18px;
    color: ${THEME.color.gray["#10-085"]};
    font-weight: 400;

    .product {
    }

    .note {
      font-weight: 500;
    }
  }

  .customFields {
    margin-left: ${(props) => (props.isMine ? 4 : 56)}px;
    color: ${THEME.color.gray["#10-085"]};
    font-size: 12px;
  }

  .price {
    text-align: right;
    font-size: 15px;
    color: ${THEME.color.primary.default};
  }

  .action {
    margin-left: ${(props) => (props.isMine ? 4 : 56)}px;
    margin-top: 16px;
  }

  .follow-wechat {
    margin-top: 8px;
    margin-left: ${(props) => (props.isMine ? 4 : 56)}px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #cc1543;
  }
`;

export default OrderCard;
