export const REQ_URL = 'coupon'
const QUERY_PARAMS = {
  relations: 'user,promotion,merchant,redeemMerchant',
  sorter: 'createdAt,DESC'
}

export interface IQueryParams {
  [propName:string]: string | number;
}
export const buildQueryString = (params:IQueryParams) => {
  const p = !params ? {
    ...QUERY_PARAMS
  } : {
    ...params,
    ...QUERY_PARAMS
  }
  return (
    '?' + 
    Object.keys(p).map((key:string) => `${key}=${p[key]}`).join('&')
  )
}
