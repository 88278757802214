// 只有 Icon 的 Logo：来自设计图 Receipt Page
export const IconLogoLovinlocal = () => (
  <svg viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2384 13.3096L13.2447 13.3033C14.9066 11.6336 14.9066 8.91595 13.2384 7.24207C11.545 5.6228 8.88976 5.6228 7.18384 7.25257C6.39795 8.08426 5.9809 9.17218 6.01024 10.3168C6.03958 11.4614 6.51321 12.5262 7.34312 13.3138C8.16883 14.0993 9.22926 14.492 10.2876 14.492C11.3501 14.492 12.4106 14.0993 13.2384 13.3096ZM10.2163 11.9151C9.77205 11.9151 9.35291 11.7428 9.03855 11.4278C8.7221 11.1128 8.54606 10.6927 8.54606 10.2454C8.54396 9.79804 8.71791 9.378 9.03855 9.05246C9.36339 8.72903 9.78882 8.56731 10.2163 8.56731C10.6439 8.56731 11.0693 8.72903 11.3941 9.05246C12.048 9.70353 12.0501 10.7662 11.4004 11.4215C11.0861 11.7386 10.6669 11.9151 10.2205 11.9151C10.2205 11.9151 10.2184 11.9151 10.2163 11.9151Z" />
    <path d="M33.0107 2.99596C29.2678 -0.755029 23.2824 -0.998654 19.246 2.3743C19.8517 3.03167 20.3547 3.74994 20.757 4.51022C23.7791 1.84294 28.3037 1.96476 31.1728 4.84206C34.1382 7.81807 34.1382 12.6612 31.1728 15.6372L25.5604 21.2616L25.5625 21.2637L17.9991 28.8497L12.0577 22.8955L17.4521 17.4896C21.4382 13.4929 21.4382 6.99268 17.4521 2.99596C13.4639 -0.998655 6.9777 -0.998655 2.98954 2.99596C-0.996514 6.99268 -0.996514 13.4929 2.98954 17.4896L6.25887 20.7512C6.80375 21.2196 7.62947 21.1566 8.09681 20.6084C8.51805 20.117 8.51805 19.4008 8.08004 18.8904L4.82749 15.6351C3.38983 14.1922 2.59764 12.2726 2.59764 10.2333C2.59974 8.19401 3.39192 6.27651 4.83378 4.83366C6.27354 3.39291 8.18483 2.59902 10.2198 2.59902C10.2219 2.59902 10.2219 2.59902 10.224 2.59902C12.2589 2.59902 14.1723 3.39501 15.6121 4.83996C18.5775 7.81597 18.5775 12.6591 15.6121 15.6351L9.29347 21.9673C8.9854 22.2781 8.85337 22.7212 8.94139 23.1476C8.95396 23.2127 8.97492 23.2757 8.99168 23.324L9.00845 23.3744C9.07342 23.5424 9.17192 23.6936 9.29975 23.8217L17.0351 31.5716L17.0749 31.6178C17.3096 31.853 17.6219 31.9874 17.953 32H17.9991C18.0054 32 18.0117 32 18.0159 32H18.0347C18.37 31.9916 18.6823 31.8551 18.938 31.5947L27.5241 22.9817L33.0086 17.4896C36.9968 13.4929 36.9968 6.99058 33.0107 2.99596Z" />
  </svg>
)

export const IconGoogleLogo = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.31888 14.5035L4.48357 17.6221L1.43013 17.6868C0.506923 15.9669 0.0164068 14.0481 0.000971036 12.0962C-0.0144647 10.1442 0.445644 8.2179 1.34154 6.48364L4.06029 6.98192L5.24997 9.68145C4.71461 11.2483 4.73846 12.9522 5.31747 14.5035H5.31888Z" fill="#FBBB00"/>
    <path d="M23.7904 9.75854C24.1975 11.8982 24.0145 14.1084 23.2609 16.1519C22.5072 18.1954 21.2115 19.9952 19.5126 21.3582L16.0908 21.1839L15.6061 18.1585C17.0201 17.3298 18.1065 16.0405 18.6834 14.5065H12.2639V9.75854H23.7904Z" fill="#518EF8"/>
    <path d="M19.5117 21.3571C18.1385 22.459 16.5397 23.2453 14.8287 23.6604C13.1177 24.0754 11.3364 24.109 9.61091 23.7588C7.88547 23.4085 6.25819 22.683 4.84442 21.6337C3.43066 20.5844 2.26503 19.2369 1.43015 17.6868L5.32077 14.5035C5.68592 15.4768 6.25928 16.3586 7.00075 17.0872C7.74222 17.8157 8.63391 18.3735 9.61344 18.7215C10.593 19.0696 11.6367 19.1994 12.6717 19.102C13.7066 19.0045 14.7078 18.6822 15.6051 18.1574L19.5117 21.3571Z" fill="#28B446"/>
    <path d="M19.6594 2.76289L15.7721 5.94523C14.8613 5.37773 13.8344 5.02292 12.7675 4.90723C11.7007 4.79154 10.6216 4.91794 9.61036 5.27702C8.59915 5.63611 7.68195 6.21862 6.92702 6.98122C6.17209 7.74382 5.59887 8.66686 5.25003 9.68164L1.34065 6.48148C2.165 4.8891 3.33574 3.5017 4.76679 2.42128C6.19784 1.34085 7.85283 0.594857 9.61009 0.238143C11.3674 -0.118572 13.1822 -0.0769416 14.9213 0.359973C16.6604 0.796888 18.2794 1.61798 19.6594 2.76289Z" fill="#F14336"/>
  </svg>
)

export const IconFacebookLogo = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.82 24H1.32C0.969455 23.9987 0.633719 23.8585 0.386314 23.6102C0.138909 23.3618 -2.49586e-06 23.0255 0 22.675L0 1.325C0 0.973588 0.139598 0.636569 0.388084 0.388084C0.636569 0.139598 0.973588 0 1.325 0L22.675 0C23.0264 0 23.3634 0.139598 23.6119 0.388084C23.8604 0.636569 24 0.973588 24 1.325V22.675C24 23.0264 23.8604 23.3634 23.6119 23.6119C23.3634 23.8604 23.0264 24 22.675 24H16.56V14.705H19.68L20.145 11.085H16.56V8.77C16.56 7.72 16.85 7.01 18.355 7.01H20.275V3.77C19.3534 3.67079 18.427 3.62238 17.5 3.625C14.735 3.625 12.84 5.315 12.84 8.41V11.085H9.69V14.705H12.82V24Z" fill="#4460A0"/>
  </svg>
)

export const IconAppleLogo = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3202 12.7525C18.355 16.3825 21.5079 17.5822 21.5426 17.6073C21.1568 18.8196 20.5972 19.9695 19.8812 21.021C18.8805 22.4835 17.8431 23.9401 16.2145 23.9788C14.6091 24.0077 14.0894 23.0244 12.2541 23.0244C10.4188 23.0244 9.83919 23.9401 8.31492 24C6.73462 24.0599 5.53298 22.4178 4.52453 20.9631C2.46319 17.9899 0.888693 12.5419 3.00412 8.86936C3.49988 7.98053 4.21824 7.2359 5.08869 6.70855C5.95914 6.1812 6.95176 5.88926 7.96911 5.86139C9.51463 5.83241 10.9829 6.90461 11.9314 6.90461C12.88 6.90461 14.6554 5.6141 16.5255 5.80343C17.2983 5.83627 19.5045 6.12026 20.9148 8.18546C20.8008 8.25501 18.2932 9.71553 18.3202 12.7525ZM15.3007 3.83289C16.1391 2.81864 16.7032 1.40642 16.5487 0C15.3412 0.0482975 13.8807 0.805603 13.0133 1.81985C12.2405 2.71818 11.5566 4.15552 11.7402 5.53296C13.0925 5.63729 14.4622 4.84714 15.3007 3.83289Z" fill="black"/>
  </svg>
)
