import { CreateSlice } from './index'

type ModifyingStateType = {
  order: any,
  productAmount: {
    [id:number]: number
  }
}
export interface IModifyingOrderSlice {
  modifyingState: ModifyingStateType;
  setOrderInModifying: (order:any) => void;
}

export const createOrderInModifyingSlice: CreateSlice<IModifyingOrderSlice> = (set) => ({
  modifyingState: {
    order: null,
    productAmount: { }
  },
  setOrderInModifying: (order: any) => set((state) => {
    state.modifyingState.order = !order ? null : { ...order }
    state.modifyingState.productAmount = order?.productions?.reduce((accu, item) => {
      if (!!item?.id && !!item?.amount) {
        accu[item?.id] = item.amount
      }
      return accu
    }, { }) || { }
    return {
      ...state,
    }
  }),
})
