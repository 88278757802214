import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { THEME, LOCAL_URL } from '../config';
// import * as config from '../config';

const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
`;

const Content = styled.div`
  text-align: center;
  line-height: 24px;

  & img {
    height: 152px;
  }
  & a {
    color: ${THEME.color.primary.default};
  }
  & button {
    display: block;
    margin: 0 auto;
    width: 232px;
    height: 48px;
    border: none;
    border-radius: 8px;
    background: ${THEME.color.primary.default};
    color: #fff;
  }
`;

// const Exception = ({type}) => (
//   <div style={{height: '100vh', marginTop: '10rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
//     <h1>💔⚠️🐞</h1>
//     <h1>{`系统报错：${type}`}</h1>
//     <Link to={LOCAL_URL.WELCOME}>转到登陆页</Link>
//   </div>
// )

const Exception = ({type}) => {
  const history = useHistory();
  return (
    <PageContainer className='flex-center align-center'>
      <Content>
        <img src=" https://res.cloudinary.com/shenmanz/image/upload/v1634607622/lovinloca/user/Error.png" alt="error" />
        <p style={{marginTop: '48px', lineHeight: '28px'}} className="ft-rb24">Something went wrong!</p>
        <p style={{marginTop: '8px'}} className="ft-rr18" dangerouslySetInnerHTML={{__html: 'Sorry, something went wrong there.<br/>Please try again.'}}></p>
        <button style={{marginTop: '40px'}} className="shadow04" onClick={() => history.push(LOCAL_URL.WELCOME)}><span className="ft-rb18">Reload</span></button>
        {/* <div style={{marginTop: '40px'}}><Link to={LOCAL_URL.CUSTOMER_SUPPORT}><span className="ft-rb18">Contact Us</span></Link></div> */}
      </Content>
    </PageContainer>
  )
}

export default function ExceptionPage ({match, location}) {
  const status = match?.params?.type || 'UNKNOWN';
  const html = location?.state?.html;
  return (
    ((!html) || (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'master')) ? 
    <Exception type={status} />
    :
    <div>
      <h1>{`Response ${status}`}</h1>
      <div dangerouslySetInnerHTML={{__html:html}} ></div>
    </div>
  );
}
