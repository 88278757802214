const IMAGE_STORE_ROOT = "https://res.cloudinary.com/shenmanz/image/upload";
const API_ROOT =
  (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "master")
  ? "https://api_tuan.shenmanz.com/app"
  :
  ((process.env.REACT_APP_VERCEL_GIT_COMMIT_REF?.indexOf('integration') ?? -1) > 0)
  ? "https://api_tuan.shenmanz.com/app"
  :
  //'https://staging.lovinlocal.com'
  "http://localhost:3030/app";
  // "https://api_tuan.shenmanz.com/app"
  ;
export const EXTERNAL_URL = {
  API_ROOT,
  IMAGE_STORE_ROOT,
};
