import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { THEME } from '../../config';

const Container = styled.div`
  height: 28px;

  position: relative;
  display: flex;
`;

const ViewModeOption = styled.div`
  width: 50%;
  height: 100%;
  text-align: center;
  border      : 2px solid ${(props) => props.active ? THEME.color.primary.default : THEME.color.gray['#5-025'] };
  background  : ${(props) => props.active ? THEME.color.primary.variant10 : 'transparent' };
  color       : ${(props) => props.active ? THEME.color.primary.default : THEME.color.gray['#4-97'] };
  font-weight : ${(props) => props.active ? 600 : 500 };
  font-family : Poppins;
  font-size   : 13px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: none;
  }
  &.right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: none;
  }
`;
const Divider = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 28px;
  width: 2px;
  border: 1px solid ${THEME.color.primary.default};
`;

const VIEW_MODE_LABEL = {
  'list': 'List View',
  'map' :  'Map View'
}

export const SwitchHighlight = () => { 
  const [mode, setMode] = useState('list');
  // console.log(mode)
  return (
    <Container>
      <ViewModeOption className="left"  active={mode === 'list'} onClick={() => setMode('list')}>{VIEW_MODE_LABEL.list}</ViewModeOption>
      <Divider />
      <ViewModeOption className="right" active={mode === 'map'}  onClick={() => setMode('map')} >{VIEW_MODE_LABEL.map}</ViewModeOption>
    </Container>
  )
}
