import { TypographyGeneral, TypographyRoboto, TypographyPoppins } from './Typography'
import { Color } from './Color'
import { Elevation } from './Elevation'
import { Button } from '../../components'

export default function UISpecPage () { 
  return (
    <>

      <h1>UI Spec</h1>

      <h2>Typography</h2>

      <h3>General</h3>
      <TypographyGeneral />
  
      <h3>Roboto</h3>
      <TypographyRoboto />

      <h3>Poppins</h3>
      <TypographyPoppins />

      <h2>Color</h2>
      <Color />

      <h2>Elevation</h2>
      <Elevation />

      <h2>Button</h2>
      <div>
        <Button size="large"  color="red">Confirm Hot Deal Redemption</Button>
        <Button size="large"  color="white">Confirm Hot Deal Redemption</Button>
        <Button size="medium" color="red">PAY TOTAL BILL</Button>
        <Button size="medium" color="white">ACTIVATE</Button>
        <Button size="small"  color="red">Login</Button>
        <Button >Default</Button>
      </div>

    </>
  )
}
