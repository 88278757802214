import React, { useState, useRef, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { formatMoneyString } from '../../lib';
import { THEME, PATTERN_REG } from '../../config';

const REG_PATTERN = PATTERN_REG.NUMBER;

const InputFieldContainer = styled.div`
  position: relative;

  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
`;
const Label = styled.p`
  color: ${THEME.color.gray['#0-087']};

  line-height: 1.5em;
  margin-bottom: .25em;
`;
const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin-bottom: .25em;
`;
const Hint = styled.p`
  color: ${THEME.color.gray['#3-76']};
  line-height: 1.2em;
  margin: .5em 0 .25em .4em;
  font-weight: 700;
  font-size: 14px;
`;

const InputContainer = styled.div`
  width: 100%;
  height: 48px;
  padding: 0 .5em 0 1em;
  border-radius: .25em;
  border: 1px solid ${(props) => !props.error ? '#c7c8ca' : THEME.color.primary.default};
  background:#fff;

  font-weight: ${(props) => props.validInput ? '700' : '500'};
  font-size: ${(props) => props.validInput ? '32px' : '16px'};
  color: ${(props) => props.validInput ? THEME.color.gray['#0-087'] : THEME.color.gray['#5-025']};

  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;

  &.red {
    color: ${THEME.color.primary.default};
    font-size: 18px;
    background: ${THEME.color.primary.variant10};
    border: 1px solid ${THEME.color.primary.default};
  }
  &:focus-within {
    border: 1px solid ${THEME.color.primary.default};
  }

  & input:-webkit-autofill,
  & input:-webkit-autofill:hover, 
  & input:-webkit-autofill:focus,
  & textarea:-webkit-autofill,
  & textarea:-webkit-autofill:hover,
  & textarea:-webkit-autofill:focus,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: ${THEME.color.gray['#0-087']};
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
const InputPrefix = styled.div`
  display: inline-block;
`;
const InputPostfix = styled.div`
  display: inline-block;
`;
const InputStyled = styled.input`
  display: inline-block;
  font: inherit;
  width: ${(props) => (props.width || '100%')};
  flex-flow: 1;

  background: transparent;
  border: hidden !important;
  outline: none;
  &.red , &.red::placeholder {
    color: ${THEME.color.primary.default};
  }
`;
/**
  因为当 SpanHelper 上面的 InputContainer 的背景色是半透明的时候，会把 SpanHelper 的内容露出来
  所以要把 SpanHelper 的字体颜色设成透明颜色
 */
const SpanHelper = styled.span`
  position: absolute;
  z-index: -1;
  color: rgba(255, 255, 255, 0);
`;

const calculateWidth = (instance) => {
  if (!instance) return null;
  // else 
  return `${parseInt(window.getComputedStyle(instance).getPropertyValue("width")) + 6}px`;  // + 4 是为了额外留出输入框光标的空间
}
interface InputFieldProps {
  onChange?: (value: string | null | undefined) => void;
  value?: string | null | undefined;
  name: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  errors?: any;
  variant?: 'red';
  [propName: string]: any
}
export const InputMoney: React.FC<InputFieldProps>  = ({onChange, value, variant, name, label, placeholder, hint, errors, ...restProps}) => {
  const refSpan = useRef(null);
  const [spanWidth, setSpanWidth] = useState(null)
  const [input, setInput] = useState(null)

  useEffect(() => {
    // console.log('effect triggered')
    setSpanWidth(calculateWidth(refSpan?.current))
  }, [refSpan?.current, input]);

  const onInputChange = (e) => {
    e.preventDefault();

    if (e.target.value === input) return;               // no change at all
    else if (REG_PATTERN.test(e.target.value)) {        // valid input
      // console.log('set value', e.target.value)
      const tmpStr = formatMoneyString(e.target.value);
      setInput(tmpStr)
      if (tmpStr !== value) {
        // console.log('callback')
        if(!!onChange) onChange(tmpStr)
      }
    }
    else {                                            // restore as before
      // console.log('set input', input)
      setInput(input || '');                          // 如果不加 || ''，新进入页面后，首次输入的非法字符会被接受 !!! @@@
    }
  }

  // console.log(value, input, spanWidth)
  return (
    <InputFieldContainer {...restProps} >
      { !label? null : <Label>{label}</Label> }
      <InputContainer className={variant} validInput={!!input} >
        <SpanHelper ref={refSpan}>{input || placeholder}</SpanHelper>
        {!input ? null : <InputPrefix>$</InputPrefix> }
        <InputStyled inputMode="decimal" className={variant} placeholder= {placeholder} 
          value={input}
          width={spanWidth || 'auto'}
          onChange={onInputChange}
        />
        <InputPostfix></InputPostfix>
      </InputContainer>
      { (!errors || !errors[name]) ? null : <ErrMsg>{errors[name].message}</ErrMsg> }
      { !hint ? null : <Hint>{hint}</Hint> }
    </InputFieldContainer>
  )
}
