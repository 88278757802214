import React, { useState } from 'react';
import styled from 'styled-components';
import { IconHartEmpty, IconHartBlock } from '../Icons';
import { THEME } from '../../config';

const FavoriteButtonContainer = styled.div`
  color: ${(props) => props.color || '#4a4a4a'};
  cursor: pointer;

  & svg {
    display: inline-block;
    fill: ${(props) => props.color || '#4a4a4a'};
    width: 1.5rem;
    height: 1.5rem;
  }
`;
interface FavoriteButtonProps {
  onClick?: () => void;
  color?: string;
  isFavorite?: boolean;
  [propName: string]: any;
}
export const FavoriteButton:React.FC<FavoriteButtonProps> = ({onClick, color, isFavorite, ...restProps}) => {
  // const [favorite, setFavorite] = useState<boolean>(!!isFavorite);
  return (
    <FavoriteButtonContainer color={ !isFavorite ? color : THEME.color.primary.default } onClick={onClick} {...restProps} >
    { !isFavorite ?
      <IconHartEmpty />
      :
      <IconHartBlock />
    }
    </FavoriteButtonContainer>
  )
}
