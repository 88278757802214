import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { IconArrowRight } from '../Layout'
import { IFormInputProps } from './type'
import { Wrapper as BaseWrapper } from './styled'

const Wrapper = styled(BaseWrapper)`
  padding: 1rem .5rem;
  border-radius: 8px;
  background-color: white;
  color: rgba(0,0,0,0.85);
  font-size: 15px;
  line-height: 21px;
  /* white-space: pre-wrap; */
`

interface INumberInputProps
extends IFormInputProps {
  type: 'number';
}
export const NumberInput: React.FC<INumberInputProps> = ({required, label, placeholder, value, onChange, type}) => {
  const [mode, setMode] = useState<'view'|'edit'>('view')
  const [nbr, setNbr] = useState<number>(value)
  const ref = useRef<any>(null)

  const goEdit = () => {
    setMode('edit')
  }
  const quitEdit = () => {
    if (isNaN(nbr)) {
      setNbr(value)
    }
    else {
      onChange(nbr)
    }
    setMode('view')
  }
  const handleChange = (e) => {
    const n = parseInt(e.target.value)
    setNbr(n)
  }
  const onKeyDown = (e:any) => {
    if (e.code === 'Enter') {
      e.preventDefault()
      quitEdit()
    }
  }

  useEffect(() => {
    if (mode === 'edit') ref.current.focus();
  }, [mode, ref.current])
  useEffect(() => {
    if (value !== nbr) {
      ref.current.value = value.toString();
      setNbr(value)
    }
  }, [value])

  return (
    <Wrapper className="flex-between align-center">
      <label>{label}<span className='required'>{required ? ' *' : ''}</span></label>
      <div className="flex-end align-center"
        style={{display: mode === 'edit' ? 'none' : '', flex: 1}}
        onClick={ goEdit }
      >
        <p>{nbr}</p>
        <IconArrowRight />
      </div>
      <input autoFocus type={type} ref={ref}
        style={{display: mode === 'view' ? 'none' : ''}}
        placeholder={placeholder}
        value={nbr}
        onChange={handleChange}
        onBlur={quitEdit}
        onKeyDown={onKeyDown}
      />
    </Wrapper>
  )
}
