import {yupResolver} from "@hookform/resolvers/yup";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Card, CardActions, Collapse} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import {Button, IconKBArrowR, InputField, ListItem, notify,} from "../../components";
import Header, {FloatBottom} from "../../components/Header";
import WhiteSpace from "../../components/WhiteSpace";
import {LOCAL_URL, THEME} from "../../config";
import {EntityContext} from "../../context";
import {Check} from "../../forms/Check";
import useMerchant from "../../hooks/useMerchant";
import iconAdd from "../../svg/icon_add.svg";
import {scrollToBottom} from "../../utils/common";
import {DEFAULT_CATEGORY_NAME} from "../Category/groupCategory";
import CustomField from "../CreateGroupBuy/Components/CustomField";
import PageLayout from "../Global/PageLayout";
import TTModal from "../Manage/components/Modal";
import {AddressCard} from "../PersonalCenter/address";

export interface ICreateGroupBuyProps {
}

const StyledForm = styled.form`
  padding: 8px;

  .tips-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .tips {
    text-align: center;
    margin-bottom: 24px;
  }

  a {
    margin: 0px 8px 0px 8px;
  }

  .flex-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.primary.default};
    }
  }
`;
const Divider = styled.div`
  height: 8px;
`;
const AddNewProduct = styled.div`
  background-color: #fff;
  height: 64px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.primary.default};

  div {
    margin-left: 8px;
  }

  margin-top: 16px;
`;
const Line = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.09);
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 8px 8px 8px;
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  .title {
    font-size: 15px;
    color: ${THEME.color.gray["#10-085"]};
  }

  .title .high-light {
    color: ${THEME.color.primary.default};
  }

  .sub-title {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 4px;
  }

  .switch {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .radio {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const StyledPickupAddress = styled.div`
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin: 0.5em 0 0.25em;
`;

const schema = yup.object().shape({
  groupToProductions: yup.array().min(1, "至少选择一个商品"),
  deliveryNotes: yup.string().when("canDelivery", {
    is: true,
    then: (schema) => schema.required("配送说明不能为空"),
  }),
  pickupAddresses: yup.array().when("canPickup", {
    is: true,
    then: (schema) => schema.min(1, "至少选择一个自提地址"),
    otherwise: (schema) => schema.min(0),
  }),
  canDelivery: yup.bool().when("canPickup", {
    is: false,
    then: (schema) => schema.isTrue("至少选择一种配送方式"),
    //otherwise: (schema) => schema.isFalse("至少选择一种配送方式"),
  }),
  bankAccount: yup.string().when("canTranPayment", {
    is: true,
    then: (schema) => schema.required("收款账号不能为空"),
  }),
  // canPickup: yup.bool().when("canDelivery", {
  //   is: false,
  //   then: (schema) => schema.isTrue("至少选择一种配送方式"),
  // }),
});

export default function ShoppingManagePage(props: ICreateGroupBuyProps) {
  const history = useHistory<any>();

  const {currentMerchantId, getCurrentMerchant} = useMerchant();
  const [currentMerchant, setCurrentMerchant] = useState<any>({});
  const [showTranAccountModal, setShowTranAccountModal] = useState(false);

  const {register, handleSubmit, reset, control, watch, errors} = useForm({
    defaultValues: {
      canDelivery: false,
      canPickup: false,
      pickupAddresses: [],
      groupToProductions: [],
      deliveryNotes: "",
      title: "",
      description: "",
      onlinePayment: false,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    model: entityModel,
    query,
    create,
    remove,
    update,
    replace,
    flush,
  } = React.useContext(EntityContext);

  const watchCanDelivery = watch("canDelivery");
  const watchCanPickup = watch("canPickup");
  const watchCanTranPayment = watch("canTranPayment");

  const onSumbit = async (data) => {
    if (!data.onlinePayment && !data.canTranPayment && !data.canCash) {
      notify.error("请至少选择一种支付方式");
      return;
    }
    if (groupBuyData.customFields) {
      for (const field of groupBuyData.customFields) {
        if (field.meta) {
          const metaSet = new Set()
          for (const m of field.meta) {
            if (metaSet.has(m)) {
              notify.error("自定义选项配置错误");
              return
            }
            metaSet.add(m)
          }
          if (metaSet.size < 2) {
            notify.error("自定义选项配置错误");
            return
          }
          if (!field.title || field.type.length === 0 || !field.select) {
            notify.error("自定义选项配置错误");
            return
          }
        } else {
          notify.error("自定义选项配置错误");
          return
        }
      }
    }
    let id = data.id;
    if (data.id && !isCopy) {
      await update("GROUP_BUY", {
        ...data,
        customFields: groupBuyData.customFields
      });
    } else {
      id = (
        await create("GROUP_BUY", {
          ...data,
          merchantId: currentMerchantId,
          customFields: groupBuyData.customFields,
          id: undefined,
        })
      ).id;
    }
    flush("GROUP_BUY");
    history.goBack()
  };

  const saveState = () => {
    replace('GROUP_BUY', {
      product: null
    })
    replace("GROUP_BUY", control.getValues());
  };

  useEffect(() => {
    const current = getCurrentMerchant()
    setCurrentMerchant(current);
  }, []);

  const restoreState = async () => {
    const groupBuyData = entityModel["GROUP_BUY"]?.response;
    console.log(groupBuyData)
    const merchant = getCurrentMerchant();
    let phone;
    if (typeof groupBuyData.phone === "undefined") {
      if (merchant?.phone && merchant?.phone !== 'NA') {
        phone = merchant.phone;
      }
    } else {
      phone = groupBuyData.phone;
    }

    let bankAccount;
    let bankAccountName;
    if (typeof groupBuyData.bankAccount === "undefined") {
      bankAccount =
        merchant && merchant.finance && merchant.finance.accountNoForClient
          ? merchant.finance.accountNoForClient
          : "";
      bankAccountName =
        merchant && merchant.finance && merchant.finance.accountNameForClient
          ? merchant.finance.accountNameForClient
          : "";
    } else {
      bankAccount = groupBuyData.bankAccount;
      bankAccountName = groupBuyData.bankAccountName;
    }
    reset({
      ...groupBuyData,
      pickupAddresses: groupBuyData.pickupAddresses
        ? groupBuyData.pickupAddresses
        : [],
      phone,
      canCash: groupBuyData.canCash || false,
      bankAccount,
      bankAccountName,
    });
  };

  const products = history?.location?.state?.products;
  const pickupAddress = history?.location?.state?.pickupAddress;
  const groupId = history?.location?.state?.groupId;
  const isCopy = history?.location?.state?.isCopy;

  const selectPickupAddress = () => {
    saveState();
    history.push(LOCAL_URL["MY_ADDRESS"], {
      type: "PICKUP",
      selectMode: true,
      from: 'STORE'
    });
  };

  React.useEffect(() => {
    restoreState();
  }, [products, pickupAddress]);

  const queryGroupBuyAndRestoreState = async () => {
    let groupBuyData = entityModel["GROUP_BUY"]?.response;
    if (!!!groupBuyData || Number(groupBuyData?.id) !== groupId) {
      await query("GROUP_BUY", {id: groupId});
      groupBuyData = entityModel["GROUP_BUY"]?.response?.data;
      reset(groupBuyData);
      replace("GROUP_BUY", groupBuyData);
    }
  };

  React.useEffect(() => {
    if (groupId) {
      queryGroupBuyAndRestoreState();
    }
  }, [groupId]);

  const groupBuyData = entityModel["GROUP_BUY"]?.response;
  const categoryProducts = []
  let category = groupBuyData?.category ?? []
  if (groupBuyData?.groupToProductions) {
    for (const cate of category) {
      const containProducts = []
      for (const product of groupBuyData.groupToProductions) {
        if (product.category) {
          if (product.category.filter(cateItem => cateItem.title == cate.title).length > 0) {
            containProducts.push(product)
          }
        }
      }
      categoryProducts.push({
        ...cate,
        products: containProducts
      })
    }
    // 处理未分类产品
    const unCategoryProds = []
    for (const product of groupBuyData.groupToProductions) {
      if (!product.category || product.category.length === 0){
        unCategoryProds.push(product)
      }
    }
    if (unCategoryProds.length > 0){
      categoryProducts.push({
        title: DEFAULT_CATEGORY_NAME,
        index: -1,
        products: unCategoryProds,
      })
    }
  } else {
    categoryProducts.push({
      products: []
    })
  }
  if (!groupBuyData) {
    return null
  }
  return (
    <div>
      <PageLayout pageName="create group buy">
        <Header title="网店编辑"/>
        <StyledForm onSubmit={handleSubmit(onSumbit)}>
          <input ref={register} name="id" style={{display: "none"}}/>
          <input
            ref={register}
            name="bankAccountName"
            style={{display: "none"}}
          />
          <Divider/>
          <label>商品列表</label>
          <Divider/>
          <StyledCard>
            <ListItem
              title="管理商品"
              required
              comments={(groupBuyData?.groupToProductions && groupBuyData.groupToProductions.length > 0) ? `已添加${groupBuyData.groupToProductions.length}件商品` : '还没有添加商品'}
              onPress={() => {
                history.push(LOCAL_URL["SHOPPING_MANAGE_PRODUCTS_PAGE"], {
                  groupId: groupBuyData.id,
                });
              }}
            />
          </StyledCard>
          <Divider/>
          <label>支付方式</label>
          <Divider/>
          <StyledCard>
            <Controller
              control={control}
              name="onlinePayment"
              render={({value, onChange}) => (
                <Item>
                  <div>
                    <div className="title">
                      在线支付
                      <span className="high-light">（认证商户可用）</span>
                    </div>
                    <div className="sub-title">
                      纽币结算，支持网银，信用卡，微信，支付宝等{" "}
                      <span
                        onClick={() => {
                          history.push(LOCAL_URL['CUSTOMER_SUPPORT'])
                        }}
                        style={{color: THEME.color.primary.default}}>
                        联系客服开通
                      </span>
                    </div>
                    <div className="radio">
                      <Check
                        disabled={!getCurrentMerchant().authentication}
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </Item>
              )}
            />
            <Line/>
            {currentMerchant &&
              currentMerchant.finance &&
              currentMerchant.finance.paymentMethod &&
              currentMerchant.finance.paymentMethod.includes("TRANSFER") && (
                <div>
                  <Controller
                    control={control}
                    name="canCash"
                    render={({value, onChange}) => (
                      <Item>
                        <div>
                          <div className="title">现金</div>
                          <div className="sub-title"></div>
                          <div className="radio">
                            <Check
                              onChange={async (value) => {
                                onChange(value);
                              }}
                              value={value}
                            />
                          </div>
                        </div>
                      </Item>
                    )}
                  />
                  <Line/>
                </div>
              )}
            {currentMerchant &&
              currentMerchant.finance &&
              currentMerchant.finance.paymentMethod &&
              currentMerchant.finance.paymentMethod.includes("TRANSFER") && (
                <div>
                  <Controller
                    control={control}
                    name="canTranPayment"
                    render={({value, onChange}) => (
                      <Item>
                        <div>
                          <div className="title">手工转账</div>
                          <div className="sub-title">
                            用户转账到您的收款银行账号
                          </div>
                          <div className="radio">
                            <Check onChange={onChange} value={value}/>
                          </div>
                        </div>
                      </Item>
                    )}
                  />
                  {watchCanTranPayment && (
                    <>
                      <Line/>
                      <Controller
                        control={control}
                        name="bankAccount"
                        render={({onChange, value}) => {
                          return (
                            <ListItem
                              onPress={() => {
                                saveState();
                                history.push(LOCAL_URL["TRANS_ACCOUNT"]);
                              }}
                              title="收款账号"
                              required
                              comments={value}
                            />
                          );
                        }}
                      />
                    </>
                  )}
                  {!errors || !errors["bankAccount"] ? null : (
                    <ErrMsg className="ft-rr12">
                      {Object(errors["bankAccount"]).message}
                    </ErrMsg>
                  )}
                </div>
              )}
          </StyledCard>

          <Divider/>
          <label>
            物流方式（可多选）
            <span style={{color: THEME.color.primary.default}}>*</span>
            {!errors || !errors["canDelivery"] ? null : (
              <ErrMsg className="ft-rr12">
                {Object(errors["canDelivery"]).message}
              </ErrMsg>
            )}
          </label>
          <Divider/>
          <StyledCard>
            <Controller
              control={control}
              name="canDelivery"
              render={({value, onChange}) => (
                <Item>
                  <div>
                    <div className="title">配送到家</div>
                    <div className="radio">
                      <Check onChange={onChange} value={value}/>
                    </div>
                  </div>
                </Item>
              )}
            />
            <Line/>
            <Controller
              control={control}
              name="canPickup"
              render={({value, onChange}) => (
                <Item>
                  <div>
                    <div className="title">自提</div>
                    <div className="radio">
                      <Check onChange={onChange} value={value}/>
                    </div>
                  </div>
                </Item>
              )}
            />
          </StyledCard>

          <Divider/>
          {watchCanDelivery && (
            <InputField
              textarea
              label="配送说明"
              required
              register={register}
              type="text"
              name="deliveryNotes"
              placeholder="请输入配送说明"
              errors={errors}
            />
          )}
          {watchCanPickup && (
            <InputField
              textarea
              label="自提说明"
              register={register}
              type="text"
              name="pickupNotes"
              placeholder="请输入自提说明"
              errors={errors}
            />
          )}
          {watchCanPickup && (
            <StyledCard>
              <Controller
                control={control}
                name="pickupAddresses"
                render={({value, onChange}) => (
                  <StyledPickupAddress>
                    <div className="flex-row">
                      <span>
                        选择自提点{" "}
                        <span style={{color: THEME.color.primary.default}}>
                          *
                        </span>
                      </span>
                      <div className="flex-row" onClick={selectPickupAddress}>
                        <span>已选择{value?.length}个</span>
                        <div style={{width: 24, height: 24}}>
                          <IconKBArrowR/>
                        </div>
                      </div>
                    </div>
                    <div>
                      {!errors || !errors["pickupAddresses"] ? null : (
                        <ErrMsg className="ft-rr12">
                          {Object(errors["pickupAddresses"]).message}
                        </ErrMsg>
                      )}
                    </div>
                    <Line/>
                    {value &&
                      value.map((v) => <AddressCard address={v} disable/>)}
                  </StyledPickupAddress>
                )}
              />
            </StyledCard>
          )}
          <WhiteSpace height={16}/>
          <Card>
            <CardActions disableSpacing onClick={() => {

              if (!groupBuyData.showAdvancedSetting) {
                setTimeout(() => {
                  scrollToBottom()
                }, 300)
              }
              replace('GROUP_BUY', {
                showAdvancedSetting: !groupBuyData.showAdvancedSetting
              })
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
                <div style={{
                  fontSize: 15,
                  color: THEME.color.gray["#12-045"],
                  flex: 1
                }}>
                  <span style={{color: '#000000', fontWeight: 400}}>高级设置</span>（自定义选项、分享文案等）
                </div>
                <div style={{width: 25}}>
                  {groupBuyData.showAdvancedSetting ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </div>
              </div>

            </CardActions>

          </Card>
          <Collapse in={groupBuyData.showAdvancedSetting} timeout="auto" unmountOnExit>
            <WhiteSpace height={16}/>
            <label>
              自定义选项
            </label>
            <WhiteSpace height={10}/>
            {groupBuyData?.customFields?.map((field, index) => {
              return (
                <CustomField
                  onBeforeNav={() => {
                    saveState();
                  }}
                  index={index}
                  key={`${index}`}
                  value={field}
                  onChange={(value) => {
                    const result = []
                    for (let i = 0; i < groupBuyData.customFields.length; i++) {
                      if (i === index) {
                        result.push(value)
                      } else {
                        result.push(groupBuyData.customFields[i])
                      }
                    }
                    replace('GROUP_BUY', {
                      customFields: result
                    })
                  }}
                  onDelete={() => {
                    const result = []
                    for (let i = 0; i < groupBuyData.customFields.length; i++) {
                      if (i !== index) {
                        result.push(groupBuyData.customFields[i])
                      }
                    }
                    replace('GROUP_BUY', {
                      customFields: result
                    })
                  }}
                />
              )
            })}

            <AddNewProduct
              onClick={() => {
                if (groupBuyData.customFields) {
                  replace('GROUP_BUY', {
                    customFields: [
                      ...groupBuyData.customFields,
                      {
                        title: '',
                        type: [],
                        select: '',
                        meta: ['', '']
                      },
                    ]
                  })
                } else {
                  replace('GROUP_BUY', {
                    customFields: [
                      {
                        title: '',
                        type: [],
                        select: '',
                        meta: ['', '']
                      },
                    ]
                  })
                }

              }}
            >
              <img src={iconAdd} alt={'add'}/>
              <div>添加自定义选项</div>
            </AddNewProduct>
            <WhiteSpace height={24}/>
            <label>
              微信分享设置
            </label>
            <WhiteSpace/>
            <InputField
              label="标题"
              register={register}
              type="text"
              name="wechatTitle"
              placeholder="请输入标题"
            />
            <Divider/>
            <InputField
              textarea
              label="副标题"
              register={register}
              type="text"
              name="wechatContent"
              placeholder="请输入副标题"
            />
          </Collapse>
          <FloatBottom>
            <Button fullWidth type="submit" size="large" color="red">
              保存
            </Button>
          </FloatBottom>
        </StyledForm>
        <TTModal
          show={showTranAccountModal}
          title="收款银行账号"
          content="您还没有设置收款银行账号"
          confirmText="现在设置"
          onConfirm={async () => {
            saveState();
            history.push(LOCAL_URL["TRANS_ACCOUNT"]);
          }}
          onCancel={() => {
            setShowTranAccountModal(false);
          }}
        />
      </PageLayout>
    </div>
  );
}
