import * as React from "react";
import styled from "styled-components";
import { THEME } from "../config";
import dayjs from "dayjs";

export interface IGroupStatusProps {
  startAt: string;
  endAt: string;
  status: "PENDING" | "PROCESSING" | "FINISHED" | "CANCELED";
  primaryColor?: string
}

const Root = styled.div`
  height: 24px;
  line-height: 24px;
  border-radius: 16px;
  font-size: 12px;
  display: inline-block;
  padding-left: 16px;
  margin-top: 16px;
  .left {
    display: inline-block;
  }
  .right {
    border-radius: 0 16px 16px 0;
    margin-left: 14px;
    background-color: ${(props) =>
      props.status === "default" || props.status === "finished"
        ? "#C4C4C4"
        : (props.primaryColor ?? THEME.color.primary.default)};
    line-height: 24px;
    display: inline-block;
    padding: 0 8px 0 8px;
    ${(props) =>
      props.status === "default" || props.status === "finished"
        ? `
    background-color: #C4C4C4;
    `
        : `
  background-color: ${props.primaryColor ?? THEME.color.primary.default};
  color: #fff;
  `}
  }
  ${(props) =>
    props.status === "default" || props.status === "finished"
      ? `
    background-color: #F5F5F5;
    color: ${THEME.color.gray["#11-065"]};
    `
      : `
  background-color: ${props.primaryColor ? '#00000010' :'rgba(204, 21, 67, 0.09)' };
  color: ${props.primaryColor ?? THEME.color.primary.default};
  `}
`;

export default function GroupStatus({
  startAt,
  endAt,
  status,
  primaryColor
}: IGroupStatusProps) {
  let statusStr;
  let text = "截团";
  let time = endAt;
  switch (status) {
    case "CANCELED":
      statusStr = "已取消";
      break;
    case "FINISHED":
      statusStr = "已截团";
      break;
    case "PENDING":
      statusStr = "未开始";
      text = "开团";
      time = startAt;
      break;
    case "PROCESSING":
      statusStr = "进行中";
      break;
    default:
      statusStr = "";
      break;
  }
  return (
    <Root status={status !== "PROCESSING" ? "default" : "processing"} primaryColor={primaryColor}>
      <div className="left">
        {text}：{dayjs(time).format("DD/MM/YYYY HH:mm")}
      </div>
      <div className="right">{statusStr}</div>
    </Root>
  );
}
