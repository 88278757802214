import React, { Fragment } from 'react';
import styled from 'styled-components';
import { THEME } from '../config'

const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;

export const Tag = styled.div`
  background: ${THEME.color.accent.green};
  color: #fff;
  height: 24px;
  padding: 0 12px 0 6px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 16px;
  white-space: nowrap;

  display: flex;
  align-items: center;

  & svg {
    margin-right: 4px;
    height: 16px;
    fill: #fff;
  }
  @media screen and (min-width: ${VIEW_MODE_THRETHOLD}) {
    top: ${(props) => (props.size !== 'big') ? '6px' : '12px'};
    right: ${(props) => (props.size !== 'big') ? '6px' : '12px'};
    height: ${(props) => (props.size !== 'big') ? '24px' : '48px;'};
    padding: ${(props) => (props.size !== 'big') ? '3px 12px 0 8px' : '14px 14px 0 8px'};
    border-radius: ${(props) => (props.size !== 'big') ? '12px' : '24px'};
    font-size: ${(props) => (props.size !== 'big') ? '12px' : '24px'};
  }

`;

export const TagSpan = styled.span`
  line-height: 22px;
  padding: ${(props) => props.padding || '4px 12px'};
  border-radius: 12px;
  background: ${THEME.color.primary.variant20};
  white-space:nowrap;
  cursor: pointer;

  & ~ & {
    margin-left: 1rem;
  }

  &.gray {
    background: #eee;
  }
`;

const PinkContainer = styled.div`
  display: inline-block;
  background: #fff;
  border-radius: ${(props) => props.radius || '5px'};
  margin-top: 8px;
`;
const PinkContent = styled.div`
  padding: ${(props) => props.padding || '2px 4px'};;
  border-radius: ${(props) => props.radius || '5px'};
  background: ${THEME.color.primary.variant20};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface PinkTagProps {
  onClick?: (tag: string | any) => void;
  radius?: string;
  padding?: string;
  className?: string;
  [propName: string]: any
}
const PinkTag:React.FC<PinkTagProps> = ({onClick, children, className, ...restProps}) => (
  <PinkContainer {...restProps} >
    <PinkContent {...restProps} >
      <span className={ className || "ft-rr10 clr-ft-gray2-48" } onClick={() => onClick(children)} >{children}</span>
    </PinkContent>
  </PinkContainer>
)

export const TagsList = ({items, ...restProps}) => (
  items.map((t, index) => (
    <Fragment key={index}>
      <span>{' '}</span>
      <PinkTag {...restProps}>{t}</PinkTag>
    </Fragment>
  ))
)
