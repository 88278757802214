import React, {Component, createRef} from 'react';
import {VariableSizeList as List} from 'react-window';
import {HeaderHeight, itemHeight} from "../../shopping/detailPage";

function defaultEasing(delta) {
  return delta;
}

export default class AnimatedList extends Component<any, any> {
  static defaultProps = {
    duration: 1000,
    easing: defaultEasing,
    onAnimationComplete: () => {
    },
  };

  listRef = createRef<any>();

  _scrollOffsetInitial = 0;
  _scrollOffsetFinal = 0;

  _animationStartTime = 0

  componentDidMount() {
    if (this.props.scrollToItem) {
      this._initAnimation();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.scrollToItem !== prevProps.scrollToItem) {
      this._initAnimation();
    }
  }

  render() {
    return <List {...this.props} onScroll={this.onScroll} ref={this.listRef} itemKey={(index) => {
      if (this.props.data) {
        if (this.props.data[index]) {
          return this.props.data[index].id ?? index
        }
      }
      return index
    }}/>;
  }

  _animate() {
    requestAnimationFrame(() => {
      const {duration, easing} = this.props;
      const now = performance.now();
      const ellapsed = now - this._animationStartTime;
      const scrollDelta = this._scrollOffsetFinal - this._scrollOffsetInitial;
      const easedTime = easing(Math.min(1, ellapsed / duration));
      const scrollOffset = this._scrollOffsetInitial + scrollDelta * easedTime;

      this.listRef.current.scrollTo(scrollOffset);

      if (ellapsed < duration) {
        this._animate();
      } else {
        this._animationStartTime = undefined;
        this._scrollOffsetInitial = this._scrollOffsetFinal;
        this.props.onAnimationComplete();
      }
    });
  }

  _initAnimation() {
    if (this._animationStartTime) {
      throw Error('Animation in progress'); // You handle this however you want.
    }

    const {itemSize, scrollToItem} = this.props;
    if (this.props.data) {
      let final = 0
      for (let i = 0; i < scrollToItem; i++) {
        try {
          const item = this.props.data[i]
          if (!item) {
            continue
          }
          if (item.type === 'CATEGORY') {
            final += HeaderHeight
          } else if (item.type === 'PRODUCT') {
            final += itemHeight
          }
        } catch (e) {

        }
      }
      // !!! For ProductTemplates page, the item.type is neither 'CATEGORY' nor 'PRODUCT' !!!
      // This will lead to the final is still 0.
      // So we use scrollToItem * itemSize()
      this._scrollOffsetFinal = final || scrollToItem * itemSize();
    } else {
      this._scrollOffsetFinal = scrollToItem * itemSize();
    }

    this._animationStartTime = performance.now();
    this._animate();
  }

  onScroll = ({scrollOffset, scrollUpdateWasRequested}) => {
    if (!scrollUpdateWasRequested) {
      this._scrollOffsetInitial = scrollOffset;
    }
    if (this.props.onScroll) {
      this.props.onScroll(scrollOffset)
    }
  };
}
