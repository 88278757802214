import React from "react";
import styled from "styled-components";
import WhiteSpace from "../../../components/WhiteSpace";
import {THEME} from "../../../config";
import {hexToRGB} from "../../../utils/common";
import {getImageById} from "../../../utils/ImageUtils";

interface FollowerTrackCardProps {
  rules: any[]
  followers: any[]
  themeColor: string
}

const FollowerTrack = (props: FollowerTrackCardProps) => {
  const total = props.rules.reduce((a, b) => a + b.followerNumber, 0)
  let temp = 0
  const keyPoints = new Map<number, number>()
  props.rules.forEach((item, index) => {
    temp = temp + item.followerNumber
    keyPoints.set(temp, index + 1)
  })
  const items: any[] = []
  for (let i = 0; i < total; i++) {
    const item: any = {
      id: i
    }
    if (i < props.followers.length) {
      // 已经被用户参与的席位
      const follower = props.followers[i]
      item.avatar = follower.avatar
      if (keyPoints.has(i + 1)) {
        item.subTitle = `奖${keyPoints.get(i + 1)} 已领取`
        item.color = THEME.color.primary.default
      }
    } else {
      // 待邀请的席位
      item.title = '待邀请'
      if (keyPoints.has(i + 1)) {
        item.title = '领奖'
        item.subTitle = `奖${keyPoints.get(i + 1)} 待领取`
        item.color = THEME.color.primary.default
      }
    }
    items.push(item)
  }
  return (
    <FollowerTrackCardContainer themeColor={props.themeColor ?? THEME.color.primary.default}>
      <div className='title'>
        <div className='left'>邀请好友进度</div>
        <div className='right'>{props.followers.length}/{total}</div>
      </div>
      <WhiteSpace height={16}/>
      <div className='icons'>
        {items.map(item => {
          return (
            <div style={{marginLeft: 8, marginRight: 8}} key={item.id}>
              <TrackItem
                {...item}
              />
            </div>

          )
        })}
      </div>
    </FollowerTrackCardContainer>
  )
}

export default FollowerTrack

const FollowerTrackCardContainer = styled.div`
  background-color: ${props=>`rgba(${hexToRGB(props.themeColor)}, 0.15)`};
  padding: 24px;
  text-align: center;

  .title {
    display: flex;
    margin-left: 8px;
    color: ${THEME.color.gray["#12-045"]};
    justify-content: space-between;

    .left {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
    }

    .right {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
    }
  }

  .icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

interface TrackItemProps {
  color?: string
  title?: string
  avatar?: string
  subTitle?: string
}

const TrackItem = (props: TrackItemProps) => {
  return (
    <div>
      <TrackItemContainer {...props}>
        {props.title ? (
          <div className='title'>{props.title}</div>
        ) : null}
        {props.avatar ? (
          <img className='avatar' src={getImageById(props.avatar)} alt=""/>
        ) : null}
      </TrackItemContainer>
      {props.subTitle ? (
        <div style={{
          fontSize: 8,
          marginTop: 4,
          color: props.color ?? THEME.color.gray["#12-045"]
        }}>
          {props.subTitle}
        </div>
      ) : null}
      <WhiteSpace height={16}/>
    </div>

  )
}

const TrackItemContainer = styled.div`
  display: inline-block;
  height: 46px;
  width: 46px;
  border-radius: 23px;
  color: ${props => props.color ?? THEME.color.gray["#12-045"]};
  border: ${props => props.avatar ? '0px' : '1px'} dashed ${props => props.color ?? THEME.color.gray["#12-045"]};

  .title {
    margin-left: 0;
    display: block;
    line-height: 46px;
    text-align: center;
    font-size: 12px;
  }

  .avatar {
    display: block;
    width: 100%;
    border-radius: 23px;
  }
`
