import React, {useContext, useEffect, useState} from "react";
import { IconRadioCheck, IconRadioUncheck } from "../../Layout"
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import {EntityContext} from "../../context";
import {useHistory} from "react-router-dom";
import Card from "./Components/Card";
import styled from "styled-components";
import {LOCAL_URL, THEME} from "../../config";
import {InputField, ListInputField, ListItem, notify} from "../../components";
import Switcher from "./Components/Switcher";
import WhiteSpace from "../../components/WhiteSpace";
import numeral from "numeral";
import {useRouter} from "../../hooks/router";
import {requestGet} from "../../lib";
import { useStore } from '../../store'
import TTModal from "../Manage/components/Modal";
import {Check} from "../../forms/Check";

const globalContext = {
  isProcessingSubmission: false,
}
const isProcessingSubmission = () => (globalContext.isProcessingSubmission)
const setProcessing = (v: boolean) => {
  globalContext.isProcessingSubmission = v
}
function OrderConfirmPage() {
  const history = useHistory<any>();
  const router = useRouter();
  const [orderAsAnonymous, setOrderAsAnonymous] = useState(false)
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  // const [processing, setProcessing] = useState(false);
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  const [noticeMessage, setNoticeMessage] = useState('');
  const [confirmText, setConfirmText] = useState<'配送' | '继续' | ''>('');
  const [customFieldData, setCustomFieldData] = useState<Map<string, Set<string>>>(new Map());

  const orderInModifying = useStore((state) => state.modifyingState.order)

  const {
    model: entityModel,
    replace,
    query,
    create,
  } = useContext(EntityContext);
  const orderData = entityModel["PLACE_ORDER_GLOBAL_DATA"].response || {};
  const groupData = entityModel["GROUP_BUY"].response.data;

  useEffect(() => {
    if (groupData && !orderData.type) {
      if (groupData.canDelivery && groupData.canPickup) {
        setConfirmText('配送')
        setNoticeMessage('请选择自提或配送')
      } else {
        setConfirmText('继续')
        if (groupData.canDelivery) {
          updateOrderData({
            type: "DELIVERY",
          });
          if (groupData.customFields){
            const showedCustomFields = groupData.customFields.filter(item=>{
              const typeSet = new Set(item.type)
              return typeSet.has('DELIVERY');
            })
            updateOrderData({
              customFields: showedCustomFields,
            });
          }
          setNoticeMessage('提示：本团购仅支持配送')
        } else if (groupData.canPickup) {
          updateOrderData({
            type: "PICKUP",
          });
          if (groupData.customFields){
            const showedCustomFields = groupData.customFields.filter(item=>{
              const typeSet = new Set(item.type)
              return typeSet.has('PICKUP');
            })
            updateOrderData({
              customFields: showedCustomFields,
            });
          }
          setNoticeMessage('温馨提示，本团购需要您到店自提')
        }
      }
      setShowNoticeModal(true)
    }
  }, [groupData]);

  const updateOrderData = (data) => {
    replace("PLACE_ORDER_GLOBAL_DATA", data);
  };

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.productions &&
      history.location.state.groupId
    ) {
      const products = history.location.state.productions;
      const groupId = history.location.state.groupId;
      query("GROUP_BUY", {id: groupId});
      updateOrderData({
        groupId: groupId,
        productions: products,
      });
    }
  }, [history.location.state?.productions]);

  useEffect(() => {
    if (orderData && orderData.productions) {
      setProducts(orderData.productions);
      let total = 0;
      let totalCNY = 0;
      for (const item of orderData.productions) {
        total += item.price * item.amount;
        totalCNY += item.priceCNY * item.amount;
      }
      setTotalPrice(total);
    }
  }, [orderData, orderData.productions]);

  useEffect(() => {
    const timer = setInterval(() => {
      updateOrderStatus();
    }, 1000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  const updateOrderStatus = async () => {
    const orderData = entityModel["PLACE_ORDER_GLOBAL_DATA"].response
    if (!orderData.id) {
      return
    }
    const {data} = await requestGet("order/orderStatusById", {
      id: orderData.id,
    });
    if (data && data.status === "CREATED") {
      router.replace({
        pathname: LOCAL_URL["CHOOSE_PAYMENT"],
        state: {
          id: data.id,
        },
      });
    } else if (data.status === "WAITING_CONFIRM") {
      router.replace(LOCAL_URL["WAITING_CONFIRM"], {
        no: data.groupIndex,
      });
    } else if (data.status === 'FAILED') {
      setProcessing(false)
      notify.error("下单失败");
      history.goBack()
    }
  };

  const placeOrder = async () => {
    // if (processing) {
    if (isProcessingSubmission()) {
      return;
    }
    setProcessing(true)

    const {type, address, note, pickupAddress, pickupName, pickupPhone} =
      orderData;

    if ( (type !== 'DELIVERY') && (type !== 'PICKUP') ) {
      notify.error("订单类型错误");
      setProcessing(false)
      return;
    }
    if (
      ( (type === 'DELIVERY') && (!address) ) ||
      ( (type === 'PICKUP') && (!pickupAddress) )
    ) {
      notify.error("请选择地址");
      setProcessing(false)
      return;
    }

    if (checkCustomField()){
      notify.error(`请填写:${checkCustomField()}`);
      setProcessing(false)
      return;
    }
    if (type === 'PICKUP'){
      if (!pickupName || !pickupPhone){
        notify.error(`请填写姓名和联系电话`);
        setProcessing(false)
        return;
      }
    }
    const params: any = {
      groupId: groupData.id,
      disableAutoCancel: !!groupData.disableAutoCancel,
      name: pickupName,
      phone: pickupPhone,
      note: note,
      shipType: type,
      address: (type === 'DELIVERY') ? address : (type === 'PICKUP') ? pickupAddress : '',
      productions: orderData.productions,
      isAnonymous: orderAsAnonymous,
    };
    if (!params.address) {
      notify.error("请选择地址");
      setProcessing(false)
      return
    }

    const meta = getMetaObject()
    if (meta){
      params.meta = meta
    }
    if (!!orderInModifying) {
      // console.log(orderInModifying)
      params.prevOrderInfo = [
        'id', 'totalPrice', 'address', 'status', 'cancelStatus', 'productions', 'updatedAt'
      ].reduce((accu:any, key:string) => {
        accu[key] = orderInModifying[key]
        return accu
      }, {})
      params.prevOrderInfo.productions = params.prevOrderInfo.productions?.map((x:any) => {
        const patial = ["id", "price", "title", "amount", "available", "defaultStock", "soldQuantity", "maxOrderQuantity"].reduce((accu:any, key:string) => {
          accu[key] = x[key]
          return accu
        }, {})
        return patial
      })
      // console.log(params)
    }
    // setProcessing(true)

    const response = await create("ORDER", {
      order: params,
    });
    if (response && response.order && response.order.id) {
      updateOrderData(response.order)
    }
      // 创建订单请求完成后，依然不能将锁打开，等待消息队列对订单的异步处理。
      // 如果将锁打开后，在订单等待被处理期间，用户点击了确认按钮，会创建新的订单。
    // setProcessing(false)
  };

  const checkCustomField = ()=>{
    let result = null
    const keys = orderData.customFields.map(item=>item.title)
    for (const key of keys){
      const value = customFieldData.get(key)
      if (!value || value.size === 0){
        result = key
      }
    }
    return result
  }
  const getMetaObject = ()=>{
    // 自定义字段
    const customOptions = {}
    const keys = customFieldData.keys()
    if (!keys){
      return  null
    }
    for (const key of keys){
      const value = customFieldData.get(key)
      customOptions[key] = Array.from(value).join(',')
    }
    return {
      customOptions
    }
  }

  const addressData = entityModel["ADDRESS"].response?.data;
  useEffect(() => {
    if (
      (!orderData.pickupAddress)  && (orderData.type === 'PICKUP') &&
      (addressData?.length === 1)
    ) {
      replace("PLACE_ORDER_GLOBAL_DATA", {
        pickupAddress: addressData[0],
      });
    }
  }, [addressData])
  useEffect(() => {
    if (orderData.type === 'PICKUP') {
      query("ADDRESS", {
        type: "PICKUP",
        groupId: groupData.id,
      });
    }
  }, [orderData.type])

  useEffect(() => {
    // console.log({orderInModifying})
    setProcessing(false)
    if (!!orderInModifying) {
      updateOrderData({
        pickupName  : orderInModifying.name,
        pickupPhone : orderInModifying.phone,
        note        : orderInModifying.note,
      });
    }
  }, [])

  // console.log({orderInModifying})

  if (!groupData) {
    return null;
  }
  return (
    <PageLayout pageName="no nav orderConfirm">
      <Header title="确认订单"/>
      <StyledForm>
        <Container>
          <Card>
            <Switcher
              current={orderData?.type}
              onChange={(selected) => {
                if (selected === "PICKUP" && !groupData.canPickup) {
                  notify.info("该团购产品不提供自提");
                  return;
                }
                if (selected === "DELIVERY" && !groupData.canDelivery) {
                  notify.info("该团购产品不提供配送");
                  return;
                }
                if (groupData.customFields){
                  const showedCustomFields = groupData.customFields.filter(item=>{
                    const typeSet = new Set(item.type)
                    return typeSet.has(selected);
                  })
                  updateOrderData({
                    customFields: showedCustomFields,
                  });
                }
                updateOrderData({
                  type: selected,
                });
              }}
            />
            <WhiteSpace/>
            {orderData?.type === "DELIVERY" ? (
              <ListItem
                required
                onPress={() => {
                  history.push({
                    pathname: LOCAL_URL["ORDER_SELECT_ADDRESS"],
                    state: {
                      type: "DELIVERY",
                      from: history.location.pathname,
                      selectedAddress: orderData.address,
                    },
                  });
                }}
                title="派送地址"
                comments={orderData.address?.formattedAddress || "请选择地址"}
              />
            ) : null}
            {orderData.type === "PICKUP" ? (
              <div>
                <div>
                  <ListItem
                    required
                    onPress={() => {
                      history.push(LOCAL_URL["ORDER_SELECT_ADDRESS"], {
                        type: "PICKUP",
                        from: history.location.pathname,
                        groupId: groupData.id,
                      });
                    }}
                    title="自提点"
                    hint={!!orderData.pickupAddress ? "更改" : "请选择"}
                    //comments={orderData.address}
                  />
                </div>
                {orderData.type === "PICKUP" && orderData.pickupAddress ? (
                  <div>
                    <div style={{paddingTop: 8, paddingBottom: 8}}>
                      <div
                        style={{
                          fontSize: 15,
                          lineHeight: "21px",
                          fontWeight: 400,
                        }}
                      >
                        {orderData.pickupAddress.addressName}
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          color: "rgba(0,0,0,0.65)",
                          lineHeight: "17px",
                        }}
                      >
                        {orderData.pickupAddress.formattedAddress}
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          color: "rgba(0,0,0,0.65)",
                          lineHeight: "17px",
                        }}
                      >
                        {orderData.pickupAddress.notes}
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          color: "rgba(0,0,0,0.65)",
                          lineHeight: "17px",
                        }}
                      >
                        {orderData.pickupAddress.telephone}
                      </div>
                    </div>
                  </div>
                ) : null}
                <Divider/>
                <ListInputField
                  required
                  name="name"
                  label="姓名"
                  value={orderData.pickupName}
                  onChange={(e) => {
                    updateOrderData({
                      pickupName: e.target.value,
                    });
                  }}
                  placeholder="请填写取货人姓名"
                  bottomDivider
                />
                <ListInputField
                  required
                  name="phone"
                  label="联系电话"
                  placeholder="请输入取货人联系电话"
                  value={orderData.pickupPhone}
                  onChange={(e) => {
                    updateOrderData({
                      pickupPhone: e.target.value,
                    });
                  }}
                />
              </div>
            ) : null}
          </Card>
          {groupData &&
            groupData.canPickup &&
            groupData.pickupNotes &&
            orderData.type === "PICKUP" && (
              <Card title="自提说明">
                <div>{groupData.pickupNotes}</div>
              </Card>
            )}
          {groupData &&
            groupData.canDelivery &&
            groupData.deliveryNotes &&
            orderData.type === "DELIVERY" && (
              <Card title="配送说明">
                {groupData.deliveryNotes ? groupData.deliveryNotes?.split('\n').map(item=>{
                  return (
                    <div style={{lineHeight: '18px'}}>{item}</div>
                  )
                }) : null}
              </Card>
            )}
          {orderData.customFields && orderData.customFields.length > 0 && (
            <div>
              {orderData.customFields.map((item: any) => {
                const _tempMap = new Map(customFieldData)
                return (
                  <Card
                    key={item.id}
                    title={item.title}
                  >
                    {item.meta.map(metaItem => {
                      const _temp = new Set(_tempMap.get(item.title) || [])
                      return (
                        <Item key={metaItem}>
                          <div>
                            <div className="title">{metaItem}</div>
                            <div className="radio">
                              <Check
                                onChange={(value) => {
                                  if (item.select === 'SINGLE') {
                                    if (_temp.has(metaItem)) {
                                      _temp.delete(metaItem)
                                    } else {
                                      _temp.clear()
                                      _temp.add(metaItem)
                                    }
                                  } else if (item.select === 'MULTI') {
                                    if (_temp.has(metaItem)) {
                                      _temp.delete(metaItem)
                                    } else {
                                      _temp.add(metaItem)
                                    }
                                  }
                                  _tempMap.set(item.title, _temp)
                                  setCustomFieldData(_tempMap)
                                }}
                                value={_temp.has(metaItem)}
                              />
                            </div>
                          </div>
                        </Item>
                      )
                    })}
                  </Card>
                )
              })}
            </div>
          )}
          <Card>
            <InputField
              textarea
              name="note"
              placeholder="添加备注"
              value={orderData.note}
              onChange={(e) => {
                updateOrderData({
                  note: e.target.value,
                });
              }}
            />
          </Card>
          <Card title="已选商品">
            <div className="order-list">
              <div className="product">商品</div>
              <div className="amount">数量</div>
              <div className="price">价格</div>
            </div>
            {products.map((item) => {
              return (
                <div className="order-list" key={item.title}>
                  <div
                    className="product"
                    style={{fontSize: 12, color: "rgba(0,0,0,0.65)"}}
                  >
                    {item.title}
                  </div>
                  <div
                    className="amount"
                    style={{fontSize: 12, color: "rgba(0,0,0,0.65)"}}
                  >
                    {item.amount}
                  </div>
                  <div
                    className="price"
                    style={{fontSize: 12, color: "rgba(0,0,0,0.65)"}}
                  >
                    {numeral((item.price * item.amount) / 100).format(
                      "$0,0.00"
                    )}
                  </div>
                </div>
              );
            })}
          </Card>

          <div style={{padding: '16px 0', gap: '.5rem'}} className="flex-start align-center"
            onClick={() => setOrderAsAnonymous((x) => !x)}
          >
            { orderAsAnonymous ?
              <IconRadioCheck />
              :
              <IconRadioUncheck />
            }
            <span>匿名购买</span>
          </div>

          <WhiteSpace height={70}/>
          <div className="bottom-float">
            <div className="left">
              合计 {numeral(totalPrice / 100).format("$0,0.00")}
            </div>
            <div
              className="right"
              onClick={async () => {
                await placeOrder();
              }}
            >
              { !orderInModifying ? '提交订单' : '修改订单'}
            </div>
          </div>
        </Container>
      </StyledForm>
      <TTModal
        show={showNoticeModal}
        title={noticeMessage}
        confirmText={confirmText}
        cancelText='自提'
        onConfirm={async () => {
          if (confirmText === '配送') {
            updateOrderData({
              type: "DELIVERY",
            });
            if (groupData.customFields){
              const showedCustomFields = groupData.customFields.filter(item=>{
                const typeSet = new Set(item.type)
                return typeSet.has('DELIVERY');
              })
              updateOrderData({
                customFields: showedCustomFields,
              });
            }
          }
          setShowNoticeModal(false)
        }}
        onCancel={confirmText === '配送' ? () => {
          updateOrderData({
            type: "PICKUP",
          });
          setShowNoticeModal(false)
          if (groupData.customFields){
            const showedCustomFields = groupData.customFields.filter(item=>{
              const typeSet = new Set(item.type)
              return typeSet.has('PICKUP');
            })
            updateOrderData({
              customFields: showedCustomFields,
            });
          }
        } : null}
      />
    </PageLayout>
  );
}

export default OrderConfirmPage;

const Container = styled.div`
  padding: 0px 16px;

  .order-list {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;

    .product {
      flex: 1;
      text-align: left;
    }

    .amount {
      width: 50px;
      text-align: center;
    }

    .price {
      text-align: center;
      width: 50px;
    }
  }

  .bottom-float {
    display: flex;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);

    .left {
      width: 191px;
      height: 40px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      font-size: 17px;
      line-height: 40px;
      border: none;
      color: white;
      padding-left: 28px;
      background-color: black;
    }

    .right {
      height: 40px;
      width: 104px;
      line-height: 40px;
      border: none;
      text-align: center;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      font-size: 17px;
      color: white;
      background-color: #cc1543;
    }
  }
`;

const StyledForm = styled.div`
  .tips-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .tips {
    text-align: center;
    margin-bottom: 24px;
  }

  a {
    margin: 0px 8px 0px 8px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.09);
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 16px;

  .title {
    font-size: 15px;
    color: ${THEME.color.gray["#10-085"]};
  }

  .title .high-light {
    color: ${THEME.color.primary.default};
  }

  .sub-title {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 4px;
  }

  .switch {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .radio {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
