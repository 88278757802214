import * as React from "react";
import styled from "styled-components";
import { LOCAL_URL, THEME } from "../../config";
import PageLayout from "../Global/PageLayout";
import { WECHAT_FOLLOW_URL } from "../../config/common";
import { Button } from "../../components";
import { useHistory } from "react-router-dom";

export interface IAddMerchantSuccessProps {}

const IconSuccess = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.5286 1.17085C59.1078 5.88121 69.5397 23.9503 64.8297 41.5292C60.119 59.1084 42.0495 69.5403 24.4706 64.83C6.89177 60.1192 -3.54048 42.0505 1.17025 24.4716C5.88024 6.89238 23.9493 -3.53951 41.5286 1.17085Z"
      fill="#0AB481"
    />
    <path
      d="M27.539 45.5215C27.1631 45.8609 26.5846 45.8364 26.2383 45.4667L14.2768 32.6926C13.8675 32.1816 13.6693 31.5377 13.7219 30.8901C13.7743 30.2419 14.0741 29.6383 14.5609 29.1986C15.0478 28.7592 15.6859 28.5166 16.3473 28.5191C17.0091 28.5216 17.6457 28.7692 18.1304 29.213L26.751 38.419C26.9816 38.6654 27.3674 38.6814 27.6179 38.455L45.6974 22.13C46.2171 21.7285 46.8728 21.5347 47.5323 21.5878C48.192 21.6406 48.8071 21.9355 49.2553 22.4147C49.7036 22.8929 49.9517 23.5203 49.9501 24.1701C49.9484 24.82 49.6972 25.4447 49.2463 25.9202L27.539 45.5215Z"
      fill="white"
    />
  </svg>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px 0px 16px;
  .text-title {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 24px;
  }
  .text-desc {
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 16px;
    a {
      text-decoration: none;
      color: ${THEME.color.primary.default};
    }
  }
`;

export default function AddMerchantSuccessPage(
  props: IAddMerchantSuccessProps
) {
  const history = useHistory<any>();
  const id = history.location?.state?.id;
  return (
    <PageLayout pageName="no nav">
      <Container>
        <IconSuccess />
        <div className="text-title">创建店铺成功</div>
        <div className="text-desc">
          所有的订单通知将通过微信公众号“新西兰神马网”发送，请关注公众号并留意消息。
          <a href={WECHAT_FOLLOW_URL} target="_blank">
            立即点击关注
          </a>
        </div>
        <div style={{ height: 90 }} />
        <Button
          onClick={() =>
            history.replace(LOCAL_URL["MERCHANT_DETAIL"] + `?id=${id}`)
          }
          fullWidth
        >
          查看店铺主页
        </Button>
      </Container>
    </PageLayout>
  );
}
