import styled from 'styled-components'
import { THEME } from '../../config'

/**
  这里搞的如此复杂，是为了解决一个只在安卓手机微信浏览器中出现的 Bug：
  多个 background 衔接的地方，会出现一条细线
 */
const SIZE = 18;
const BG_COLOR = THEME.color.primary.variant;
const HOLE_COLOR = 'transparent';

const Container = styled.div`
  position: relative;
`;
const Half = styled.div`
  position: absolute;
  height: 100%;
  background: ${BG_COLOR};
  border-radius: 20px;
`;
const Left = styled(Half)`
  left: 0;
  width: ${`calc(100% - ${SIZE}px)`};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;
const Right = styled(Half)`
  right: 0;
  width: ${SIZE}px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  background: ${`
    radial-gradient(circle at bottom right, ${HOLE_COLOR} ${SIZE-1}px, ${BG_COLOR} ${SIZE}px) top,
    radial-gradient(circle at top right,    ${HOLE_COLOR} ${SIZE-1}px, ${BG_COLOR} ${SIZE}px) bottom;
  `};
  background-size: 100% 30%, 100% 70%;
  background-repeat: no-repeat;
`;
const Content = styled.div`
  position: relative;
  z-index: 99;
`
/**
  background: ${`
    radial-gradient(circle at bottom right, ${HOLE_COLOR} ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) top,
    radial-gradient(circle at top    right, ${HOLE_COLOR} ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) center 40%,
    radial-gradient(circle at bottom right, ${BG_COLOR}   ${SIZE}px, ${BG_COLOR} ${SIZE+1}px) bottom;
  `};
  background-size: 100% 30%, 100% 25%, 100% 45%;
  background-repeat: no-repeat;
  border-radius: 20px;
 */

export const Card = ({children}) => (
  <Container>
    <Left />
    <Right />
  
    <Content>
      {children}
    </Content>
  </Container>
)
