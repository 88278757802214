import styled from 'styled-components'
import { IconEmpty } from './SVGIcons'

const Wrapper = styled.div`
  width: 100%;
  & p {
    font-size: 12px;
    line-height: 16px;
    margin-top: .75rem;
    color: rgba(0,0,0,0.45);
  }
`
export const EmptyBlock = (props: any) => {
  return (
    <Wrapper className="flex-center align-center direction-col" {...props}>
      <IconEmpty />
      <p>暂无记录</p>
    </Wrapper>
  )
}
