export const TypographyGeneral = () => (
  <div>
  </div>
)
export const TypographyRoboto = () => (
  <div>
  </div>
)
export const TypographyPoppins = () => (
  <div>
  </div>
)
