import { useState } from 'react'
import dayjs from "dayjs";
import { LOCAL_URL } from '../../config'
import { useLocationMerchantDataChecker } from '../../hooks'
import { requestPost } from '../../lib'
import { Layout, Header, toast } from '../../Layout'
import { Form } from './Form'
import { REQ_URL } from './helper'
import { IPromotionRecord } from './type.entity'
import { IFormFields } from './type.form'
import { converEntity2Fields, converFields2Entity } from './common';

const defaultData: IPromotionRecord = {
  posterLink: '', // "tuangou/n82mjllta0w13tjz0rlj",
  name: "",
  dueDate: dayjs().add(1, 'M').format('YYYY-MM-DD'),
  couponLiveTime: 7,
  rules: {
    total: 0,
    rules: [],
  },
  description: "",
  copywriting: "",
}
export default function Page () {
  const { history, location, merchantId, merchantData } = useLocationMerchantDataChecker()
  const [record, setRecord] = useState<IPromotionRecord>(
    (!location.state?.formData) ? defaultData :
    converFields2Entity(location.state.formData, merchantId)
  )
  
  const onSubmit = async (fields: IFormFields) => {
    // console.log('submit', data)
    const data = converFields2Entity(fields, merchantId)
    const result = await requestPost(REQ_URL, data)
    // console.log(fields, data, result)
    if((result.code === 0) && ('number' === typeof result.data.id)) {
      toast.success('创建成功')
      // because previous page may be preference select list page
      history.push(LOCAL_URL.PROMOTION_LIST, {
        merchantData,
      })
    } else {
      toast.error('创建失败')
    }
  }

  const onBack = () => {
    history.push(LOCAL_URL.PROMOTION_LIST, {
      merchantData
    })
  }
  
  return (
    <Layout>
      <Header title="创建活动" onBack={onBack} />
      <Form
        defaultValues={converEntity2Fields(record)}
        submitHandler={onSubmit}
        merchantData={merchantData}
      >
      </Form>
    </Layout>
  )
}
