import { Box } from './common'

export const Elevation = () => (
  <div style={{fontSize: '.8rem'}}>
    <Box className="small shadow01">
      <p>01 dp</p>
    </Box>
    <Box className="small shadow01">
      <p>01 dp</p>
    </Box>
    <Box className="small shadow02">
      <p>02 dp</p>
    </Box>
    <Box className="small shadow03">
      <p>03 dp</p>
    </Box>
    <Box className="small shadow04">
      <p>04 dp</p>
    </Box>
    <Box className="small shadow06">
      <p>06 dp</p>
    </Box>
    <Box className="small shadow08">
      <p>08 dp</p>
    </Box>
    <Box className="small shadow09">
      <p>09 dp</p>
    </Box>
    <Box className="small shadow12">
      <p>12 dp</p>
    </Box>
    <Box className="small shadow16">
      <p>16 dp</p>
    </Box>
    <Box className="small shadow24">
      <p>24 dp</p>
    </Box>
  </div>
)
