import React from "react";
import styled from "styled-components";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";
import { IconClear } from "../Icons";
// import { THEME } from '../../config';

/**
  ScreenMaskXXXXX 会阻止用户点击蒙版时，关闭 对话框 或 抽屉
 */

/**
  如果这里不设置 height: 100vh
  那么，DrawerBox 的透明背景和 Material Drawer 的蒙版，还是有痕迹显露出来
  因为设置了 height 100vh
  所以，需要设置 ReceiptBox position 为 relative
  然后，设置 DrawerContainer position 为 absolute bottom 0
 */
const MaskLayer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;
const DrawerMaskLayer = styled(MaskLayer)``;
const DialogMaskLayer = styled(MaskLayer)`
  background: ${(props) => props.background};
  /* z-index: 0; */
`;

const DrawerContainer = styled.div`
  position: absolute;
  bottom: 0;

  padding: ${props => props.padding || '24px'};
  width: 100%;
  background: #fff;
  border-radius: 2em 2em 0 0;
  text-align: center;
`;

const DialogContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top || "50%"};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;

const MaskCloseButtonArea = styled.div`
  position: absolute;
  top: 0;
  right: 8px;
  transform: translateY(-100%);
  color: #fff;
  cursor: pointer;

  & svg {
    width: 32px;
    fill: #fff;
  }
`;

const useStyles = makeStyles({
  paper: {
    background: "transparent",
  },
});

interface ScreenMaskDialogProps {
  children?: any;
  open?: boolean;
  onClose: () => void;
  background?: string;
  [propName: string]: any;
  containerTop?: any;
}
export const ScreenMaskDialog: React.FC<ScreenMaskDialogProps> = ({
  children,
  open,
  background,
  onClose,
  containerTop,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      classes={{ paper: classes.paper }}
      style={{ background: "transparent" }}
      open={open}
      onClose={onClose}
    >
      <DialogMaskLayer background={background} />

      <DialogContainer top={containerTop}>
      { !!onClose &&
        <MaskCloseButtonArea onClick={onClose}>
          <IconClear />
        </MaskCloseButtonArea>
      }
        {children}
      </DialogContainer>
    </Dialog>
  );
};

interface ScreenMaskDrawerProps {
  children?: any;
  open?: boolean;
  onClose: () => void;
  padding?: string;
  [propName: string]: any;
}
export const ScreenMaskDrawer: React.FC<ScreenMaskDrawerProps> = ({
  children,
  open,
  onClose,
  padding,
}) => {
  const classes = useStyles();
  return (
    <Drawer
      classes={{ paper: classes.paper }}
      style={{ background: "transparent" }}
      anchor="bottom"
      open={open}
      onClose={onClose}
    >
      <DrawerMaskLayer>
        <DrawerContainer padding={padding}>
          <MaskCloseButtonArea onClick={onClose}>
            <IconClear />
          </MaskCloseButtonArea>

          {children}
        </DrawerContainer>
      </DrawerMaskLayer>
    </Drawer>
  );
};
