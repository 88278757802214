import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, ListInputField, NZBankAccountFormat } from "../../components";
import PageLayout from "../Global/PageLayout";
import { THEME } from "../../config";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import { requestGet, requestPost } from "../../lib";
import useMerchant from "../../hooks/useMerchant";
import { EntityContext } from "../../context";

export interface IAddAddressProps {}

const Container = styled.div`
  padding: 8px;

  .tips-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .tips {
    text-align: center;
    margin-bottom: 24px;
  }

  a {
    margin: 0px 8px 0px 8px;
  }

  .bottom-float {
    display: flex;
    flex-direction: row;
    height: 64px;
    position: fixed;
    bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
  }
`;

const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 8px 8px 8px;
`;

export default function TransAccount(props: IAddAddressProps) {
  const [account, setAccount] = useState("");
  const [no, setNo] = useState("");

  const history = useHistory<any>();

  const { refreshGlobalDependence, replace } = React.useContext(EntityContext);
  const from = history?.location?.state?.from ?? 'GROUP';
  const storeName = from === 'GROUP' ? 'GROUP_BUY' : 'STORE_CONFIG'

  const { currentMerchantId } = useMerchant();
  const getTransAccount = async () => {
    const { data } = await requestGet("manage/transAccount", {
      merchantId: currentMerchantId,
    });
    setAccount(data.account);
    setNo(data.no);
  };

  const updateAccount = async () => {
    console.log(no, account);
    const { data } = await requestPost("manage/setTransAccount", {
      merchantId: currentMerchantId,
      accountNo: no,
      accountName: account,
    });
    if (data) {
      await refreshGlobalDependence();
      replace(storeName, {
        bankAccount: no,
        bankAccountName: account,
      });
      history.goBack();
    }
  };

  useEffect(() => {
    getTransAccount();
  }, []);

  return (
    <PageLayout pageName="no nav addAddress">
      <Header title="收款账号" />
      <div
        style={{
          padding: 16,
          fontSize: 12,
          lineHeight: "17px",
          color: THEME.color.gray["#12-045"],
        }}
      >
        请正确填写以下信息，选择手工转账的用户，将付款到以下银行账号。仅限店主可以设置和修改。管理员无此权限。
      </div>
      <Container>
        <StyledCard>
          <ListInputField
            value={account}
            required
            label="收款账户名"
            placeholder="请填写收款银行账户名"
            bottomDivider
            onChange={(e) => {
              setAccount(e.target.value);
            }}
          />
          <NZBankAccountFormat
            value={no}
            required
            placeholder="请填写收款银行账号"
            label="收款账号"
            onChange={(e) => {
              setNo(e.target.value);
            }}
          />
        </StyledCard>
        <div className="bottom-float">
          <Button
            fullWidth
            onClick={async () => {
              await updateAccount();
            }}
          >
            确定
          </Button>
        </div>
      </Container>
    </PageLayout>
  );
}
