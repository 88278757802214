import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 2rem;
  background-color: rgba(118, 118, 128, 0.12);
  border-radius: 8px;
  color: rgba(0,0,0,0.65);

  position: relative;
`
const Button = styled.div`
  position: absolute;
  top: 2px;

  width: 50%;
  height: calc(100% - 4px);
  border-radius: 7px;
  background-color: white;

  &.left {
    left: 2px;
  }
  &.right {
    right: 2px;
  }
`

const TextLayer  = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;

`
export const SwitchOption = styled.div`
  flex: 1;
  text-align: center;
  &.active {
    color: ${props => props.theme?.color?.primary?.HEX || 'green'};
    font-weight: 500;
  }
`
export const Switch = ({position, children}) => {
  return (
    <Wrapper>
      <Button className={position} />
      <TextLayer className="flex-between align-center">
        { children }
      </TextLayer>
    </Wrapper>
  )
}
