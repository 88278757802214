import dayjs from 'dayjs'
import { getImageByIdWithSize } from "../../utils/ImageUtils"
import { ICouponRecord } from './type.entity'

export enum CouponStatusEnum { ALL, VALID, USED, EXPIRED, };
export const COUPON_STATUS_LABEL  = ['全部','待使用','已使用','已过期'];

export interface ICouponInfo extends ICouponRecord {
  status: CouponStatusEnum;
}

const getImageUrl = (id:string) => getImageByIdWithSize(id, /*'crop'*/ 'fill', 96, 96);
export const parseData
: (items: ICouponRecord[]) => ICouponInfo[]
= (items) => (
  items.map((item) => {
    const due = dayjs(item.dueDate)
    const valid = due.isValid() && dayjs().isBefore(due);
    const status = (!!item.redeemTime || !!item.redeemMerchant?.name) ? CouponStatusEnum.USED :
                  valid ? CouponStatusEnum.VALID : 
                  CouponStatusEnum.EXPIRED
                  ;
    const userAvatarUrl = !item?.user?.avatar ? '' : getImageUrl(item.user.avatar)
    return ({ ...item, status, userAvatarUrl })
  }) || []
)

export interface ICouponStatistics {
  all: number;
  valid: number;
  used: number;
  expired: number;
}
export const statisticData = (items: ICouponInfo[]) => {
  const result: ICouponStatistics = {
    all: items?.length,
    valid: 0,
    used: 0,
    expired: 0,
  }
  if (!result.all) return result

  items.forEach((item: ICouponInfo) => {
    switch(item.status) {
    case CouponStatusEnum.EXPIRED:
      result.expired++;
      break;
    case CouponStatusEnum.USED:
      result.used++;
      break;
    case CouponStatusEnum.VALID:
      result.valid++;
      break;
    }
  })
  return result
}
