import { IPromotionRule } from './type.entity'

export const autoDescription = ({merchantName, dueDate, rules}:{
  merchantName: string;
  dueDate: string;
  rules: IPromotionRule[];
}) => {
  const ruletxt = rules?.map((rule) => (`邀请${rule.followerNumber}位好友，获得${rule.preference?.name}`)).join('；') || '';
  return (
`成功${ruletxt}.
有效期至${dueDate}
本活动最终解释权归${merchantName}所有
`
  )
}

export const autoCopyWriting = ({merchantName, promotionName, ruleNames}:{
  merchantName: string;
  promotionName: string;
  ruleNames: string[];
}) => (`我在${merchantName}${promotionName}活动中领取了${ruleNames.join('，')}，数量有限，速领！`)

export const REQ_URL = 'promotion'
