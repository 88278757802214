import React, { createContext, useState, useEffect } from "react";
import {
  userCache,
  indexOfLocation,
  request,
  requestPost,
  requestPut,
  requestGet,
  getParams,
} from "../lib";
import { EXTERNAL_URL, LOCAL_URL, ORDER_TYPE_OPTIONS } from "../config";
import { notify } from "../components";
import { LocationDS } from "./data.d";
import { checkResponseBody } from "./helper";
import {setUser} from "../lib/firebase";

const API_ROOT = EXTERNAL_URL.API_ROOT;

const DEFAULT_MODEL = {
  ready: false,
  profile: null,
  // membership: null,
  cachedKeywords: [],
  sensorAllowed: true,
  locations: [],
  location: undefined,

  filterOfMyOrders: (o) =>
    !!o.redeemCode ||
    ("PURCHASE" === o.type && "PREMIUM_MEMBERSHIP" === o.productType),
};

/**
 userCache:
 token
 profile
 */
const updateUserCache = (newObj) => {
  if (!newObj) return;
  const origin = JSON.parse(userCache.get() || "{}");
  const updated = Object.assign(origin, newObj);
  userCache.set(JSON.stringify(updated));
};
export const UserContext = createContext<any>({});

export const UserContextProvider = ({ children }) => {
  const [model, setModel] = useState<any>({ ...DEFAULT_MODEL });

  useEffect(() => {
    const { profile, cachedKeywords, locations, location, token } = JSON.parse(
      userCache.get() || "{}"
    );
    let newToken = token;
    // 使用url中的 token 初始化 userStore
    const params = getParams(window.location.search);
    if (params && params.token) {
      newToken = params.token;
      userCache.set(
        JSON.stringify({
          token: newToken,
        })
      );
    }
    if (newToken && !profile) {
      loginWithToken(newToken);
    }
    setModel({
      ...model,
      profile: profile || null,
      cachedKeywords: cachedKeywords || [],
      locations: locations || [],
      location,
      ready: true,
      token: newToken,
    });
  }, []);

  const sendPasscode2Email = async (payload) => {
    // console.log(payload)
    const result = await requestPost("passcode/email", payload);
    const data = checkResponseBody(result);
    return data;
  };
  const sendPasscode2SMS = async (payload) => {
    // console.log(payload)
    const result = await requestPost("passcode/sms", payload);
    const data = checkResponseBody(result);
    return data;
  };

  const precheck = async (payload) => {
    const result = await requestPost("enduser/precheck", payload);
    return checkResponseBody(result);
  };

  const register = async (payload) => {
    // console.log(payload)
    const result = await requestPost("enduser/register", payload);
    const data = checkResponseBody(result);
    if (!data) return;
    // notify.success('Your account is ready!', 2000);
    userCache.set(
      JSON.stringify({
        token: data?.jwt,
        profile: data?.user,
      })
    );
    setModel({
      ...model,
      profile: data?.user,
      inviteCode: data?.inviteCode,
      ready: true,
    });
  };
  const login = async (payload) => {
    // console.log(payload);
    const result = await requestPost("auth/wechatLogin", payload);

    // 针对错误密码和无效账号，特殊处理，返回相应的错误
    if (result.statusCode === 400) {
      return result;
    }
    // else

    const data = checkResponseBody(result);
    if (!data) return;

    // notify.success('Welcome back!', 2000);
    userCache.set(
      JSON.stringify({
        token: data?.jwt,
        profile: data?.user,
      })
    );
    setModel({
      ...model,
      profile: data?.user,
      ready: true,
    });
    setUser(data?.user)
    return data;
  };

  const mockLogin = async (payload) => {
    const result = await requestPost("auth/mock-login", payload);

    // 针对错误密码和无效账号，特殊处理，返回相应的错误
    if (result.statusCode === 400) {
      return result;
    }
    // else

    const data = checkResponseBody(result);
    if (!data) return;

    notify.success('Welcome back!', 2000);
    userCache.set(
      JSON.stringify({
        token: data?.jwt,
        profile: data?.user,
      })
    );
    setModel({
      ...model,
      token: data?.jwt,
      profile: data?.user,
      ready: true,
    });
    return data;
  };

  const loginWithToken = async (token) => {
    const { data } = await requestGet("auth/loginWithToken", { token });
    if (data) {
      userCache.set(
        JSON.stringify({
          token: token,
          profile: data,
        })
      );
      setModel({
        ...model,
        profile: data,
        token: token,
        ready: true,
      });
    }
  };

  const check3rdPartyProfile = async (payload) => {
    const result = await requestPost("oauth/profile", payload);
    // const result = await requestPost('https://dev.lovinlocal.com/oauth/profile', payload);
    const data = checkResponseBody(result);
    if (!data) return;

    // notify.success('Welcome back!', 2000);
    userCache.set(
      JSON.stringify({
        token: data?.jwt,
        profile: data?.user,
      })
    );
    setModel({
      ...model,
      profile: data?.user,
      token: data?.jwt,
      ready: true,
    });
    return data;
  };
  const logout = () => {
    userCache.clear();
    setModel({ ...DEFAULT_MODEL, ready: true });
  };
  const updateProfile = async (payload) => {
    const result = await requestPut("enduser/profile", payload);
    const profile = checkResponseBody(result);
    if (!profile) return;

    setModel({
      ...model,
      profile,
    });
    updateUserCache({ profile });
  };
  const binding = async (payload) => {
    const result = await requestPut("enduser/binding", payload);
    const profile = checkResponseBody(result);
    if (!profile) return;

    setModel({
      ...model,
      profile,
    });
    updateUserCache({ profile });
    return profile;
  };
  const resetPassword = async (payload) => {
    const result = await requestPut("enduser/password", payload);
    const data = checkResponseBody(result);
  };

  const cacheKeywords = (keywords?: string | undefined) => {
    if (keywords === undefined) model.cachedKeywords = [];
    else if (!keywords) return;
    else
      model.cachedKeywords = Array.from(
        new Set([...model.cachedKeywords, keywords])
      );
    setModel({ ...model });
    updateUserCache({ cachedKeywords: model.cachedKeywords });
  };
  const setSensorAllowed = (sensorAllowed) => {
    setModel({ ...model, sensorAllowed });
  };
  const setLocation = (location: LocationDS) => {
    if (indexOfLocation(location, model.locations) === -1) {
      model.locations.unshift(location);
    }
    const locations = [...model.locations];
    setModel({
      ...model,
      locations,
      location,
    });
    updateUserCache({ locations, location });
  };

  const requestHash = async () => {
    // console.log(payload)
    const result = await request("enduser/hash");
    const data = checkResponseBody(result);
    return data;
  };

  return (
    <UserContext.Provider
      value={{
        model,

        isReady: !!model?.ready,
        isAnonymous: !model?.token,

        userName: model?.profile?.firstName || model?.profile?.name || "",
        location: model?.location,
        userId: model?.profile?.id ?? null,
        cacheKeywords,
        setSensorAllowed,
        setLocation,

        sendPasscode2Email,
        sendPasscode2SMS,

        precheck,
        register,
        login,
        logout,
        mockLogin,
        check3rdPartyProfile,
        resetPassword,
        updateProfile,
        binding,
        requestHash,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
