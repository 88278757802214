import * as React from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import IconBack from "./Icons/IconBack";
import {THEME} from "../config";

export interface IHeaderProps {
  title?: string;
  right?: JSX.Element;
  onBackClicked?: () => void;
  backgroundColor?: string;
  hidePlaceholder?: boolean
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.backgroundColor ?? '#cc1543'};
  gap: 8px;
  height: ${THEME.size.HEADER_BAR_HEIGHT};
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  overflow: hidden;
  @media (min-width: ${(props) => props.theme.desktopMinWidth}) {
    width: ${(props) => props.theme.maxWidth};
  }

  @media (max-width: ${(props) => props.theme.mobileMaxWidth}) {
    width: 100%;
    left: 0;
  }

  .placeholder {
    height: ${THEME.size.HEADER_BAR_HEIGHT};
  }
`;

export const FloatBottom = ({children}) => (
  <>
    <div style={{height: 64}}/>
    <StyledFloatBottom>{children}</StyledFloatBottom>
  </>
);

export const StyledFloatBottom = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: row;
  height: 64px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 23px;

  .title {
    width: 100%;
    font-size: 15px;
    color: #fff;
    margin-left: 8px;
  }
`;

const Placeholder = styled.div`
  height: ${THEME.size.HEADER_BAR_HEIGHT};
`;

function Header(props: IHeaderProps) {
  const history = useHistory();
  return (
    <>
      <Root backgroundColor={props.backgroundColor} className="ellipsis debugx">
        <LeftContainer className="ellipsis debugx"
          onClick={() => {
            if (props.onBackClicked) {
              props.onBackClicked();
            } else {
              history.goBack();
              // if (history.action !== "POP") {
              //   history.goBack();
              // } else {
              //   history.push("/");
              // }
            }
          }}
        >
          <IconBack/>
          <div className="title ellipsis" dangerouslySetInnerHTML={{ __html: props.title }}></div>
        </LeftContainer>
        {props.right}
      </Root>
      {props.hidePlaceholder ? null : (
        <Placeholder/>
      )}
    </>
  );
}

export default Header;
