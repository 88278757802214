import styled from 'styled-components';
// import { IconClock } from './Icons';
import { THEME, LOCAL_URL } from '../config';

const IconClock = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0.666626H6V1.99996H10V0.666626ZM7.33333 9.33329H8.66667V5.33329H7.33333V9.33329ZM12.6867 4.92663L13.6333 3.97996C13.3467 3.63996 13.0333 3.31996 12.6933 3.03996L11.7467 3.98663C10.7133 3.15996 9.41333 2.66663 8 2.66663C4.68667 2.66663 2 5.35329 2 8.66663C2 11.98 4.68 14.6666 8 14.6666C11.32 14.6666 14 11.98 14 8.66663C14 7.25329 13.5067 5.95329 12.6867 4.92663ZM8 13.3333C5.42 13.3333 3.33333 11.2466 3.33333 8.66663C3.33333 6.08663 5.42 3.99996 8 3.99996C10.58 3.99996 12.6667 6.08663 12.6667 8.66663C12.6667 11.2466 10.58 13.3333 8 13.3333Z" />
  </svg>
)
const TimerContainer = styled.div`
  width: 115px;
  text-align: center;
`;
const TitleBox = styled.div`
  width: 100%;
  height: 28px;
  background: ${THEME.color.primary.default};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  line-height:28px;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    height: 16px;
    fill: #fff;
    transform: translateY(-1px);
    margin-right: 4px;
  }
`;
const CounterBox = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${THEME.color.primary.default};

  display: flex;
  justify-content: space-between;
  align-items: center;

  & div {
    display: inline-block;
    border-radius: 2px;
    height: 22px;
    width: 16px;
    background: ${THEME.color.primary.default};
    color: #fff;
  }
`;

export const TimerCounter = ({mmss}) => {

  return(
    <TimerContainer className="shadow02">
      <TitleBox>
        <IconClock />
        <span className="ft-rb14">STARTS IN</span>
      </TitleBox>
      <CounterBox>
        <div className="ft-rb18">{mmss[0]}</div>
        <div className="ft-rb18">{mmss[1]}</div>
        :
        <div className="ft-rb18">{mmss[2]}</div>
        <div className="ft-rb18">{mmss[3]}</div>
      </CounterBox>
    </TimerContainer>
  )
}

