import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {requestPost, requestPut } from "../../../lib";
import {Button} from "../../../components";
import Header from "../../../components/Header";
import useMerchant from '../../../hooks/useMerchant'
import PageLayout from "../../Global/PageLayout";
import { toast } from "../../../Layout";

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: .5rem 1rem;
  background-color: white;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
`
const InputWrapper = styled.div`
  height: 56px;
  margin: 1rem;
  padding: 1rem 1rem 1rem .5rem;
  border-radius: 8px;
  background-color: white;

  & label {
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
  }
  & span {
    color: red;
  }
  & input {
    border: none;
    outline: none;
    text-align: right;
  }
`

interface ICategoryDetailsProps {
  data?: {
    id?: number;
    title?: string;
  };
  newIndex?: number
  exit: (isCancelled:boolean) => void;
}

/**
  This is not a real page, which has its own route
  Instead, this page is a part of Category List Page in a special mode
 */
export const CategoryDetailsPage: React.FC<ICategoryDetailsProps> = ({exit, newIndex, data}) => {
  const { currentMerchantId } = useMerchant();
  const [title, setTitle] = useState<string>(data?.title || '')
  const isEditingExist = ('number' === typeof data?.id)

  const onConfirm = async () => {
    // console.log({title, currentMerchantId, newIndex, isNaN: ('number' === typeof newIndex) }, data)
    if (isEditingExist) {
      if (!!title) {
        const result = await requestPut(`category`, {
          id: data.id,
          title,
        })
        if(result?.code !== 0) {
          toast.error('更新失败！')
        }
        exit(false)
      }
    } else if ( (!!title) && ('number' === typeof currentMerchantId) && ('number' === typeof newIndex) )  {
      const result = await requestPost(`category`, {
        index: newIndex,
        merchantId: currentMerchantId,
        title,
      })
      if(result?.code !== 0) {
        toast.error('添加失败！')
      }
      exit(false)
    } else {
      toast.error('参数错误！')
      exit(false)
    }
  }

  const onKeyDown = (e:any) => {
    if (e.code === 'Enter') {
      onConfirm()
    }
  }

  return (
    <PageLayout pageName="category new no nav">
      <Header onBackClicked={() => exit(true)}
        title={(isEditingExist) ? "更新分类名称" : "添加新分类" }
      />

      <InputWrapper className="flex-between align-center">
        <label>分类名称<span>*</span></label>
        <input type="text" placeholder="请输入分类名称，最多8个字"
          value={title}
          onChange={(e:any) => setTitle(e?.target?.value)}
          onKeyDown={onKeyDown}
        />
      </InputWrapper>

      <ButtonWrapper>
        <Button fullWidth disabled={!title} onClick={onConfirm}>确定</Button>
      </ButtonWrapper>

    </PageLayout>
  )
}
