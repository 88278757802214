import dayjs from "dayjs";
import React, {useState} from "react";
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {Button, notify} from "../../components";
import Header, {FloatBottom} from '../../components/Header'
import {LOCAL_URL, THEME} from "../../config";
import {Layout} from '../../Layout'
import {requestPost, requestPut} from "../../lib";
// import {getImageById} from "../../utils/ImageUtils";
import RedeemCouponWarning from "./components/RedeemCouponWarning";
import { MerchantInfoCard } from '../Coupon/MerchantInfoCard'

const Wrapper = styled.div`
  padding: .75rem 1rem;

  & div.block {
    background-color: white;
    border-radius: 8px;
    font-size: 12px;
    line-height: 18px;
  }

  & div.status {
    padding: 1.5rem;
    background-color: ${props => props.theme.color.primary.HEX};
    color: white;
  }

  & div.description {
    padding: 1.5rem 1.5rem 2.5rem;
    color: rgba(0, 0, 0, 0.45);
  }

  & p.bold {
    font-weight: 500;
  }

  & p.large {
    font-size: 18px;
    line-height: 24px;
  }

  & p.title {
    margin-bottom: 8px;
  }

  & p {
    margin-top: 8px;
  }

  /* .merchantCard {
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      height: 40px;
      width: 40px;
      border-radius: 20px;

      img {
        width: 100%;
      }
    }

    .name {
      padding-left: 12px;
      flex: 1;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: ${THEME.color.gray["#10-085"]}
    }

    .right {
      height: 32px;
      min-width: 64px;
      color: white;
      background-color: ${props => props.primaryColor ?? THEME.color.primary.default};
      border-radius: 4px;
      line-height: 16px;
      padding: 8px;
      font-weight: 500;
      font-size: 12px;
    }
  } */
`
export default function CouponDetailInPromotionPage() {
  const location = useLocation()
  const history = useHistory()
  const [showRedeemModal, setShowRedeemModal] = useState(false)
  const {
    coupon,
    merchant,
    preference
  } = location.state || {};

  let descArray = []
  let expired = false
  if (coupon) {
    descArray = coupon.description.split('\n')
    expired = dayjs().isAfter(dayjs(coupon.dueDate))
  } else {
    descArray = preference.description.split('\n')
  }

  const redeem = async ()=>{
    try {
      const {data} = await requestPost('coupon/redeem',{
        couponId: coupon.id
      })
      if (data){
        history.replace(LOCAL_URL.COUPON_REDEEM, {
          data:{
            ...data,
            merchant: merchant
          }
        })
      }
    }catch (e) {
      notify.error('核销失败')
    }
  }

  return (
    <Layout style={{position: 'relative'}}>
      <Header title="优惠券" backgroundColor={merchant?.meta?.themeColor} hidePlaceholder/>
      <Wrapper className="flex-start direction-col" style={{gap: '.75rem'}} primaryColor={merchant?.meta?.themeColor}>
        {coupon ?
          (<div className='block status'>
            <p className='bold large'>{expired ? '已过期' : (coupon.redeemTime ? '已使用' : '待使用')}</p>
            <p>{ expired ? (`该券已于${dayjs(coupon.dueDate).format('YYYY年MM月DD日')}过期`) : (coupon.redeemTime ? `使用时间:${dayjs(coupon.redeemTime).format('YYYY年MM月DD日 HH:mm')}` : '您已成功领取本券，详见使用规则')}</p>
          </div>) : null
        }
        <div className='block description'>
          <p className='bold large' style={{color: 'rgba(0,0,0,0.85)', marginBottom: '16px'}}>
            {coupon?.name ?? preference?.name}
          </p>
          {descArray.map((item, index) => {
            return (
              <p key={index}>{item}</p>
            )
          })}
        </div>
        {/* <div className='merchantCard'
             onClick={() => {
               history.push(LOCAL_URL["MERCHANT_DETAIL"] + `?id=${merchant.id}`)
             }}
        >
          <div className='icon'>
            <img src={getImageById(merchant.logo)} alt=""/>
          </div>
          <div className='name'>
            {merchant.name}
          </div>
          <div className='right'>
            进入店铺
          </div>
        </div> */}
        <MerchantInfoCard info={merchant} />
      </Wrapper>
      {(coupon && !coupon.redeemTime && !expired) ? (
        <FloatBottom>
          <Button
            fullWidth
            size="large"
            primaryColor={merchant?.meta?.themeColor}
            onClick={async () => {
              const {applicationScope} = coupon
              if (applicationScope && applicationScope.setting) {
                if (applicationScope.setting.includes('IN-STORE')) {
                  setShowRedeemModal(true)
                } else {
                  notify.info('该券只能用于在线网店')
                }
              } else {
                notify.info('卡券数据异常，请联系客服。')
              }
            }}
          >
            使用本券
          </Button>
        </FloatBottom>
      ) : null}
      <RedeemCouponWarning
        open={showRedeemModal}
        onOK={()=>{
          redeem()
        }}
        onCancel={()=>{
          setShowRedeemModal(false)
        }}
      />
    </Layout>
  )
}
