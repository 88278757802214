import React, { useState } from "react";
import { LOCAL_URL, THEME, WEB_ROOT } from "../../../config";
import { getImageById, uploadImage } from "../../../utils/ImageUtils";
import { isAndroid } from "../../../utils/common";
import { useRouter } from "../../../hooks/router";
import { requestPost } from "../../../lib";
import styled from "styled-components";
import numeral from "numeral";
import dayjs from "dayjs";
import LoadingDialog from "../../../components/LoadingDialog";

const ImageInput = styled("input")({
  display: "none",
});

type ReceiptInfoProps = {
  order: any;
  role: "USER" | "MERCHANT";
  group: any;
  onRefresh: () => void;
};

function ReceiptInfo(props: ReceiptInfoProps) {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  const notifyMessage =
    `你好，你参加的接龙：${props.group.title}，还没有上传付款截图，请付款后及时上传。` +
    "%0a" +
    `账户名：${props.group.bankAccountName}` +
    "%0a" +
    `账号：${props.group.bankAccount}` +
    "%0a" +
    `Reference：${props.order.groupIndex}` +
    "%0a" +
    `金额：${numeral(props.order.totalPrice).divide(100).format("$0,0.00")}` +
    "%0a" +
    `付款后，请点击以下链接上传付款截图：${WEB_ROOT}${LOCAL_URL["PAYMENT_UPLOAD"]}?orderNo=${props.order.id}` +
    "%0a" +
    `谢谢.` +
    "%0a" +
    `${props.order.merchant.name}`;
  const onUploadSuccess = async (
    orderId: number,
    url: string,
    actionId: number | null
  ) => {
    await requestPost("order/public/prepay", {
      orderId: orderId,
      imageUrl: url,
      actionId: actionId,
    });
  };

  const receiptActionBtnClicked = (
    type: "VIEW_IMAGE" | "VIEW_ACCOUNT",
    meta?: any
  ) => {
    switch (type) {
      case "VIEW_ACCOUNT": {
        router.push(LOCAL_URL["PAYMENT_UPLOAD"], {
          orderNo: props.order.id,
        });
        break;
      }
      case "VIEW_IMAGE": {
        router.push(LOCAL_URL["RECEIPT_VIEW"], {
          status: props.order.status,
          image: meta?.receiptUrl,
          orderId: props.order.id,
          role: props.role,
          userMobile: props.order.address.telephone,
          message: notifyMessage,
        });
      }
    }
  };
  let lastTransactions = null;
  if (props.order.transactions) {
    lastTransactions =
      props.order.transactions[props.order.transactions.length - 1];
  }
  if (
    props.order.status === "PRE_PAID" &&
    lastTransactions &&
    lastTransactions.paymentMethod === "TRANSFER"
  ) {
    // 订单处于确认付款的状态
    const prepaidActions = props.order.actions
      .reverse()
      .filter((item) => item.actionType === "PRE_PAID");
    if (prepaidActions.length === 0) {
      // 用户没有上传过截图
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: 16,
          }}
        >
          <div
            style={{
              height: 88,
              width: 88,
              backgroundColor: THEME.color.gray["#5-025"],
              textAlign: "center",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                fontSize: 12,
                color: THEME.color.gray["#12-045"],
                paddingBottom: 8,
              }}
            >
              未上传截图
            </div>
          </div>
          <div style={{ flex: 1 }}>
            {props.role === "USER" ? (
              <div
                style={{
                  fontSize: 12,
                  color: THEME.color.gray["#10-085"],
                  paddingLeft: 8,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <div
                    onClick={() => {
                      receiptActionBtnClicked("VIEW_ACCOUNT");
                    }}
                    style={{
                      display: "inline-block",
                      padding: "4px 8px",
                      borderRadius: 16,
                      backgroundColor: "white",
                      border: "1px solid",
                      borderColor: THEME.color.gray["#12-045"],
                    }}
                  >
                    查看付款帐号信息
                  </div>
                </div>
                <label htmlFor={`icon-button-file-${props.order.id}-upload`}>
                  <ImageInput
                    accept="image/*"
                    id={`icon-button-file-${props.order.id}-upload`}
                    type="file"
                    onChange={async (e) => {
                      setLoading(true);
                      const public_id = await uploadImage(e.target.files[0]);
                      const imageUrl = getImageById(public_id);
                      await onUploadSuccess(props.order.id, imageUrl, null);
                      setLoading(false);
                      props.onRefresh();
                    }}
                  />
                  <div
                    style={{
                      display: "inline-block",
                      padding: "4px 8px",
                      borderRadius: 16,
                      backgroundColor: "white",
                      border: "1px solid",
                      borderColor: THEME.color.gray["#12-045"],
                      marginTop: 8,
                    }}
                  >
                    上传付款截图
                  </div>
                </label>
              </div>
            ) : (
              <div
                style={{
                  fontSize: 12,
                  color: THEME.color.gray["#10-085"],
                  paddingLeft: 8,
                }}
              >
                <a
                  href={`sms:${props.order.shipType === 'PICKUP' ? props.order.phone: props.order.address.telephone}${
                    isAndroid ? "?" : "&"
                  }body=${notifyMessage}`}
                  style={{
                    textDecoration: "none",
                    display: "inline-block",
                    padding: "4px 8px",
                    color: THEME.color.gray["#10-085"],
                    borderRadius: 16,
                    backgroundColor: "white",
                    border: "1px solid",
                    borderColor: THEME.color.gray["#12-045"],
                  }}
                >
                  提醒用户上传正确截图
                </a>
              </div>
            )}
          </div>
          <LoadingDialog visible={loading} />
        </div>
      );
    } else {
      // 用户曾经上传过截图
      return prepaidActions.map((item) => {
        return (
          <div key={item.id}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: 12,
              }}
            >
              <span style={{ color: THEME.color.gray["#12-045"] }}>{`${dayjs(
                item.updatedAt
              ).format("YYYY-MM-DD HH:mm")} ${
                props.order?.user?.name
              }更新了`}</span>
              <a
                style={{ textDecoration: "underline", color: "inherit" }}
                onClick={() => {
                  receiptActionBtnClicked("VIEW_IMAGE", item.meta);
                }}
              >
                付款截图
              </a>
            </div>
            <div
              style={{
                marginTop: 8,
                display: "flex",
                flexDirection: "row",
                paddingBottom: 16,
              }}
            >
              <div
                style={{
                  height: 88,
                  width: 88,
                  backgroundColor: THEME.color.gray["#5-025"],
                  textAlign: "center",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <img
                  style={{
                    height: "100%",
                    opacity: 0.6,
                    filter: "alpha(opacity=60)",
                  }}
                  src={item.meta?.receiptUrl}
                  alt=""
                />
              </div>
              <div style={{ flex: 1 }}>
                {props.role === "USER" ? (
                  <div
                    style={{
                      fontSize: 12,
                      color: THEME.color.gray["#10-085"],
                      paddingLeft: 8,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <div
                        onClick={() => {
                          receiptActionBtnClicked("VIEW_ACCOUNT");
                        }}
                        style={{
                          display: "inline-block",
                          padding: "4px 8px",
                          borderRadius: 16,
                          backgroundColor: "white",
                          border: "1px solid",
                          borderColor: THEME.color.gray["#12-045"],
                        }}
                      >
                        查看付款帐号信息
                      </div>
                    </div>
                    <label
                      htmlFor={`icon-button-file-${props.order.id}-${item.id}`}
                    >
                      <ImageInput
                        accept="image/*"
                        id={`icon-button-file-${props.order.id}-${item.id}`}
                        type="file"
                        onChange={async (e) => {
                          setLoading(true);
                          const public_id = await uploadImage(
                            e.target.files[0]
                          );
                          const imageUrl = getImageById(public_id);
                          await onUploadSuccess(
                            props.order.id,
                            imageUrl,
                            item.id
                          );
                          setLoading(false);
                          props.onRefresh();
                        }}
                      />
                      <div
                        style={{
                          display: "inline-block",
                          padding: "4px 8px",
                          borderRadius: 16,
                          backgroundColor: "white",
                          border: "1px solid",
                          borderColor: THEME.color.gray["#12-045"],
                          marginTop: 8,
                        }}
                      >
                        重新上传付款截图
                      </div>
                    </label>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: 12,
                      color: THEME.color.gray["#10-085"],
                      paddingLeft: 8,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <div
                        onClick={() => {
                          receiptActionBtnClicked("VIEW_IMAGE", item.meta);
                        }}
                        style={{
                          display: "inline-block",
                          padding: "4px 8px",
                          borderRadius: 16,
                          backgroundColor: "white",
                          border: "1px solid",
                          borderColor: THEME.color.gray["#12-045"],
                        }}
                      >
                        查看付款截图
                      </div>
                    </div>
                    <div>
                      <a
                        href={`sms:${props.order.shipType === 'PICKUP' ? props.order.phone: props.order.address.telephone}${
                          isAndroid ? "?" : "&"
                        }body=${notifyMessage}`}
                        style={{
                          textDecoration: "none",
                          display: "inline-block",
                          padding: "4px 8px",
                          color: THEME.color.gray["#10-085"],
                          borderRadius: 16,
                          backgroundColor: "white",
                          border: "1px solid",
                          borderColor: THEME.color.gray["#12-045"],
                          marginTop: 8,
                        }}
                      >
                        提醒用户上传正确截图
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <LoadingDialog visible={loading} />
          </div>
        );
      });
    }
  } else {
    return null;
  }
}

export default ReceiptInfo;
