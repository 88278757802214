import {Backdrop, CircularProgress} from "@mui/material";
import html2canvas from "html2canvas";
import {QRCodeSVG} from "qrcode.react";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import IconClose from "../../../components/Icons/IconClose";
import {LOCAL_URL, THEME, WEB_ROOT} from "../../../config";
import {getPublicFromUrl} from "../../../lib/util";
import {getWidth} from "../../../utils/common";
import {getImageById, getImageByIdWithSize} from "../../../utils/ImageUtils";

interface ShareImageModalModalProps {
  copyUrl: string;
  avatar: string;
  name: string;
  userId: number;
  posterLink: string;
  copywriting: string;
  promotionId: number;
  show: boolean;
  onDismiss: () => void;
}

const ShareImageModal = (props: ShareImageModalModalProps) => {
  const {
    avatar,
    name,
    userId,
    posterLink,
    copywriting,
    promotionId,
    show,
    onDismiss
  } = props
  const ref = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [loading, setlLoading] = React.useState(false);
  const [finishedImage, setFinishedImage] = useState(0)
  const posterUrl = getImageByIdWithSize(posterLink, 'fill', 295 * 3, 175 * 3)
  const avatarUrl = getImageById(avatar)

  const loadImageAsync = async () => {
    // let images = ref.current.getElementsByTagName("img");
    setlLoading(true);
    //
    // images[0].src = posterUrl;
    // images[1].src = avatarUrl;
    // // Wait for it to load.
    // await new Promise((resolve) => {
    //   images[0].onload = resolve;
    // });
    // await new Promise((resolve) => {
    //   images[1].onload = resolve;
    // });
    try {
      html2canvas(ref.current, {
        // width: 343,
        // height: 522,
        // width: getWidth() * 0.8,
        // height: getWidth() * 0.8 * 363 / 343 + 32,
        allowTaint: true,
        useCORS: true,
      })
        .then((canvas) => {
          imgRef.current.src = canvas.toDataURL();
          imgRef.current.style.display = "block";
          ref.current.style.display = "none";
          setFinishedImage(0)
        })
    } catch (err) {
    } finally {
      setlLoading(false);
    }

  };

  // useEffect(() => {
  //   if (
  //     ref.current === null ||
  //     imgRef.current === null
  //     // imgRef.current.hasChildNodes()
  //   ) {
  //     return;
  //   }
  //   loadImageAsync();
  // }, [ref, imgRef, props.show]);

  useEffect(()=>{
    if (finishedImage ==2){
      loadImageAsync();
    }
  }, [finishedImage])

  if (!props.show) {
    return null
  }
  return (
    <Backdrop
      open={props.show}
      onClick={() => {
        props.onDismiss()
      }}
    >
      <Container>
        {loading && <CircularProgress style={{alignSelf: "center"}}/>}
        <div className="btn-close" onClick={onDismiss}>
          <IconClose/>
        </div>
        <img className="share-image" ref={imgRef} alt='share image'/>
        <div ref={ref} className="share-content">
          <div className='poster-link'>
            <img src={posterUrl} alt="" onLoad={()=>{
              setFinishedImage(finishedImage + 1)
            }}/>
          </div>
          <div className='content'>
            <div className='avatar'>
              <img src={avatarUrl} alt="" onLoad={()=>{
                setFinishedImage(finishedImage + 1)
              }}/>
            </div>
            <div className='right'>
              <div className='username'>{name}</div>
              <div className='copywriting'>{copywriting}</div>
            </div>
          </div>
          <div className="qrcode-container">
            <QRCodeSVG
              size={64}
              value={props.copyUrl}
            />
          </div>
        </div>
        <div className="save-img-desc">长按图片转发或保存</div>
      </Container>
    </Backdrop>
  )
}

const Container = styled.div`
  width: 80%;

  .btn-close {
    text-align: center;
  }

  .share-image {
    width: 100%;
    display: none;
  }

  .share-content {
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    .poster-link {
      img {
        width: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      margin-top: -12px;

      .avatar {
        width: 32px;
        border-radius: 16px;
        overflow: hidden;

        img {
          width: 32px;
          border: 2px solid white;
          border-radius: 16px;
        }
      }

      .right {
        flex: 1;
        padding-top: 14px;
        display: flex;
        flex-direction: column;

        .username {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
        }

        .copywriting {
          margin-top: 8px;
          font-weight: 400;
          font-size: 10px;
        }
      }
    }

    .qrcode-container {
      margin-top: 24px;
      text-align: center;
    }
  }

  .save-img-desc {
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: white;
    margin-top: 36px;
  }
`

export default ShareImageModal
