import React, { useState } from 'react';
import styled from 'styled-components';
import { THEME } from '../../config';

const ListInputFieldContainer = styled.div`
  font-family: Roboto;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ListInputContainer = styled.div`
  position: relative;
  flex: 1;
  padding-left: 16px;
  & div {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  & div svg {
    width: 16px;
  }
`;

const ListInputStyled = styled.input`
  width: 100%;
  height: 48px;
  font-size: 15px;
  padding: .75em .5em .75em 1em;
  // border-radius: .25em;
  border: 0px;
  color: ${THEME.color.gray['#0-087']};
  background: ${(props) => props.background || 'auto'};
  text-align: right;
  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    font-size: 15px;
    color: ${THEME.color.gray['#5-025']};
  }
`;
const Label = styled.p`
  color: ${THEME.color.gray['#0-087']};
  font-size: 15px;
  line-height: 1.5em;
  margin-bottom: .25em;
`;
const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin: .5em 0 .25em;
`;
const Hint = styled.p`
  color: ${THEME.color.gray['#3-76']};
  line-height: 1.2em;
  margin: .5em 0 .25em .4em;
  font-weight: 700;
  font-size: 14px;
`;

interface ListInputFieldProps {
  required?: boolean
  register?: any;
  type?: string;
  noEye?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  hint?: string;
  errors?: any;
  background?: string;
  inputMode?: string;
  [propName: string]: any
}

const Divider = styled.div`
width: 100%;
height: 1px;
background-color: rgba(0, 0, 0, 0.09);
`
export const ListInputField: React.FC<ListInputFieldProps> = ({ register, noEye, type, label, name, placeholder, hint, errors, background, bottomDivider, required, inputMode, ...restProps }) => {
  const [openState, setOpenState] = useState(false)
  return (
    <>
      <ListInputFieldContainer {...restProps} >
        {!label ? null : <Label>{label}{required ? (<span style={{ color: THEME.color.primary.default }}>*</span>) : null}</Label>}
        <ListInputContainer>
          <ListInputStyled
            inputMode={inputMode}
            type={(type === 'password') ? (!openState ? type : 'text') : (type || 'text')} className="ft-rr15"
            background={background}
            name={name}
            placeholder={placeholder}
            ref={register}
            error={(!!errors && !!errors[name])}
            {...restProps}
          />
        </ListInputContainer>
        {(!errors || !errors[name]) ? null : <ErrMsg className="ft-rr12">{errors[name].message}</ErrMsg>}
        {!hint ? null : <Hint>{hint}</Hint>}
      </ListInputFieldContainer>
      {bottomDivider && <Divider />}
    </>
  )
}

export const IntegerInputField: React.FC<ListInputFieldProps> = ({ register, label, name, placeholder, errors, background, bottomDivider, required, ...restProps }) => {
  return (
    <>
      <ListInputFieldContainer {...restProps} >
        {!label ? null : <Label>{label}{required ? (<span style={{ color: THEME.color.primary.default }}>*</span>) : null}</Label>}
        <ListInputContainer>
          <ListInputStyled className="ft-rr15"
            type='number' min="1" step="1"
            ref={register}
            name={name}
            background={background}
            placeholder={placeholder}
            error={(!!errors && !!errors[name])}
            {...restProps}
            // value={text}
            // onChange={onInputChange}
          />
        </ListInputContainer>
        {(!errors || !errors[name]) ? null : <ErrMsg className="ft-rr12">{errors[name].message}</ErrMsg>}
      </ListInputFieldContainer>
      {bottomDivider && <Divider />}
    </>
  )
}
