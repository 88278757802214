import React from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import WhiteSpace from "../../components/WhiteSpace";
import {THEME} from "../../config";

const items = [
  // 'https://res.cloudinary.com/shenmanz/image/upload/v1661143649/tuangou/icon/introduce1.png',
  'https://res.cloudinary.com/shenmanz/image/upload/v1661143649/tuangou/icon/introduce2.png',
  'https://res.cloudinary.com/shenmanz/image/upload/v1661143649/tuangou/icon/introduce3.png',
  'https://res.cloudinary.com/shenmanz/image/upload/v1661143649/tuangou/icon/introduce4.png',
  'https://res.cloudinary.com/shenmanz/image/upload/v1661143649/tuangou/icon/introduce5.png',
  'https://res.cloudinary.com/shenmanz/image/upload/v1661143649/tuangou/icon/introduce6.png',
  'https://res.cloudinary.com/shenmanz/image/upload/v1661143649/tuangou/icon/introduce7.png',
]
const width = (window.innerWidth > 768 ? 768 : window.innerWidth);
const height = width / 375 * 812

function AboutPage() {

  return (
    // <PageLayout pageName="no nav aboutPage">
    <PageLayout pageName="aboutPage">
      <Header title="关于大吉"/>
      <Container>
        <WhiteSpace height={26}/>
        <img className='logo' src="https://res.cloudinary.com/shenmanz/image/upload/v1661139853/tuangou/icon/daji.png"
             alt="logo"/>
        <div className='title'>大吉</div>
        <div className='sub-title'>“大吉”，新西兰最好用的团购工具。</div>
        <div
          className='desc'>免费的独立网店、微信群团购、收单对账、社交网络推广。“一键轻松数字化”解决方案，支持本地商家轻松实现互联网高科技化，降成本！增利润！生意大吉！
        </div>
        <WhiteSpace height={64}/>
          {
            items.map((item, i) => <img
              className='carousel-image'
              style={{width: '100%'}}
              key={i}
              src={item}
              alt={item}
            />)
          }
        <WhiteSpace height={36}/>
        <div className='intro'>如有使用问题、商业合作，请长按以下二维码，添加大吉客服微信。</div>
        <img className='qrcode' src="https://res.cloudinary.com/shenmanz/image/upload/v1661388759/tuangou/icon/qr.jpg" alt="shenma"/>
        <WhiteSpace height={64}/>
        <div className='sub-title'>版权信息</div>
        <div className='sub-title'>SHENMA LIMITED</div>
        <WhiteSpace height={64}/>
      </Container>
    </PageLayout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 80px;
    height: 80px;
  }

  .title {
    margin-top: 8px;
    font-family: 'PingFang SC', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  }

  .sub-title {
    margin-top: 4px;
    font-family: 'PingFang SC', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: ${THEME.color.gray["#12-045"]};
  }

  .desc {
    font-family: 'PingFang SC', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    padding: 24px;
    color: ${THEME.color.gray["#11-065"]};
  }

  .carousel{
    width: 100%;
    height: ${height};
    img{
      width: 100%;
    }
  }

  .intro{
    padding: 24px;
    font-family: 'PingFang SC',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;
  }
  
  .qrcode{
    width: 340px;
  }
  
`

export default AboutPage

