import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {THEME} from "../../config";

const SVGEyeOpen = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.18759 8C6.18759 8.46413 6.37196 8.90925 6.70015 9.23744C7.02834 9.56563 7.47346 9.75 7.93759 9.75C8.40172 9.75 8.84684 9.56563 9.17503 9.23744C9.50322 8.90925 9.68759 8.46413 9.68759 8C9.68759 7.53587 9.50322 7.09075 9.17503 6.76256C8.84684 6.43437 8.40172 6.25 7.93759 6.25C7.47346 6.25 7.02834 6.43437 6.70015 6.76256C6.37196 7.09075 6.18759 7.53587 6.18759 8ZM14.722 7.59688C13.2407 4.47656 11.0017 2.90625 8.00009 2.90625C4.99697 2.90625 2.75947 4.47656 1.27822 7.59844C1.2188 7.72425 1.18799 7.86165 1.18799 8.00078C1.18799 8.13991 1.2188 8.27732 1.27822 8.40312C2.75947 11.5234 4.99853 13.0938 8.00009 13.0938C11.0032 13.0938 13.2407 11.5234 14.722 8.40156C14.8423 8.14844 14.8423 7.85469 14.722 7.59688ZM7.93759 10.75C6.41884 10.75 5.18759 9.51875 5.18759 8C5.18759 6.48125 6.41884 5.25 7.93759 5.25C9.45634 5.25 10.6876 6.48125 10.6876 8C10.6876 9.51875 9.45634 10.75 7.93759 10.75Z"
      fill="black"
      fillOpacity="0.66"
    />
  </svg>
);
const SVGEyeClose = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.93759 9.75013C8.40172 9.75013 8.84684 9.56575 9.17503 9.23756C9.50322 8.90938 9.68759 8.46426 9.68759 8.00013C9.68759 7.94888 9.68525 7.8981 9.68087 7.84794L7.7854 9.74341C7.83556 9.74778 7.88618 9.75013 7.93759 9.75013ZM13.7301 2.587L13.0626 1.92013C13.0392 1.8967 13.0074 1.88354 12.9742 1.88354C12.9411 1.88354 12.9093 1.8967 12.8859 1.92013L11.1777 3.62872C10.2355 3.14716 9.17624 2.90638 8.00009 2.90638C4.99697 2.90638 2.75634 4.47044 1.27822 7.59856C1.2188 7.72437 1.18799 7.86178 1.18799 8.00091C1.18799 8.14004 1.2188 8.27744 1.27822 8.40325C1.86884 9.64731 2.58004 10.6445 3.41181 11.3947L1.75884 13.047C1.73542 13.0704 1.72226 13.1022 1.72226 13.1354C1.72226 13.1685 1.73542 13.2003 1.75884 13.2237L2.42587 13.8908C2.44931 13.9142 2.48109 13.9273 2.51423 13.9273C2.54737 13.9273 2.57915 13.9142 2.60259 13.8908L13.7301 2.76388C13.7417 2.75227 13.7509 2.73848 13.7572 2.72331C13.7635 2.70813 13.7668 2.69187 13.7668 2.67544C13.7668 2.65901 13.7635 2.64275 13.7572 2.62757C13.7509 2.6124 13.7417 2.59861 13.7301 2.587ZM5.18759 8.00013C5.18755 7.52514 5.31053 7.05823 5.54457 6.6449C5.77861 6.23157 6.11571 5.8859 6.52305 5.64158C6.93038 5.39725 7.39406 5.2626 7.8689 5.25074C8.34374 5.23887 8.81356 5.3502 9.23259 5.57388L8.4729 6.33356C8.16653 6.23546 7.83905 6.22365 7.5264 6.29942C7.21376 6.37518 6.92802 6.53561 6.70054 6.76308C6.47307 6.99056 6.31265 7.27629 6.23688 7.58894C6.16111 7.90159 6.17293 8.22906 6.27103 8.53544L5.51134 9.29513C5.29815 8.89681 5.18693 8.4519 5.18759 8.00013Z"
      fill="black"
      fillOpacity="0.65"
    />
    <path
      d="M14.7215 7.59702C14.1715 6.43869 13.5169 5.49416 12.7576 4.76343L10.5054 7.01577C10.6953 7.51204 10.7374 8.05267 10.6266 8.57235C10.5158 9.09202 10.257 9.56849 9.88123 9.94421C9.50552 10.3199 9.02904 10.5788 8.50937 10.6896C7.9897 10.8004 7.44907 10.7583 6.95279 10.5684L5.04248 12.4787C5.92873 12.8888 6.91446 13.0939 7.99967 13.0939C11.0028 13.0939 13.2434 11.5298 14.7215 8.40171C14.781 8.2759 14.8118 8.1385 14.8118 7.99937C14.8118 7.86023 14.781 7.72283 14.7215 7.59702Z"
      fill="black"
      fillOpacity="0.65"
    />
  </svg>
);
const InputFieldContainer = styled.div`
  font-family: Roboto;
  font-size: 16px;
`;
const InputContainer = styled.div`
  position: relative;

  & div {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  & div svg {
    width: 16px;
  }
`;

const InputStyled = styled.input`
  width: 100%;
  height: 48px;
  padding: 0.75em 0.5em 0.75em 1em;
  border-radius: 0.25em;
  border: 1px solid ${(props) => (!props.error ? "#c7c8ca" : THEME.color.primary.default)};
  color: ${THEME.color.gray["#0-087"]};
  background: ${(props) => props.background || "auto"};

  &:focus {
    border: 1px solid ${THEME.color.primary.default};
    outline: none;
  }

  &::placeholder {
    font-size: 15px;
    color: ${THEME.color.gray["#5-025"]};
  }
`;

const TextareaStyled = styled.textarea`
  width: 100%;
  padding: 0.75em 0.5em 0.75em 1em;
  resize: none;
  border-radius: 0.25em;
  border: ${(props) =>
          props.error ? `1px solid ${THEME.color.primary.default}` : "none"};
  color: ${THEME.color.gray["#0-087"]};
  background: ${(props) => props.background || "auto"};
  overflow-y: hidden;

  &:focus {
    border: 1px solid ${THEME.color.primary.default};
    outline: none;
  }

  &::placeholder {
    color: ${THEME.color.gray["#5-025"]};
  }

  min-height: 8em;
`;

const Label = styled.p`
  color: ${THEME.color.gray["#0-087"]};
  line-height: 1.5em;
  margin-bottom: 0.25em;
`;
const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin: 0.5em 0 0.25em;
`;
const Hint = styled.p`
  color: ${THEME.color.gray["#3-76"]};
  line-height: 1.2em;
  margin: 0.5em 0 0.25em 0.4em;
  font-weight: 700;
  font-size: 14px;
`;

interface InputFieldProps {
  register?: any;
  type?: string;
  noEye?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  hint?: string;
  errors?: any;
  background?: string;
  required?: boolean;
  disabled?: boolean

  [propName: string]: any;
}

export const InputField: React.FC<InputFieldProps> = ({
                                                        textarea,
                                                        required,
                                                        register,
                                                        noEye,
                                                        type,
                                                        label,
                                                        name,
                                                        placeholder,
                                                        hint,
                                                        errors,
                                                        background,
                                                        disabled,
                                                        ...restProps
                                                      }) => {
  const [openState, setOpenState] = useState(false);
  useEffect(() => {
    adjustTextAreaHeight()
  })
  const adjustTextAreaHeight = () => {
    const textarea = document.getElementById('textarea');
    if (textarea){
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }

  };
  return (
    <InputFieldContainer {...restProps}>
      {!label ? null : (
        <Label>
          {label}
          {required ? (
            <span style={{color: THEME.color.primary.default}}>*</span>
          ) : null}
        </Label>
      )}
      <InputContainer>
        {!textarea ? (
          <InputStyled
            type={
              type === "password"
                ? !openState
                  ? type
                  : "text"
                : type || "text"
            }
            className="ft-rr15"
            background={background}
            name={name}
            placeholder={placeholder}
            ref={register}
            disabled={disabled}
            error={!!errors && !!errors[name]}
          />
        ) : (
          <TextareaStyled
            id='textarea'
            type={
              type === "password"
                ? !openState
                  ? type
                  : "text"
                : type || "text"
            }
            className="ft-rr15"
            background={background}
            name={name}
            onChange={(e) => {
              e.target.style.height = "inherit";
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            placeholder={placeholder}
            ref={register}
            error={!!errors && !!errors[name]}
            {...restProps}
          />
        )}
        {type === "password" && !noEye && (
          <div onClick={() => setOpenState(!openState)}>
            {!openState ? <SVGEyeOpen/> : <SVGEyeClose/>}
          </div>
        )}
      </InputContainer>
      {!errors || !errors[name] ? null : (
        <ErrMsg className="ft-rr12">{errors[name].message}</ErrMsg>
      )}
      {!hint ? null : <Hint>{hint}</Hint>}
    </InputFieldContainer>
  );
};
