import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import { IconArrowLeft } from './SVGIcons'

const Wrapper = styled.div`
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.theme.size.header.height};
  width: 100%;
  padding: 1rem;

  background-color: ${props => props.theme.color.primary.HEX};
  color: white;

  & button {
    border:none;
    background-color: transparent;
  }
  & svg {
    display:block;
  }
`

interface IHeaderProps {
  title: string;
  icon?: ReactElement;
  onBack?: () => void;
}
export const Header: React.FC<IHeaderProps> = ({title, icon, onBack}) => {
  const history = useHistory()
  const onBackClick = () => {
    if (!onBack) {
      history.go(-1)
    } else {
      onBack()
    }
  }
  return (
    <Wrapper className="flex-between align-center">
      <div className="flex-start align-center" style={{gap: '.1rem'}}>
        <button onClick={onBackClick}> <IconArrowLeft /> </button>
        <span>{title}</span>
      </div>
      { icon }
    </Wrapper>
  )
}
