import { notify } from '../components'
export const checkResponseBody = (payload, command="") => {
  // console.log(payload)
  const defMsg = `${command|| 'Command'} fail!`;
  if (payload instanceof Error) {
    notify.error(
      `${payload.message}` || defMsg,
      3000
    );
    return null;
  }
  else if (!!payload.statusCode) {
    notify.error(
      `[${payload.statusCode}]${payload.message} ${payload.error || ''}`,
      3000
    );
    return null;
  }
  else if (payload.code !== 0 || payload.msg !== 'success') {
    notify.error(
      `${payload.msg}` || defMsg,
      3000
    );
    return null;
  }
  else return payload?.data;
}
