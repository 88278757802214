import React from "react";
import styled from "styled-components";
import {LOCAL_URL, THEME} from "../../../config";
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import PrintIcon from '@mui/icons-material/Print';
import NavigationIcon from '@mui/icons-material/Navigation';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {useHistory} from "react-router-dom";

function OrderOperatorMenu(props:{groupId: number}){
  const history = useHistory()
  return(
    <Container>
      <div className='item' onClick={()=>{
        history.push(LOCAL_URL["GROUP_STATIST"], {
          groupId: props.groupId
        })
      }}>
        <div className='icon'>
          <StackedBarChartIcon/>
        </div>
        <div className='title'>
          销售统计
        </div>
      </div>
      <div className='item' onClick={()=>{

      }}>
        <div className='icon' style={{backgroundColor: '#ccc'}}>
          <PrintIcon/>
        </div>
        <div className='title'>
          打印面单
        </div>
      </div>
      <div className='item'>
        <div className='icon' style={{backgroundColor: '#ccc'}}>
          <NavigationIcon/>
        </div>
        <div className='title'>
          规划路线
        </div>
      </div>
      <div className='item' onClick={()=>{
        history.push(LOCAL_URL["ORDER_EXPORT"], {
          groupId: props.groupId
        })
      }}>
        <div className='icon'>
          <FileDownloadIcon/>
        </div>
        <div className='title'>
          导出订单
        </div>
      </div>
    </Container>
  )
}

export default OrderOperatorMenu

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon{
      height: 24px;
      width: 24px;
      color: white;
      background-color: ${THEME.color.primary.default};
      border-radius: 12px;
      text-align: center;
      padding-top: 2px;
      svg{
        font-size: 20px;
        text-align: center;
      }
    }
    .title{
      margin-top: 4px;
      font-size: 8px;
      font-weight: 400;
      align-items: center;
      color: ${THEME.color.gray["#0-087"]};
    }
  }
`
