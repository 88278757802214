import React from "react";
import styled from "styled-components";
import {
  IconRadioButton,
  IconRadioButtonUnChecked,
  IconRadioButtonDisabled, IconRadioButtonCheckedCircle,
} from "../components";
import { THEME } from "../config";

const Container = styled.div`
  display: flex;
  align-items: center;
  &.inline {
    display: inline-flex;
  }
`;
const IconBlock = styled.div`
  background: transparent;
  /* border: none; */
  height: 24px;
  width: 24px;
  margin-right: 8px;

  & svg {
    width: 100%;
    height: 100%;
    fill: ${(props) =>
      props.checked ? THEME.color.primary.default : THEME.color.gray["#3-76"]};
  }
`;

interface RadioProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  label?: string;
  disabled?: boolean;
  [propName: string]: any;
}
export const Radio: React.FC<RadioProps> = ({
  onChange,
  value,
  label,
  disabled,
  ...restProps
}) => {
  const onClick = () => {
    // console.log('onclick')
    if (disabled) return;
    if (!onChange) return;
    onChange(!value);
  };
  return (
    <Container onClick={onClick} {...restProps}>
      <IconBlock checked={!!value}>
        {/* {!!value ? <IconRadioButton /> : <IconRadioButtonUnChecked />} */}
        {disabled ? (
          <IconRadioButtonDisabled />
        ) : !!value ? (
          <IconRadioButtonCheckedCircle />
        ) : (
          <IconRadioButtonUnChecked />
        )}
      </IconBlock>
      <span className="ft-rr16 clr-ft-gray0-087">{label}</span>
    </Container>
  );
};
