import * as React from "react";
import {useEffect, useState} from "react";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import styled from "styled-components";
import {Button, IconKBArrowR, InputField, ListItem, notify,} from "../../components";
import {isEmpty, requestPost} from "../../lib";
import {DEFAULT_CATEGORY_NAME} from "../Category/groupCategory";
import PageLayout from "../Global/PageLayout";
import iconAdd from "../../svg/icon_add.svg";
import {LOCAL_URL, THEME} from "../../config";
import {useHistory} from "react-router-dom";
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import {EntityContext} from "../../context";
import {ProductCard} from "../Products/productTemplates";
import {AddressCard} from "../PersonalCenter/address";
import {useDialog} from "../../hooks";
import useMerchant from "../../hooks/useMerchant";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import Header, {FloatBottom} from "../../components/Header";
import ImageNTextComponent from "../../business/components/ImageNTextComponent";
import TTModal from "../Manage/components/Modal";
import dayjs from "dayjs";
import {Check} from "../../forms/Check";
import {Card, CardActions, Collapse} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WhiteSpace from "../../components/WhiteSpace";
import CustomField from "./Components/CustomField";
import {Switch} from './Components/Switch'
import {scrollToBottom} from "../../utils/common";
import {ChoiceInput} from '../../FormElements'
import {ModalMask} from '../../Layout'
import {VIEW_MODE_LIST} from './common'

const TipsImageSrc = {
  'LARGE-IMAGE': 'https://res.cloudinary.com/shenmanz/image/upload/v1680589056/tuangou/icon/user-help-tips-view-mode-large-image-v2.png',
  'NORMAL': 'https://res.cloudinary.com/shenmanz/image/upload/v1680589055/tuangou/icon/user-help-tips-view-mode-normal.png-v2.png',
}

export interface ICreateGroupBuyProps {
}

const StyledForm = styled.form`
  padding: 8px;

  .tips-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .tips {
    text-align: center;
    margin-bottom: 24px;
  }

  a {
    margin: 0px 8px 0px 8px;
  }

  .flex-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: ${THEME.color.primary.default};
    }
  }
`;
const Divider = styled.div`
  height: 8px;
`;
const AddNewProduct = styled.div`
  background-color: #fff;
  height: 64px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.primary.default};

  div {
    margin-left: 8px;
  }

  margin-top: 16px;
`;
const Line = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.09);
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 8px 8px 8px;
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  .title {
    font-size: 15px;
    color: ${THEME.color.gray["#10-085"]};
  }

  .title .high-light {
    color: ${THEME.color.primary.default};
  }

  .sub-title {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 4px;
  }

  .switch {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .radio {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const StyledPickupAddress = styled.div`
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ErrMsg = styled.p`
  color: ${THEME.color.primary.default};
  line-height: 1.5em;
  margin: 0.5em 0 0.25em;
`;

const schema = yup.object().shape({
  title: yup.string().required("拼团名称不能为空"),
  phone: yup.string().required("手机号不能为空"),
  groupToProductions: yup.array().min(1, "至少选择一个商品"),
  deliveryNotes: yup.string().when("canDelivery", {
    is: true,
    then: (schema) => schema.required("配送说明不能为空"),
  }),
  pickupAddresses: yup.array().when("canPickup", {
    is: true,
    then: (schema) => schema.min(1, "至少选择一个自提地址"),
    otherwise: (schema) => schema.min(0),
  }),
  canDelivery: yup.bool().when("canPickup", {
    is: false,
    then: (schema) => schema.isTrue("至少选择一种配送方式"),
    //otherwise: (schema) => schema.isFalse("至少选择一种配送方式"),
  }),
  bankAccount: yup.string().when("canTranPayment", {
    is: true,
    then: (schema) => schema.required("收款账号不能为空"),
  }),
  // canPickup: yup.bool().when("canDelivery", {
  //   is: false,
  //   then: (schema) => schema.isTrue("至少选择一种配送方式"),
  // }),
});

interface IDisableAutoCancelControlProps {
  value: boolean;
  onChange: (v: boolean) => void;
}

const DisableAutoCancelControl: React.FC<IDisableAutoCancelControlProps> = ({value, onChange}) => {
  return (
    <>
      <div className="flex-between align-center">
        <p style={{fontSize: '15px', color: 'rgba(0,0,0,0.85)'}}>自动取消未支付订单</p>
        <Switch value={value} onChange={onChange}/>
      </div>
      <div style={{marginTop: '8px', lineHeight: '18px', fontSize: '12px', color: 'rgba(0,0,0,0.45)'}}>
        <p>
          开启该选项，订单超过1小时未支付，将被自动取消，并恢复被占用的商品库存；
        </p>
        <p>
          关闭该选项，未支付订单将一直占用商品库存。
        </p>
      </div>
    </>
  )
}
export default function CreateGroupBuy(props: ICreateGroupBuyProps) {
  const history = useHistory<any>();

  const dialogHook = useDialog();

  const [tipsImgUrl, setTipsImgUrl] = useState<string | null>(null)
  const {currentMerchantId, getCurrentMerchant} = useMerchant();
  const [currentMerchant, setCurrentMerchant] = useState<any>({});
  const [showTranAccountModal, setShowTranAccountModal] = useState(false);
  const [categoryProducts, setCategoryProducts] = useState<any>([])
  const {register, handleSubmit, reset, control, watch, errors} = useForm({
    defaultValues: {
      canDelivery: false,
      canPickup: false,
      pickupAddresses: [],
      groupToProductions: [],
      deliveryNotes: "",
      title: "",
      description: "",
      onlinePayment: false,
      viewMode: 'NORMAL'
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    model: entityModel,
    query,
    create,
    remove,
    update,
    replace,
    flush,
  } = React.useContext(EntityContext);
  const groupBuyData = entityModel["GROUP_BUY"]?.response;

  const watchCanDelivery = watch("canDelivery");
  const watchCanPickup = watch("canPickup");
  const watchCanTranPayment = watch("canTranPayment");

  const createNewGroup = async (groupData: any) => {
    const tmpdata = (
      await create("GROUP_BUY", {
        ...groupData,
        id: undefined,
      })
    )
    if (!tmpdata?.id) {
      notify.error("创建失败，请重试！")
      history.go(-1)
    }
    return tmpdata
  }
  const onSumbit = async (data) => {
    if (!data.onlinePayment && !data.canTranPayment && !data.canCash) {
      notify.error("请至少选择一种支付方式");
      return;
    }
    if (groupBuyData.customFields) {
      for (const field of groupBuyData.customFields) {
        if (field.meta) {
          const metaSet = new Set()
          for (const m of field.meta) {
            if (metaSet.has(m)) {
              notify.error("自定义选项配置错误");
              return
            }
            metaSet.add(m)
          }
          if (metaSet.size < 2) {
            notify.error("自定义选项配置错误");
            return
          }
          if (!field.title || field.type.length === 0 || !field.select) {
            notify.error("自定义选项配置错误");
            return
          }
        } else {
          notify.error("自定义选项配置错误");
          return
        }
      }
    }
    let id = data.id;
    if (data.id && !isCopy) {
      await update("GROUP_BUY", {
        ...data,
        merchantId: currentMerchantId,
        category: groupBuyData.category,
        customFields: groupBuyData.customFields
      });
    } else {
      const tempdata = await createNewGroup({
        ...data,
        merchantId: currentMerchantId,
        category: groupBuyData.category,
        customFields: groupBuyData.customFields,
      })
      id = tempdata?.id;
    }
    flush("GROUP_BUY");
    history.replace(LOCAL_URL["GROUP_BUY_DETAIL"] + `?groupId=${id}`, {
      fromCreatePage: true,
    });
  };

  const saveState = () => {
    replace('GROUP_BUY', {
      product: null
    })
    replace("GROUP_BUY", control.getValues());
  };

  useEffect(() => {
    const current = getCurrentMerchant()
    setCurrentMerchant(current);
  }, []);

  const restoreState = async () => {
    const groupBuyData = entityModel["GROUP_BUY"]?.response;
    const merchant = getCurrentMerchant();
    let phone;
    if (typeof groupBuyData.phone === "undefined") {
      if (merchant?.phone && merchant?.phone !== 'NA') {
        phone = merchant.phone;
      }
    } else {
      phone = groupBuyData.phone;
    }

    let bankAccount;
    let bankAccountName;
    if (typeof groupBuyData.bankAccount === "undefined") {
      bankAccount =
        merchant && merchant.finance && merchant.finance.accountNoForClient
          ? merchant.finance.accountNoForClient
          : "";
      bankAccountName =
        merchant && merchant.finance && merchant.finance.accountNameForClient
          ? merchant.finance.accountNameForClient
          : "";
    } else {
      bankAccount = groupBuyData.bankAccount;
      bankAccountName = groupBuyData.bankAccountName;
    }
    // 生成分类信息开始
    const cateGoryMap = new Map<string, any>()
    if (groupBuyData.category) {
      for (const cate of groupBuyData.category) {
        cateGoryMap.set(cate.title, cate)
      }
    }
    if (groupBuyData.groupToProductions && groupBuyData.groupToProductions.length > 0) {
      for (const prod of groupBuyData.groupToProductions) {
        if (prod.category) {
          for (const cate of prod.category) {
            cateGoryMap.set(cate.title, cate)
          }
        }
      }
    }
    cateGoryMap.delete(DEFAULT_CATEGORY_NAME)
    const category = Array.from(cateGoryMap.values())

    replace('GROUP_BUY', {
      category
    })
    // 生成分类信息结束
    reset({
      ...groupBuyData,
      pickupAddresses: groupBuyData.pickupAddresses
        ? groupBuyData.pickupAddresses
        : [],
      phone,
      canCash: groupBuyData.canCash || false,
      canTranPayment: groupBuyData.canTranPayment || false,
      bankAccount,
      bankAccountName,
    });
  };

  const selectProduct = () => {
    saveState();
    history.push(LOCAL_URL["PRODUCT_TEMPLATE"], {selectMode: true});
  };

  const manageCategory = () => {
    saveState()
    history.push(LOCAL_URL["CATEGORY"], {
      type: 'GROUP',
      data: {
        category: groupBuyData.category,
        productions: groupBuyData.groupToProductions
      }
    });
  }

  const products = history?.location?.state?.products;
  const pickupAddress = history?.location?.state?.pickupAddress;
  const groupId = history?.location?.state?.groupId;
  const isCopy = history?.location?.state?.isCopy;

  const selectPickupAddress = () => {
    saveState();
    history.push(LOCAL_URL["MY_ADDRESS"], {type: "PICKUP", selectMode: true});
  };

  React.useEffect(() => {
    restoreState();
  }, [products, pickupAddress]);

  const queryGroupBuyAndRestoreState = async () => {
    let groupBuyData = entityModel["GROUP_BUY"]?.response;
    if (!!!groupBuyData || Number(groupBuyData?.id) !== groupId) {
      await query("GROUP_BUY", {id: groupId});
      groupBuyData = entityModel["GROUP_BUY"]?.response?.data;
      if (!!isCopy) {
        groupBuyData.title = '【复制】' + groupBuyData.title
        groupBuyData.groupToProductions.forEach((x: any) => {
          x.soldQuantity = 0;
          x.available = x.productionTemplate.defaultStock;
        })
        // console.log(groupBuyData)
        const newGroupData = [
          'bankAccount', 'bankAccountName',
          'canCash', 'canDelivery', 'canPickup', 'canTranPayment',
          'title', 'description', 'onlinePayment',
          'phone', 'pickupAddresses', 'pickupNotes',
          'category', 'customFields', 'viewMode', 'disableAutoCancel',
          'groupToProductions',
          'startAt', 'endAt',
        ].reduce((accu: any, key: string) => {
          accu[key] = groupBuyData[key]
          return accu
        }, {
          merchantId: currentMerchantId,
        })
        newGroupData.startAt = dayjs().add(3, 'day').startOf('day').format()
        newGroupData.endAt = dayjs().add(3 + 15, 'day').endOf('day').format()
        const tempdata = await createNewGroup(newGroupData)
        flush("GROUP_BUY");
        history.replace(LOCAL_URL["CREATE_GROUP_BUY"], {
          groupId: tempdata?.id,
        });
        return
      }
      reset(groupBuyData);
      replace("GROUP_BUY", groupBuyData);
    }
  };

  React.useEffect(() => {
    if (groupId) {
      queryGroupBuyAndRestoreState();
    }
  }, [groupId]);

  const removeProduct = (id) => {
    const groupBuyData = entityModel["GROUP_BUY"]?.response;
    const {groupToProductions} = groupBuyData;

    control.setValue(
      "groupToProductions",
      groupToProductions.filter((gp) => gp.id !== id)
    );
    replace("GROUP_BUY", {
      groupToProductions: groupToProductions.filter((gp) => gp.id !== id),
    });
  };

  /**
   * 交换产品顺序，
   * 1，无分类信息或者只有一个分类，则产品顺序与该分类下产品顺序一致，传参时category为null
   * 2，多个分类信息，传参时，category为点击产品对应的分类
   * **/
  const swapProduct = (category, idx1, idx2) => {
    if (category === null) {
      const groupBuyData = entityModel["GROUP_BUY"]?.response;
      const {groupToProductions} = groupBuyData;

      const temp = groupToProductions[idx1];
      groupToProductions[idx1] = groupToProductions[idx2];
      groupToProductions[idx2] = temp;
      control.setValue("groupToProductions", groupToProductions);
      replace("GROUP_BUY", {groupToProductions});
    } else {
      const newResult = []
      const newProdList = []
      for (const finalCate of categoryProducts) {
        const _products = finalCate.products
        if (finalCate.title === category.title) {
          const temp1 = _products[idx1];
          const temp2 = _products[idx2];
          _products[idx1] = _products[idx2];
          _products[idx2] = temp1;
          newResult.push({
            ...finalCate,
            products: _products
          })
        } else {
          newResult.push(finalCate)
        }
        newProdList.push(..._products)
      }
      setCategoryProducts(newResult)
      control.setValue("groupToProductions", newProdList);
      replace("GROUP_BUY", {groupToProductions: newProdList});
    }

  };

  useEffect(() => {
    const finalResult = []
    const category = groupBuyData.category ?? []
    const nameSet = new Set()
    const filteredCategory = []
    for (const cate1 of category) {
      if (!nameSet.has(cate1.title)) {
        nameSet.add(cate1.title)
        filteredCategory.push(cate1)
      }
    }
    if (groupBuyData.groupToProductions) {
      for (const cate of filteredCategory) {
        const containProducts = []
        for (const product of groupBuyData.groupToProductions) {
          if (product.category) {
            if (product.category.filter(cateItem => cateItem.title === cate.title).length > 0) {
              if (containProducts.filter(item => item.productionTemplate.title === product.productionTemplate.title).length === 0) {
                containProducts.push(product)
              }
            }
          }else {
            containProducts.push(product)
          }
        }
        finalResult.push({
          ...cate,
          products: containProducts
        })
      }
      // 处理未分类
      const unCategoryProds = []
      for (const product of groupBuyData.groupToProductions) {
        if (!product.category || product.category.length === 0) {
          unCategoryProds.push(product)
        }
      }
      if (unCategoryProds.length > 0) {
        finalResult.push({
          title: DEFAULT_CATEGORY_NAME,
          products: unCategoryProds
        })
      }
    } else {
      finalResult.push({
        products: []
      })
    }
    setCategoryProducts(finalResult)
  }, [groupBuyData])


  return (
    <div>
      <PageLayout pageName="create group buy">
        <Header title=""/>
        <StyledForm onSubmit={handleSubmit(onSumbit)}>
          <input ref={register} name="id" style={{display: "none"}}/>
          <input
            ref={register}
            name="bankAccountName"
            style={{display: "none"}}
          />
          <InputField
            label="拼团名称"
            register={register}
            type="text"
            name="title"
            required
            placeholder="请输入拼团名称"
            errors={errors}
          />
          <Divider/>
          <InputField
            label="团长联系方式"
            register={register}
            type="text"
            name="phone"
            required
            placeholder="请输入团长手机号"
            errors={errors}
          />
          <Divider/>
          <div style={{marginBottom: 8}}>拼团介绍</div>
          <Controller
            control={control}
            name="description"
            render={({value, onChange}) => (
              <ImageNTextComponent value={value} onChange={onChange}/>
            )}
          />

          <Divider/>
          <WhiteSpace/>
          <div className="flex-space-between">
            <label>
              <span style={{color: THEME.color.gray["#11-065"]}}>拼团商品</span>
              <span style={{color: THEME.color.primary.default}}>*</span>
            </label>
            <span className="text" onClick={manageCategory}>
              管理该团购分类
            </span>
          </div>
          <WhiteSpace/>
          <div>
            {!errors || !errors["groupToProductions"] ? null : (
              <ErrMsg className="ft-rr12">
                {Object(errors["groupToProductions"]).message}
              </ErrMsg>
            )}
          </div>
          {categoryProducts.length === 1 ? (
            <Controller
              control={control}
              name="groupToProductions"
              render={({value}) =>
                value && value.length > 0 ? (
                  <div
                    style={{
                      background: "#fff",
                      padding: "8px 0 8px 0",
                      marginTop: 8,
                    }}
                  >
                    {value.map((gp, index) => (
                      <ProductCard
                        dialogHook={dialogHook}
                        gpId={gp.id}
                        showOpContainer
                        key={gp.id}
                        idx={index}
                        size={value.length}
                        soldQuantity={gp.soldQuantity}
                        available={gp.available}
                        product={gp.productionTemplate}
                        onRemoveFromGroup={(id) => {
                          removeProduct(id);
                        }}
                        onEditClick={(id) => {
                          saveState();
                          const {groupToProductions} = groupBuyData;
                          const groupToProduction = groupToProductions.filter(
                            (gp) => gp.id === id
                          )[0];
                          const productionTemplate =
                            groupToProduction.productionTemplate;
                          history.push(LOCAL_URL["CREATE_PRODUCT"], {
                            fromGroupPage: true,
                            groupId: groupToProduction.groupId,
                            values: {
                              ...productionTemplate,
                              soldQuantity: groupToProduction.soldQuantity,
                              available: groupToProduction.available,
                              groupToProductionId: groupToProduction.id,
                            },
                          });
                        }}
                        onCopyClick={(id) => {
                          saveState();
                          const {groupToProductions} = groupBuyData;
                          const groupToProduction = groupToProductions.filter(
                            (gp) => gp.id === id
                          )[0];
                          delete groupToProduction.templateId
                          const productionTemplate =
                            groupToProduction.productionTemplate;

                          let groupToProductionId = new Date().getTime();

                          replace("GROUP_BUY", {
                            groupToProductions: [
                              ...groupToProductions,
                              {
                                ...groupToProduction,
                                id: groupToProductionId,
                              },
                            ],
                          });

                          history.push(LOCAL_URL["CREATE_PRODUCT"], {
                            fromGroupPage: true,
                            values: {
                              ...productionTemplate,
                              groupToProductionId,
                              soldQuantity: 0,
                              available: productionTemplate.defaultStock,
                            },
                          });
                        }}
                        onSwap={(idx1, idx2) => swapProduct(null, idx1, idx2)}
                      />
                    ))}
                  </div>
                ) : null
              }
            />
          ) : (
            <Controller
              control={control}
              name="groupToProductions"
              render={() => {
                return (
                  <div>
                    {categoryProducts.map((category, cateIndex) => {
                      return (
                        <div key={category.title}>
                          <div style={{
                            borderLeftColor: THEME.color.primary.default,
                            borderLeftWidth: 2,
                            borderLeftStyle: "solid",
                            paddingLeft: 8,
                            marginTop: 16
                          }}>{category.title}</div>
                          {category.products.length > 0 ? category.products.map((gp, index) => {
                            return (
                              <div key={gp.id}>
                                <ProductCard
                                  dialogHook={dialogHook}
                                  gpId={gp.id}
                                  showOpContainer
                                  idx={index}
                                  size={category.products.length}
                                  soldQuantity={gp.soldQuantity}
                                  available={gp.available}
                                  product={gp.productionTemplate}
                                  onRemoveFromGroup={(id) => {
                                    removeProduct(id);
                                  }}
                                  onEditClick={(id) => {
                                    saveState();
                                    const {groupToProductions} = groupBuyData;
                                    const groupToProduction = groupToProductions.filter(
                                      (gp) => gp.id === id
                                    )[0];
                                    const productionTemplate =
                                      groupToProduction.productionTemplate;

                                    replace("GROUP_BUY", {
                                      product: groupToProduction
                                    });
                                    history.push(LOCAL_URL["CREATE_PRODUCT"], {
                                      fromGroupPage: true,
                                      groupId: groupToProduction.groupId,
                                      values: {
                                        ...productionTemplate,
                                        category: groupToProduction.category,
                                        soldQuantity: groupToProduction.soldQuantity,
                                        available: groupToProduction.available,
                                        groupToProductionId: groupToProduction.id,
                                      },
                                    });
                                  }}
                                  onCopyClick={(id) => {
                                    saveState();
                                    const {groupToProductions} = groupBuyData;
                                    const groupToProduction = groupToProductions.filter(
                                      (gp) => gp.id === id
                                    )[0];
                                    delete groupToProduction.templateId
                                    const productionTemplate =
                                      groupToProduction.productionTemplate;

                                    let groupToProductionId = new Date().getTime();

                                    replace("GROUP_BUY", {
                                      groupToProductions: [
                                        ...groupToProductions,
                                        {
                                          ...groupToProduction,
                                          id: groupToProductionId,
                                        },
                                      ],
                                      product: groupToProduction
                                    });

                                    history.push(LOCAL_URL["CREATE_PRODUCT"], {
                                      fromGroupPage: true,
                                      values: {
                                        category: groupToProduction.category,
                                        ...productionTemplate,
                                        groupToProductionId,
                                        soldQuantity: 0,
                                        available: productionTemplate.defaultStock,
                                      },
                                    });
                                  }}
                                  onSwap={(idx1, idx2) => swapProduct(category, idx1, idx2)}
                                />
                              </div>
                            )
                          }) : (
                            <div style={{
                              padding: 16,
                              textAlign: 'center',
                            }}>
                              -暂无产品-
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )
              }}/>
          )}
          <div style={{
            display: "flex"
          }}>
            <AddNewProduct
              onClick={() => {
                saveState();
                history.push(LOCAL_URL["CREATE_PRODUCT"], {
                  fromGroupPage: true,
                });
              }}
            >
              <img src={iconAdd}/>
              <div>创建新商品</div>
            </AddNewProduct>
            <AddNewProduct
              onClick={selectProduct}
            >
              <img src={iconAdd}/>
              <div>从商品库添加</div>
            </AddNewProduct>
          </div>

          <Divider/>
          <label>商品卡片样式</label>
          <Divider/>
          <StyledCard>
            <Controller
              control={control}
              name="viewMode"
              render={(field) => (
                <ChoiceInput {...field} ref={undefined}
                             handleTips={{
                               'LARGE-IMAGE': () => setTipsImgUrl(TipsImageSrc['LARGE-IMAGE']),
                               'NORMAL': () => setTipsImgUrl(TipsImageSrc['NORMAL']),
                             }}
                             required
                             type='SINGLE'
                             options={VIEW_MODE_LIST}
                />
              )}
            />
          </StyledCard>

          <Divider/>
          <label>拼团设置</label>
          <Divider/>
          <StyledCard>
            <Controller
              control={control}
              name="startAt"
              render={({onChange, value}) => (
                <MobileDateTimePicker
                  value={isCopy ? dayjs().format() : value || dayjs().format()}
                  onChange={onChange}
                  renderInput={(params: any) => {
                    const {inputProps} = params;
                    const {onClick, value} = inputProps;
                    onChange(value);
                    return (
                      <ListItem
                        onPress={onClick}
                        title="开团时间"
                        required
                        comments={dayjs(value).format("DD/MM/YYYY HH:mm")}
                      />
                    );
                  }}
                />
              )}
            />
            <Line/>
            <Controller
              control={control}
              name="endAt"
              render={({onChange, value}) => (
                <MobileDateTimePicker
                  value={
                    isCopy
                      ? dayjs().add(7, "day").format()
                      : value || dayjs().add(7, "day").format()
                  }
                  onChange={onChange}
                  renderInput={(params: any) => {
                    const {inputProps} = params;
                    const {onClick, value} = inputProps;
                    onChange(value);
                    return (
                      <ListItem
                        onPress={onClick}
                        title="截团时间"
                        required
                        comments={dayjs(value).format("DD/MM/YYYY HH:mm")}
                      />
                    );
                  }}
                />
              )}
            />
          </StyledCard>

          <Divider/>
          <label>支付方式</label>
          <Divider/>
          <StyledCard>
            <Controller
              control={control}
              name="onlinePayment"
              render={({value, onChange}) => (
                <Item>
                  <div>
                    <div className="title">
                      在线支付
                      <span className="high-light">（认证商户可用）</span>
                    </div>
                    <div className="sub-title">
                      纽币结算，支持网银，信用卡，微信，支付宝等{" "}
                      <span
                        onClick={() => {
                          history.push(LOCAL_URL['CUSTOMER_SUPPORT'])
                        }}
                        style={{color: THEME.color.primary.default}}>
                        联系客服开通
                      </span>
                    </div>
                    <div className="radio">
                      {/* <Switch value={value} onChange={onChange} /> */}
                      {/* <Radio
                        value={value}
                        onChange={onChange}
                        disabled={getCurrentMerchant().authentication}
                      /> */}
                      <Check
                        disabled={!getCurrentMerchant().authentication}
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </Item>
              )}
            />
            <Line/>
            {currentMerchant &&
              currentMerchant.finance &&
              currentMerchant.finance.paymentMethod &&
              currentMerchant.finance.paymentMethod.includes("CASH") && (
                <div>
                  <Controller
                    control={control}
                    name="canCash"
                    render={({value, onChange}) => (
                      <Item>
                        <div>
                          <div className="title">现金/到店支付</div>
                          <div className="sub-title"></div>
                          <div className="radio">
                            <Check
                              onChange={async (value) => {
                                onChange(value);
                              }}
                              value={value}
                            />
                          </div>
                        </div>
                      </Item>
                    )}
                  />
                  <Line/>
                </div>
              )}
            {currentMerchant &&
              currentMerchant.finance &&
              currentMerchant.finance.paymentMethod &&
              currentMerchant.finance.paymentMethod.includes("TRANSFER") && (
                <div>
                  <Controller
                    control={control}
                    name="canTranPayment"
                    render={({value, onChange}) => (
                      <Item>
                        <div>
                          <div className="title">手工转账</div>
                          <div className="sub-title">
                            用户转账到您的收款银行账号
                          </div>
                          <div className="radio">
                            <Check onChange={onChange} value={value}/>
                          </div>
                        </div>
                      </Item>
                    )}
                  />
                  {watchCanTranPayment && (
                    <>
                      <Line/>
                      <Controller
                        control={control}
                        name="bankAccount"
                        render={({onChange, value}) => {
                          return (
                            <ListItem
                              onPress={() => {
                                saveState();
                                history.push(LOCAL_URL["TRANS_ACCOUNT"]);
                              }}
                              title="收款账号"
                              required
                              comments={value}
                            />
                          );
                        }}
                      />
                    </>
                  )}
                  {!errors || !errors["bankAccount"] ? null : (
                    <ErrMsg className="ft-rr12">
                      {Object(errors["bankAccount"]).message}
                    </ErrMsg>
                  )}
                </div>
              )
            }
            <Line/>
            <Controller
              control={control}
              name="disableAutoCancel"
              render={({onChange, value}) => {
                return (
                  <DisableAutoCancelControl value={!value} onChange={(v: boolean) => onChange(!v)}/>
                );
              }}
            />

          </StyledCard>

          <Divider/>
          <label>
            物流方式（可多选）
            <span style={{color: THEME.color.primary.default}}>*</span>
            {!errors || !errors["canDelivery"] ? null : (
              <ErrMsg className="ft-rr12">
                {Object(errors["canDelivery"]).message}
              </ErrMsg>
            )}
          </label>
          <Divider/>
          <StyledCard>
            <Controller
              control={control}
              name="canDelivery"
              render={({value, onChange}) => (
                <Item>
                  <div>
                    <div className="title">配送到家</div>
                    <div className="radio">
                      <Check onChange={onChange} value={value}/>
                    </div>
                  </div>
                </Item>
              )}
            />
            <Line/>
            <Controller
              control={control}
              name="canPickup"
              render={({value, onChange}) => (
                <Item>
                  <div>
                    <div className="title">自提</div>
                    <div className="radio">
                      <Check onChange={onChange} value={value}/>
                    </div>
                  </div>
                </Item>
              )}
            />
          </StyledCard>

          <Divider/>
          {watchCanDelivery && (
            <InputField
              textarea
              label="配送说明"
              required
              register={register}
              type="text"
              name="deliveryNotes"
              placeholder="请输入配送说明"
              errors={errors}
            />
          )}
          {watchCanPickup && (
            <InputField
              textarea
              label="自提说明"
              register={register}
              type="text"
              name="pickupNotes"
              placeholder="请输入自提说明"
              errors={errors}
            />
          )}
          {watchCanPickup && (
            <StyledCard>
              <Controller
                control={control}
                name="pickupAddresses"
                render={({value, onChange}) => (
                  <StyledPickupAddress>
                    <div className="flex-row">
                      <span>
                        选择自提点{" "}
                        <span style={{color: THEME.color.primary.default}}>
                          *
                        </span>
                      </span>
                      <div className="flex-row" onClick={selectPickupAddress}>
                        <span>已选择{value?.length}个</span>
                        <div style={{width: 24, height: 24}}>
                          <IconKBArrowR/>
                        </div>
                      </div>
                    </div>
                    <div>
                      {!errors || !errors["pickupAddresses"] ? null : (
                        <ErrMsg className="ft-rr12">
                          {Object(errors["pickupAddresses"]).message}
                        </ErrMsg>
                      )}
                    </div>
                    <Line/>
                    {value &&
                      value.map((v) => <AddressCard address={v} disable/>)}
                  </StyledPickupAddress>
                )}
              />
            </StyledCard>
          )}
          <WhiteSpace height={16}/>
          <Card>
            <CardActions disableSpacing onClick={() => {

              if (!groupBuyData.showAdvancedSetting) {
                setTimeout(() => {
                  scrollToBottom()
                }, 300)
              }
              replace('GROUP_BUY', {
                showAdvancedSetting: !groupBuyData.showAdvancedSetting
              })
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
                <div style={{
                  fontSize: 15,
                  color: THEME.color.gray["#12-045"],
                  flex: 1
                }}>
                  <span style={{color: '#000000', fontWeight: 400}}>高级设置</span>（自定义选项、分享文案等）
                </div>
                <div style={{width: 25}}>
                  {groupBuyData.showAdvancedSetting ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </div>
              </div>

            </CardActions>

          </Card>
          <Collapse in={groupBuyData.showAdvancedSetting} timeout="auto" unmountOnExit>
            <WhiteSpace height={16}/>
            <label>
              自定义选项
            </label>
            <WhiteSpace height={10}/>
            {groupBuyData?.customFields?.map((field, index) => {
              return (
                <CustomField
                  onBeforeNav={() => {
                    saveState();
                  }}
                  index={index}
                  key={`${index}`}
                  value={field}
                  onChange={(value) => {
                    const result = []
                    for (let i = 0; i < groupBuyData.customFields.length; i++) {
                      if (i === index) {
                        result.push(value)
                      } else {
                        result.push(groupBuyData.customFields[i])
                      }
                    }
                    replace('GROUP_BUY', {
                      customFields: result
                    })
                  }}
                  onDelete={() => {
                    const result = []
                    for (let i = 0; i < groupBuyData.customFields.length; i++) {
                      if (i !== index) {
                        result.push(groupBuyData.customFields[i])
                      }
                    }
                    replace('GROUP_BUY', {
                      customFields: result
                    })
                  }}
                />
              )
            })}

            <AddNewProduct
              onClick={() => {
                if (groupBuyData.customFields) {
                  replace('GROUP_BUY', {
                    customFields: [
                      ...groupBuyData.customFields,
                      {
                        title: '',
                        type: [],
                        select: '',
                        meta: ['', '']
                      },
                    ]
                  })
                } else {
                  replace('GROUP_BUY', {
                    customFields: [
                      {
                        title: '',
                        type: [],
                        select: '',
                        meta: ['', '']
                      },
                    ]
                  })
                }

              }}
            >
              <img src={iconAdd} alt={'add'}/>
              <div>添加自定义选项</div>
            </AddNewProduct>
            <WhiteSpace height={24}/>
            <label>
              微信分享设置
            </label>
            <WhiteSpace/>
            <InputField
              label="标题"
              register={register}
              type="text"
              name="wechatTitle"
              placeholder="请输入标题"
            />
            <Divider/>
            <InputField
              textarea
              label="副标题"
              register={register}
              type="text"
              name="wechatContent"
              placeholder="请输入副标题"
            />
          </Collapse>
          <FloatBottom>
            <Button fullWidth type="submit" size="large" color="red">
              立即发布
            </Button>
          </FloatBottom>
        </StyledForm>
        <TTModal
          show={showTranAccountModal}
          title="收款银行账号"
          content="您还没有设置收款银行账号"
          confirmText="现在设置"
          onConfirm={async () => {
            saveState();
            history.push(LOCAL_URL["TRANS_ACCOUNT"]);
          }}
          onCancel={() => {
            setShowTranAccountModal(false);
          }}
        />

        <ModalMask
          visible={!!tipsImgUrl}
          onClick={() => setTipsImgUrl(null)}
        >
          <div style={{width: '100%', height: '100%', display: 'grid', placeItems: 'center'}}>
            <img src={tipsImgUrl} alt="tips" width="100%"/>
          </div>
        </ModalMask>

        {dialogHook?.renderDialog?.()}

      </PageLayout>

      <ModalMask visible={!!isCopy}>
        <div style={{
          width: '100%',
          height: '100%',
          display: 'grid',
          placeItems: 'center',
          fontSize: '1.4rem',
          color: 'white',
        }}>
          复制中，请稍后 ...
        </div>
      </ModalMask>
    </div>
  );
}
