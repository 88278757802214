import * as React from "react";
import { Button } from "../../components/Button";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

export interface IContactCustomerServiceProps {}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 16px;
  .title {
    margin-top: 80px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 17px;
  }
  .desc {
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 8px;
    font-size: 15px;
    margin-bottom: 16px;
  }
  img {
    align-self: center;
    margin-top: 40px;
  }
  .am-button-ghost {
    color: #cc1543;
    border: 1px solid #cc1543;
  }
`;
export default function CustomerService(props: IContactCustomerServiceProps) {
  const history = useHistory();
  return (
    <Root>
      <div className="desc">请联系神马客服付款</div>
      <img src="/static/tuan-service-wechat.png" alt="" />
      <div className="desc">微信扫码联系客服</div>
      <Button variant="frame" fullWidth onClick={() => history.goBack()}>
        返回团购接龙
      </Button>
    </Root>
  );
}
