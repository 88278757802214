import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { THEME } from "../config";
import { EntityContext } from "../context";
import { IconKBArrowR, ListIcon } from "./Icons";

const ListItemContainer = styled.div`
  height: 36px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  & svg {
    width: 26px;
    fill: ${THEME.color.gray["#12-045"]};
  }

  & div {
    display: flex;
    align-items: center;
  }

  & div div {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 8px;
    background: ${THEME.color.primary.default};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & div div svg {
    fill: #fff;
  }
  .title {
    width: 100px;
  }
`;

interface ListItemProps {
  title: string;
  required?: boolean;
  comments?: string;
  hint?: string;
  icon?: any;
  url?: string;

  [propName: string]: any;
}

export const ListItem: React.FC<ListItemProps> = ({
  title,
  hint,
  comments,
  icon,
  url,
  onPress,
  required,
  ...restProps
}) => {
  const history = useHistory();
  const { model: entityModel, query } = useContext(EntityContext);

  const onClick = () => {
    history.push({
      pathname: url,
      state: {
        context: { ...restProps },
      },
    });
  };

  // useEffect(() => {
  //   if (!entityModel['MY_CARDS'].ready) query('MY_CARDS');
  // }, [])

  //const anyBankCard = !!entityModel['MY_CARDS'].response?.data?.length;
  return (
    <ListItemContainer onClick={onPress || onClick}>
      <div className="title ft-rr15 clr-ft-000">
        {icon && (
          <div>
            <ListIcon type={icon} />
          </div>
        )}
        {title}
        {required ? (
          <span style={{ color: THEME.color.primary.default }}>*</span>
        ) : null}
      </div>
      <div style={{ maxWidth: "75%" }}>
        {!!hint && !comments && (
          <span
            className="ft-rr15 clr-ft-gray12-45"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {hint}
          </span>
        )}
        {!!comments && (
          <span
            className="ft-rr15 clr-ft-gray12-45"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "#000",
            }}
          >
            {comments}
          </span>
        )}

        {/* {(!anyBankCard) && (title === 'My Wallet') &&
          <span className="ft-rr12 clr-ft-primary">
            <svg width="8" height="8" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="4" fill="#CC1543" />
            </svg>
            Add a card
          </span>
        } */}
        <span className="ft-rr15 clr-ft-gray12-45" style={{ height: 26 }}>
          <IconKBArrowR />
        </span>
      </div>
    </ListItemContainer>
  );
};
