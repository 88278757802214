import styled from 'styled-components'
import { CustomTheme } from './Theme'

const Wrapper = styled.div`
  min-height: 100vh;
  padding-top: ${props => props.theme.size.header.height};
  background-color:  ${props => props.theme.color.bgNormal.HEX};
`

export const Layout = ({children, ...rest}) => {
  return (
    <CustomTheme>
      <Wrapper {...rest}>
        { children }
      </Wrapper>
    </CustomTheme>
  )
}
