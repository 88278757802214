import React, { useEffect, useState, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { THEME } from '../../config';

const ControllerContainer = styled.div`
  width: 100%;
`;

const CodeGroup = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
`;
const InputContainer = styled.div`
  width: 56px;
  height: 64px;
  border: 1px solid ${THEME.color.gray['#6-d9']};
  border-radius: 4px;
  background: #fff;
  padding: 2px;

  &:focus-within,
  &.filled {
    border: 1px solid ${THEME.color.gray['#0-087']};
  }
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  
  text-align: center;

  border: none;
  font-family: Roboto;
  font-size: 24px;
  color: ${THEME.color.gray['#0-087']};
  &:focus {
    border: none;
    outline: none;
  }
`;

export const InputPasscode = ({errMsg, value, onChange}) => {
  const [code, setCode] = useState(['', '', '', '']);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const inputRefs = [ref1, ref2, ref3, ref4];

  useEffect(() => {
    // console.log('input effect', value, code)
    if (value === code.join('')) return;
    else if (!!value) setCode([value[0] || '', value[1] || '', value[2] || '', value[3] || ''])
    else setCode(['', '', '', ''])
  }, [value]);

  const onInputChange = (index) => (e) => {
    const c = e.target.value;
    if (!!c) {
      code[index] = c;
      setCode([...code]);
      if (index < 3) inputRefs[index+1].current.focus();
      onChange(code.join(''));
    }
  }

  const onFocus = (index) => (e) => {
    // console.log(index, e);
    code[index] = '';
    setCode([...code]);
  }

  // console.log(errMsg)
  return (
    <ControllerContainer>
      <CodeGroup>
      { [1,2,3,4].map((n, index) => (
        <InputContainer key={index} className={!code[index] ? '' : 'filled'}>
          <Input type="number"inputMode="numeric" pattern="[0-9]*" autoFocus={n === 1} value={code[index] || ''} onFocus={onFocus(index)} onChange={onInputChange(index)} ref={inputRefs[index]} />
        </InputContainer>
      )) } 
      </CodeGroup>

      {/* <p style={{marginTop: '4px'}} className="ft-rr12 clr-ft-primary" >hello</p> */}
      {!!errMsg && <p style={{marginTop: '4px'}} className="ft-rr12 clr-ft-primary" >{errMsg}</p> } 
      
    </ControllerContainer>
  )
}
