// 小房子
// export const IconHome = () => (
//   <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M7.99998 16V11H12V16C12 16.55 12.45 17 13 17H16C16.55 17 17 16.55 17 16V8.99997H18.7C19.16 8.99997 19.38 8.42997 19.03 8.12997L10.67 0.599971C10.29 0.259971 9.70998 0.259971 9.32998 0.599971L0.969976 8.12997C0.629976 8.42997 0.839976 8.99997 1.29998 8.99997H2.99998V16C2.99998 16.55 3.44998 17 3.99998 17H6.99998C7.54998 17 7.99998 16.55 7.99998 16Z" />
//   </svg>
// )
// 放大镜
export const IconSearch = () => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 11H11.71L11.43 10.73C12.63 9.33002 13.25 7.42002 12.91 5.39002C12.44 2.61002 10.12 0.390015 7.32001 0.0500152C3.09001 -0.469985 -0.469985 3.09001 0.0500152 7.32001C0.390015 10.12 2.61002 12.44 5.39002 12.91C7.42002 13.25 9.33002 12.63 10.73 11.43L11 11.71V12.5L15.25 16.75C15.66 17.16 16.33 17.16 16.74 16.75C17.15 16.34 17.15 15.67 16.74 15.26L12.5 11ZM6.50002 11C4.01002 11 2.00002 8.99002 2.00002 6.50002C2.00002 4.01002 4.01002 2.00002 6.50002 2.00002C8.99002 2.00002 11 4.01002 11 6.50002C11 8.99002 8.99002 11 6.50002 11Z" />
  </svg>
)
// 订单
// export const IconOrder = () => (
//   <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M9 6H13C13.55 6 14 5.55 14 5C14 4.45 13.55 4 13 4H9C8.45 4 8 4.45 8 5C8 5.55 8.45 6 9 6ZM9 10H13C13.55 10 14 9.55 14 9C14 8.45 13.55 8 13 8H9C8.45 8 8 8.45 8 9C8 9.55 8.45 10 9 10ZM9 14H13C13.55 14 14 13.55 14 13C14 12.45 13.55 12 13 12H9C8.45 12 8 12.45 8 13C8 13.55 8.45 14 9 14ZM4 4H6V6H4V4ZM4 8H6V10H4V8ZM4 12H6V14H4V12ZM17 0H1C0.45 0 0 0.45 0 1V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V1C18 0.45 17.55 0 17 0ZM16 16H2V2H16V16Z" />
//   </svg>
// )
// 用户
// export const IconAccount = () => (
//   <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM10 17.2C7.5 17.2 5.29 15.92 4 13.98C4.03 11.99 8 10.9 10 10.9C11.99 10.9 15.97 11.99 16 13.98C14.71 15.92 12.5 17.2 10 17.2Z" />
//   </svg>
// )
