import React from 'react';
import Dialog from '@mui/material/Dialog';
import {ScreenMaskDialog, NavMenu} from '../../components';
import {ComingSoon} from './ComingSoon';
import {useHistory} from "react-router-dom";
import {LOCAL_URL} from "../../config";
// import FinalResult from '../Result/Congratulations.register';
// import ShareLocation from '../Location/Share';

export type PopType = 'DRAWER_NAV_BAR' | 'DRAWER_LOGIN' | 'DIALOG_REGISTER' | 
                      // 'MASK_REGISTER_RESULT' | 'DIALOG_LOCATION' | 'DIALOG_REG_SHARE_LOCATION' | 
                      'DIALOG_LOGIN' | 'DIALOG_PWD_RST' | 'MASK_COMING_SOON' | 'SORRY_MOBILE_ONLY' | 'REFERRAL';

interface PopActionsProps {
  pageName?: string;
  isPCMode?: boolean;
  open?: PopType;
  initStep?: number,
  setOpen?: (value: PopType) => void;
  onClose?: () => void;
  onBarsClick?: () => void;
}

export const PopActions = (props: PopActionsProps) => {
  const {pageName, isPCMode, open, setOpen, onClose, onBarsClick} = props;
  const history = useHistory()
  return (
    // (open === 'MASK_REGISTER_RESULT') ?
    // <ScreenMaskDialog open={true} onClose={onClose
    //   // () => setOpen('DIALOG_REG_SHARE_LOCATION')
    // }>
    //   <FinalResult/>
    // </ScreenMaskDialog>
    // :
    // ((open === 'DIALOG_LOCATION') || (open === 'DIALOG_REG_SHARE_LOCATION')) ?
    // <Dialog open={true} onClose={onClose}>
    //   <ShareLocation onClose={onClose} variant={(open === 'DIALOG_LOCATION') ? 'location' : 'share-only'}/>
    // </Dialog>
    // :
    (open === 'MASK_COMING_SOON') ?
    <ScreenMaskDialog open={true} onClose={onClose}>
      <ComingSoon/>
    </ScreenMaskDialog>
    :
    null
  )
}
