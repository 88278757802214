import React, {useContext, useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {isWeixinBrowser} from "../../utils/common";
import {domain, LOCAL_URL, wxWebAppId} from "../../config";
import {Button} from "../../components";
import logo from "../../logo.svg";
import {getRedirectUrl} from "../../utils/persistence";
import {useRouter} from "../../hooks/router";
import {EntityContext, UserContext} from "../../context";
import dayjs from "dayjs";

function WechatLogin() {
  const [processing, setProcessing] = useState(false);
  const {
    model: userModel,
    login,
    mockLogin,
    isAnonymous,
    isReady,
  } = useContext(UserContext);
  const router = useRouter();
  const refName = useRef<any>(null)
  const refPass = useRef<any>(null)

  const loginAction = async () => {
    await login({
      code: router.query.code,
    });
    window.location.href = getRedirectUrl() + "#" + dayjs().valueOf();
  };

  const onMockLogin = async () => {
    await mockLogin({
      name: refName.current.value,
      password: refPass.current.value,
    });
  };

  useEffect(() => {
    if (router.query.code) {
      setProcessing(true);
      loginAction();
    }
  }, [router.query.code]);

  useEffect(() => {
    if (!isAnonymous && router.query.path) {
      router.replace(router.query.path);
    } else if (!isAnonymous && isReady) {
      router.push('/')
    }
  }, [userModel]);

  const isIntegrationCommit = ((process.env.REACT_APP_VERCEL_GIT_COMMIT_REF?.indexOf('integration') ?? -1) > 0);
  const isDevEnv = (process.env.NODE_ENV === "development")
  return (
    <>
      <LogoContainer>
        <img className="logo" src="/logo.png" alt="Logo"/>
        <div style={{marginTop: 15}}>
          <img className="sologan" src="/sologan.png" alt=""/>
        </div>
        {/* <div style={{ marginTop: 15 }}>
          <div>神马团购</div>
        </div> */}
      </LogoContainer>
      <LoginContainer>
        <div className="title">欢迎登陆大吉</div>
        <div style={{height: 60}}/>
        {isWeixinBrowser && (
          <div
            style={{
              backgroundColor: processing ? "#ccc" : "rgb(11, 222, 110)",
              color: "white",
              height: 40,
              width: "70%",
              display: "flex",
              lineHeight: "40px",
              fontSize: "18px",
              borderRadius: "10px",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            onClick={() => {
              if (processing) {
                return;
              }
              // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wxWebAppId}&redirect_uri=${encodeURI(
              //   `${domain}${LOCAL_URL["USER_LOGIN"]}`
              // )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
              window.location.href = `https://wechat.shenmanz.com?callback=${encodeURI(
                `${domain}${LOCAL_URL["USER_LOGIN"]}`
              )}`;
              setProcessing(true);
            }}
          >
            <i
              style={{fontSize: "26px", marginRight: 10}}
              className="iconfont icon-wechat-fill"
            />
            <span>{processing ? "正在登录中..." : "微信快捷登录"}</span>
          </div>
        )}
        {(isIntegrationCommit || isDevEnv) &&
            <div className="flex-center direction-col" style={{padding: '2rem 7rem 0'}}>
                <input type="text" ref={refName}/>
                <input type="text" ref={refPass}/>
                <Button onClick={onMockLogin}>Mock Login</Button>
            </div>
        }
        <div style={{height: 150}}/>
      </LoginContainer>
    </>
  );
}

export default WechatLogin;

const LogoContainer = styled.div`
  margin-top: 40px;
  text-align: center;

  .logo {
    width: 16%;
    display: inline-block;
  }

  .sologan {
    display: inline-block;
    width: 45%;
  }
`;
const LoginContainer = styled.div`
  margin-top: 60px;
  font-size: 16px;
  text-align: center;

  .title {
    text-align: center;
    font-size: 20px;
  }

  .phone-login {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }

  .login-wxweb {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(11, 222, 110);
  }

  .login-wxweb:before {
    border-color: rgb(11, 222, 110);
  }

  .am-button-icon:before {
    font-size: 25px;
  }

  .am-button-primary::before {
    display: none;
  }
`;

const WechatBtn = styled(Button)`
  width: 70%;
  background-color: rgb(11, 222, 110);
  color: white;
  border: none;
`;
