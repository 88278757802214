import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { THEME, LOCAL_URL } from "../config";

// const VIEW_MODE_THRETHOLD = THEME.size.BROWSER_MODE_THRESHOLD;
const CONTAINER_HEIGHT = THEME.size.BREAD_CRUMBS_HEIGHT;
const HEADER_BAR_HEIGHT = THEME.size.HEADER_BAR_HEIGHT;

const Container = styled.div`
  /* border: 1px solid #ddd; */
  position: absolute;
  top: ${(props) => props.top || HEADER_BAR_HEIGHT};

  height: ${CONTAINER_HEIGHT};
  display: inline-block;
  font-family: Roboto;
  font-size: 14px;
`;

const CrumbItem = styled.div`
  display: inline-block;
  & span {
    color: ${(props) => props.color || "#fff"};
  }
  & span.slash {
    color: #afafaf;
  }
  & a {
    text-decoration: none;
    color: ${(props) => props.color || "#fff"};
  }
`;

interface CrumbItemProps {
  label: string;
  url?: string;
  color?: string;
  onClick?: () => void;
}
interface BreadCrumbsProps {
  top?: string;
  items: Array<CrumbItemProps>;
}
export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ items, top }) => {
  return (
    <Container top={top} className="debugx">
      {items.map((item, index) => (
        <CrumbItem key={index} {...item}>
          {index === 0 ? "" : <span className="slash">&nbsp;/&nbsp;</span>}
          {!item.url ? (
            <span onClick={item.onClick}>{item.label}</span>
          ) : (
            <Link to={item.url}>{item.label}</Link>
          )}
        </CrumbItem>
      ))}
    </Container>
  );
};
