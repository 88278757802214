import {getAnalytics, logEvent, setUserProperties} from "firebase/analytics";

export const setEventLog = (eventName: string, meta: any) => {
  if (process.env.NODE_ENV === 'production') {
    const analytics = getAnalytics();
    logEvent(analytics, eventName, meta);
  }
}

export const setUser = (meta: any) => {
  if (process.env.NODE_ENV === 'production') {
    const analytics = getAnalytics();
    setUserProperties(analytics, meta);
  }
}

export const setScreen = (screenName: string) => {
  if (process.env.NODE_ENV === 'production') {
    const analytics = getAnalytics();
    logEvent(analytics, 'screen_view', {
      firebase_screen: screenName,
      firebase_screen_class: screenName
    });
  }

}
