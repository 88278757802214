import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Segment, Divider, Button, Quantity } from '../components';
import { THEME } from '../config';

/**
  Copy from components

 */

const CSIZE = 18;
const BG_COLOR = THEME.color.primary.variant;
const HOLE_COLOR = 'transparent';
const CardContainer = styled.div`
  width: 100%;

  background: ${`
      radial-gradient(circle at bottom right, ${HOLE_COLOR} ${CSIZE}px, ${BG_COLOR} ${CSIZE+1}px) top,
      radial-gradient(circle at top right,    ${HOLE_COLOR} ${CSIZE}px, ${BG_COLOR} ${CSIZE+1}px) bottom;
    `};
  background-size: 100% 30%, 100% 70%;
  background-repeat: no-repeat;
  border-radius: 20px;
`;

const TSIZE = 12;
const Ticket = styled.div`
  width: 100%;

  background: ${(props) => `
    radial-gradient(circle at top right,     ${HOLE_COLOR} ${TSIZE}px, ${BG_COLOR} ${TSIZE+1}px) top left,
    radial-gradient(circle at top left,      ${HOLE_COLOR} ${TSIZE}px, ${BG_COLOR} ${TSIZE+1}px) top right,
    radial-gradient(circle at bottom right,  ${HOLE_COLOR} ${TSIZE}px, ${BG_COLOR} ${TSIZE+1}px) bottom left,
    radial-gradient(circle at bottom left,   ${HOLE_COLOR} ${TSIZE}px, ${BG_COLOR} ${TSIZE+1}px) bottom right;
  `};
  background-size: 60% 50%, 40% 50%, 60% 50%, 40% 50%;
  background-repeat: no-repeat;
  border-radius: .5rem;

  position: relative;
`;
export const TicketDash = styled.div`
  position: absolute;
  height: ${`calc(100% - ${2 * (TSIZE + 6)}px)`};
  left: calc(60% - 1px);
  top: 50%;
  transform: translateY(-50%);
  border-left: 2px dashed #e8d6bd;
`;
export default function TestingPage () { 

  const [quantity, setQuantity] = useState(1);

  return (
    <Segment>
      <Link to="/">Home</Link>

      <Divider margin="1rem" />
      <Quantity value={quantity} onChange={setQuantity} />

      <Divider margin="1rem" />
      <Quantity value={quantity} onChange={setQuantity} limit={3} />
      
      <Divider margin="1rem" />


      <CardContainer>
        <div style={{height: '200px'}} />
      </CardContainer>

    </Segment>
  )
}
