import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7777777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
`

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 10rem;
  padding: 32px 0 18px;
  font-size: 1rem;
  color: white;
  background-color: rgba(30, 30, 30, 0.75);
  border-radius: 8px;

  & button {
    cursor: pointer;
    border: none;
  }

  & > svg {
  }
  &.info > svg {
  }
  &.warning > svg {
  }
  &.error > svg {
  }
  &.success > svg {
  }
`
