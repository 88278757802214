import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;

  & ~ & {
    margin: 1rem 0;
  }
  & label {
    text-align: right;
    min-width: 20%;
    flex: 0;
  }
  & input, textarea {
    flex: 1;
    padding: .2rem;
  }
`

interface IInputFieldProps
extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  name: string;
  type?: string;
  value: string;
  onChange: (e: any) => void;
}

export const InputField: React.FC<IInputFieldProps> = ({label, name, type, value, onChange}) => {
  return (
    <Wrapper className="debugx">
      <label htmlFor={name}>{label}</label>
      { type === 'textarea' ? 
        <textarea rows={7} name={name} value={value} onChange={onChange} />
        :
        <input name={name} type={type || "text"} value={value} onChange={onChange}/>
      }
    </Wrapper>
  )
}
