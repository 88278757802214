import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {THEME} from "../../../config";
import {IconCloseCircle, IconKBArrowL, IconSearch} from "../../../components";

const Container = styled.div`
  width: 100%;
  height: 36px;
  background: rgba(0, 0, 0, 0.09);;
  border-radius: 18px;
  border: ${(props) => props.border || 'none'};

  font-family: Roboto;
  font-size: 12px;

  position: relative;

  & div {
    /* border: 1px solid #f00; */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  & div.left {
    height: 18px;
    left: 8px;
  }
  & div.right {
    height: 16px;
    right: 12px;
  }
  & div svg {
    height: 100%;
    fill: ${(props => props.fill || 'rgba(60,60,67,.6)')};
  }
`;

const InputStyled = styled.input`
  width: 100%;
  height: 100%;
  text-indent: 1em;
  border: none;
  background-color: transparent;
  color: ${THEME.color.gray['#0-087']};
  padding: .75em .5em .75em 16px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;

  &:focus {
    outline: none;
  }
`;

interface InputFieldProps {
  onSearch?: () => void;
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  icon?: 'back' | 'search';
  [propName: string]: any
}
export const InputSearch: React.FC<InputFieldProps>  = ({onSearch, onChange, value, placeholder, icon, ...restProps}) => {
  const toSearch = () => {
    if (!!onSearch) onSearch();
  }
  const onKeyUp = (e) => {
    if (e.key !== "Enter") return;
    // console.log('Enter')
    toSearch();
  }
  return (
    <Container {...restProps} >
      <div className="left" onClick={toSearch}>
      { (icon === 'back') ? <IconKBArrowL /> : <IconSearch /> }
      </div>
      <InputStyled type='text' placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} onKeyUp={onKeyUp} />
      { !value ? null : <div className="right" onClick={() => onChange('')}><IconCloseCircle /></div> }
    </Container>
  )
}
