import React from "react";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import WhiteSpace from "../../components/WhiteSpace";
import {THEME} from "../../config";

const width = (window.innerWidth > 768 ? 768 : window.innerWidth);
const height = width / 375 * 812

function CustomerSupport() {

  return (
    <PageLayout pageName="no nav aboutPage">
      <Header title="联系客服"/>
      <Container>
        <WhiteSpace height={36}/>
        <div className='intro'>请长按以下二维码，添加大吉客服微信。</div>
        <img className='qrcode' src="https://res.cloudinary.com/shenmanz/image/upload/v1661151116/tuangou/icon/shenma.png" alt="shenma"/>
        <WhiteSpace height={64}/>
        <WhiteSpace height={64}/>
      </Container>
    </PageLayout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 80px;
    height: 80px;
  }

  .title {
    margin-top: 8px;
    font-family: 'PingFang SC', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  }

  .sub-title {
    margin-top: 4px;
    font-family: 'PingFang SC', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: ${THEME.color.gray["#12-045"]};
  }

  .desc {
    font-family: 'PingFang SC', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    padding: 24px;
    color: ${THEME.color.gray["#11-065"]};
  }

  .carousel{
    width: 100%;
    height: ${height};
    img{
      width: 100%;
    }
  }

  .intro{
    padding: 24px;
    font-family: 'PingFang SC',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;
  }

  .qrcode{
    width: 160px;
    height: 160px;
  }
`

export default CustomerSupport

