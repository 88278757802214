import * as React from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import PageLayout from "../Global/PageLayout";
import EmptyView from "../../components/EmptyView";
import { Button } from "../../components";
import { useHistory } from "react-router-dom";
import { LOCAL_URL, THEME } from "../../config";
import { EntityContext } from "../../context";
import { Radio } from "../../forms/Radio";
import iconEdit from "../../svg/icon_edit.svg";
import iconRubbishBin from "../../svg/icon_rubbish.svg";
import iconAdd from "../../svg/icon_add.svg";
import { useContext, useEffect } from "react";
import { useRouter } from "../../hooks/router";

export interface IAddressProps {}

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const EmptyContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 56px);
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
`;

const StyledAddressCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  .formattedAddress {
    color: ${THEME.color.gray["#10-085"]};
    font-size: 15px;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .extend-info {
    font-size: 12px;
    color: ${THEME.color.gray["#12-045"]};
    margin-top: 6px;
  }
`;

const AddButton = styled.div`
  background-color: #fff;
  height: 48px;
  border: 1px solid #cc1543;
  margin-left: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${THEME.color.gray["#12-045"]};

  div {
    margin-left: 8px;
  }

  margin-top: 16px;
`;

export const AddressCard = (props: any) => {
  const { address, selected, onClick, onDeleteClick, disable } = props;
  const { id, contactName, formattedAddress, extendInfo, telephone, notes, addressName } =
    address;

  const history = useHistory();

  return (
    <StyledAddressCard
      onClick={() => {
        onClick(address);
      }}
    >
      <div className="flex-row">
        <div className="formattedAddress">{formattedAddress}</div>
        <Radio value={selected} />
      </div>
      {addressName && (<div className="extend-info">{addressName}</div>)}
      <div className="extend-info">{extendInfo}</div>
      <div className="extend-info">{notes}</div>
      <div className="flex-row">
        <div className="extend-info">
          {`${contactName ? contactName : ""} ${telephone}`}
        </div>
        {!disable && (
          <div className="flex-row">
            <img
              src={iconEdit}
              onClick={() => {
                if (address.type === "PICKUP") {
                  history.push(LOCAL_URL["ADD_PICK_UP_POINTER"], { address });
                } else {
                  history.push(LOCAL_URL["ADD_ADDRESS"], { address });
                }
              }}
            />
            <div style={{ marginLeft: 8 }} />
            <img
              src={iconRubbishBin}
              onClick={() => {
                onDeleteClick(id);
              }}
            />
          </div>
        )}
      </div>
    </StyledAddressCard>
  );
};

export default function OrderAddressSelectPage(props: IAddressProps) {
  const history = useHistory<any>();
  const type: "DELIVERY" | "PICKUP" = history.location?.state?.type || "PICKUP";
  const groupId = history.location?.state?.groupId;
  let title;
  let url;

  if (type === "DELIVERY") {
    title = "收货地址";
    url = LOCAL_URL["ADD_ADDRESS"];
  } else {
    title = "自提点";
  }

  const {
    model: entityModel,
    query,
    remove,
    replace,
  } = React.useContext(EntityContext);
  const addressData = entityModel["ADDRESS"].response?.data;

  React.useEffect(() => {
    const params: any = {
      type,
    };
    if (groupId) {
      params.groupId = groupId;
    }
    query("ADDRESS", params);
  }, []);

  // 只有一个地址时，选择默认地址
  useEffect(() => {
    if (entityModel["ADDRESS"].response?.data) {
      const address = entityModel["ADDRESS"].response?.data;
      if (address && address.length === 1) {
        setSelectedAddress(address[0]);
      }
      if (address && address.length > 1) {
        if (history.location.state.selectedAddress) {
          const targetAddress = address.filter(
            (item) => item.id === history.location.state.selectedAddress.id
          );
          if (targetAddress && targetAddress.length > 0) {
            setSelectedAddress(targetAddress[0]);
          }
        }
      }
    }
  }, [entityModel["ADDRESS"].response?.data]);

  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const onConfirm = () => {
    if (type === "DELIVERY") {
      replace("PLACE_ORDER_GLOBAL_DATA", {
        address: selectedAddress,
      });
    } else {
      replace("PLACE_ORDER_GLOBAL_DATA", {
        pickupAddress: selectedAddress,
      });
    }

    history.goBack();
  };

  return (
    <PageLayout pageName="myAddress">
      <Header title={title} />
      <Container>
        {addressData && addressData.length > 0 ? (
          <AddressContainer>
            {addressData.map((address) => (
              <AddressCard
                key={address.id}
                disable={type === "PICKUP"}
                address={address}
                selected={address.id === selectedAddress?.id}
                onClick={(address) => {
                  setSelectedAddress(address);
                }}
                onDeleteClick={async (id) => {
                  await remove("ADDRESS", { id });
                  query("ADDRESS", { type });
                }}
              />
            ))}
            {type === "DELIVERY" && (
              <AddButton onClick={() => history.push(url)}>
                <img src={iconAdd} />
                <div>添加新{title}</div>
              </AddButton>
            )}
            <FloatBottom>
              <Button fullWidth onClick={onConfirm}>
                确定
              </Button>
            </FloatBottom>
          </AddressContainer>
        ) : (
          <EmptyContainer>
            <EmptyView desc={`还没有添加${title}`} />
            <div style={{ height: 72 }} />
            {type === "DELIVERY" && (
              <Button onClick={() => history.push(url)}>添加{title}</Button>
            )}
          </EmptyContainer>
        )}
      </Container>
    </PageLayout>
  );
}

const Container = styled.div`
  //padding-left: 16px;
  //padding-right: 16px;
`;

const FloatBottom = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: row;
  height: 64px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.09);
`;
