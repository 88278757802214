import dayjs from 'dayjs'

// 'Asia/Shanghai|PRC|CTS' 'Pacific/Auckland|NZ' 'GMT'
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const duration = require('dayjs/plugin/duration')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

// console.log((dayjs as any).tz.guess())
// const m:any = dayjs();
// console.log('##### m ####')
// console.log(m)
// console.log(m.format())
// console.log(m.utc().format(), m.utc().format('YYYY-MM-DD HH:mm:ss'))
// console.log(m.tz('NZ').format(), m.tz('NZ').format('YYYY-MM-DD HH:mm:ss'))

// const m:any = (dayjs as any).tz('2021-1-1 00:00:00', 'NZ');
// console.log('##### m ####')
// console.log(m)
// console.log(m.format())
// console.log(m.utc().format(), m.utc().format('YYYY-MM-DD HH:mm:ss'), m.utc().endOf('d').format('YYYY-MM-DD HH:mm:ss'))
// console.log(m.tz('Asia/Shanghai').format(), m.tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss'), m.tz('Asia/Shanghai').endOf('d').format('YYYY-MM-DD HH:mm:ss'))

export const newMoment        = (dt?: string) => dayjs(dt);
export const parseUTCDateTime = (dt?: string) => !dt ? dayjs() : (dayjs as any).tz(dt, 'UTC');
export const parseUTC2Local   = (dt?: string) => newMoment(parseUTCDateTime(dt).$d);
export const calculateDuration= (mLast, mFirst) => (dayjs as any).duration(mLast.diff(mFirst));
