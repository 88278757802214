import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter as Router, Link, Redirect, Route,} from "react-router-dom";
import GlobalStyle from "./global.styled";
import "./App.css";
import {LOCAL_URL, SYS_APP_VERSION, THEME} from "./config";
import {getParams, isEmpty, isWeiXinBrowser, paramsCache, requestGet, thisApp,} from "./lib";
import {EntityContext, EntityContextProvider, UserContext, UserContextProvider, OrdersContextProvider } from "./context";
import { useWechatShareStore } from './pages/Wechat/store'
import ExceptionPage from "./pages/Exception";
import CouponDetailInPromotionPage from "./pages/Promotion/CouponDetail";
import PromotionDetailPage from "./pages/Promotion/Details";
import ShoppingDetailPage from "./pages/shopping/detailPage";
import UISpecPage from "./pages/UISpec";
import TestingPage from "./pages/Testing";
import MerchantDetailWithBG from "./pages/Merchant/detailWithBG";

import PromotionListPage from "./pages/Promotion";
import PromotionCreateNewPage from "./pages/Promotion/CreateNew";
import PromotionEditingPage from "./pages/Promotion/EditingPage";
import PreferenceListPage from "./pages/Preference";
import PreferenceSelectPage from "./pages/Preference/Select";
import PreferenceDetailsPage from "./pages/Preference/Details";
import CouponListPage from "./pages/Coupon";
import CouponDetailsPage from "./pages/Coupon/Details";
import CouponRedeemPage from "./pages/Coupon/Redeem";

import {PrivateRoutes} from "./Private";
import {createTheme, ThemeProvider} from "@mui/material";
import WechatLogin from "./pages/Auth/WechatLogin";
import Warning from "./pages/Warning";
import ClearPage from "./pages/Manage/Clear";
import CustomerServicePage from "./pages/PersonalCenter/customerService";
import GroupBuyDetailPage from "./pages/CreateGroupBuy/detail";
import {isiOS} from "./utils/common";
import mitt, {EVENT} from './utils/Event'
import UploadReceiptPage from "./pages/Payment/UploadReceipt";
import {useRouter} from "./hooks/router";
import AboutPage from "./pages/PersonalCenter/about";
import CustomerSupport from "./pages/Common/customerSupport";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

const wx = require("weixin-js-sdk");


const params: any = getParams(window.location.href);
if (!isEmpty(params)) {
  paramsCache.set(JSON.stringify(params));
}

// @ts-ignore
window.entryUrl = window.location.href.split("#")[0];

const Root = () => {
  return (
    <div className="App">
      <header className="App-header">
        <Link
          style={{marginTop: "1em"}}
          className="App-link"
          to={LOCAL_URL["WELCOME"]}
        >
          Welcome
        </Link>
        <Link
          style={{marginTop: "1em"}}
          className="App-link"
          to={LOCAL_URL["UI_SPEC"]}
        >
          UI Spec
        </Link>
        <Link
          style={{marginTop: "1em"}}
          className="App-link"
          to={LOCAL_URL["TESTING"]}
        >
          Testing
        </Link>
        <Link
          style={{marginTop: "1em"}}
          className="App-link"
          to={LOCAL_URL["PROMOTION_LIST"]}
        >
          Promotion Page
        </Link>
        <Link
          style={{marginTop: "1em"}}
          className="App-link"
          to={LOCAL_URL["MERCHANT_DETAIL"] + `?id=14`}
        >
          Merchant Details Page
        </Link>
        <Link
          style={{marginTop: "1em"}}
          className="App-link"
          to={LOCAL_URL["COUPON_REDEEM"]}
        >
          Coupon Redeem Page
        </Link>
        <Link
            style={{marginTop: "1em"}}
            className="App-link"
            to={LOCAL_URL["PAYMENT_RECEIPT"]}
        >
          Upload Receipt
        </Link>
      </header>
    </div>
  );
};

const AppRoute = () => {
  const {
    model: entityModel,
    replace,
    query,
    refreshGlobalDependence,
  } = useContext(EntityContext);
  const {model: userModel, isAnonymous, location} = useContext(UserContext);
  // const statistics = useStatistics();
  useEffect(() => {
    // if (!entityModel["HOME_PAGE_DATA"]?.ready) query("HOME_PAGE_DATA");
    // if (!entityModel["ACTIVITY_LOG"]?.ready) query("ACTIVITY_LOG");

    if (!isAnonymous) {
      refreshGlobalDependence?.();
    }
  }, [userModel]);

  // useEffect(() => {
  //   replace("GLOBAL_DATA", {
  //     data: refreshGlobalStores(
  //       entityModel["HOME_PAGE_DATA"].response?.data,
  //       userModel?.location?.position,
  //       statistics
  //     ),
  //   });
  // }, [
  //   entityModel["HOME_PAGE_DATA"].response,
  //   userModel?.location?.position,
  //   statistics.isValid,
  // ]);
  const route = useRouter()
  const publicRoute = new Set([
    LOCAL_URL["PAYMENT_UPLOAD"],
    LOCAL_URL["GROUP_BUY_DETAIL"]
  ])

  const shallRedirect2Warning = (!isWeiXinBrowser()) && (!publicRoute.has(route.pathname)) &&
                                (process.env.NODE_ENV === "production") &&
                                ( (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF?.indexOf('integration') ?? -1) < 0 )
                                ;
  return (
    <>
      { shallRedirect2Warning && (
        <Redirect to={LOCAL_URL.WARNING} />
      )}

      <Route path={LOCAL_URL["PROMOTION_LIST"]}       exact component={PromotionListPage}/>
      <Route path={LOCAL_URL["PROMOTION_CREATE_NEW"]} exact component={PromotionCreateNewPage}/>
      <Route path={LOCAL_URL["PROMOTION_EDITING"]} exact component={PromotionEditingPage}/>
      <Route path={LOCAL_URL["PROMOTION_DETAIL"]}     exact component={PromotionDetailPage}/>
      <Route path={LOCAL_URL["PREFERENCE_LIST"]}      exact component={PreferenceListPage}/>
      <Route path={LOCAL_URL["PREFERENCE_SELECT"]}    exact component={PreferenceSelectPage}/>
      <Route path={LOCAL_URL["PREFERENCE_DETAILS"]}   exact component={PreferenceDetailsPage}/>
      <Route path={LOCAL_URL["COUPON_LIST"]}          exact component={CouponListPage}/>
      <Route path={LOCAL_URL["COUPON_DETAILS"]}       exact component={CouponDetailsPage}/>
      <Route path={LOCAL_URL["COUPON_DETAILS_IN_PROMOTION"]}       exact component={CouponDetailInPromotionPage}/>
      <Route path={LOCAL_URL["COUPON_REDEEM"]}        exact component={CouponRedeemPage}/>

      <Route path={LOCAL_URL["WARNING"]} exact component={Warning}/>
      <Route
        path={LOCAL_URL["MERCHANT_DETAIL"]}
        exact
        component={MerchantDetailWithBG}
      />
      <Route
        path={LOCAL_URL["GROUP_BUY_DETAIL"]}
        component={GroupBuyDetailPage}
      />
      <Route
        path={LOCAL_URL["SHOPPING_DETAIL"]}
        component={ShoppingDetailPage}
      />
      {/* <Route path={`${LOCAL_URL["WELCOME"]}/:action`}             component={WelcomePage} />

      <Route path={`${LOCAL_URL["PUBLIC_CHANNEL_SLUG"]}/:slug`}   component={ChannelSlugPage} /> */}

      {/* <Route path={`${LOCAL_URL["MEMBERSHIP"]}`}                  component={MembershipPage} /> */}
      {/* <Route path={`${LOCAL_URL["COFFEE_PASS"]}`}                 component={CoffeePassPage} /> */}
      {/* <Route path={`${LOCAL_URL["FAQ"]}`}                         component={FAQ} />
      <Route path={`${LOCAL_URL["ABOUT"]}`}                       component={About} /> */}

      <Route path={LOCAL_URL["PRIVATE"]} component={PrivateRoutes}/>
      <Route path={LOCAL_URL["USER_LOGIN"]} component={WechatLogin}/>
      <Route path={LOCAL_URL["PAYMENT_UPLOAD"]} component={UploadReceiptPage} />
      <Route path={LOCAL_URL["CLEAR"]} component={ClearPage}/>
      <Route path={LOCAL_URL["ABOUT"]} component={AboutPage}/>
      <Route path={LOCAL_URL["CUSTOMER_SUPPORT"]} component={CustomerSupport}/>
      <Route
        path={LOCAL_URL["CUSTOMER_SERVICE"]}
        component={CustomerServicePage}
      />

      {/* <Route path={`${LOCAL_URL["PRIVACY_POLICY"]}`} exact        component={() => <Redirect to={{ pathname: `${LOCAL_URL["DOC_CONTENT"]}/privacy` }} />} />
      <Route path={`${LOCAL_URL["DOC_CONTENT"]}/:key`}            component={LazyDocContentPage} /> */}

      {/* <Route path={LOCAL_URL["HOME"]}           exact             component={() => <Redirect to={{ pathname: LOCAL_URL["WELCOME"] }} />} /> */}
      <Route
        path={`${LOCAL_URL["EXCEPTION"]}`}
        exact
        component={ExceptionPage}
      />
      <Route
        path={`${LOCAL_URL["EXCEPTION"]}/:type`}
        component={ExceptionPage}
      />

      <Route path={LOCAL_URL["UI_SPEC"]} component={UISpecPage}/>
      <Route path={LOCAL_URL["TESTING"]} component={TestingPage}/>

      {/* <Route path={`${LOCAL_URL['PREMIUM_SUPPORT']}/:email`}      component={PremiumSupportPage} />
      <Route path={LOCAL_URL['PREMIUM_SUPPORT']} exact            component={PremiumSupportPage} />
      <Route path={LOCAL_URL['CUSTOMER_SUPPORT']} exact           component={CustomerSupportPage} /> */}

      <Route
        path={LOCAL_URL["ROOT"]}
        exact
        component={
          process.env.NODE_ENV === "development"
            ? Root
            : () => <Redirect to={{pathname: LOCAL_URL["WELCOME"]}}/>
        }
      />

      {/* <Route path={LOCAL_URL["PARTNERS"]}                         component={() => {
        window.location.replace("https://partners.lovinlocal.com");
        return null;
      }} />

      <Route path={LOCAL_URL['NOTICE_ABOUT_WECHAT']} exact        component={NoticeWechatPage} /> */}
    </>
  );
};

export default function App() {
  const setResult = useWechatShareStore((state:any) => state.setResult)
  const [shareTitle, setShareTitle] = useState('大吉快团，新西兰最好用的团购工具');
  const [shareContent, setShareContent] = useState('无门槛，一分钟轻松开店。商品发布、群发分享、订单管理一步到位');
  const [shareUrl, setShareUrl] = useState('');
  const [shareImage, setShareImage] = useState('https://res.cloudinary.com/shenmanz/image/upload/v1686552217/tuangou/icon/daji2.jpg');
  useEffect(() => {
    console.log(SYS_APP_VERSION.version);
    console.log(SYS_APP_VERSION.build);
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: THEME.color.primary.default,
      },
      secondary: {
        main: THEME.color.secondary.default,
      },
    },
  });

  useEffect(() => {
    if (isWeiXinBrowser()) {
      document.addEventListener(
        'WeixinJSBridgeReady',
        wxReady,
        false
      );
    }
    mitt.on(EVENT.SET_SHARE_CONTENT, ({url, title, content, image}:{
      url: string;
      title: string;
      content: string;
      image: string;
    }) => {
      console.log('设置分享：', url, title, content, image)
      setResult('设置分享')
      setShareContent(content)
      setShareTitle(title)
      setShareImage(image)
      setShareUrl(url)
    });
    return () => {
      document.removeEventListener('WeixinJSBridgeReady', wxReady)
      mitt.off(EVENT.SET_SHARE_CONTENT)
    }
  }, [])

  useEffect(()=>{
    if (shareUrl){
      wxReady()
    }
  }, [shareUrl])

  const wxReady = async () => {
    if (!isWeiXinBrowser()) {
      return
    }
    //更新微信 web 分享
    const {data} = await requestGet("jssdk", {
      // @ts-ignore
      url: isiOS ? window.entryUrl : window.location.href.split("#")[0],
    });
    if (data) {
      const config = data
      wx.config(config);
      const apiList = config['jsApiList'];
      wx.ready(()=>{
        wx.checkJsApi({
          jsApiList: apiList, // 需要检测的JS接口列表,
          success: (res) => {
            const {checkResult} = res;
            if (checkResult.updateAppMessageShareData) {
              //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
              wx.updateAppMessageShareData({
                title: shareTitle, // 分享标题
                desc: shareContent || '新西兰最大中文分类信息网站', // 分享描述
                link: shareUrl || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImage, // 分享图标
                success: () => {
                  // 设置成功
                  setResult('设置成功（1）')
                }
              });
            } else {
              // 即将废弃接口
              wx.onMenuShareAppMessage({
                title: shareTitle, // 分享标题
                desc: shareContent || '新西兰最大中文分类信息网站', // 分享描述
                link: shareUrl || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImage // 分享图标
              });
            }
            if (checkResult.updateTimelineShareData) {
              //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
              wx.updateTimelineShareData({
                title: shareTitle, // 分享标题
                link: shareUrl || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImage, // 分享图标
                success: function () {
                  // 设置成功
                  setResult('设置成功（2）')
                }
              });
            } else {
              // 即将废弃接口
              wx.onMenuShareTimeline({
                title: shareTitle, // 分享标题
                link: shareUrl || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImage // 分享图标
              });
            }
          },
        });
      });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <EntityContextProvider>
          <OrdersContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router ref={(ref) => (thisApp.router = ref)}>
                <GlobalStyle/>
                <Route component={AppRoute}/>
              </Router>
            </LocalizationProvider>
          </OrdersContextProvider>
        </EntityContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  );
}
